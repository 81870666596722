import React from 'react'
import Form from "react-bootstrap/Form"
import {useFormContext} from "react-hook-form"
import NotifyMeByForm from "./NotifyMeByForm"
import NotifyAreaForm from "./NotifyAreaForm"
import NotifyTimeRangeForm from "./NotifyTimeRangeForm"



const  AvlShiftNotificationsSection = ({data: userProfileData}) => {

    // console.log("userProfileData.notification = " + JSON.stringify(userProfileData.notification))
    const { register} = useFormContext() // retrieve all hook methods

    return (
        <div>

            <Form.Group>
                <div>
                    <label className="hsCheckbox">
                        <input type="checkbox"
                               name='notfyAsOptIn'
                               defaultChecked = {userProfileData.notification.notfyAsOptIn}
                               ref={register}


                        />
                        <span className="hsCheckmark"/>

                    </label>
                </div>

                <div className="hsCheckboxLabel boldFont">
                    <span>Notify me about Available Shifts</span>
                </div>

            </Form.Group>

            <NotifyMeByForm  data={userProfileData.notification}/>
            <NotifyAreaForm  data={userProfileData}/>
            <NotifyTimeRangeForm data={userProfileData.notification}/>



        </div>
    )


}

export default AvlShiftNotificationsSection