import React from "react"
import If from "components/If"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import VerifyEmailOrPhone, {VERIFY_TYPE} from "./VerifyEmailOrPhone"
import {useFormContext} from "react-hook-form"


const OtherEmailVerifyForm = ({
                                  userProfileData, name, emailNotifyMethod, isOtherEmailInitiallyVerified,
                                  isOtherEmailCurrentlyVerified, handleVerifyButtonClick,
                                  showVerifyEmailDialog, setShowVerifyEmailDialog, setEmailVerified
                              }) => {

    const {register, getValues, errors, setError} = useFormContext()


    //This validation should only be triggered at "Verify" button click, not at User Profile "Submit"
    function validateEmailOnTrigger(){
        let emailInputValue = getValues(name)
        let re = /^([\w-]+(?:\.[\w-]+)*)@([\w-]+(?:\.[\w-]+)*)$/
        let emailEmptyMsg = 'Email is required'
        let emailFormatErrMsg = 'Email must be of the form "name@host", where name and host each contain one or more letters, numbers, hyphens, underscores, and periods, and periods cannot be the first or last character in either the name or host.'

        if(emailInputValue && re.test(emailInputValue)){
            return true
        }
        else{
            let emailErrMsg =  (!emailInputValue) ? emailEmptyMsg : emailFormatErrMsg
            setError(name, {
                type: "manual",
                message: emailErrMsg
            })
            return false
        }


    }


    return (
        <>
            <Form.Group>
                <Form.Control type="text"
                              name={name}
                              inputMode="email"
                              maxLength="254"
                              ref={register({
                                  // required: 'Email is required',
                                  // validate: validateEmail
                              })}
                              defaultValue={userProfileData.notification.notfyEmailOther}
                              onChange={() => {
                                  let emailInputValue = getValues(name)
                                  // console.log("emailInputValue =" + emailInputValue)
                                  if (isOtherEmailInitiallyVerified()) {
                                      if (userProfileData.notification.notfyEmailOther !== emailInputValue) {
                                          setEmailVerified(false)
                                      } else if (userProfileData.notification.notfyEmailOther === emailInputValue) {
                                          setEmailVerified(true)
                                      }
                                  }
                              }}/>
            </Form.Group>
            {errors[name] && <p className="error">{errors[name].message}</p>}

            {isOtherEmailCurrentlyVerified() &&
            (<Form.Group className="floatRight"><span className="formLabel">
                        <i className="fas fa-check"></i>&nbsp;Verified</span></Form.Group>)}

            {!isOtherEmailCurrentlyVerified() && (
                <Form.Group className="floatRight">
                    <Button variant="primary"
                            onClick={() => {
                                //validate this email text entry using react-hook-form. Only upon success, bring up "verify" modal dialog
                                //let res = await trigger(name)
                                let res = validateEmailOnTrigger()
                                // console.log("validate email result = " + (res))
                                if (res) {
                                    handleVerifyButtonClick()
                                }
                            }}

                    >Verify...</Button>
                </Form.Group>

            )
            }

            <If condition={() => {
                return showVerifyEmailDialog
            }}>
                <VerifyEmailOrPhone
                    verifyType={VERIFY_TYPE.email}
                    email={getValues(name)}
                    notfyEmailTo={emailNotifyMethod}
                    setEmailVerified={setEmailVerified}
                    close={() => {
                        setShowVerifyEmailDialog(false)
                    }}/>
            </If>

        </>
    )
}

export default OtherEmailVerifyForm