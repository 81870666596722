// Enhancing logging by appending datetime and allowing for capture of logging

import {formatDateSimple} from "./date";

export const LOGLEVEL = {
    ERROR: {level: 0, label: 'ERROR'},
    WARNING: {level: 1, label: 'WARN'},
    INFO: {level: 2, label: 'INFO'},
    DEBUG: {level: 3, label: 'DEBUG'},
    TRACE: {level: 4, label: 'TRACE'},
}

function ascending(key1, key2) {
    if (key1 < key2) return -1;
    else if (key1 > key2) return +1;
    else return 0;
}

function descending(key1, key2) {
    if (key1 > key2) return -1;
    else if (key1 < key2) return +1;
    else return 0;
}

// Purge logs more than an hour old
//     const purgeLogsBefore = datetime.getTime() - (1000 * 60 * 60)
const logsPurgeBefore = (datetime) => {
    let keys = Object.keys(sessionStorage).sort(ascending);
    for (let key of keys) {
        if (key.startsWith("log.")) {
            const logDateTime = parseInt(key.substring(4))
            if (logDateTime >= datetime) break
            sessionStorage.removeItem(key)
        }
    }
}

// Purge logs to limit count of entries to 'numberToRetain'
const logsPurgeFIFO = (numberToRetain) => {
    let keys = Object.keys(sessionStorage).sort(descending);
    if (keys.length > numberToRetain) {
        keys = keys.splice(numberToRetain)
        for (let key of keys) {
            sessionStorage.removeItem(key)
        }
    }
}

const logsWrite = (datetime, args) => {
    //try {
    sessionStorage.setItem("log." + datetime.getTime(), args.join(" "))
    // } catch (error) {
    logsPurgeFIFO(75)
    //     logsWrite(datetime,args)
    // }
}

export function logsRead() {
    const logs = []
    let keys = Object.keys(sessionStorage).sort(ascending)
    for (let key of keys) {
        if (key.startsWith("log.")) {
            logs.push(sessionStorage.getItem(key))
        }
    }
    return logs
}

function prefixLogEntry(datetime, loglevel, args) {
    args.unshift(loglevel.label)
    args.unshift(formatDateSimple(datetime, "YYYY.MM.DD HH.mm.ss.SSS"))
    return args
}

export function logsInit() {
    // Reset logging in sessionStorage
    let keys = Object.keys(sessionStorage);
    for (let key of keys) {
        if (key.startsWith("log.")) sessionStorage.removeItem(key)
    }

    console.stdlog = console.log.bind(console)
    console.stderror = console.error.bind(console)
    console.stdwarn = console.warn.bind(console)
    console.stdlog = console.log.bind(console)
    console.stdinfo = console.info.bind(console)
    console.stddebug = console.debug.bind(console)
    console.stdtrace = console.trace.bind(console)

    console.log = function () {
        const datetime = new Date()
        let args = prefixLogEntry(datetime, LOGLEVEL.INFO, Array.prototype.slice.call(arguments))
        logsWrite(datetime, args)
        console.stdlog.apply(console, args);
    }
    console.error = function () {
        const datetime = new Date()
        let args = prefixLogEntry(datetime, LOGLEVEL.ERROR, Array.prototype.slice.call(arguments))
        logsWrite(datetime, args)
        console.stderror.apply(console, args);
    }
    console.warn = function () {
        const datetime = new Date()
        let args = prefixLogEntry(datetime, LOGLEVEL.WARNING, Array.prototype.slice.call(arguments))
        logsWrite(datetime, args)
        console.stdwarn.apply(console, args);
    }
    console.debug = function () {
        const datetime = new Date()
        let args = prefixLogEntry(datetime, LOGLEVEL.DEBUG, Array.prototype.slice.call(arguments))
        logsWrite(datetime, args)
        console.stddebug.apply(console, args);
    }
    console.trace = function () {
        const datetime = new Date()
        let args = prefixLogEntry(datetime, LOGLEVEL.TRACE, Array.prototype.slice.call(arguments))
        logsWrite(datetime, args)
        console.stdtrace.apply(console, args);
    }

    console.debug("ENHANCED LOGGING HAS BEEN ENABLED")
}