import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import {useSignOutMutation, useStatusMutation, useUserQuery} from 'components/UserProfile/UserProfileGQL'
import {isEMP, isSA, ROLE} from "components/Role";
import {Route, Switch,HashRouter} from "react-router-dom";
import Features, {getDefaultFeature, getFeature, getFeatureRole, getFeatureURL, getInitPageURL} from "features";
import View from "components/View";
import {processUserQueryResult} from "./components/UserProfile/UserProfileData";
import {redirectToSignin, appUrl} from "./helpers/http";
import {ActionDefs, findAction, isActionDef} from './actions'
import ServiceStatus from "./helpers/serviceStatus";
import {isEqual} from "./helpers/object";
import {useStoreActions} from "./Store";
import useReferredState from "./helpers/state";
import NetworkStatus from "./helpers/networkStatus";
import ServiceStatusView from "./components/View/ServiceStatusView";
import SplashView from "./components/View/SplashView";
import {useParamsAtStartupQuery, useServerInfoQuery} from "./components/Settings/SettingsGQL";
import {putParamsAtStartupInGlobalScope, putServerInfoInGlobalScope} from "./components/Settings/SettingsData";
import {toggleMenu} from "./components/Menu";
import {getSWInstalledAt, setSWInstalledAt, setSWStatus, SWStatus} from "./helpers/update";
import {BroadcastChannel} from "broadcast-channel";

function App() {
    const actions = useStoreActions()
    const [networkStatusRef, setNetworkStatus] = useReferredState(
        navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE
    )

    console.log("App render; networkStatus is " + networkStatusRef.current.key)

    const handleOffline = (event) => {
        console.log("Handle event: offline")
        console.log("Current networkStatus is " + networkStatusRef.current.key + "; Setting networkStatus to OFFLINE")
        actions.setOnline(false)
        setNetworkStatus(NetworkStatus.OFFLINE)
    }
    const handleOnline = (event) => {
        console.log("Handle event: online")
        console.log("Current networkStatus is " + networkStatusRef.current.key + "; Setting networkStatus to ONLINE")
        actions.setOnline(true)
        setNetworkStatus(NetworkStatus.ONLINE)
    }
    const handleServiceOffline = (event) => {
        console.log("Handle event: service_offline")
        if (networkStatusRef.current === NetworkStatus.ONLINE) {
            console.log("Current networkStatus is " + networkStatusRef.current.key + "; Setting networkStatus to SERVICE_OFFLINE")
            actions.setOnline(false)
            setNetworkStatus(NetworkStatus.SERVICE_OFFLINE)
        }
    }
    const handleServiceLockout = (event) => {
        console.log("Handle event: service_lockout")
        if (networkStatusRef.current === NetworkStatus.ONLINE) {
            console.log("Current networkStatus is " + networkStatusRef.current.key + "; Setting networkStatus to SERVICE_LOCKOUT")
            actions.setOnline(false)
            setNetworkStatus(NetworkStatus.SERVICE_LOCKOUT)
        }
    }
    const handleServiceOnline = (event) => {
        console.log("Handle event: service_online")
        if (networkStatusRef.current !== NetworkStatus.ONLINE) {
            console.log("Current networkStatus is " + networkStatusRef.current.key + "; Setting networkStatus to ONLINE")
            actions.setOnline(true)
            setNetworkStatus(NetworkStatus.ONLINE)
        } else {
            console.log("Current networkStatus is already " + networkStatusRef.current.key)
        }
    }
    const handleBeforeInstallPrompt = (event) => {
        console.log("beforeinstallprompt event fired; cancelling default behavior")
        event.preventDefault()
    }
    /**
     * SW will broadcast a message with status=ACTIVE when SW has been activated.
     *
     *    If this is the first time the SW has been registered there is no need to restart again. Current datetime is
     *    added to localStorage with key=SW-INSTALLED.
     *
     *    The next time a SW is activated we know it is an update since SW-INSTALLED already exist, so we set
     *    localStorage key=SW-STATUS to UPDATED. Each time a view is rendered we check for SW-STATUS = UPDATED and
     *    show the Update ANSOS2Go dialog if found. Tapping Continue removes SW-STATUS from localStorage and sets
     *    SW-UPDATED to the current ISO datetime and relaunches the app.
     *
     * @param event
     */
    const handleMessageFromServiceWorker = (event) => {
        console.log("Received message from SW: "+(event?event.status:"NULL"))
        const installedAt = getSWInstalledAt()
        if (!installedAt) {
            setSWInstalledAt()
        } else {
            setSWStatus(SWStatus.UPDATED)
        }
    }
    useEffect(() => {
        window.addEventListener("offline", handleOffline)
        window.addEventListener("online", handleOnline)
        window.addEventListener("service_offline", handleServiceOffline)
        window.addEventListener("service_online", handleServiceOnline)
        window.addEventListener("service_lockout", handleServiceLockout)
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
        const channel = new BroadcastChannel('sw-messages');
        channel.addEventListener('message', handleMessageFromServiceWorker)
        return () => {
            window.removeEventListener("offline", handleOffline)
            window.removeEventListener("online", handleOnline)
            window.removeEventListener("service_offline", handleServiceOffline)
            window.removeEventListener("service_online", handleServiceOnline)
            window.removeEventListener("service_lockout", handleServiceLockout)
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt)
        }
    }, [])
    console.log("Fetching serverInfo from cache-first...")
    let [{data: dataSI}] = useServerInfoQuery("cache-first")
    putServerInfoInGlobalScope(dataSI) // serverInfo is now accessible anywhere; use getServerInfo()

    console.log("Fetching ParamsAtStartup from cache-first...")
    let [{data: dataPS}] = useParamsAtStartupQuery("cache-first")
    putParamsAtStartupInGlobalScope(dataPS) // paramsAtStartup is now accessible anywhere; use getParamsAtStartup()

    console.log("Fetching user from cache-first...")
    let [{
        fetching: fetchingUQ,
        data: dataUQ,
        error: errorUQ,
        stale: staleUQ
    }, userQuery] = useUserQuery("cache-first")
    let [user, serviceStatus] = processUserQueryResult(fetchingUQ, dataUQ, errorUQ)
    console.log("user=" + (user ? (user.userName + "-" + user.defaultRole) : "null"))
    const initialFeature = getFeature(window.location)
    const initialRole = getFeatureRole(initialFeature)
    console.log("hash=" + window.location.hash.substring(1) + "; initialFeature=" + JSON.stringify(initialFeature) + ";initialRole=" + initialRole)
    const [role, setRole] = useState(initialRole ? initialRole : user.defaultRole)

    /**
     * Sets role to default role if role has not been set;
     * Otherwise toggles role from EMP to SA or SA to EMP.
     * @returns url of the default feature for the new role
     */
    const changeRole = () => {
        console.log("changeRole()...role=" + role)

        if (role === ROLE.NONE || user.defaultRole === ROLE.NONE) {
            console.log("Setting role to " + user.defaultRole)
            setRole(ROLE[user.defaultRole])
            return getDefaultFeature(user, ROLE[user.defaultRole]).url
        } else if (role === ROLE.EMP && isSA(user)) {
            console.log("Setting role to " + ROLE.SA)
            setRole(ROLE.SA)
            return getDefaultFeature(user, ROLE.SA).url
        } else if (role === ROLE.SA && isEMP(user)) {
            console.log("Setting role to " + ROLE.EMP)
            setRole(ROLE.EMP)
            return getDefaultFeature(user, ROLE.EMP).url
        }
    }

    const [signoutResult, signoutMutation] = useSignOutMutation()
    const signout = (onError) => {
        signoutMutation({}).then(result => {
            console.log("return from signout mutation result = " + JSON.stringify(result))
            if (result.error) {
                onError(result.error)
            } else {
                console.log("signout() - setRole(none)")
                setRole(ROLE.NONE)
            }
        })
    }
    const [statusResult, statusMutation] = useStatusMutation()
    const signin = (onError) => {
        statusMutation({}).then(result => {
            console.log("return from status mutation result = " + JSON.stringify(result))
            if (result.error) {
                onError(result.error)
            } else {
                console.log("signin() - redirectToSignin()")
                redirectToSignin()
            }
        })
    }

    /**
     * Gets list of actions that can be performed based on role.
     * @returns [Action, Action, ...]
     */
    function getActions(all = false) {
        const actions = []
        if (all) actions.push(Actions.SIGN_IN)
        if (all || user.hasMultipleDcpos) actions.push(Actions.SWITCH_PROFILE)
        actions.push(Actions.SIGN_OUT)
        actions.push(Actions.MENU)
        return actions
    }

    let Actions = JSON.parse(JSON.stringify(ActionDefs))
    Actions.SIGN_IN.function = signin
    Actions.SIGN_OUT.function = signout
    Actions.MENU.function = toggleMenu

    // Disable action if network is unavailable
    Actions.SWITCH_PROFILE.disabled = !networkStatusRef.current.online
    Actions.SIGN_IN.disabled = !networkStatusRef.current.online
    Actions.SIGN_OUT.disabled = !networkStatusRef.current.online

    // Check for feature URL (must have ?uri=web+ansos://{path} or ?feature={FEATURE})
    const featureUrl = getFeatureURL()
    if (featureUrl) window.location = featureUrl

    //Check if there is an initial page that user should be redirect to
    const initPageUrl = getInitPageURL(user.defaultRole)
    if(initPageUrl) window.location = initPageUrl


    if (fetchingUQ) {
        return <SplashView/>
    } else if (serviceStatus && !serviceStatus.success) {
        if (!isEqual(serviceStatus, ServiceStatus.AUTHENTICATION_FAILURE)) {
            if (isActionDef(serviceStatus.action)) {
                const action = findAction(serviceStatus.action, getActions(true))
                serviceStatus.function = action.function
            }
            console.log('APP error: ' + JSON.stringify(serviceStatus))
            return <ServiceStatusView serviceStatus={serviceStatus}/>
        }
    }

    const isFromSignOut = signoutResult && signoutResult.data && signoutResult.data.signout
    //console.log("coming from signout: " + isFromSignOut)

    return (
        <HashRouter>
            <Switch>
                <Route exact path={Features.LAUNCH.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions(true)}
                                           feature={Features.LAUNCH}
                                           isFromSignOut={isFromSignOut}/>}/>
                <Route exact path={Features.ABOUT.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.ABOUT}
                                           isFromSignOut={isFromSignOut}/>}/>
                <Route exact path={Features.DEFAULT.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.DEFAULT}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.CALENDAR.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.CALENDAR}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.NOTIFICATIONS.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.NOTIFICATIONS}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.ASSIGNMENTS.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.ASSIGNMENTS}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.AVAILABLE_SHIFTS_CALENDAR.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.AVAILABLE_SHIFTS_CALENDAR}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.WORK_CALENDAR.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.WORK_CALENDAR}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.TIMEOFF_CALENDAR.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.TIMEOFF_CALENDAR}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.MY_SCHEDULE.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.MY_SCHEDULE}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.MY_AREA_SCHEDULE.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.MY_AREA_SCHEDULE}
                                           isFromSignOut={isFromSignOut}/>}/>


                <Route exact path={Features.AREA_SCHEDULE.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.AREA_SCHEDULE}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.USER_PROFILE.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.USER_PROFILE}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.RECIPIENT_SWAPS.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.RECIPIENT_SWAPS}
                                           isFromSignOut={isFromSignOut}/>}/>

                <Route exact path={Features.SWAPS_STATUS.url}
                       render={() => <View user={user} networkStatus={networkStatusRef.current}
                                           actions={getActions()}
                                           feature={Features.SWAPS_STATUS}
                                           isFromSignOut={isFromSignOut}/>}/>
            </Switch>
        </HashRouter>
    )
}

export default App