import React, {useContext, useState} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Card from 'react-bootstrap/Card'
import PotentialAssignmentControl from "./PotentialAssignmentControl"
import OriginalAssignmentControl from "./OriginalAssignmentControl"
import {RECIPIENT} from "./SwapStatusData"
import {useForm} from 'react-hook-form'



const ProposalAsRecipientForm = ({proposalAsRecipient, areas}) => {
    const {register, getValues} = useForm()

    const [showSwapPartnerNote, setShowSwapPartnerNote] = useState(false)

    let recipientWorkshift = {
        code: proposalAsRecipient.recWsCode,
        from:  proposalAsRecipient.recWsFrom,
        to: proposalAsRecipient.recWsTo,
        label: proposalAsRecipient.recWsLabel,
        date: proposalAsRecipient.recWorkdate,
        workMinutes: proposalAsRecipient.recWsWorkMinutes,
        areaId: proposalAsRecipient.recWsAreaId,
        format: proposalAsRecipient.recWsFormat,
    }

    let initiatorWorkshift = {
        code: proposalAsRecipient.initWsCode,
        from:  proposalAsRecipient.initWsFrom,
        to: proposalAsRecipient.initWsTo,
        label: proposalAsRecipient.initWsLabel,
        date: proposalAsRecipient.initWorkdate,
        workMinutes: proposalAsRecipient.initWsWorkMinutes,
        areaId: proposalAsRecipient.initWsAreaId,
        format: proposalAsRecipient.initWsFormat,
    }

    let partnerEmployee = {
        empName: proposalAsRecipient.initEmpName,
        dcpos: proposalAsRecipient.initDcpos,
        skill: proposalAsRecipient.initSkill,
        jobCode: proposalAsRecipient.initJobCode,
        note: proposalAsRecipient.initNote,
        status: proposalAsRecipient.partnerStatus
    }

    let viewerEmployeeStatus = {
        note: proposalAsRecipient.recNote,
        status: proposalAsRecipient.viewerStatus,
        role: RECIPIENT
    }

    let disablePartnerNoteCheck = !partnerEmployee.note
    let includeNotesSpanClassName=  disablePartnerNoteCheck ? "disabledIncludeNotesSpan" : "includeNotesSpan"

    return (
        <div className={"swapProposal"} >

            <OriginalAssignmentControl originalWorkshift={recipientWorkshift}
                                       viewerEmployeeStatus={viewerEmployeeStatus}
                                       areas={areas}
            />

            <Accordion defaultActiveKey="0">
                <Card className = "swapCard">
                    <Card.Header className = "swapCardHeader">
                        <div className="spaceBetween">
                            <div className="spaceBetween">
                                <div className="bigLabel">Potential Assignment (1)</div>
                                <div className="includeNotes">
                                    <label className="hsCheckbox">
                                        <input type="checkbox"
                                               name="showPartnerNote"
                                               disabled={disablePartnerNoteCheck}
                                               defaultChecked={false}
                                               ref={register}
                                               onChange={() => {
                                                   const showNoteChecked = getValues("showPartnerNote")
                                                   setShowSwapPartnerNote(showNoteChecked)
                                               }}
                                        />
                                        <span className="hsCheckmark"/>

                                    </label>
                                    {/*<span className={includeNotesSpanClassName}>Include notes</span>*/}

                                </div>
                                <div className={includeNotesSpanClassName}>
                                    <span>Include notes</span>
                                </div>
                            </div>
                            <SwapCustomToggle eventKey={proposalAsRecipient.sortByProposalId}>
                            </SwapCustomToggle>
                        </div>

                    </Card.Header>
                    <Accordion.Collapse eventKey={proposalAsRecipient.sortByProposalId} >
                        <Card.Body className="potentialAssignmentsCardBody">
                            <PotentialAssignmentControl partnerWorkshift={initiatorWorkshift}
                                                        partnerEmployee={partnerEmployee}
                                                        showSwapPartnerNote={showSwapPartnerNote}
                                                        areas={areas}
                            />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>

        </div>

    )


}

export function SwapCustomToggle({children,  eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.
    const toggleOnClick = useAccordionToggle(eventKey, () => {
        callback && callback(eventKey)
    });
    const isCurrentEventKey = currentEventKey === eventKey

    return (
        <div className="spaceBetween">
            {children}
            <div>
                <button className="swapToggleButton"
                        type="button"
                        onClick={toggleOnClick}
                >

                    {isCurrentEventKey ?
                        <div><i className="fas fa-angle-up"></i></div>
                        : <div><i className="fas fa-angle-down"></i></div>

                    }
                </button>
            </div>
        </div>
    );
}






export default ProposalAsRecipientForm