import {useEffect, useState} from "react";

function eventAndHiddenNames() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
        hidden = "mozHidden";
        visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    } else {
        console.log('Page Visibility API not supported')
    }
    return [visibilityChange, hidden]
}

/**
 * useVisiblity hook listens for a change in the document's visible state
 * @returns current visibility state
 */
export function useVisibility() {

    const [isVisible, setVisible] = useState(null)

    const toggleVisible = () => {
        if (isVisible === null) {
            console.log("isVisible is null; setting to true")
            setVisible(true)
        } else {
            console.log("isVisible is " + isVisible + "; setting to " + !isVisible)
            setVisible(!isVisible)
        }
    }

    useEffect(() => {
        const [visibilityChange, hidden] = eventAndHiddenNames()
        console.log("useVisiblity() initialized: listening for " + visibilityChange)
        document.addEventListener(visibilityChange, toggleVisible, false)
        return () => {
            console.log(visibilityChange + " listener removed")
            document.removeEventListener(visibilityChange, toggleVisible, false)
        }
    })

    console.log("useVisibility hook returning isVisible=" + isVisible)
    return isVisible
}

/**
 * Is the page currently in standalone display mode (used by PWA)?
 * @return {boolean}
 * @see https://micahjon.com/2021/pwa-twa-detection/
 */
export function isInStandaloneMode() {
    return Boolean(
        (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
        || window.navigator.standalone, // Fallback for iOS
    )
}

/**
 * The viewport height is from bottom of status bar to bottom of screen, even when display-mode is browser
 * and browser navbar is using part of the app's view area. So, this work-around sets the --vh variable
 * to 1/100 of the window's inner height which if from bottom of status bar to top of navbar (if present).
 *
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export function setCssVariable_vh() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}