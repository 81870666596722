import React from "react";
import {makeServiceStatus, ServiceStatus} from "../../../helpers/serviceStatus";
import {stringToDate, dateToString, stringToDateTime, formatInServerTZ_24hour} from "helpers/date"
import moment from 'moment'
import {isOpen, translateFormat, PENDING_STATUS} from "./swapInitiateProposalHelper"

export function processSwapInitEmpSchAndRecAreaData(fetchingSAQ, dataSAQ, errorSAQ, origialAssignmentForSwap) {
    if (fetchingSAQ) {
        return {recipientAreas: [],
            primarySkill: '',
            compSkill: '',
            initEmpAssignmentsAndRequests: [],
        }
    }

    if (errorSAQ) {
        const serviceStatus = makeServiceStatus(dataSAQ ? dataSAQ.swapInitEmpSchAndRecAreaList : null, errorSAQ)
        if (serviceStatus && !serviceStatus.success) {
            return {recipientAreas: [],
                primarySkill: '',
                compSkill: '',
                initEmpAssignmentsAndRequests: [],
                serviceStatus: serviceStatus
            }
        }
    }

    if (dataSAQ && dataSAQ.swapInitEmpSchAndRecAreaList) {
        console.log("Fetching data dataSAQ.swapInitEmpSchAndRecAreaList = ")
        console.log(dataSAQ.swapInitEmpSchAndRecAreaList)
        let areaList = createAreas(dataSAQ.swapInitEmpSchAndRecAreaList.recAreaList)
        let compSkillConcatStr = ''
        dataSAQ.swapInitEmpSchAndRecAreaList.initEmpCompSkillSet.forEach((compSkill) => {
            compSkillConcatStr += compSkill
        })

        let initEmpAssignmentsAndRequests = new Array()
        let initEmpSchedule = filterOrgininalAssignmentForSwap(
            dataSAQ.swapInitEmpSchAndRecAreaList.initEmpPeriodAssignmentsAndRequests, origialAssignmentForSwap)

        initEmpSchedule.forEach((asgnOrReq) => {
            let singleAsgnOrReq = buildSingleInitAsgnOrReq(asgnOrReq)
            initEmpAssignmentsAndRequests.push( singleAsgnOrReq )

        })

        return {recipientAreas: areaList,
                primarySkill: dataSAQ.swapInitEmpSchAndRecAreaList.initEmpPrimarySkill,
                compSkill: compSkillConcatStr,
                initEmpAssignmentsAndRequests: initEmpAssignmentsAndRequests,
                serviceStatus: ServiceStatus.SUCCESS
                }

    }else{
        return {recipientAreas: [],
            primarySkill: '',
            compSkill: '',
            initEmpAssignmentsAndRequests: [],
        }
    }


}

// filter out original assignment up for swap. Reason being, "initEmpSchedule" is used to
//compute/display nearby workshifts. The one to swap away would not cause any "nearby"
function filterOrgininalAssignmentForSwap(initEmpPeriodAssignmentsAndRequests, origialAssignmentForSwap){
    return   initEmpPeriodAssignmentsAndRequests.filter(
        (asgn) => {
            return (
                ! (asgn.areaId === origialAssignmentForSwap.areaId
                &&  asgn.code === origialAssignmentForSwap.code
                &&  asgn.date === origialAssignmentForSwap.date
                &&  asgn.from === origialAssignmentForSwap.from
                &&  asgn.workMinutes === origialAssignmentForSwap.workMinutes
                &&  asgn.totalMinutes === origialAssignmentForSwap.totalMinutes)
            )
        })
}


function buildSingleInitAsgnOrReq(asgnOrReq){
    let assignment = {
        code: asgnOrReq.code,
        date: stringToDate(asgnOrReq.date),
        from: stringToDateTime(asgnOrReq.from),
        to: stringToDateTime(asgnOrReq.to),
        workMinutes: asgnOrReq.workMinutes,
        totalMinutes: asgnOrReq.totalMinutes,
        areaId: asgnOrReq.areaId,
        label: asgnOrReq.label,
        format: translateFormat(asgnOrReq.format),
        type: asgnOrReq.work ? "Work" : "Timeoff",
        pendingStatus: asgnOrReq.pending ? /*"Pending"*/ PENDING_STATUS : "",
        uniqueKey:     (asgnOrReq.code + ":"
                        + asgnOrReq.areaId + ":"
                        + formatInServerTZ_24hour(stringToDateTime(asgnOrReq.from)) + ":"
                        + asgnOrReq.workMinutes + ":"
                        + asgnOrReq.totalMinutes +   ":"
                        + asgnOrReq.date + ":"
                        + asgnOrReq.pending),

    }
    return assignment

}

/**
 * process swappable recipients query results for (ONE area, one period). Upon success, it would return a map
 * keyed by a single date, and value being an array of assignments on this day
 * @param fetchingSRQ
 * @param dataSRQ
 * @param errorSRQ
 * @param areaName
 * @returns {{swapRecAssignmentByDayMap: null}|{swapRecAssignmentByDayMap: Map<any, any>}|{swapRecAssignmentByDayMap: Array}|{swapRecAssignmentByDayMap: null, fetchRecipientsServiceStatus: serviceStatus}}
 */
export function processSwapRecAssignmentsData(fetchingSRQ, dataSRQ, errorSRQ, areaName) {
    if (fetchingSRQ) {
        return {swapRecAssignmentMapByDay: null, swapRecAssignmentMapByUniqueKey: null}
    }


    if (errorSRQ) {
        const serviceStatus = makeServiceStatus(dataSRQ ? dataSRQ.swapRecipientAssignments : null, errorSRQ)
        if (serviceStatus && !serviceStatus.success) {
            return {swapRecAssignmentMapByDay: null,
                swapRecAssignmentMapByUniqueKey: null,
                fetchRecipientsServiceStatus: serviceStatus
            }
        }
    }

    if (dataSRQ && dataSRQ.swapRecipientAssignments) {
        console.log("Fetching data dataSAQ.swapRecipientAssignments = ")
        console.log(dataSRQ.swapRecipientAssignments)
        let swapRecAssignmentMapByDay = new Map()
        let swapRecAssignmentMapByUniqueKey = new Map()

        dataSRQ.swapRecipientAssignments.forEach((swapAssignment) => {
            let workDateStr = swapAssignment.date
            let uniqueKey = buildUniqueKey(swapAssignment)
            let singleAssignment = buildSingleSwapAssignment(swapAssignment, uniqueKey, areaName)

            swapRecAssignmentMapByUniqueKey.set(uniqueKey, singleAssignment)

            if(swapRecAssignmentMapByDay.has(workDateStr)){
                swapRecAssignmentMapByDay.get(workDateStr).push(singleAssignment)
            }else{
                let assignmentsByDay = []
                assignmentsByDay.push(singleAssignment)
                swapRecAssignmentMapByDay.set(workDateStr, assignmentsByDay)
            }

        })
        // console.log("After process, the map is = " + JSON.stringify(swapRecAssignmentByDayMap))
        return {swapRecAssignmentMapByDay: swapRecAssignmentMapByDay,
                 swapRecAssignmentMapByUniqueKey: swapRecAssignmentMapByUniqueKey}


    }else{
        return {swapRecAssignmentMapByDay: null, swapRecAssignmentMapByUniqueKey: null}
    }


}


function buildUniqueKey(swapAssignment) {
    //(ansosId:areaId:code:startTimeInMilitary:workMinutes:breakMinutes:workDate)
    return swapAssignment.swapEmployee.ansosId + ":"
    + swapAssignment.areaId + ":"
    + swapAssignment.wsCodeId + ":"
    + formatInServerTZ_24hour(stringToDateTime(swapAssignment.from)) + ":"
    + swapAssignment.workMinutes + ":"
    + swapAssignment.breakMinutes +   ":"
    + swapAssignment.date
}

function buildSingleSwapAssignment(swapAssignment, uniqueKey, areaName){
    let assignment = {
        empName: swapAssignment.swapEmployee.empName,
        ansosId: swapAssignment.swapEmployee.ansosId,
        dcpos: swapAssignment.swapEmployee.dcpos,
        skill: swapAssignment.swapEmployee.skill,
        jobCode: swapAssignment.swapEmployee.jobCode,
        code: swapAssignment.code,
        wsCodeId: swapAssignment.wsCodeId,
        date: stringToDate(swapAssignment.date),
        from: swapAssignment.from,
        to: swapAssignment.to,
        workMinutes: swapAssignment.workMinutes,
        breakMinutes: swapAssignment.breakMinutes,
        label: swapAssignment.wsLabel,
        format: swapAssignment.wsFormat,
        areaId: swapAssignment.areaId,
        timeSort: swapAssignment.timeSort,
        overtime: swapAssignment.overtime,
        overlap: swapAssignment.overlap,
        areaName: areaName,
        uniqueKey: uniqueKey

    }
    return assignment

}

/**
 * Find the target swap recipient that UI should position to.
 * Initially positioned to show the first (if any) workshifts on the same date as the one to be swapped,
 * plus, at the top of the list, show the nearest workshift that occurs before that date (if any)
 *
 * @param initWorkDate: date in string format. Ex: "2021-09-01"
 * @param swapRecipientMapByDay: key is date in string format, value is an array of assignments on this day
 * @returns {null|target swap recipient that UI should scroll to}
 */
export function findTargetRecipientToPosition(initWorkDate, swapRecipientMapByDay){
    if(!swapRecipientMapByDay) return null
    let sortedDateArray = [...swapRecipientMapByDay.keys()]
    let theDayBefore = findClosetDayBefore(initWorkDate, sortedDateArray)
    // console.log("theDayBefore = " + theDayBefore)
    let assignmentsDayArr = swapRecipientMapByDay.get(theDayBefore)
    if(moment(theDayBefore).isBefore(initWorkDate)) {
        return assignmentsDayArr[assignmentsDayArr.length - 1]
    }else{
        return assignmentsDayArr[0]
    }
}

//try to find the closest date before "initWorkDate" in this sorted array "sortedDateArray"
function findClosetDayBefore(initWorkDate, sortedDateArray){
    if (sortedDateArray.includes(initWorkDate)) {
        const index = sortedDateArray.findIndex(workDate => workDate === initWorkDate)
        return (index > 0) ? sortedDateArray[index - 1] : initWorkDate
    }
    else{
        let dateBeforeArr = []//this temp array would store all previous dates before "initWorkDate"
        sortedDateArray.map((aDate)=>{
            if(moment(aDate).isBefore(initWorkDate)) {
                dateBeforeArr.push(aDate)
            }
        })
        return (dateBeforeArr.length>0) ? dateBeforeArr[dateBeforeArr.length-1] : sortedDateArray[0]
    }
}


export function amITargetRecipient(swapRecAssignment, targetRecipient){

    return targetRecipient && swapRecAssignment && (dateToString(swapRecAssignment.date) === dateToString(targetRecipient.date)
    && swapRecAssignment.areaId === targetRecipient.areaId
    && swapRecAssignment.code  === targetRecipient.code
    && swapRecAssignment.from  === targetRecipient.from
    && swapRecAssignment.workMinutes  === targetRecipient.workMinutes
    && swapRecAssignment.breakMinutes === targetRecipient.breakMinutes)

}



function createAreas(areas) {
    let areaList = []
    areas.forEach((area) => {
        areaList.push({id:area.area.id, name:area.area.name, open:area.open, participating: area.participating})
    })
    return areaList
}


export function makeInitiateSwapProposalResultStatus(fetchingISPM, dataISPM, errorISPM) {
    if(errorISPM){
        let serviceStatus = makeServiceStatus(null, errorISPM)
        return serviceStatus
    }
    if(dataISPM && dataISPM.initiateSwapProposal) {
        return ServiceStatus.SUCCESS_SWAP_PROPOSAL_INITIATED
    }
    else return ServiceStatus.UNKNOWN
}
