import React, {useEffect, useState} from 'react'
import EnumSelectControl from '../EnumSelectControl'
import Form from "react-bootstrap/Form"
import {useFormContext} from "react-hook-form"
import PrimaryAltPhoneVerifyControl from "./VerifyEmailOrPhone/PrimaryAltPhoneVerifyControl"
import OtherPhoneVerifyControl from "./VerifyEmailOrPhone/OtherPhoneVerifyControl"


export const NOTIFY_TXT_TO  = Object.freeze( {
    notSendText: 'Do not send texts',
    yesPrimaryText: 'Primary Phone',
    yesAlternateText: 'Alternate Phone',
    yesOtherText: 'Other Phone',
})




const PhoneNotifyForm = ({data: userProfileData, txtVerified, setTxtVerified}) => {


    const { register } = useFormContext()

    //with async nature of useQuery, userProfileData (which is result of useQuery hook) is not available at initial render time
    //useState initial state only set at the 1st render time. In order to initialize based on query result,
    //we will take advantage of combined power of (useEffect + setState) to achieve our intention.
    const [txtNotifyMethod, setTxtNotifyMethod] = useState( undefined)


    useEffect(() => {
        // Only set state when "userProfileData changes", which would be then be when "useQuery" hook result fetching completes
        // With the dependencies of this useEffect, it would only run once, exactly what we want.
        //Here is the sequence of events:
        //1. when this form initially renders, txt verified related info is initialized to false
        //2. useQuery hook result returns, and a re-render would happen.
        //3. Now useEffect runs, and do this setTxtXXX, which would bring in a re-render
        //4. This time, "txtVerified" & "txtNotifyMethod" have the correct initial value based on query result. All good
        setTxtNotifyMethod(userProfileData.notification.notfyTxtTo)
    }, [userProfileData.notification.notfyTxtTo])




    const isPrimaryPhoneVerified = () => {
        return (txtVerified
                && userProfileData.notification.notfyTxtTo ==='yesPrimaryText'
                && userProfileData.notification.notfyTxtPhoneNumVerified === userProfileData.phone)
    }

    const isAltPhoneVerified = () => {
        return (txtVerified
                && userProfileData.notification.notfyTxtTo ==='yesAlternateText'
                && userProfileData.notification.notfyTxtPhoneNumVerified === userProfileData.altPhone
        )
    }


    const isOtherPhoneInitiallyVerified = () => {
        return (userProfileData.notification.notfyTxtVerified && userProfileData.notification.notfyTxtTo ==='yesOtherText')
    }

    const isOtherPhoneCurrentlyVerified = () => {
        return (txtVerified && userProfileData.notification.notfyTxtTo ==='yesOtherText')
    }


    const getCellCarrier = () => {
        let carrierOptionArr =[...userProfileData.notification.carrierOptionList]
        let carrierOptionKeyValueObj = {}
        carrierOptionArr.map(
                key => carrierOptionKeyValueObj[key] = key)
        // console.log(JSON.stringify(carrierOptionKeyValueObj))

        let obj1 = { Carrier: 'Select Carrier'};
        let mergedcarrierOption = { ...obj1, ...carrierOptionKeyValueObj };
        return Object.entries(mergedcarrierOption)
    }


    const handleTextDropdownChange = (selectedMethod) => {
        if(txtVerified){
            setTxtVerified(false)
        }else{
            if(userProfileData.notification.notfyTxtVerified
                    && selectedMethod === userProfileData.notification.notfyTxtTo){
                setTxtVerified(true)

            }
        }
        setTxtNotifyMethod(selectedMethod)

    }




    return (

            <Form.Group>
                <fieldset className="fieldsetClass">
                    <legend className="formLabel">Send Text Notifications to</legend>
                    {/*div key is to force "EmumSelectControl" to re-render and take the newer defaultValue when appropriate*/}
                    <div key={userProfileData.notification.notfyTxtTo}>
                        <Form.Group>
                            <EnumSelectControl name="txtNotifyTo"
                                    enumEntries = {Object.entries(NOTIFY_TXT_TO)}
                                    selectedOption={userProfileData.notification.notfyTxtTo}
                                    onChange={handleTextDropdownChange}
                                    register={register}
                            />
                        </Form.Group>
                    </div>

                    {/*dropdown selected "Do not send Text" */}
                    {NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.notSendText &&
                    <span></span>

                    }


                    {/*dropdown selected "Primary Phone" */}
                    {NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.yesPrimaryText &&
                    <PrimaryAltPhoneVerifyControl data={userProfileData} isPhoneVerified={isPrimaryPhoneVerified}
                            txtNotifyMethod={txtNotifyMethod} getCellCarrier={getCellCarrier} setTxtVerified={setTxtVerified}/>

                    }

                    {/*dropdown selected "Alt Phone" */}
                    {NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.yesAlternateText &&
                    <PrimaryAltPhoneVerifyControl data={userProfileData} isPhoneVerified={isAltPhoneVerified}
                            txtNotifyMethod={txtNotifyMethod} getCellCarrier={getCellCarrier} setTxtVerified={setTxtVerified}/>
                    }

                    {/*dropdown selected "Other Phone" */}
                    {NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.yesOtherText &&
                    <OtherPhoneVerifyControl data={userProfileData}
                            name="otherPhone"
                            txtNotifyMethod={txtNotifyMethod}
                            setTxtVerified={setTxtVerified}
                            isOtherPhoneInitiallyVerified={isOtherPhoneInitiallyVerified}
                            isOtherPhoneCurrentlyVerified={isOtherPhoneCurrentlyVerified}
                            getCellCarrier={getCellCarrier}


                    />
                    }

                </fieldset>
            </Form.Group>


    )


}

export default PhoneNotifyForm