import React from "react"
import { default as ReactSelect } from "react-select"




const MultiSelect = props => {

    const getAllOptions = () => [props.selectAllOption, ...props.options];

    //Here is how to style selected option: https://stackoverflow.com/questions/45291508/how-to-style-react-select-options
    const customStyle = {
        // control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { /*data, isDisabled, isFocused,*/ isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected  ? "var(--hs-blue-60)" : "white",
            };
        },
        control: (styles, { /*data, isDisabled,*/ isFocused}) => ({
            ...styles,
            backgroundColor: "white",
            boxShadow: "none",
            borderColor: isFocused ? "1px solid var(--hs-blue-20)" : styles.borderColor,
            "&:hover": {
                borderColor: isFocused ? "1px solid var(--hs-blue-20)" : styles.borderColor,
                boxShadow: "none",
            }
        }),

        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "var(--color-text)",
        }),

    };


    if (props.allowSelectAll) {
        return (
                <ReactSelect
                        {...props}
                        options={getAllOptions()}
                        styles={customStyle}
                        onChange={(selected, event) => {

                            if (selected !== null && selected.length > 0) {
                                if (selected[selected.length - 1].value === props.selectAllOption.value) {
                                    return props.onChange(getAllOptions())
                                }
                                let result = []
                                if (selected.length === props.options.length) {
                                    if(selected.some(element=> element.value === props.selectAllOption.value)) {
                                        result = selected.filter(
                                                option => option.value !== props.selectAllOption.value
                                        )
                                    } else if (event.action === "select-option") {
                                        result = getAllOptions()
                                    }
                                    return props.onChange(result)
                                }
                            }

                            return props.onChange(selected)
                        }}
                />
        )
    }

    return (
            <ReactSelect {...props} />
    )
}



// const selectAllOption = {
//     label: "Select All",
//     value: "*"
// }

export default MultiSelect
