import {formatInServerTZ_12hour, formatInServerTZ_24hour} from "./date";

export function formatWorkshift(workshift, format = workshift.format) {
    switch (format) {
        case 0:
            return formatTimeRangeLabel(workshift.from, workshift.to, workshift.label);
        case 1:
            return formatCodeTimeRangeLabel(workshift.code, workshift.from, workshift.to, workshift.label);
        case 2:
            return formatCodeLabel(workshift.code, workshift.label);
        case 3:
            return formatCodeTimeRangeArea(workshift.code, workshift.from, workshift.to, workshift.areaId);
        case 4:
            return formatCodeTimeAMPMLabel(workshift.code, workshift.from, workshift.to, workshift.label);
        case 5:
            return formatCodeLabelArea(workshift.code, workshift.label, workshift.areaId);
        default:
            return formatCodeTimeRangeLabel(workshift.code, workshift.from, workshift.to, workshift.label);
    }
}

function formatTimeRangeLabel(startTime, endTime, label) {
    var times = formatTimeRange(startTime, endTime);
    if (times === label) {
        return label;
    } else {
        return (times + " " + label).trim();
    }
}

function formatCodeTimeRangeLabel(code, startTime, endTime, label) {
    var times = formatTimeRange(startTime, endTime);
    if (times === label) {
        if (code) {
            return (code + ":" + label).trim();
        } else {
            return label;
        }
    } else {
        if (code) {
            return (code + ":" + times + " " + label).trim();
        } else {
            return (times + " " + label).trim();
        }
    }
}

function formatCodeLabelArea(code, label, areaId) {
    var out = formatCodeLabel(code, label);
    if (areaId) out += " " + areaId;
    return out;
}

function formatCodeLabel(code, label) {
    var out = label;
    if (code) out = (code + ":" + label).trim();
    return out;
}

function formatCodeTimeRangeArea(code, startTime, endTime, areaId) {
    var out = formatCodeTimeRange(code, startTime, endTime);
    if (areaId) out += " " + areaId;
    return out;
}

function formatCodeTimeRange(code, startTime, endTime) {
    var out = formatTimeRange(startTime, endTime);
    if (code) out = code + ":" + out;
    return out;
}

function formatTimeRange(startTime, endTime) {
    var start = formatInServerTZ_24hour(startTime);
    if (start === "0000") start = "2400";
    var end = formatInServerTZ_24hour(endTime);
    if (end === "0000") end = "2400";
    return start + "-" + end;
}

function formatCodeTimeAMPMLabel(code, startTime, endTime, label) {
    var out = formatTimeAMPMRange(startTime, endTime);
    if (code) out = code + ":" + out;
    return (out + " " + label).trim();
}

function formatTimeAMPMRange(startTime, endTime) {
    var start = formatInServerTZ_12hour(startTime);
    start = start.replace(/^0+/, '').replace(' am', 'A')
        .replace(' pm', 'P').replace(':00', '');
    var end = formatInServerTZ_12hour(endTime);
    end = end.replace(/^0+/, '').replace(' am', 'A')
        .replace(' pm', 'P').replace(':00', '');

    return start + "-" + end;
}



