import React, {useState} from 'react'
import ServiceStatus, {makeServiceStatus} from "helpers/serviceStatus";
import Spinner from "components/Spinner";
import Notice from "components/Notice";
import NotificationForm from "./NotificationForm";
import {useSubmitRequestMutation} from "components/Request/RequestGQL";


export const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})

const Notification = ({notification, assignments, areas, onSuccess, onCancel, show, setShow}) => {
    const [request, setRequest] = useState({})
    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})
    const [submitRequestResult, submitRequest] = useSubmitRequestMutation()

    console.log("<Notification> show=" + show + "; notification=" + (notification ? notification.id : "NULL"))

    const actions = {
        submit: request => {
            setShow(SHOW.SPINNER)
            const variables = {"request": request}
            console.log("SUBMITREQUEST VARIABLE=" + JSON.stringify(variables))
            submitRequest(variables).then(result => {
                console.log("SUBMITREQUEST result=" + JSON.stringify(result))
                setServiceStatus(makeServiceStatus(result.data ? result.data.submitRequest : null, result.error))
                if (serviceStatus) {
                    setShow(SHOW.NOTICE)
                } else {
                    setShow(SHOW.NONE)
                    actions.cancel()
                }
            })
        },
        resubmit: request => {
            request.resubmit = true
            actions.submit(request)
        },
        cancel: () => {
            setShow(SHOW.NONE)
            onCancel()
        }
    }

    function handleNoticeResponse(confirmed) {
        if (confirmed) {
            setShow(SHOW.NONE)
            actions.resubmit(request)
        } else {
            setShow(SHOW.NONE)
            if (serviceStatus.success) {
                onSuccess()
            } else {
                onCancel()
            }
        }
    }

    switch (show) {
        case SHOW.NOTICE: {
            return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                           onClose={handleNoticeResponse} show={true}/>
        }
        case SHOW.SPINNER: {
            return <Spinner show={true}/>
        }
        case SHOW.FORM: {
            if (notification) {
                return (
                    <NotificationForm notification={notification} request={request} actions={actions} assignments={assignments} areas={areas}/>
                )
            } else {
                return <></>
            }
        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }
}

export default Notification
