import React, {useEffect, useState} from 'react';
import {toError} from "helpers/serviceStatus";
import {useNotificationsQuery} from "components/Notification/NotificationGQL";
import {fillNotification, getNotification, processNotificationsData} from "components/Notification/NotificationData";
import NotificationList from "components/Notification/NotificationList";
import Notification, {SHOW} from "components/Notification";
import Bulletin from "components/Bulletin";
import Spinner from "components/Spinner";
import {getSearchParam} from "helpers/http";
import If from "components/If";
import Features, {loadFeature} from "../../features";
import {useViewClasses} from "./View";


const NotificationsView = ({layout, areas}) => {
    const [{fetching, data, error}] = useNotificationsQuery()
    const {notifications, assignmentMapByDay, serviceStatus} = processNotificationsData(fetching, data, error)
    const [notification, setNotification] = useState()
    const [assignments, setAssignments] = useState()
    //show -- show the dialog to submit avl shift request. The reason this state is promoted from "Notification.jsx" to its parent
    //is to address this scenario: After successfully submitting one request from the 'Notification list' the list is unresponsive
    // when trying to select a second workshift.
    const [show, setShow] = useState(SHOW.FORM)

    useEffect(() => {
        console.log("Check for notif parameter...")
        if (notifications) {
            const notificationKey = getSearchParam('notif')
            console.log(notificationKey ? "notif=" + notificationKey : "No notif parameter found")
            if (notificationKey) {
                const notificationId = notificationKey.slice(1)
                const item = getNotification(notifications, notificationId)
                console.log(item ? "Found notification" : "Did not find notification in notifications[" + notifications.length + "]")
                if (item){
                    notificationSelected(item)
                }
            }
        }
    }, [notifications])

    const onSelect = (notificationId) => {
        console.log("Selected notification " + notificationId)
        let selectedNotification = getNotification(notifications, notificationId)
        notificationSelected(selectedNotification)
        console.log("notification set to " + JSON.stringify(notification))
    }

    const notificationSelected = (selectedNotification) => {
        //If a notification is selected, then show the dialog to submit avl shift request
        if(selectedNotification) {
            setShow(SHOW.FORM)
        }
        setNotification(selectedNotification)
        let assignments = assignmentMapByDay.get(selectedNotification.workshift.on)
        setAssignments(assignments)
    }

    const handleNotificationReturn = (success) => {
        console.log("HANDLE NOTIFICATION RETURN...success=" + success)
        if (getSearchParam('notif') || success) {
            loadFeature(Features.NOTIFICATIONS)
        } else {
            setNotification(undefined)
        }
    }

    let body
    if (fetching) {
        const fillers = [fillNotification(1), fillNotification(2), fillNotification(3),
            fillNotification(4), fillNotification(5)]
        body = <div id="notificationsView">
            <NotificationList notifications={fillers}/>
            <Spinner show={true}/>
        </div>
    } else if (notifications && notifications.length > 0) {
        console.log("...Notifications[" + notifications.length + "] ")
        console.log("[0]=" + JSON.stringify(notifications[0]))



        body = <div id="notificationsView">
            <If condition={() => {
                return notification
            }}>
                <Notification notification={notification}
                              assignments={assignments}
                              areas={areas}
                              onSuccess={() => handleNotificationReturn(true)}
                              onCancel={() => handleNotificationReturn(false)}
                              show={show}
                              setShow={setShow}/>
            </If>
            <NotificationList notifications={notifications} onSelect={onSelect} />
        </div>
    } else {
        const message = "There are currently no notifications of available shifts to display. Check the Available Shifts calendar."
        body = <Bulletin message={message}/>
    }

    return layout({body, error: toError(serviceStatus), classes: useViewClasses()})
}

export default NotificationsView