/**
 * Compare equality of two javascript objects.
 * You can't just use (obj1 === obj2). That only compares the references of the two objects.
 * This isEqual(obj1, obj2) function will compare the number of properties of the two objects,
 * values and nested objects to determine if objects are equal.
 */
export function isEqual(obj1, obj2) {
    let props1 = Object.getOwnPropertyNames(obj1);
    let props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length !== props2.length) {
        return false;
    }
    for (let i = 0; i < props1.length; i++) {
        let prop = props1[i];
        let bothAreObjects = typeof(obj1[prop]) === "object" && typeof(obj2[prop]) === "object";
        if ((!bothAreObjects && (obj1[prop] !== obj2[prop]))
            || (bothAreObjects && !isEqual(obj1[prop], obj2[prop]))) {
            return false;
        }
    }
    return true;
}