import React, {useEffect, useState} from 'react'
import Banner from "components/Banner"
import './View.css'
import {useSubmitIssueMutation} from "../Issue/IssueGQL";
import {createIssue} from "../Issue/IssueData";
import ServiceStatus, {makeServiceStatus} from "../../helpers/serviceStatus";

const messageStyle = {
    margin: "5rem",
    textAlign: "center",
    whiteSpace: "pre-wrap",
    fontsize: "2rem"
}

/**
 * Render a error screen that can be rendered when fatal error is encountered and
 * caught by ErrorBoundary.
 * @returns {JSX.Element}
 */
const ErrorView = ({errorMessage, callstack}) => {

    const [submitIssueResult, submitIssue] = useSubmitIssueMutation()
    const [result, setResult] = useState(ServiceStatus.RUNTIME_ERROR.message.replace('{0}',"processing...one moment please"))

    useEffect(() => {
        if (errorMessage) {
            const issue = createIssue(errorMessage + callstack)
            const variables = {"issue": issue}
            submitIssue(variables).then(result => {
                console.log(JSON.stringify(result))
                const serviceStatus = makeServiceStatus(result.data ? result.data.submitIssue : null, result.error)
                if (result && result.data && result.data.submitIssue) {
                    setResult(ServiceStatus.RUNTIME_ERROR.message.replace('{0}',result.data.submitIssue))
                } else if (serviceStatus) {
                    setResult(serviceStatus.message)
                } else {
                    setResult("weird...got nothing back from submitIssue")
                }
            })
        }
    }, [errorMessage])

    return (
        <div id="errorView">
            <Banner/>
            <div style={messageStyle}>
                {result}
            </div>
        </div>
    )
}

export default ErrorView