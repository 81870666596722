import moment from 'moment'
import {dateToString, momentToDateString} from 'helpers/date'
import {makeServiceStatus} from "helpers/serviceStatus";
import {isPeriodArchived} from "helpers/period";
import {mapToSortedArray} from "../../helpers/array";

export const calendarView = {
    MONTH: 'Month',
    LIST: 'List'
}

export const calendarModes = {
    CALENDAR: 'Calendar',
    AVAILABLE_SHIFTS: "Available Shifts",
    WORK: 'Work',
    TIMEOFF: 'TimeOff'
}

export const publishStatuses = {
    PUBLISHED: 'P',
    NOT_PUBLISHED: 'N',
    BALANCING: 'B',
    RESET: 'R',
    ARCHIVED: 'A'
}

export const publishStatusTitle = (publishStatus) => {
    switch (publishStatus) {
        case publishStatuses.ARCHIVED:
            return ""
        case publishStatuses.PUBLISHED:
            return "Published"
        case publishStatuses.BALANCING:
            return "Recalled"
        case publishStatuses.RESET:
            return "Reset"
        case publishStatuses.NOT_PUBLISHED:
        default:
            return "Fixed Scheduling Patterns"
    }
}

export const publishStatusClass = (publishStatus) => {
    switch (publishStatus) {
        case publishStatuses.ARCHIVED:
            return "archived"
        case publishStatuses.PUBLISHED:
            return "published"
        case publishStatuses.BALANCING:
            return "balancing"
        case publishStatuses.RESET:
            return "reset"
        case publishStatuses.NOT_PUBLISHED:
        default:
            return "not-published"
    }
}

export const requestTypeTitle = (requestType) => {
    switch (requestType) {
        case "ASR":
            return "Available Shift"
        case "WR":
            return "Work"
        case "TOR":
            return "Time Off"
        default:
            return null
    }
}

export function processCalendarData(fetchingCD, dataCD, errorCD, terminateDate) {
    if (errorCD) {
        const serviceStatus = makeServiceStatus(dataCD ? dataCD.calendar : null, errorCD)
        if (serviceStatus && !serviceStatus.success) {
            return {days: new Map(), areas: [], serviceStatus: serviceStatus}
        }
    }

    if (dataCD && dataCD.calendar) {
        let areas = createAreas(dataCD.calendar)
        let days = createDays(dataCD.calendar, areas, terminateDate)
        return {days: days, areas: areas}
    } else {
        return {days: new Map(), areas: []}
    }
}

function createAreas(calendar) {
    let areas = new Map()
    calendar.areas.forEach((area) => {
        areas.set(area.id, {...area, timeOffOpen: false, workOpen: false, shiftsOpen: false})
    })
    loadAreasWithPeriodData(areas, calendar.periods)
    return mapToSortedArray(areas)
}

function createDays(calendar, areas, terminateDate) {
    let days = new Map()
    loadDays(days, calendar.from, calendar.to, areas)
    loadDaysWithPeriodData(days, calendar.periods, areas, terminateDate)
    loadDaysWithAssignments(days, calendar.assignments)
    return days
}

function newDay(date, areas) {
    let day = {}
    day.date = date
    day.homeArea = {}
    day.areas = new Map()
    areas.forEach(area => day.areas.set(area.id, {id: area.id, name: area.name}))
    day.assignments = []
    day.workOpen = false
    return day
}

export function getDay(days, date) {
    let day
    try {
        if (days) day = days.get(dateToString(date))
    } catch (err) {
        console.log(err)
    }

    if (!day) {
        day = newDay(date, [])
    }
    return day
}

export function getAreas(day) {
    if (day && day.areas) {
        return mapToSortedArray(day.areas)
    } else {
        return null
    }
}

function loadDays(days, startDate, endDate, areas) {
    const today = moment(new Date())
    const range = {from: startDate, to: endDate}
    for (let m = moment(startDate); m.diff(endDate, 'days') < 0; m.add(1, 'days')) {
        let ref = momentToDateString(m)
        let day = newDay(m.toDate(), areas)
        day.period = {archived: m.isBefore(today)}
        day.range = range
        days.set(ref, day)
    }
}

function loadDaysWithPeriodData(days, periods, areas, terminateDate) {
    if (!periods || periods.length === 0) return
    let beforeYesterday = moment().subtract(2, 'days')
    periods.forEach(period => {
        let periodStart = moment(period.from)
        let periodEnd = moment(period.to)
        for (var m = moment(periodStart); m.diff(periodEnd, 'days') <= 0; m.add(1, 'days')) {
            // Only include for current and future periods
            // Is home area?
            let home = false
            period.home.some((m, range) => {
                let homeStart = moment(range.from)
                let homeEnd = moment(range.to)
                if (homeStart.diff(m, 'days') <= 0 &&
                    homeEnd.diff(m, 'days') >= 0) {
                    home = true
                }
                return home
            })

            let ref = momentToDateString(m)
            let day = days.get(ref)
            if (!day) {
                day = newDay(m.toDate(), areas)
                days.set(ref, day)
            }
            day.period = period // Assign period reference to day
            let area = day.areas.get(period.areaId)

            if (m.isAfter(beforeYesterday)) {
                area.timeOffOpen = period.timeOffOpen
                area.workOpen = period.workOpen
                area.shiftsOpen = period.shiftsOpen
            }
            area.publishStatus = period.publishStatus
            area.reset = period.reset
            area.notice = period.notice
            if (home) {
                day.homeArea = area
            }
            //need to account for "terminateDate" if exists
            if (area.workOpen) {
                if(terminateDate) {
                    day.workOpen = moment(terminateDate).isAfter(m)
                }else{
                    day.workOpen = true
                }
            }
        }
    });
}

function loadDaysWithAssignments(days, assignments) {
    if (!assignments || assignments.length === 0) return
    assignments.forEach(assignment => {
        let m = moment(assignment.date)
        let ref = momentToDateString(m)
        let day = days.get(ref)
        if (day) {
            assignment.day = day // Need reference to day for Assignment Detail
            // Add area name to assignment
            let area = day.areas ? day.areas.get(assignment.areaId) : null
            assignment.areaName = area ? area.name : ""
            assignment.publishStatus = day.period.archived ? publishStatuses.ARCHIVED : (day.homeArea ? day.homeArea.publishStatus : publishStatuses.NOT_PUBLISHED)
            assignment.notice = day.homeArea ? day.homeArea.notice : null

            day.assignments.push(assignment)
        }
    });
}

function loadAreasWithPeriodData(areas, periods) {
    if (periods && periods.length > 0) {
        let foundHomeArea = false
        let beforeYesterday = moment().subtract(2, 'days')
        let today = moment()
        periods.forEach(period => {
            period.archived = isPeriodArchived(period)
            let periodStart = moment(period.from)
            let periodEnd = moment(period.to)
            if (periodEnd.isAfter(beforeYesterday)) {
                let area = areas.get(period.areaId)
                if (!area) {
                    console.error("Period area '" + period.areaId + "' is missing from calendar.areas")
                    area = {
                        id: period.areaId,
                        timeOffOpen: false,
                        workOpen: false,
                        shiftsOpen: false,
                        shiftDates: [],
                        home: false
                    }
                    areas.set(area.id, area)
                }
                // If today is in this period check if home area
                if (!foundHomeArea && periodStart.diff(today, 'days') <= 0 && periodEnd.diff(today, 'days') >= 0) {
                    period.home.some((today, range) => {
                        let homeStart = moment(range.from)
                        let homeEnd = moment(range.to)
                        if (homeStart.diff(today, 'days') <= 0 &&
                            homeEnd.diff(today, 'days') >= 0) {
                            foundHomeArea = area.home = true
                        }
                    })
                }
                if (period.timeOffOpen) area.timeOffOpen = true
                if (period.workOpen) area.workOpen = true
                if (period.shiftsOpen) area.shiftsOpen = true
            }
        });
    }
}
