import React from 'react';
import {toError} from "helpers/serviceStatus";
import {useSwapProposalsRequestsQuery} from "components/Swaps/SwapGQL"
import {processSwapProposalsAndRequestsResult, fillSwapStatus} from "components/Swaps/SwapStatus/SwapStatusData"
import SwapStatusList from "components/Swaps/SwapStatus/SwapStatusList"
import Bulletin from "components/Bulletin"
import Spinner from "components/Spinner"
import {useViewClasses} from "./View";



const SwapStatusView = ({layout, areas}) => {


    const [{fetching: fetchingPSPQ, data: dataPSPQ, error: errorPSPQ}, reexecuteQuery] = useSwapProposalsRequestsQuery()
    const {swapProposalsAndRequests, serviceStatus} = processSwapProposalsAndRequestsResult(fetchingPSPQ, dataPSPQ, errorPSPQ)



    const refresh = () => {
        reexecuteQuery({ requestPolicy: 'network-only' })
    }


    let body

    if (fetchingPSPQ) {
        const fillers = [fillSwapStatus(1), fillSwapStatus(2)]

        body = (
            <div id="swapStatusView" >
                <div className={"disabled"}>
                    <SwapStatusList  swapProposalsAndRequests={fillers} areas={areas} />
                </div>
                <Spinner show={true}/>
            </div>
        )
    }
    else if (swapProposalsAndRequests && swapProposalsAndRequests.length > 0) {
        console.log("logging Swap proposals and requests..." + JSON.stringify(swapProposalsAndRequests))
        body = (
            <div id="swapStatusView">
                <SwapStatusList  swapProposalsAndRequests={swapProposalsAndRequests} areas={areas} refresh={refresh} />
            </div>
        )
    } else {
        const message = "There are no swap requests or proposals awaiting your review at this time."
        body = <Bulletin message={message}/>
    }

    return layout({body, error: toError(serviceStatus), classes:useViewClasses()})

}


export default SwapStatusView
