import React from 'react'
import {ListGroup} from "react-bootstrap";
import NotificationDetail from "./NotificationDetail";
import "./notification.css"

const NotificationList = ({notifications, onSelect}) => {
    if (notifications) {
        return (
            <ListGroup id="notificationList" variant="flush">
                {notifications.map(notification => notification.isAvailable ? (
                        <ListGroup.Item className="notification available" key={notification.id} action
                                        onClick={() => onSelect(notification.id)}>
                            <NotificationDetail notification={notification}/>
                            <div className="arrow"><i className="fas fa-chevron-circle-right"></i></div>
                        </ListGroup.Item>) :
                    (<ListGroup.Item className="notification" key={notification.id} disabled>
                        <NotificationDetail notification={notification}/>
                        <div className="arrow"><i className="fas fa-chevron-circle-right"></i></div>
                    </ListGroup.Item>)
                )}
            </ListGroup>
        )
    } else {
        return <></>
    }
}

export default NotificationList