import {useStoreActions, useStoreState} from "Store";
import {Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useSwapInitEmpSchAndRecAreaQuery} from "../SwapGQL"
import {processSwapInitEmpSchAndRecAreaData} from "./SwapInitiateProposalData"
import AreaFormControl from "components/AreaFormControl"
import SwapInitAssignmentControl from "./SwapInitAssignmentControl"
import {useForm} from "react-hook-form"
import {calculateAreaId, isOpen} from "./swapInitiateProposalHelper"
import SwapListRecipients from "./SwapListRecipients"
import SwapInitiateButtonControl from "./SwapInitiateButtonControl"
import "./swapInitiateProposal.css"
import "../swaps.css"
import {getArea} from "helpers/area"

const SwapListForm = ({
                          selectedSwapRecipient,
                          setSelectedSwapRecipient,
                          availableSwapRecipients,
                          setAvailableSwapRecipients,
                          initiateSwapProposalActions,
                          initiatorPrimarySkillRef,
                          setSwapNote,
                          recipientAreaId,
                          setRecipientAreaId,
                          nearbyInitEmpAsgnForRecipientsMap,
                          setNearbyInitEmpAsgnForRecipientsMap,
                          overlapInitEmpAsgnForRecipientsMap,
                          setOverlapInitEmpAsgnForRecipientsMap
                      }) => {

    const {register} = useForm()
    const state = useStoreState()
    const actions = useStoreActions()
    const assignment = {...state.assignment}
    const selectedRecipientKeyArr =[...selectedSwapRecipient]
    const [enableSubmitButton, setEnableSubmitButton] = useState(selectedRecipientKeyArr && selectedRecipientKeyArr.length > 0)

    //Step #1: fetch employee schedule & swappable recipient areas (GraphQL Query #1)
    const [{fetching: fetchingSAQ, data: dataSAQ, error: errorSAQ}]
        = useSwapInitEmpSchAndRecAreaQuery(assignment.date, assignment.areaId)

    //Step #2: Process results from GraphQL Query #1. Some result(ex: skill) would become input for subsequent 2nd query
    const {
        recipientAreas,
        primarySkill,
        compSkill,
        initEmpAssignmentsAndRequests,
        fetchInitEmpSchServiceStatus
    } = processSwapInitEmpSchAndRecAreaData(fetchingSAQ, dataSAQ, errorSAQ, assignment)


    //Step #3: calculate the real recipient Area Id, accounting for "not open" or other scenarios.
    const calculatedRecipientAreaId = calculateAreaId(recipientAreas, recipientAreaId)

    //Step #4: this "useEffect" would run after render. It is to set "primary skill", and would only run ONCE
    useEffect(() => {
        initiatorPrimarySkillRef.current = primarySkill
    }, [primarySkill])


    if (fetchInitEmpSchServiceStatus) {
        console.log("loading SwapListForm error: " + fetchInitEmpSchServiceStatus)
        return <></>
    }

    //back to "assignment detail" dialog
    function onBack() {
        actions.updateAssignActionWithNoSwap()
        //Now once user back to "assignment detail" dialog, let us consider this "swap" flow done. Housekeeping
        setSelectedSwapRecipient(new Array())
        setAvailableSwapRecipients(new Map())
        setNearbyInitEmpAsgnForRecipientsMap(new Map())
        setOverlapInitEmpAsgnForRecipientsMap(new Map())
        setSwapNote('')
    }


    const onAreaDropdownChange = (selectedAreaId) => {
        //update the state for "selected area"
        setRecipientAreaId(selectedAreaId)
    }


    const onSwapListFormSubmit = (input) => {
        //update the store to move on to "review" screen
        actions.updateAssignActionWithSwapReviewSelected(true)
    }


    function close() {
        initiateSwapProposalActions.close()
    }

    //Handle check/uncheck on the recipient ws
    //The reason we are not using "getValues" hook is that we need to review items in the order they are checked/added
    //Hence we will get raw selections here. (react-hook-form "getValues" hook isn't able to tell chronological order)
    function handleListCheckChange(event){
        //Step 1: find all selections and update "selectedSwapRecipient"
        let changedValue = event.target.value;
        let currSelections = [...selectedSwapRecipient]

        if (event.target.checked ) {
            currSelections.push(changedValue)
        }
        else {
            let index = currSelections.indexOf(changedValue);
            currSelections.splice(index, 1);
        }
        setSelectedSwapRecipient(currSelections)

        //Step 2: enable or disable submit button
        setEnableSubmitButton(currSelections && currSelections.length>0 )

    }

    //Step #5: render
    return (
        <Modal show={true} onHide={close} centered={true} backdrop="static">
            <Form>
                <Modal.Header className={"swapModalHeader"} closeButton>
                    <Modal.Title>
                        <span className={"swapLeftArrow"}><i className="fa fa-chevron-left" onClick={onBack}></i></span>
                        <span>Propose a Swap</span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={"swapModalBody"}>
                    <SwapInitAssignmentControl swapAssignment={assignment}/>

                    <div className="initSwapLabel boldFont">
                        Showing Shifts for:
                    </div>
                    <div className="swapAreaDiv">
                        <AreaFormControl name="areaId" areas={recipientAreas}
                                         selectedArea={getArea(recipientAreas, recipientAreaId)}
                                         isOpen={isOpen}
                                         label={" (Not Available)"}
                                         className={"swapAreaSelect"}
                                         register={register}
                                         onChange={onAreaDropdownChange}
                        />
                    </div>

                    <SwapListRecipients
                        primarySkill={primarySkill}
                        compSkill={compSkill}
                        recipientAreaId={calculatedRecipientAreaId}
                        recipientAreas={recipientAreas}
                        selectedSwapRecipient={selectedSwapRecipient}
                        availableSwapRecipients={availableSwapRecipients}
                        setAvailableSwapRecipients={setAvailableSwapRecipients}
                        initEmpAssignmentsAndRequests={initEmpAssignmentsAndRequests}
                        nearbyInitEmpAsgnForRecipientsMap={nearbyInitEmpAsgnForRecipientsMap}
                        setNearbyInitEmpAsgnForRecipientsMap={setNearbyInitEmpAsgnForRecipientsMap}
                        handleListCheckChange={handleListCheckChange}
                        overlapInitEmpAsgnForRecipientsMap={overlapInitEmpAsgnForRecipientsMap}
                        setOverlapInitEmpAsgnForRecipientsMap={setOverlapInitEmpAsgnForRecipientsMap}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <SwapInitiateButtonControl
                        onSwapListFormSubmit={onSwapListFormSubmit}
                        selectedSwapRecipient={selectedSwapRecipient}
                        enableSubmitButton={enableSubmitButton}
                    />
                </Modal.Footer>
            </Form>
        </Modal>


    )
}


export default SwapListForm