import React from "react";
import './AreaSchedule.css'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {validEmail, validPhoneNumber} from 'helpers/contact'
import NameValueList from "components/NameValueList"

/**
 * Modal dialog to show employee information including email and phone.
 *
 * @param employee
 * @param hide to hide or show
 * @returns {*}
 * @constructor
 */
const EmployeeContact = ({employee, onClose}) => {

    function callPhone(phoneNumber) {
        return `tel:${phoneNumber}`
    }

    function textPhone(phoneNumber) {
        return `sms:${phoneNumber}`
    }

    function email(emailId) {
        return `mailto:${emailId}`
    }

    function hide() {
        onClose()
    }

    if (employee) {
        let body = new Map();
        body.set("DCPOS", {text: employee.dcpos})
        body.set("Skill", {text: employee.skill})
        body.set("Phone", {text: validPhoneNumber(employee.phone) ? employee.phone : ''})
        body.set("Email", {text: validEmail(employee.email) ? employee.email.replaceAll("@", "\u200b@") : ''})

        return <Modal id="employeeContact" show={true} onHide={hide} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {employee.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NameValueList map={body} size={'medium'} striped={true}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" href={callPhone(employee.phone)}
                        disabled={!validPhoneNumber(employee.phone)}>
                    <i className="fas fa-phone"></i> Call
                </Button>
                <Button variant="secondary" href={textPhone(employee.phone)}
                        disabled={!validPhoneNumber(employee.phone)}>
                    <i className="fas fa-comment"></i> Text
                </Button>
                <Button variant="secondary" href={email(employee.email)} disabled={!validEmail(employee.email)}>
                    <i className="fas fa-at"></i> Email
                </Button>
            </Modal.Footer>
        </Modal>
    } else {
        return <></>
    }
}
export default EmployeeContact;