import React, {useState} from 'react'
import {calendarModes, getAreas, getDay} from 'components/Calendar/CalendarData'
import Calendar from 'components/Calendar'
import CalendarDay from 'components/CalendarDay'
import Request, {RequestType} from 'components/Request'
import {dateToString} from 'helpers/date'
import AreaFormControl from "components/AreaFormControl"
import If from "components/If";
import {ALLAreaId, createALLArea, getArea} from "helpers/area";
import {useAreaAvailableShiftDatesQuery} from "./AvailableShiftsGQL";
import {processAreaAvailableShiftDatesData} from "./AvailableShiftsData";
import {toError} from "helpers/serviceStatus";
import {relaunch} from "helpers/http";
import Notice from "components/Notice";
import {actionFunction, isActionDef} from "../../actions";
import './AvailableShiftsControls.css'

const AvailableShifts = ({days, areas, defaultArea, actions, onMonthChange, user}) => {
    console.log("trace...AvailableShifts...")

    const [date, setDate] = useState(new Date())
    const [selectedArea, setSelectedArea] = useState(defaultArea)
    const [showRequest, setShowRequest] = useState(false)

    let [{fetching, data, error}] = useAreaAvailableShiftDatesQuery(selectedArea)
    let {areaAvailableShiftDates, serviceStatus} = processAreaAvailableShiftDatesData(fetching, data, error)

    console.log("areaAvailableShiftDates=" + JSON.stringify(areaAvailableShiftDates))

    // If error encountered set error in parent and return empty body
    if (serviceStatus) {
        const error = toError(serviceStatus)
        if (error) {
            if (isActionDef(error.action)) {
                error.action = actionFunction(error.action, actions)
            }
            return <Notice message={error.message} type={error.noticeType}
                           onClose={serviceStatus.function ? serviceStatus.function : relaunch} show={true}/>
        }
    }

    const area = {...selectedArea, shiftDates: areaAvailableShiftDates}

    function onDateChange(date) {
        setDate(date)
    }

    function onAreaChange(areaId) {
        let selectedArea = (areaId === ALLAreaId) ? {...createALLArea()} : getArea(areas, areaId)
        setSelectedArea(selectedArea)
    }

    function isOpen(area) {
        return (area && 'shiftsOpen' in area)?area.shiftsOpen:true
    }

    const dayBannerActions = (day) => {
        return (
            <>
                <If condition={() => {
                    return showRequest
                }}>
                    <Request requestType={RequestType.OPEN_SHIFT} date={date} areas={getAreas(getDay(days, date))}
                             areaId={area.id} close={() => setShowRequest(false)}/>
                </If>
                <div
                    className={"request" + (!day || !area.shiftDates || !area.shiftDates.includes(dateToString(day.date)) ? " disabled" : "")}
                    onClick={() => setShowRequest(true)}>Request Available Shift <i
                    className={"fas fa-chevron-circle-right"}/></div>
            </>
        )
    }

    return <div id="availableShifts">
        <Calendar date={date} days={days} area={area}
                  mode={calendarModes.AVAILABLE_SHIFTS}
                  onDateChange={onDateChange}
                  onMonthChange={onMonthChange}
                  user={user}/>
        <AreaFormControl name="areaId" areas={areas}
                         selectedArea={area} isOpen={isOpen}
                         onChange={onAreaChange}
                         addAllAreaOption={true}/>
        <CalendarDay day={getDay(days, date)} dayBannerActions={dayBannerActions}/>
    </div>
}

export default AvailableShifts