import React from 'react';
import {toError} from "helpers/serviceStatus";
import {useProposedSwapProposalsQuery} from "components/Swaps/SwapGQL"
import  ProposedSwapsList from "components/Swaps/SwapsAsRecipient/ProposedSwapsList"
import {processProposedSwapProposalsResult, fillSwapRecipient} from "components/Swaps/SwapsAsRecipient/SwapsAsRecipientData"
import Bulletin from "components/Bulletin"
import Spinner from "components/Spinner"
import {useViewClasses} from "./View";

const SwapsAsRecipientView = ({layout}) => {


    const [{fetching: fetchingPSPQ, data: dataPSPQ, error: errorPSPQ}, reexecuteQuery] = useProposedSwapProposalsQuery()
    const {proposedSwapProposals, serviceStatus} = processProposedSwapProposalsResult(fetchingPSPQ, dataPSPQ, errorPSPQ)

    const refresh = () => {
        reexecuteQuery({ requestPolicy: 'network-only' })
    }

    let body

    if (fetchingPSPQ) {
        const fillers = [fillSwapRecipient(1)]
        body = (
            <div id="recipientSwapsView" >
                <div className={"disabled"}>
                    <ProposedSwapsList  proposedSwapProposals={fillers}  />
                </div>
                <Spinner show={true}/>
            </div>
        )
    }
    else if (proposedSwapProposals && proposedSwapProposals.length > 0) {
        console.log("logging Proposed Swaps..." +  JSON.stringify(proposedSwapProposals))
        body = (
            <div id="recipientSwapsView">
                <ProposedSwapsList  proposedSwapProposals={proposedSwapProposals} refresh={refresh}/>
            </div>
        )
    } else {
        const message = "There are no swap proposals awaiting your review at this time."
        body = <Bulletin message={message}/>
    }


    return layout({body, error: toError(serviceStatus), classes:useViewClasses()})
}

export default SwapsAsRecipientView

