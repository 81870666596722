import React from "react";
import {Form, Modal} from "react-bootstrap";
import {useStoreActions, useStoreState} from "Store"
import {SwapUIMode, SwapWarningMode} from "./swapInitiateProposalHelper"
import {minutesToDurationInHours, weekdayAndDayOfMonth} from "helpers/date"
import {formatWorkshift} from 'helpers/workshift'
import {getParamsAtStartup} from "components/Settings/SettingsData"
import SwapRecAssignmentControl from "./SwapRecAssignmentControl"

const SwapNearbyWorkshifts = ({
                                  availableSwapRecipients,
                                  selectedSwapRecipient,
                                  setSelectedSwapRecipient,
                                  nearbyInitEmpAsgnForRecipientsMap,
                                  overlapInitEmpAsgnForRecipientsMap
                              }) => {
    const state = useStoreState()
    const actions = useStoreActions()

    const assignment = {...state.assignment}
    const initAreaName = assignment.areaName


    let selectedRecipientKey = state.assignAction.swapSelectedRecipient.selectedSwapRecAssignmentKey
    let swapRecAssignment = availableSwapRecipients.get(selectedRecipientKey)


    let swapWarningMode = state.assignAction.swapSelectedRecipient.swapWarning //It can be either "Nearby" or "Overlap"

    const nearbyHours = (getParamsAtStartup()).swapNearbyWarningThresholdInMinutes / 60
    let nearbyInitSchedule = nearbyInitEmpAsgnForRecipientsMap.get(selectedRecipientKey)//an array

    let overlapInitSchedule = overlapInitEmpAsgnForRecipientsMap.get(selectedRecipientKey)//an array


    function onBack() {
        if (state.assignAction.swapSelectedRecipient.from === SwapUIMode.LIST) {
            actions.updateAssignActionWithSwapListSelected(true)
        } else {
            actions.updateAssignActionWithSwapReviewSelected(true)
        }
    }

    function handleNearbyCheckChange(event) {
        let changedValue = event.target.value;
        let currSelections = [...selectedSwapRecipient]

        if (event.target.checked) {
            currSelections.push(changedValue)
        } else {
            let index = currSelections.indexOf(changedValue);
            currSelections.splice(index, 1);
        }
        setSelectedSwapRecipient(currSelections)
    }

    const NearbyInitScheduleList = () => {

        return (
            nearbyInitSchedule.map((initAssignment) => {
                return (
                    <div className="swapShift" key={initAssignment.uniqueKey}>
                        <div>
                            <span className="boldFont">&nbsp;{weekdayAndDayOfMonth(initAssignment.date, 0)}</span>
                            <br/>
                            &nbsp;{formatWorkshift(initAssignment)}&nbsp;({minutesToDurationInHours(initAssignment.workMinutes)}hrs)
                            <br/>
                            <span className="mediumFont">
                              &nbsp;<span
                                className="boldFont">Area:</span> {initAssignment.areaId}&nbsp;{initAreaName}&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="boldFont">Type:</span> {initAssignment.type}&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="boldFont">Status:</span> {initAssignment.pendingStatus}
                            </span>
                        </div>

                        {initAssignment.endsNearby &&
                        <div className="swapWarning boldFont">
                            <i className="fas fa-exclamation-triangle"></i>
                            <span>This shift ends within {nearbyHours} hours of the shift you may swap with above.</span>
                        </div>
                        }

                        {initAssignment.startsNearby &&
                        <div className="swapWarning boldFont">
                            <i className="fas fa-exclamation-triangle"></i>
                            <span>This shift starts within {nearbyHours} hours of the shift you may swap with above.</span>
                        </div>
                        }


                    </div>
                )

            })

        )

    }

    const OverlapInitScheduleList = () => {

        return (
            overlapInitSchedule.map((initAssignment) => {
                return (
                    <div className="swapShift" key={initAssignment.uniqueKey}>
                        <div>
                            <span className="boldFont">&nbsp;{weekdayAndDayOfMonth(initAssignment.date, 0)}</span>
                            <br/>
                            &nbsp;{formatWorkshift(initAssignment)}&nbsp;({minutesToDurationInHours(initAssignment.workMinutes)}hrs)
                            <br/>
                            <span className="mediumFont">
                              &nbsp;<span
                                className="boldFont">Area:</span> {initAssignment.areaId}&nbsp;{initAreaName}&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="boldFont">Type:</span> {initAssignment.type}&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="boldFont">Status:</span> {initAssignment.pendingStatus}

                            </span>
                        </div>

                        <div className="swapWarning boldFont">
                            <i className="fas fa-exclamation-triangle"></i>
                            <span>This shift overlaps the shift you may swap with above.</span>
                        </div>
                    </div>
                )

            })

        )

    }

    return (
        <Modal show={true} centered={true} backdrop="static">
            <Form>
                <Modal.Header className={"swapModalHeader"}  /*closeButton*/>
                    <Modal.Title>
                        <span className={"swapLeftArrow"}><i className="fa fa-chevron-left" onClick={onBack}></i></span>
                        {swapWarningMode == SwapWarningMode.NEARBY && <span>Your Nearby Shifts</span> }
                        {swapWarningMode == SwapWarningMode.OVERLAP && <span>Your Overlap Shifts</span> }
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className={"swapModalBody"}>

                    <div className="initSwapLabel boldFont">
                        Potential Shift to Swap With:
                    </div>

                    <SwapRecAssignmentControl swapRecAssignment={swapRecAssignment}
                                              mode={SwapUIMode.NEARBY}
                                              name={"nearbySwapRecipient"}
                                              selectedSwapRecipient={selectedSwapRecipient}
                                              onCheckChange={handleNearbyCheckChange}

                    />

                    <div className="initSwapLabel boldFont">
                        Your schedule:
                    </div>

                    {swapWarningMode == SwapWarningMode.NEARBY && nearbyInitSchedule && nearbyInitSchedule.length > 0
                    && <NearbyInitScheduleList/>
                    }

                    {swapWarningMode == SwapWarningMode.OVERLAP && overlapInitSchedule && overlapInitSchedule.length > 0
                    && <OverlapInitScheduleList/>
                    }


                </Modal.Body>
            </Form>
        </Modal>
    )


}

export default SwapNearbyWorkshifts