import React from 'react'
import Form from "react-bootstrap/Form"
import {useFormContext} from "react-hook-form";
import OtherEmailVerifyControl from "./VerifyEmailOrPhone/OtherEmailVerifyControl";

const SAContactForm = ({data: userProfileData, emailVerified, setEmailVerified}) => {

    const {register} = useFormContext()
    const isOtherEmailInitiallyVerified = () => {
        return userProfileData.notification.notfyEmailVerified
    }

    const isOtherEmailCurrentlyVerified = () => {
        return emailVerified
    }

    return (
        <>
            <Form.Group>
                <Form.Label className="formLabel">Primary address</Form.Label>
                <OtherEmailVerifyControl data={userProfileData}
                                         name={"otherEmail"}
                                         emailNotifyMethod={'oEmail'}
                                         setEmailVerified={setEmailVerified}
                                         isOtherEmailInitiallyVerified={isOtherEmailInitiallyVerified}
                                         isOtherEmailCurrentlyVerified={isOtherEmailCurrentlyVerified}/>
                <Form.Control type="hidden"
                              as="input" name="emailNotifyTo"
                              ref={register}
                              defaultValue={'oEmail'}
                />
            </Form.Group>

        </>
    )
}

export default SAContactForm