import React from 'react';
import Notice from "components/Notice";
import {useUserQuery} from "components/UserProfile/UserProfileGQL";
import {processUserQueryResult} from "components/UserProfile/UserProfileData";
import {relaunch, toHash} from "helpers/http";
import {getDefaultFeature} from "features";
import SplashView from "./SplashView";
import {Redirect} from "react-router-dom";

const DefaultRedirect = ({layout, actions}) => {
    console.log("Fetching user from network-only...")
    let [{fetching, data, error, stale}] = useUserQuery("cache-only")
    console.log("useUserQuery results: " + fetching + "," + (data ? 'data' : 'nodata') + "," + (error ? 'error' : 'noerror') + "," + stale)
    if (fetching) return <SplashView/>
    let [user, serviceStatus] = processUserQueryResult(fetching, data, error)

    function handleNoticeResponse() {
        relaunch()
    }

    if (serviceStatus) {
        return (
            <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                    onClose={handleNoticeResponse} show={true}/>
        )
    }

    const hash = toHash(getDefaultFeature(user, user.defaultRole).url)
    console.log("Forwarding to user default feature (" + hash + ") ...")
    return <Redirect to={hash}/>
}

export default DefaultRedirect