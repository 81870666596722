import React, {useState} from 'react'
import {useSubmitRequestMutation} from './RequestGQL';
import ServiceStatus, {makeServiceStatus} from "helpers/serviceStatus";
import {dateToString} from "helpers/date";
import Spinner from "components/Spinner";
import Notice from "components/Notice";
import RequestControl from "./RequestControl";
import './Request.css'

export const RequestType = {
    OPEN_SHIFT: 'OPEN_SHIFT',
    WORK: 'WORK',
    TIME_OFF: 'TIME_OFF',
    SWAP: 'SWAP'
}

export const RequestTypeTitle = {
    OPEN_SHIFT: 'Available Shift',
    WORK: 'Work',
    TIME_OFF: 'Time Off',
    SWAP: 'Swap'
}

const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})

const Request = ({requestType, date, areas, areaId, close}) => {
    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})
    const [show, setShow] = useState(SHOW.FORM)
    const [request] = useState({
        requestType: requestType,
        areaId: areaId,
        startDate: dateToString(date),
        endDate: dateToString(date),
        resubmit: false
    })

    const [submitRequestResult, submitRequest] = useSubmitRequestMutation()

    const actions = {
        submit: request => {
            setShow(SHOW.SPINNER)
            const variables = {"request": request}
            console.log(variables)
            submitRequest(variables).then(result => {
                console.log(result)
                setServiceStatus(makeServiceStatus(result.data ? result.data.submitRequest : null, result.error))
                if (serviceStatus) {
                    setShow(SHOW.NOTICE)
                } else {
                    setShow(SHOW.NONE)
                    actions.cancel()
                }
            })
        },
        resubmit: request => {
            request.resubmit = true
            actions.submit(request)
        },
        cancel: () => {
            close()
        }
    }

    function handleNoticeResponse(confirmed) {
        if (confirmed) {
            setShow(SHOW.NONE)
            actions.resubmit(request)
        } else {
            if (serviceStatus.success) {
                setShow(SHOW.NONE)
                actions.cancel()
            } else {
                setShow(SHOW.FORM)
            }
        }
    }

    switch (show) {
        case SHOW.NOTICE: {
            return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                           onClose={handleNoticeResponse} show={true}/>
        }
        case SHOW.SPINNER: {
            return <Spinner show={true}/>
        }
        case SHOW.FORM: {
            return (
                <RequestControl request={request} areas={areas} actions={actions} areaId={areaId}/>
            )
        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }
}

export default Request