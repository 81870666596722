import React, {useEffect, useState} from "react"
import {useSendEmailVerificationCodeQuery} from "../../UserProfileGQL"
import {makeSendEmailVerificationCodeStatus} from "../../UserProfileData"
import PrimaryEmailVerifyForm from "./PrimaryEmailVerifyForm"
import ServiceStatus from "../../../../helpers/serviceStatus"



const PrimaryEmailVerifyControl = ({data: userProfileData, emailNotifyMethod, isPrimaryEmailVerified, setEmailVerified}) => {

    //"emailTo" is query input variable for "SendEmailVerification" useQuery.
    //having query input variable value initialized to "undefined" on purpose to pause the execution of useQuery hook
    //This query should only be executed when "verify" button is clicked
    const [emailToQueryLatch, setEmailToQueryLatch] = useState(undefined)
    const [showVerifyEmailDialog, setShowVerifyEmailDialog] = useState(false)

    const [{fetching: fetchingEVQ, data: dataEVQ, error: errorEVQ}]
            = useSendEmailVerificationCodeQuery(emailToQueryLatch, userProfileData.email)

    let serviceStatus = makeSendEmailVerificationCodeStatus(fetchingEVQ, dataEVQ, errorEVQ)
    // console.log("requestEmailVerifyCode-- serviceStatus = " + JSON.stringify(serviceStatus))


    useEffect(() => {
        // console.log("PrimaryEmailVerifyControl useEffect reset EmailToQueryLatch to undefined")
        setEmailToQueryLatch(undefined) //reset latch back to "undefined" as soon as fetch query returns result
    }, [dataEVQ])

    if( (serviceStatus !== ServiceStatus.SUCCESS) && (serviceStatus !== ServiceStatus.UNKNOWN))  {
        console.log("Error out when generating verification code: " + serviceStatus)
        return <></>
    }

    function handleVerifyButtonClick(){
        //Step 1. Changing query input variable value to trigger useQuery hook executing queries
        setEmailToQueryLatch(emailNotifyMethod)

        //Step 2. bring up "Verify" dialog
        setShowVerifyEmailDialog(true)

    }



    return (
            <PrimaryEmailVerifyForm  userProfileData={userProfileData} emailNotifyMethod={emailNotifyMethod}
                    isPrimaryEmailVerified={isPrimaryEmailVerified}  handleVerifyButtonClick={handleVerifyButtonClick}
                    showVerifyEmailDialog={showVerifyEmailDialog}
                    setShowVerifyEmailDialog={setShowVerifyEmailDialog}  setEmailVerified={setEmailVerified}
            />

    )
}

export default PrimaryEmailVerifyControl