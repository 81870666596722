import {logsRead} from "helpers/logging";
import moment from "moment";

export function createIssue(description) {
    const issue = {
        userAgent: navigator.userAgent,
        deviceDatetime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
        description: btoa (description),
        logs: encodeLogs(logsRead())
    }
    return issue
}

function encodeLogs(strArray){
    let encodedArray=[]
    if(strArray == null){
        return encodedArray
    }

    strArray.forEach(each=>{
        encodedArray.push(btoa (each))
    })

    return encodedArray
}

