import React from 'react'
import {
    duration,
    formatInServerTZ_24hour,
    formatInServerTZ_DOW,
    formatDowDate,
    formatInServerTZ_TimeStamp,
} from "helpers/date"
import NameValueList from "components/NameValueList"
import {useStoreActions, useStoreState} from "Store";
import Modal from "react-bootstrap/Modal";
import './Assignment.css'
import AssignOptions from "./AssignOptions";
import {publishStatusClass, publishStatusTitle, requestTypeTitle} from "components/Calendar/CalendarData";
import {formatWorkshift} from "helpers/workshift";


function Assignment() {
    const state = useStoreState()
    const actions = useStoreActions()

    if (state.assignAction.retractSelected === true && state.assignAction.count === 0) {
        actions.updateAssignAction({
            retractable: false,
            swapable: false,
            openAccordian: null,
            count: 0,
            retractSelected: false,
            swapListSelected: false,
            swapReviewSelected: false,
            swapNearbyWorkshiftsSelected: false
        })
    }

    function hide() {
        actions.updateAssignment(null)
    }

    function assignOptions() {
        if (state.online) {
            return <AssignOptions areaId={state.assignment.areaId}
                                  code={state.assignment.code}
                                  startTime={state.assignment.from ? formatInServerTZ_TimeStamp(state.assignment.from) : ''}
                                  endTime={state.assignment.to ? formatInServerTZ_TimeStamp(state.assignment.to) : ''}
                                  workMinutes={state.assignment.workMinutes}
                                  totalMinutes={state.assignment.totalMinutes}
                                  workDate={state.assignment.date}
                                  label={state.assignment.label}
                                  pending={state.assignment.pending}/>
        } else {
            return <></>
        }
    }

    function showAssignment() {
        return (state.assignment && state.assignAction.retractSelected === false
            && state.assignAction.swapListSelected === false && state.assignAction.swapReviewSelected === false
            && state.assignAction.swapNearbyWorkshiftsSelected === false)
    }

    if (showAssignment()) {
        console.log(state.assignment)
        let map = new Map()
        if (!state.assignment.day.period.archived) {
            map.set('Period Status', {
                className: publishStatusClass(state.assignment.publishStatus),
                text: publishStatusTitle(state.assignment.publishStatus) + (state.assignment.notice ? " (" + state.assignment.notice + ")" : "")
            })
        }
        map.set('Date', {text: formatDowDate(state.assignment.date)})
        map.set('Workshift', {text: formatWorkshift(state.assignment)})
        map.set('Start', {text: formatInServerTZ_24hour(state.assignment.from) + " " + formatInServerTZ_DOW(state.assignment.from)})
        map.set('End', {text: formatInServerTZ_24hour(state.assignment.to) + " " + formatInServerTZ_DOW(state.assignment.to)})
        map.set('Duration', {text: duration(state.assignment.workMinutes)})

        map.set('Area', {
            image: state.assignment.home ? 'fa-home' : 'fa-share',
            text: state.assignment.areaId + " " + state.assignment.areaName
        })
        if (state.assignment.team || state.assignment.task) {
            map.set('Team', {text: state.assignment.team})
            map.set('Task', {text: state.assignment.task})
        }
        // Assignment will only have type if employee requested this assignment (Available Shift, Work or Time Off)
        if (state.assignment.type) {
            map.set('Workshift Type', {text: requestTypeTitle(state.assignment.type)})
            map.set('Status', state.assignment.pending ? {
                image: "fa-question-circle",
                text: "Pending"
            } : {text: "Approved"})
        } else {
            map.set('Workshift Type', {text: state.assignment.work ? "Work" : "Time Off"})
        }

        const title = state.assignment.pending ? "Request Detail":"Workshift Detail"
        return (
            (
                <>
                    <Modal id='assignment' show={true} onHide={hide} centered={true} style={{maxHeight: "600"}}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <NameValueList map={map} striped={false}/>
                        </Modal.Body>
                        <Modal.Footer>
                            {assignOptions()}
                        </Modal.Footer>
                    </Modal>
                </>
            )
        )
    } else {
        return <></>
    }
}

export default Assignment
