import {makeServiceStatus} from "helpers/serviceStatus";

export function processAreaAvailableShiftDatesData(fetching, data, error) {
    if (error) {
        const serviceStatus = makeServiceStatus(data ? data.areaAvailableShiftDates : null, error)
        if (serviceStatus && !serviceStatus.success) {
            console.log("trace...processAreaAvailableShiftDatesData: ERROR")
            return {areaAvailableShiftDates: [], serviceStatus: serviceStatus}
        }
    }

    if (data && data.areaAvailableShiftDates) {
        const dates = data.areaAvailableShiftDates.dates
        console.log("trace...processAreaAvailableShiftDatesData: dates=" + dates)
        return {areaAvailableShiftDates: dates}
    } else {
        console.log("trace...processAreaAvailableShiftDatesData: NO DATA")
        return {areaAvailableShiftDates: []}
    }
}

