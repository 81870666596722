import React from "react";

export function featureDisabled(feature) {
    const message = feature.title + " has been temporarily disabled while your device is offline"
    return bulletin(message)
}

export function bulletin(message) {
    return <div id='bulletin'>{message}</div>
}

