import React from 'react';
import Form from "react-bootstrap/Form";
import './NameValueList.css'
/**
 * Renders a Table with no column headers and with a row for each name/value pair in map.
 *
 * @param map a Map() object with name value pairs
 * @returns {JSX.Element}
 * @constructor
 */
const NameValueList = ({map, size="medium",striped = true, borders = true}) => {
    if (!map) return <></>

    // const capitalize = (text) => {
    //     return text.charAt(0).toUpperCase() + text.slice(1)
    // }

    // value can be string or {image: , text: }
    const row = (key, value) => {
        if (value && value.image) {
            return <Form.Group key={key}>
                <Form.Label className='my-auto'>{key}</Form.Label>
                <div className={'form-control-plaintext'+ (value.className ? " " +value.className : "")}><i className={"fas " + value.image}/> {value.text}</div>
            </Form.Group>
        } else {
            return <Form.Group key={key}>
                <Form.Label className='my-auto'>{key}</Form.Label>
                <div className={'form-control-plaintext' + (value.className ? " " +value.className : "")} type="text">{value.text}</div>
            </Form.Group>
        }
    }

    return <div className={'name-value-list'+ (striped?' striped':'')+' '+size}>
        {[...map].map((value, key) => row(value[0], value[1]))}
    </div>
}

export default NameValueList