import React from 'react'
import {useCalendarQuery} from 'components/Calendar/CalendarGQL'
import {processCalendarData} from 'components/Calendar/CalendarData'
import {toError} from "helpers/serviceStatus";
import {useViewClasses} from "./View";
import {filterAreaList, getDefaultOrHomeArea} from "helpers/area";
import AvailableShifts from "components/AvailableShifts";

const AvailableShiftsView = ({layout, user, actions}) => {
    console.log("trace...AvailableShiftsView...")

    const [{fetching: fetchingCD, data: dataCD, error: errorCD}, reExecuteCalendarQuery] = useCalendarQuery()
    let {days, areas, serviceStatus} = processCalendarData(fetchingCD, dataCD, errorCD)

    const error = (serviceStatus ? toError(serviceStatus) : null)

    const empAreas = filterAreaList(areas, user.areasEmp.map(area => area.id))

    const onMonthChange = () => {
        reExecuteCalendarQuery({ requestPolicy: 'network-only' })
    }

    let body = (
        <div id="availableShiftsView">
            <AvailableShifts days={days} areas={empAreas} defaultArea={getDefaultOrHomeArea(user)} actions={actions} onMonthChange={onMonthChange} user={user}/>}
        </div>
    )

    return layout({body, error: error, classes: useViewClasses()})
}

export default AvailableShiftsView