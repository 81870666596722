import React from 'react';
import LegalNotice from "components/LegalNotice/LegalNotice";
import {useLegalNoticeQuery} from "components/LegalNotice/LegalNoticeGQL";
import {processLegalNoticeData} from "components/LegalNotice/LegalNoticeData";
import {useViewClasses} from "./View";

const AboutView = ({layout}) => {
    console.log("About view...")
    const [{fetching, data, error}] = useLegalNoticeQuery(false)
    const {legalNotice, serviceStatus} = processLegalNoticeData(fetching, data, error)

    let body = <LegalNotice legalNotice={legalNotice}/>

    return (layout({body, error: serviceStatus, classes: useViewClasses()}))
}

export default AboutView