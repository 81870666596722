export function storeString(key, value) {
    if (key && value) {
        window.localStorage.setItem(key, value)
    } else {
        removeString(key)
    }
}

export function removeString(key) {
    if (key) window.localStorage.removeItem(key)
}

export function fetchString(key) {
    return key ? window.localStorage.getItem(key) : null
}

export function storeDate(key, date) {
    if (key && date && date instanceof Date) {
        storeString(key, date.toISOString())
    } else {
        removeString(key)
    }
}

export function fetchDate(key) {
    const isoDateString = fetchString(key)
    if (isoDateString) {
        return new Date(isoDateString)
    } else {
        return null
    }
}