import React from 'react'
import Notice from "components/Notice"
import Banner from "components/Banner"
import {relaunch} from "helpers/http";
import './View.css'

/**
 * Render a page like Launch page, but without user and options sections.
 * Show serviceStatus Notice on top, then relaunch app.
 * @param serviceStatus ServiceStatus object, expected to be not null
 * @returns {JSX.Element}
 * @constructor
 */
const ServiceStatusView = ({serviceStatus}) => {
    return (
        <div id="serviceStatus">
            <Banner/>
            <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                    onClose={serviceStatus.function?serviceStatus.function:relaunch} show={true}/>
        </div>
    )
}

export default ServiceStatusView