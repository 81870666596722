import React, {useEffect, useRef, useState} from 'react'
import {useStoreActions, useStoreState} from "Store"
import Spinner from "components/Spinner";
import SwapListForm from "./SwapListForm";
import SwapReviewForm from "./SwapReviewForm";
import SwapNearbyWorkshifts from "./SwapNearbyWorkshifts"
import {useInitiateSwapProposalMutation} from "../SwapGQL"
import ServiceStatus from "helpers/serviceStatus"
import {makeInitiateSwapProposalResultStatus} from "./SwapInitiateProposalData"
import Notice from "components/Notice"

//entry point for "initiate a swap proposal"
function Swap() {
    //Note SHOW.NONE is taken out on purpose here. Instead it would be a scenario in "FORM"
    //The reason is so that state in overmind store can derive what to display: list, review, nearby workshift or empty(none).
    //This way end user could start a new swap workflow right after a prior submission, instead of being stuck with "NONE"
    const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2})
    const state = useStoreState()

    const actions = useStoreActions()
    const [show, setShow] = useState(SHOW.FORM)

    const [initiateSwapProposalResult, initiateSwapProposal] = useInitiateSwapProposalMutation()

    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})

    //The reason below states are kept at this parent level is that its children such as "list", "review", "nearby workshifts"
    //need to exchange data/communicate with each other.

    //The reason this state is at this parent level (instead of "SwapListForm.jsx") is so that "SwapNearbyWorkshift" dialog can return to
    // the the "list view" with the right area
    const [recipientAreaId, setRecipientAreaId] = useState(null)

    //The reason this is a "state" variable is that user-entered note would not get lost, should end user explores in this 2 way wizard-like navigation
    const [swapNote, setSwapNote] = useState('')


    //"selectedSwapRecipients" is an array of the selected recipients (represented by keys) across all areas
    const [selectedSwapRecipient, setSelectedSwapRecipient] = useState([])

    //"availableSwapRecipients" is a flat map keyed by unique id for swap recipient, value being swap recipient assignment JS object
    //It comprises of all recipients for all areas
    //The purpose of this variable is like a dictionary so that "Review" or "Neayby ws" UI can render selected swap recipient details based on keys
    const [availableSwapRecipients, setAvailableSwapRecipients] = useState(new Map())

    //"nearbyInitEmpAsgnForRecipientsMap" is a map keyed by unique id for swap recipient assignment; value being an array of initiator assignments/requests
    // that is within N hours of this recipient assignment--here N is defined in "swap.nearbyWorkshift.warningThreshold" in wsm.properties.
    const [nearbyInitEmpAsgnForRecipientsMap, setNearbyInitEmpAsgnForRecipientsMap] = useState(new Map())

    //"overlapInitEmpAsgnForRecipientsMap" is a map keyed by unique id for swap recipient assignment; value being an array of initiator assignments
    // that overlaps this recipient assignment
    const [overlapInitEmpAsgnForRecipientsMap, setOverlapInitEmpAsgnForRecipientsMap] = useState(new Map())


    // Deploy class instance variables independent of the state so that it would not trigger component re-rendering
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
    // https://reactjs.org/docs/hooks-reference.html#useref

    //"initiatorPrimarySkill" is used as an instance variable here ("primary skill" is NOT a "state" since no rerender needed. We need a way to share it across components)
    //it is fetched via GraphQL query in "Swap List", and then it is used as an input to "Initiate proposal" mutation in "Swap review"
    const initiatorPrimarySkillRef = useRef('')


    //Below useEffect would only run ONCE -- it is actually setting the initial value of "recipient Area Id"
    useEffect(() => {
        setRecipientAreaId(state.assignment ? state.assignment.areaId : null)
    }, [state.assignment])


    const initiateSwapProposalActions = {
        submit: (swapProposalInput) => {
            setShow(SHOW.SPINNER)
            let variables = {"swapInitiateProposalInput": swapProposalInput}
            initiateSwapProposal(variables).then(({fetching: fetchingISPM, data: dataISPM, error: errorISPM}) => {
                console.log("after initiate swap proposal, data = " + JSON.stringify(dataISPM))
                let initiateSwapProposalStatus = makeInitiateSwapProposalResultStatus(fetchingISPM, dataISPM, errorISPM)
                setServiceStatus(initiateSwapProposalStatus)
                setShow(SHOW.NOTICE)

            })
        },

        close: () => {//end of this workflow. Do housekeeping
            actions.updateAssignment(null)
            actions.updateAssignActionWithNoSwap()//Note this would derive a equivalent of "SHOW.NONE" so that nothing is shown
            setSelectedSwapRecipient([])
            setAvailableSwapRecipients(new Map())
            setNearbyInitEmpAsgnForRecipientsMap(new Map())
            setOverlapInitEmpAsgnForRecipientsMap(new Map())
            setSwapNote('')
        }
    }


    function handleNoticeResponse() {
        if (serviceStatus.success) {
            initiateSwapProposalActions.close()
        }
        setShow(SHOW.FORM)

    }

    function showSwapList() {
        return (state.assignAction.count && state.assignAction.count === 1 &&
            state.assignAction.swapListSelected && state.assignAction.swapListSelected === true)
    }

    function showSwapReview() {
        return (state.assignAction.count && state.assignAction.count === 1 &&
            state.assignAction.swapReviewSelected && state.assignAction.swapReviewSelected === true)
    }


    function showSwapNearbyWorkshifts() {
        return (state.assignAction.count && state.assignAction.count === 1 &&
            state.assignAction.swapNearbyWorkshiftsSelected && state.assignAction.swapNearbyWorkshiftsSelected === true)
    }


    switch (show) {
        case SHOW.NOTICE: {
            return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                           onClose={handleNoticeResponse}
                           show={true}/>
        }
        case SHOW.SPINNER: {
            return <Spinner show={true}/>
        }
        case SHOW.FORM: {//4 sub-scenarios: list, review, nearby workshift, empty(none)
            if (showSwapList()) {//#1. "List" view
                return (
                    <SwapListForm
                        selectedSwapRecipient={selectedSwapRecipient}
                        setSelectedSwapRecipient={setSelectedSwapRecipient}
                        availableSwapRecipients={availableSwapRecipients}
                        setAvailableSwapRecipients={setAvailableSwapRecipients}
                        initiateSwapProposalActions={initiateSwapProposalActions}
                        initiatorPrimarySkillRef={initiatorPrimarySkillRef}
                        setSwapNote={setSwapNote}
                        recipientAreaId={recipientAreaId}
                        setRecipientAreaId={setRecipientAreaId}
                        nearbyInitEmpAsgnForRecipientsMap={nearbyInitEmpAsgnForRecipientsMap}
                        setNearbyInitEmpAsgnForRecipientsMap={setNearbyInitEmpAsgnForRecipientsMap}
                        overlapInitEmpAsgnForRecipientsMap={overlapInitEmpAsgnForRecipientsMap}
                        setOverlapInitEmpAsgnForRecipientsMap={setOverlapInitEmpAsgnForRecipientsMap}
                    />
                )
            } else if (showSwapReview()) {//#2. "Review" view
                return (
                    <SwapReviewForm
                        selectedSwapRecipient={selectedSwapRecipient}
                        setSelectedSwapRecipient={setSelectedSwapRecipient}
                        availableSwapRecipients={availableSwapRecipients}
                        initiateSwapProposalActions={initiateSwapProposalActions}
                        initiatorPrimarySkillRef={initiatorPrimarySkillRef}
                        swapNote={swapNote}
                        setSwapNote={setSwapNote}
                        nearbyInitEmpAsgnForRecipientsMap={nearbyInitEmpAsgnForRecipientsMap}
                    />
                )
            } else if (showSwapNearbyWorkshifts()) {//#3. "Nearby Workshift" view
                return (
                    <SwapNearbyWorkshifts
                        availableSwapRecipients={availableSwapRecipients}
                        selectedSwapRecipient={selectedSwapRecipient}
                        setSelectedSwapRecipient={setSelectedSwapRecipient}
                        nearbyInitEmpAsgnForRecipientsMap={nearbyInitEmpAsgnForRecipientsMap}
                        overlapInitEmpAsgnForRecipientsMap={overlapInitEmpAsgnForRecipientsMap}

                    />
                )
            } else {//#4.  "NONE" view; equivalent to SHOW.NONE
                return <></>
            }
        }
        default: {
            return <></>
        }
    }

}


export default Swap
