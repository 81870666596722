import React, {useState} from 'react';
import {calendarModes, getDay, processCalendarData} from 'components/Calendar/CalendarData'
import Calendar from 'components/Calendar'
import CalendarDay from 'components/CalendarDay'
import {useCalendarQuery} from 'components/Calendar/CalendarGQL';
import {toError} from "helpers/serviceStatus";
import {useViewClasses} from "./View";

const CalendarView = ({layout, user}) => {
    console.log("trace...CalendarView")
    const [date, setDate] = useState(new Date())
    let [{fetching: fetchingCD, data: dataCD, error: errorCD, stale: staleCD}, reExecuteCalendarQuery] = useCalendarQuery()
    console.log("useCalendarQuery results: " + fetchingCD + "," + (dataCD ? 'data' : 'nodata') + "," + (errorCD ? 'error' : 'noerror') + "," + staleCD)
    let {days, serviceStatus} = processCalendarData(fetchingCD, dataCD, errorCD)
    console.log("calendar.days=" + JSON.stringify(days))
    console.log("calendar.serviceStatus=" + JSON.stringify(serviceStatus))

    const onMonthChange = () => {
        reExecuteCalendarQuery({ requestPolicy: 'network-only' })
    }

    const noAction = () => (<></>)

    let body = (
        <div id="monthView" style={{border: 0, margin: 0, padding: 0}}>
            <Calendar date={date} days={days} mode={calendarModes.CALENDAR}
                      onDateChange={(date) => setDate(date)}
                      onMonthChange={onMonthChange}
                      user={user}/>
            <CalendarDay day={getDay(days, date)} dayBannerActions={noAction}/>
        </div>
    )
    return layout({body, error: toError(serviceStatus), classes: useViewClasses()})
}

export default CalendarView