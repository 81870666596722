import React from 'react'
import {formatWorkshift} from 'helpers/workshift'
import {formatDate, minutesToDurationInHours, formatInServerTZ} from "helpers/date"
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button"
import {useForm} from "react-hook-form"

const ProposedSwapProposalControl = ({proposedSwap, actions}) => {

    const {register, getValues, setValue, handleSubmit} = useForm({ mode: 'onSubmit' })


    let initiatorWorkshift = {
        code: proposedSwap.initWsCode,
        from:  proposedSwap.initWsFrom,
        to: proposedSwap.initWsTo,
        label: proposedSwap.initWsLabel,
        date: proposedSwap.initWorkdate,
        workMinutes: proposedSwap.initWsWorkMinutes,
        areaId: proposedSwap.initWsAreaId,
        format:proposedSwap.initWsFormat,
    }


    let recipientWorkshift = {
        code: proposedSwap.recWsCode,
        from:  proposedSwap.recWsFrom,
        to: proposedSwap.recWsTo,
        label: proposedSwap.recWsLabel,
        date: proposedSwap.recWorkdate,
        workMinutes: proposedSwap.recWsWorkMinutes,
        areaId: proposedSwap.recWsAreaId,
        format: proposedSwap.recWsFormat,
    }

    let recWsText = formatWorkshift(recipientWorkshift)
    let initWsText = formatWorkshift(initiatorWorkshift)

    const onAccept = (input) => {
        let assignmentChanged = proposedSwap.initiatorAssignmentChanged || proposedSwap.recipientAssignmentChanged
        let swapAcceptDeclineInput = {
            proposalId: proposedSwap.recProposalId,
            note: input.swapRecipientNote,
            assignmentChanged: assignmentChanged
        }
        actions.acceptProposal(swapAcceptDeclineInput)

    }


    const onDeny = (input) => {
        let assignmentChanged = proposedSwap.initiatorAssignmentChanged && proposedSwap.recipientAssignmentChanged
        let swapAcceptDeclineInput = {
            proposalId: proposedSwap.recProposalId,
            note: input.swapRecipientNote,
            assignmentChanged: assignmentChanged
        }
        actions.denyProposal(swapAcceptDeclineInput)
    }

    const MAX_NOTE_LEN = 254

    function handleSwapNoteChange(){

        let noteContent = getValues("swapRecipientNote")
        if (noteContent.length > MAX_NOTE_LEN) {
            noteContent = noteContent.slice(0, MAX_NOTE_LEN);
        }
        setValue("swapRecipientNote", noteContent )

    }

    return (
        <div className={"swapProposal paddingTen"} >
            <form>
            <div>
                <div className="swapGeneralInfo boldFont">
                    {proposedSwap.initEmpName}
                </div>

                <div className="swapGeneralInfo">
                    <span className="boldFont">DCPOS:</span>&nbsp;{proposedSwap.initDcpos}&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="boldFont">Skill:</span>&nbsp;{proposedSwap.initSkill}&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="boldFont">Job Class:</span>&nbsp;{proposedSwap.initJobCode}
                </div>
            </div>

            <div className="swapLabel">
                Has proposed to work this shift for you:
            </div>
            <div className="swapPartner lightBlueBg">
                <table className="swapPartnerTable">
                    <tbody>
                        <tr>
                            <td className="dateCol">
                                <span className="boldFont">Date:</span> {formatDate(recipientWorkshift.date)}
                            </td>
                            <td>
                                <div className="flexDisplay">
                                    <span className="boldFont">{recWsText.substring(0,2)}</span>&nbsp;{recWsText.substring(2)}
                                    {proposedSwap.recipientAssignmentChanged &&
                                        <span className="smallFont"><i className="fas fa-exclamation-triangle"></i></span>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="dateCol">
                                <span className="boldFont">Area:</span> {recipientWorkshift.areaId}
                            </td>
                            <td>
                                <span className="boldFont">Dur:</span> {minutesToDurationInHours(recipientWorkshift.workMinutes)}hrs
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>

            <div className="swapLabel">
                If you will work this shift for them:
            </div>
            <div className="swapPartner lightBlueBg">
                <table className="swapPartnerTable">
                    <tbody>
                        <tr>
                            <td className="dateCol">
                                <span className="boldFont">Date:</span> {formatDate(initiatorWorkshift.date)}
                            </td>
                            <td>
                                <div className="flexDisplay">
                                    <span className="boldFont">{initWsText.substring(0,2)}</span>&nbsp;{initWsText.substring(2)}
                                    {proposedSwap.initiatorAssignmentChanged &&
                                        <span className="smallFont"><i className="fas fa-exclamation-triangle"></i></span>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="dateCol">
                                <span className="boldFont">Area:</span> {initiatorWorkshift.areaId}
                            </td>
                            <td>
                                <span className="boldFont">Dur:</span> {minutesToDurationInHours(initiatorWorkshift.workMinutes)}hrs
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="swapGeneralInfo marginTop10">
                <span className="boldFont">Proposal sent:</span> {formatDate(proposedSwap.initiateDate)}&nbsp;&nbsp;{formatInServerTZ(proposedSwap.initiateDate,'HH:mm')}
            </div>
            <div className="swapGeneralInfo marginTop10">
                <span className="boldFont">Proposal note:</span>
                <br/>
                {proposedSwap.initNote}

            </div>


            {(proposedSwap.initiatorAssignmentChanged || proposedSwap.recipientAssignmentChanged) &&
            <div className="swapWarning">
                <i className="fas fa-exclamation-triangle"></i>
                <span
                    className="boldFont">This workshift assignment has been modified</span> since this swap was initiated. If
                you accept this proposal, the swap request will be sent to <span className="boldFont">Administrator for processing.</span>

            </div>
            }


            {proposedSwap.recipientOverTime &&
            <div className="swapWarning">
                        <i className="fas fa-exclamation-triangle"></i>
                        <span className="boldFont">You will be in overtime</span> if you accept this proposal. This will need <span className="boldFont">Administrator approval.</span>

            </div>

            }

            <hr className="swapSeparator" />

            <div className="swapLabel">
                <span className="boldFont">Response Note</span><span className="swapGeneralInfo italicFont">&nbsp;(optional)</span>
                <br/>
                <Form.Control
                    as="textarea"
                    name="swapRecipientNote"
                    ref={register}
                    className={"swapRecipientNote"}
                    defaultValue =""
                    onChange={handleSwapNoteChange}
                />
            </div>

            <div className="swapButton">
                <Button onClick={handleSubmit(onAccept)} >
                    <i className="fas fa-check-circle"></i>&nbsp;&nbsp;Accept
                </Button>
                <Button onClick={handleSubmit(onDeny)} >
                    <i className="fas fa-times-circle"></i>&nbsp;&nbsp;Decline
                </Button>
            </div>

            </form>
        </div>
    )

}


export default ProposedSwapProposalControl