import React from 'react'
import './Assignment.css'
import {formatWorkshift} from "helpers/workshift";

function AssignmentFormatter({assignment}) {
    if (!assignment) return <></>
    let title = formatWorkshift(assignment)
    let pending = assignment.pending ? <i className="fas fa-question-circle"/> : ""
    let float = !assignment.home ? <i className="fas fa-share"/> : ""
    let subtitle = (!assignment.home ? assignment.areaId + " " + assignment.areaName + " " : "") +
        (assignment.team ? "Team:" + assignment.team + " " : "") +
        (assignment.task ? "Task:" + assignment.task : "")
    if (subtitle.length > 0) {
        return <div className="assignment">
            <span className="assignmentTitle">{title} {pending}</span>
            <br/><span className="assignmentSubtitle">{float} {subtitle}</span>
        </div>
    } else {
        return <div className="assignment">
            <span className="assignmentTitle">{title} {pending}</span>
        </div>

    }
}

export default AssignmentFormatter