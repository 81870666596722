import React from 'react'
import {useForm} from 'react-hook-form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import NotificationDetail from "./NotificationDetail"
import {formatWorkshift} from "helpers/workshift"
import {RequestType} from "components/Request"

const NotificationForm = ({notification, request, actions, assignments, areas}) => {
    const {register, handleSubmit, errors} = useForm()

    const onSubmit = input => {
        console.log("submit...input=" + JSON.stringify(input))
        request.requestType = RequestType.OPEN_SHIFT
        request.areaId = notification.areaId
        request.startDate = notification.workshift.on
        request.endDate = notification.workshift.on
        request.workshiftId = notification.workshift.workshiftId
        request.workshiftName = notification.workshift.name
        request.workshiftStartTime = notification.workshift.from
        request.workshiftEndTime = notification.workshift.to
        request.workshiftSkill = notification.workshift.skill
        request.resubmit = false
        request.note = input.note
        actions.submit(request)
    }

    function findAreaName(targetAreaId){
        if(areas) {
            let resultArea = areas.find(
                area => area.id === targetAreaId
            )
            return resultArea ? resultArea.name : targetAreaId
        }else return ""
    }

    return (
        <div id='notificationForm'>
            <Modal centered show={true} backdrop="static" onHide={actions.cancel}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>Available Shift</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <NotificationDetail notification={notification} style='form'/>
                        <Form.Group controlId="request.note">
                            <Form.Label className="noteLabel">Note</Form.Label>
                            <Form.Control as="textarea" name="note" placeholder="Enter a note (optional)"
                                          defaultValue={notification.note}
                                          ref={register}/>
                        </Form.Group>

                        <Form.Group>
                            <span className="notificationDetail boldFont">My assignments:</span>
                            {assignments && assignments.length > 0
                            && assignments.map((singleAssignment, index) => {
                                return (
                                    <div key={index} className="notificationAssignments">
                                        {formatWorkshift(singleAssignment)}  {findAreaName(singleAssignment.areaId)}
                                    </div>
                                )
                            })
                            }

                            { (!assignments || assignments.length ==0)
                            && <div className="notificationAssignments">No assignments</div>

                            }


                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" value="Submit">Submit</Button>
                        <Button variant="secondary" onClick={actions.cancel}>Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default NotificationForm