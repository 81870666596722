import React, {useState} from 'react'
import {useStoreActions, useStoreState} from "Store";
import {useSubmitRetractionMutation} from "./RetractGQL";
import ServiceStatus, {makeServiceStatus,} from "helpers/serviceStatus";
import RetractForm from "./RetractForm";
import Notice from "components/Notice";
import Spinner from "components/Spinner";

function Retract() {
    const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})
    const state = useStoreState()
    const actions = useStoreActions()
    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})
    const [show, setShow] = useState(SHOW.FORM)

    const [submitRetractResult, submitRetract] = useSubmitRetractionMutation()

    const retractActions = {
        submit: variables => {
            setShow(SHOW.SPINNER)
            submitRetract(variables).then(result => {
                console.log(`Retract result in Retract.jsx ${JSON.stringify(result)}`)
                if (result.data && result.data.submitRetract) {
                    result.data.submitRetract.status = "SUCCESS_RETRACTED"
                }
                setServiceStatus(makeServiceStatus(result.data ? result.data.submitRetract : null, result.error))
                if (serviceStatus) {
                    setShow(SHOW.NOTICE)
                }
                setShow(SHOW.NOTICE)
                retractActions.cancel()
            })
        },
        cancel: () => {
            actions.updateAssignment(null)
            hide()
        }
    }

    function hide() {
        actions.updateAssignActionWithRetractSelected(false)
    }

    function handleNoticeResponse() {
        setShow(SHOW.FORM)
        if (serviceStatus.success) {
            retractActions.cancel()
        }
    }


    switch (show) {
        case SHOW.NOTICE: {
            return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                           onClose={handleNoticeResponse} show={true}/>
        }
        case SHOW.SPINNER: {
            return <Spinner show={true}/>
        }
        case SHOW.FORM: {
            if (state.assignAction.count && state.assignAction.count === 1 &&
                state.assignAction.retractSelected && state.assignAction.retractSelected === true) {

                return (
                    <RetractForm retractActions={retractActions}/>
                )
            }
        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }
}

export default Retract