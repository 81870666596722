import React, {useContext, useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import {FormProvider, useForm} from "react-hook-form"
import Form from "react-bootstrap/Form"
import AccountSection from "./AccountSection"
import ContactSection from "./ContactSection"
import AvlShiftNotificationsSection from "./AvlShiftNotificationsSection"
import RoleSection from "./RoleSection"
import CalendarSubscription from "./CalendarSubscription"
import Accordion from 'react-bootstrap/Accordion'
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Card from 'react-bootstrap/Card'
import If from "components/If"
import Notice from "../Notice"
import {ifEmailVerifyingRequired, ifSARoleOnly, ifTextVerifyingRequired} from "./userProfileHelper"


const UserProfileForm = ({data, actions, setUserProfileDirty, shouldOpenContact}) => {
    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            customDisplayName: "",
            emailNotifyTo: "",
            otherEmail: "",
            txtNotifyTo: "",
            otherPhone: "",
            cellCarrier: "Carrier",
            notfyAsOptIn: false,
            notifyBy: "",
            notifyArea: "",
            empSelectedNotifyArea: "",
            notifyShiftBetween: false,
            notifyDNDBetween: false,
            notifyStartTime: "",
            notifyEndTime: "",
            noDisturbStartTime: "",
            noDisturbEndTime: "",
            useEmpHomeArea: false,
            empDefaultAreaId: "",
            defaultRole: "EMP",
            saDefaultAreaId: "",
            saDefaultPage: "",

        }
    })
    const {dirtyFields} = methods.formState

    const [showUnverifiedNotice, setShowUnverifiedNotice] = useState(false)
    //"userProfileInput" is used to remember  user input throughout the re-renders.
    // otherwise user input would get lost in case of "unverified notice".
    const [userProfileInput, setUserProfileInput] = useState(undefined)

    //"emailVerified" state is something logically resides in grandchild component "EmailNotifyForm".
    //However we need it here so that we can decide if an "Unverified Notice" should display when end user tries to
    //save profile. Hence this state is promoted up to this level.
    const [emailVerified, setEmailVerified] = useState(false)

    //Ditto for "txtVerified" state. It logically resides in "PhoneNotifyForm" but grandparent
    //needs access to ths state. Hence it is promoted to this level
    const [txtVerified, setTxtVerified] = useState(false)
    const notVerifiedMsg = 'Changes to your email and/or text notification settings have not been verified. If you continue, those changes will not be saved'

    const ACCOUNT_SECTION_EVTKEY = "Account"
    const CONTACT_SECTION_EVTKEY = "Contact"
    const NOTIFY_SECTION_EVTKEY = "Notifications"
    const ROLE_SECTION_EVTKEY = "Role"
/*    const SUBS_SECTION_EVTKEY = "Subscription"*/


    const onProfileFormSubmit = (input) => {
        //console.log("UserProfileForm onClick input " + JSON.stringify(input))
        //1. In case "email/txt" needs verification, bring up a warning prompt. User can choose what to do from there.
        if (ifEmailVerifyingRequired(input, emailVerified, data)
            || ifTextVerifyingRequired(input, txtVerified, data)) {
            setUserProfileInput(input)
            setShowUnverifiedNotice(true)
        }
        //2. Otherwise submit and save the profile
        else {
            actions.submit(input)
        }

    }


    function handleNoticeResponse(confirmed) {
        //End user chooses to submit anyway
        if (confirmed) {
            //unverified email/txt should not be sent. Massage it back to initial state
            userProfileInput.emailNotifyTo = data.notification.notfyEmailTo
            userProfileInput.otherEmail = (data.notification.notfyEmailOther===null ? "" : data.notification.notfyEmailOther)
            userProfileInput.txtNotifyTo = data.notification.notfyTxtTo
            userProfileInput.otherPhone = (data.notification.notfyTxtOther===null ? "" : data.notification.notfyTxtOther)
            // console.log("At submit time, userProfileInput = " + JSON.stringify(userProfileInput))
            actions.submit(userProfileInput)
            setShowUnverifiedNotice(false)

        }
        //End user chooses to "cancel". Let us hide this notice.
        else {
            setShowUnverifiedNotice(false)
        }
    }

    function resetDirtyCheckSourceValue() {
        // console.log("=======>>>>>UserProfileForm useEffect Reset default value: customDisplayName = "
        //         + data.customDisplayName
        //     + "; emailNotifyTo =" + data.notification.notfyEmailTo
        //     + "; otherEmail =" + data.notification.notfyEmailOther
        //     + "; txtNotifyTo = " + data.notification.notfyTxtTo
        //     + "; otherPhone = " + data.notification.notfyTxtOther
        //         +";notfyAsOptIn = " + data.notification.notfyAsOptIn
        //         +";notifyBy = " + data.notification.notfyAsBy
        //         +" ;notifyArea = " + data.notification.notfyAsAllAreas
        //         +" ;empSelectedNotifyArea = " + (data.notification.notfyAsSelectedAreas ? data.notification.notfyAsSelectedAreas.join() : "")
        //         +" ;notifyShiftBetween = " + data.notification.notfyAsShiftsBtwnEnabled
        //         +" ;notifyDNDBetween = " + data.notification.notfyAsNoDisturbEnabled
        //         +" ;notifyStartTime = " + data.notification.notfyAsShiftsBtwnStart
        //         +" ;notifyEndTime = " + data.notification.notfyAsShiftsBtwnEnd
        //         +" ;noDisturbStartTime = " + data.notification.notfyAsNoDisturbStart
        //         +" ;noDisturbEndTime = " + data.notification.notfyAsNoDisturbEnd
        //     +" ;useEmpHomeArea = " + ((data.empDefaultAreaId)? "false" : "true")
        //     +" ;empDefaultAreaId = " + ((data.empDefaultAreaId)? data.empDefaultAreaId :
        //                     (data.dcpos ? data.dcpos.substring(0, 2) : ""))
        //     +" ;defaultRole = " + data.defaultRole
        //     +" ;saDefaultAreaId = " + data.saDefaultAreaId
        //     +" ;saDefaultPage = " + data.saDefaultPage
        // )
        methods.reset({
            customDisplayName: (data.customDisplayName) ? data.customDisplayName : "",
            emailNotifyTo: data.notification.notfyEmailTo,
            otherEmail: data.notification.notfyEmailOther,
            txtNotifyTo: data.notification.notfyTxtTo,
            otherPhone: data.notification.notfyTxtOther,
            cellCarrier: "Carrier",
            notfyAsOptIn: data.notification.notfyAsOptIn,
            notifyBy: data.notification.notfyAsBy,
            notifyArea: data.notification.notfyAsAllAreas,
            empSelectedNotifyArea: (data.notification.notfyAsSelectedAreas ? data.notification.notfyAsSelectedAreas.join() : ""),
            notifyShiftBetween: data.notification.notfyAsShiftsBtwnEnabled,
            notifyDNDBetween: data.notification.notfyAsNoDisturbEnabled,
            notifyStartTime: data.notification.notfyAsShiftsBtwnStart,
            notifyEndTime: data.notification.notfyAsShiftsBtwnEnd,
            noDisturbStartTime: data.notification.notfyAsNoDisturbStart,
            noDisturbEndTime: data.notification.notfyAsNoDisturbEnd,
            useEmpHomeArea: ((data.empDefaultAreaId) ? "false" : "true"),
            empDefaultAreaId: ((data.empDefaultAreaId) ? data.empDefaultAreaId :
                (data.dcpos ? data.dcpos.substring(0, 2) : "")),
            defaultRole: data.defaultRole,
            saDefaultAreaId: data.saDefaultAreaId,
            saDefaultPage: data.saDefaultPage,
        })

    }

    function setEmailOrTxtVerified() {
        // Only set state when "userProfileData changes", which would be then be when "useQuery" hook result fetching completes
        // With the dependencies of this useEffect, it would only run once, exactly what we want.
        //Here is the sequence of events:
        //1. when this form initially renders, email verified related info is initialized to false
        //2. useQuery hook result returns, and a re-render would happen.
        //3. Now useEffect runs, and do this setTxtXXX, which would bring in a re-render
        //4. This time, "emailVerified" & "notfyTxtVerified"  have the correct initial value based on query result. All good
        setEmailVerified(data.notification.notfyEmailVerified)
        //If only SA role, "txtVerified" is irrelevant. Setting "txtVerified" state a "true" for convenience
        if (ifSARoleOnly(data.roles)) {
            setTxtVerified(true)
        }
        //Otherwise this user has employee role, let's set "txtVerified" based on user profile query result
        else {
            setTxtVerified(data.notification.notfyTxtVerified)
        }
    }

    //subscribe to "formState" dirtyFields state
    useEffect(() => {
        //Note: I find that formState.isDirty is not as reliable  as formState.dirtyFields
        //in that "formState.isDirty" work as if "isTouched".
        let changedFieldCnt = 0
        let changedFields  = Object.keys(dirtyFields)
        if(changedFields) {
            //"welcomeBanner" checkbox should be excluded from being counted towards being "dirty", since it is
            //not really part of "user profile" data. Rather it is merely a convenience UI indicator
            if (changedFields.includes("welcomeBanner")) {
                changedFieldCnt = changedFields.length - 1
            } else {
                changedFieldCnt = changedFields.length
            }
        }
        let isDirtyAlt = ( changedFieldCnt > 0 )
        setUserProfileDirty(isDirtyAlt)
    }, [methods.formState])


    useEffect(() => {
        //1. Setting email/txt verified state after "user profile query" fetches the result
        setEmailOrTxtVerified()
        //2. Setting the source for "check form dirty" comparision.
        resetDirtyCheckSourceValue()
    }, [data.notification.notfyEmailVerified, data.notification.notfyTxtVerified])


    return (

        // pass all methods into the context
        <FormProvider {...methods} >
            <Form>
                <Accordion defaultActiveKey={ACCOUNT_SECTION_EVTKEY}>
                    <Card>
                        <Card.Header className = "profileCardHeader">
                            <CustomToggle eventKey={ACCOUNT_SECTION_EVTKEY} >
                                <div className="profileBigLabel">Account</div>
                            </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={ACCOUNT_SECTION_EVTKEY}>
                            <Card.Body>
                                <AccountSection data={data}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>


                <Accordion {...(shouldOpenContact ? {defaultActiveKey: CONTACT_SECTION_EVTKEY} : {}) }>
                    <Card>
                        <Card.Header className = "profileCardHeader">
                            <CustomToggle eventKey={CONTACT_SECTION_EVTKEY}>
                                <div className="profileBigLabel">Contact</div>
                            </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={CONTACT_SECTION_EVTKEY} >
                            <Card.Body>
                                <ContactSection data={data}
                                                emailVerified={emailVerified}
                                                setEmailVerified={setEmailVerified}
                                                txtVerified={txtVerified}
                                                setTxtVerified={setTxtVerified}

                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>



                <If condition={() => {
                    return !ifSARoleOnly(data.roles)
                }}>
                    <Accordion>
                        <Card>
                            <Card.Header className = "profileCardHeader">
                                <CustomToggle eventKey={NOTIFY_SECTION_EVTKEY}>
                                    <div className="profileBigLabel">Available Shift Notifications<span
                                        className="alertFont"> (Alerts)</span></div>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={NOTIFY_SECTION_EVTKEY}>
                                <Card.Body>
                                    <AvlShiftNotificationsSection data={data}/>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </If>

                <Accordion>
                    <Card>
                        <Card.Header className = "profileCardHeader">
                            <CustomToggle eventKey={ROLE_SECTION_EVTKEY}>
                                <div className="profileBigLabel">Role</div>
                            </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={ROLE_SECTION_EVTKEY}>
                            <Card.Body>
                                <RoleSection data={data}/>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
{/*                <If condition={() => {
                    return !ifSARoleOnly(data.roles)
                }}>
                    <Accordion>
                        <Card>
                            <Card.Header className = "profileCardHeader">
                                <CustomToggle eventKey={SUBS_SECTION_EVTKEY}>
                                    <div className="profileBigLabel">Calendar Subscription</div>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={SUBS_SECTION_EVTKEY}>
                                <Card.Body>
                                    <CalendarSubscription data={data}/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                </If>*/}

                <Notice message={notVerifiedMsg}
                        onClose={handleNoticeResponse}
                        labels={["Continue", "Cancel"]}
                        show={showUnverifiedNotice}/>


                <div className="floatRight submitDiv">

                    <Button variant="primary"
                            onClick={methods.handleSubmit(onProfileFormSubmit)}
                    >Submit</Button>
                </div>

                {/* below is debugging what fields are dirty */}
                {/*<pre>{JSON.stringify(methods.formState, null, 2)}</pre>*/}
            </Form>
        </FormProvider>


    )


}

function CustomToggle({children, eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.
    const toggleOnClick = useAccordionToggle(eventKey, () => {
        callback && callback(eventKey)
    });
    const isCurrentEventKey = currentEventKey === eventKey

    return (
        <div className="spaceBetween" onClick={toggleOnClick}>
            {children}
            <div>
                <button className="toggleButton	profileToggle"
                        type="button"
                >
                    {isCurrentEventKey ?
                        <div><i className="fas fa-angle-up"></i></div>
                        : <div><i className="fas fa-angle-down"></i></div>

                    }
                </button>
            </div>
        </div>
    )
}


export default UserProfileForm