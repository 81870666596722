import {makeServiceStatus} from "../../helpers/serviceStatus";

function emptyRequestForm() {
    return {workshifts: null}
}

export function processRequestFormData(fetching, data, error) {
    if (error) {
        const serviceStatus = makeServiceStatus(data ? data.requestForm : null, error)
        if (serviceStatus && !serviceStatus.success) {
            return {requestForm: emptyRequestForm(), serviceStatus: serviceStatus}
        }
    }
    if (data && data.requestForm) {
        return {requestForm: data.requestForm}
    } else {
        return {requestForm: emptyRequestForm}
    }
}