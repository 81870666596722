import "./legalNotice.css"
import React from "react";
import {getSWInstalledAt, getSWUpdatedAt} from "helpers/update";

const PRIVACY_POLICY_URL = "https://www.healthstream.com/privacy-policy"

function clickLink(linkId) {
    let element = document.getElementById(linkId)
    if (element) {
        const clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        })
        element.dispatchEvent(clickEvent)
    }
}

const LegalNotice = ({legalNotice}) => {
    const installedAt = getSWInstalledAt()
    const updatedAt = getSWUpdatedAt()

    return <div id="legalNotice">
        <p/>
        <span className="title">{legalNotice.appNameAndVersion}</span>
        <p/>
        &copy;&nbsp;{legalNotice.copyrightYear}&nbsp;{legalNotice.afterCopyrightYear}
        <p/>
        <span className="lineUnder">{legalNotice.subtitle}</span>
        <p/>
        {legalNotice.content}
        <p/>
        <a href={legalNotice.webPage}>{legalNotice.displayName}</a>
        <br/>
        {legalNotice.address1}
        <br/>
        {legalNotice.address2}
        <br/>
        {legalNotice.phone}
        <p/>
        {legalNotice.registered}
        <p/>
        <div id='privacypolicy' onClick={() => clickLink("privacypolicy-link")}>
            <span>Privacy Policy for ANSOS2Go  <i className={"fas fa-external-link-alt"}/></span>
        </div>
        <a id='privacypolicy-link' style={{display: 'hidden'}} href={PRIVACY_POLICY_URL} target="_blank"
           rel="noreferrer noopener"/>
        <p/>
        {legalNotice.appNameAndVersion}
        <br/>
        {legalNotice.appNameVersionBuildInfo}
        <p/>
        <br/>{installedAt ? "Installed: " + installedAt.toLocaleString() : ""}
        <br/>{updatedAt ? "Updated: " + updatedAt.toLocaleString() : ""}
    </div>
}

export default LegalNotice