import gql from 'graphql-tag'
import {useQuery} from 'urql'

// SERVERINFO

export const SERVERINFO_QUERY = gql`
query ServerInfo {
    serverInfo {
        appName
        appVersion
        buildInfo
        vendor
        organizationName
        timezone
        locale
        id
    }
}`

export function useServerInfoQuery(requestPolicy="cache-and-network") {
    console.log("useServerInfoQuery()")
    return useQuery({
        query: SERVERINFO_QUERY,
        variables: {},
        requestPolicy: requestPolicy,
    })
}

export const PARAMSATSTARUP_QUERY = gql`
query ParamsAtStartup {
    paramsAtStartup {
        dateFormat
        swapNearbyWarningThresholdInMinutes
        installPromptInterval
        nonWSMLicenseOnly
        primaryEmailOnlyNotification
        id
    }
}
`


export function useParamsAtStartupQuery(requestPolicy) {
    console.log("useParamsAtStartupQuery()")
    return useQuery({
        query: PARAMSATSTARUP_QUERY,
        variables: {},
        requestPolicy: requestPolicy
    })
}


export function clearCachedParamsAtStartupQuery(result, cache) {
    console.log("REMOVE ParamsAtStartup FROM CACHE...")
    const data = cache.readQuery({query: PARAMSATSTARUP_QUERY, variables: {}})
    if (data && data.paramsAtStartup) cache.invalidate({__typename: 'ParamsAtStartup', id: data.paramsAtStartup.id});
}



