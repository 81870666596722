import React, {useEffect, useState} from 'react'
import Modal from "react-bootstrap/Modal"
import './Install.css'
import {PLATFORM, platformIs} from "helpers/platform";
import {didShowInstallPrompt, hideInstallPrompt, isInstalled} from "helpers/install";
import {
    InstallAndroidPromotionCard,
    InstallAndroidStepsCard,
    InstalliOSPromotionCard,
    InstalliOSStepsCard,
    UpdatedCard
} from "./InstallCards";
import {getParamsAtStartup} from "components/Settings/SettingsData";
import {removeSWStatus, setSWUpdatedAt} from "helpers/update";
import {relaunch} from "helpers/http";

const SHOW = Object.freeze({PROMOTION: 1, INSTALL_STEPS: 2, UPDATED: 3, NONE: 0})

const Install = ({updated}) => {

    const [platform] = useState(platformIs())
    const [show, setShow] = useState()

    const onUpdate = () => {
        removeSWStatus()
        setSWUpdatedAt()
        relaunch()
    }

    useEffect(() => {
        setShow((updated ? SHOW.UPDATED : (isInstalled() || hideInstallPrompt()) ? SHOW.NONE : SHOW.PROMOTION))
    }, [])

    let card
    switch (show) {
        case SHOW.PROMOTION:
            didShowInstallPrompt()
            if (platform === PLATFORM.IOS) {
                card = <InstalliOSPromotionCard onContinue={() => setShow(SHOW.INSTALL_STEPS)}
                                                onClose={() => setShow(SHOW.NONE)}/>
            } else {
                card = <InstallAndroidPromotionCard onContinue={() => setShow(SHOW.INSTALL_STEPS)}
                                                    onClose={() => setShow(SHOW.NONE)}/>
            }
            break
        case SHOW.INSTALL_STEPS:
            if (platform === PLATFORM.IOS) {
                card = (<InstalliOSStepsCard onClose={() => setShow(SHOW.NONE)}/>)
            } else {
                card = (<InstallAndroidStepsCard onClose={() => setShow(SHOW.NONE)}/>)
            }
            break
        case SHOW.UPDATED:
            card = <UpdatedCard onContinue={onUpdate}/>
            break
        case SHOW.NONE:
        default:
            return <></>
    }

    return <Modal id="install" show={true} centered>{card}</Modal>
}

export default Install