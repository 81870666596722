import React, {useEffect, useState} from 'react';
import {useViewClasses} from "./View";
import AreaSchedule from 'components/AreaSchedule'
import {useAreaScheduleQuery} from 'components/AreaSchedule/AreaScheduleGQL'
import {processAreaScheduleData} from "components/AreaSchedule/AreaScheduleData"
import AreaScheduleControls from "components/AreaSchedule/AreaScheduleControls";
import EmployeeContact from "components/AreaSchedule/EmployeeContact";
import {ROLE} from "components/Role";
import Spinner from "components/Spinner";
import {toError} from "helpers/serviceStatus";
import {dateToString} from "helpers/date";
import {isPeriodPublished, periodsPublished} from "helpers/period";
import {bulletin} from "helpers/bulletin";
import {formatWorkshift} from "helpers/workshift";
import {getArea} from "helpers/area";

const AreaScheduleView = ({layout, role, defaultAreaId}) => {
    const [date, setDate] = useState(dateToString(new Date()))
    const [areaId, setAreaId] = useState(defaultAreaId)
    const [employee, setEmployee] = useState(null)

    role = role === ROLE.EMP ? role : ROLE.SA; // role is either EMP or SA now

    const [{fetching, data, error}] = useAreaScheduleQuery(areaId, date, role)

    const {
        skillGroups,
        periodDays,
        areas,
        periods,
        selectedArea,
        selectedPeriod,
        serviceStatus
    } = processAreaScheduleData(fetching, data, error, role)

    useEffect(() => {
        const todayColumn = document.getElementsByClassName('th today')[0]
        if (todayColumn) {
            todayColumn.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})
        } else {
            const day1Column = document.getElementsByClassName('th day')[0]
            if (day1Column) {
                day1Column.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})
            }
        }
        const areaRow = document.getElementById('areaScheduleControls')
        if (areaRow) {
            areaRow.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})
        }
    }, [areaId, date, fetching])

    function onAreaChange(areaId) {
        setAreaId(areaId)
    }

    function onPeriodChange(periodFrom) {
        setDate(periodFrom)
    }

    const onEmployeeClick = role === ROLE.SA ? ((employee) => {
        setEmployee(employee)
    }) : null

    const onEmployeeContactClose = () => {
        if (employee) setEmployee(null)
    }

    const formatAssignment = (assignment) => {
        const workshift = {
            code: assignment.code,
            from: assignment.from,
            to: assignment.to,
            label: assignment.label,
            format: assignment.format,
        }
        const rows = []
        if (role === ROLE.EMP) {
            rows.push({
                classname: "row1" + (assignment.float ? " float" : null),
                content: formatWorkshift({...workshift, areaId: assignment.float ? assignment.areaId : null})
            })
        } else {
            rows.push({classname: "row1", content: formatWorkshift(workshift)})
            if (assignment.float) {
                const assignmentArea = getArea(areas, assignment.areaId)
                if (assignmentArea) {
                    rows.push({classname: "row2", content: assignmentArea.name})
                } else {
                    console.error("Area("+assignment.areaId+") is missing from areas: "+JSON.stringify(areas))
                }
            }
        }
        return rows
    }

    function message(skillGroups, periods, selectedPeriod, role, fetching) {
        if (fetching) return <></>

        if (role === ROLE.EMP) {
            if (periodsPublished(periods) === 0) {
                return bulletin("No schedules are available to view at this time.")
            } else if (!isPeriodPublished(selectedPeriod)) {
                return bulletin("The schedule for this period is not published.")
            }
        }

        if (!skillGroups || skillGroups.length === 0) {
            return bulletin("The schedule for " + selectedArea.name + " has not been Reset for this period.")
        }
    }

    const format = {formatAssignment}

    let body = (
        <div id="areaScheduleView">
            <AreaScheduleControls areas={areas} selectedArea={selectedArea} onAreaChange={onAreaChange}
                                  periods={periods} selectedPeriod={selectedPeriod}
                                  onPeriodChange={onPeriodChange} role={role}/>
            <AreaSchedule selectedAreadId={areaId} selectedDate={date} skillGroups={skillGroups}
                          periodDays={periodDays} onEmployeeClick={onEmployeeClick} format={format}/>
            <Spinner show={fetching}/>
            <EmployeeContact employee={employee} onClose={onEmployeeContactClose}/>
            {message(skillGroups, periods, selectedPeriod, role, fetching)}
        </div>
    )
    return layout({body, error: toError(serviceStatus), classes: useViewClasses()})
}

export default AreaScheduleView