import React, {useState} from 'react'
import Notice from "components/Notice"
import Spinner from "components/Spinner"
import VerifyEmailOrPhoneForm from "./VerifyEmailOrPhoneForm"
import {useVerifyEmailVerificationCodeMutation, useVerifyPhoneVerificationCodeMutation} from "../../UserProfileGQL"
import ServiceStatus from "helpers/serviceStatus"
import {makeVerifyEmailVerificationCodeStatus, makeVerifyPhoneVerificationCodeStatus} from "../../UserProfileData"
import {useFormContext} from "react-hook-form"


const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})

export const VERIFY_TYPE = Object.freeze({
    email: 'email',
    phone: 'phone',
})

const VerifyEmailOrPhone = ({
                                verifyType, email, notfyEmailTo, setEmailVerified,
                                notfyTxtTo, phone, carrier, setTxtVerified, close
                            }) => {

    const {reset} = useFormContext()
    const [show, setShow] = useState(SHOW.FORM)
    const [verifyServiceStatus, setVerifyServiceStatus] = useState({...ServiceStatus.UNKNOWN})
    const [verifyEmailVerificationCodeResult, verifyEmailVerificationCode] = useVerifyEmailVerificationCodeMutation()
    const [verifyPhoneVerificationCodeResult, verifyPhoneVerificationCode] = useVerifyPhoneVerificationCodeMutation()

    const actions = {
        verifyEmail: (notfyEmailTo, email, verificationCode) => {
            setShow(SHOW.SPINNER)
            const variables = {"notfyEmailTo": notfyEmailTo, "email": email, "verificationCode": verificationCode}
            verifyEmailVerificationCode(variables).then(({
                                                             fetching: fetchingVEVM,
                                                             data: dataVEVM,
                                                             error: errorVEVM
                                                         }) => {
                let emailVerifyStatus = makeVerifyEmailVerificationCodeStatus(fetchingVEVM, dataVEVM, errorVEVM)
                setVerifyServiceStatus(emailVerifyStatus)

                if (emailVerifyStatus) {
                    setShow(SHOW.NOTICE)
                    if (emailVerifyStatus.success === true) {
                        //reset default value to take care of "dirty" flag
                        const defaultValues = {
                            emailNotifyTo: dataVEVM.verifyEmailVerificationCode.notification.notfyEmailTo,
                            otherEmail: dataVEVM.verifyEmailVerificationCode.notification.notfyEmailOther,
                        }
                        reset({defaultValues})
                        setEmailVerified(true)
                    }
                } else {
                    setShow(SHOW.FORM)
                }
                setVerifyServiceStatus(emailVerifyStatus)


            })

        },

        verifyPhone: (notfyTxtTo, phone, carrier, verificationCode) => {
            setShow(SHOW.SPINNER)
            const variables = {
                "notfyTxtTo": notfyTxtTo,
                "phone": phone,
                "carrier": carrier,
                "verificationCode": verificationCode
            }
            // console.log("verifyPhone mutation variables = " + JSON.stringify(variables))
            verifyPhoneVerificationCode(variables).then(({
                                                             fetching: fetchingVEVM,
                                                             data: dataVEVM,
                                                             error: errorVEVM
                                                         }) => {
                let phoneVerifyStatus = makeVerifyPhoneVerificationCodeStatus(fetchingVEVM, dataVEVM, errorVEVM)
                if (phoneVerifyStatus) {
                    setShow(SHOW.NOTICE)
                    if (phoneVerifyStatus.success === true) {
                        const defaultValues = {
                            txtNotifyTo: dataVEVM.verifyPhoneVerificationCode.notification.notfyTxtTo,
                            otherPhone: dataVEVM.verifyPhoneVerificationCode.notification.notfyTxtOther,
                            cellCarrier: dataVEVM.verifyPhoneVerificationCode.notification.notfyTxtCarrier
                        }
                        reset({defaultValues})
                        setTxtVerified(true)
                    }
                } else {
                    setShow(SHOW.FORM)
                }
                setVerifyServiceStatus(phoneVerifyStatus)


            })

        },

        cancel: () => {
            close()
        }
    }


    function handleNoticeResponse(confirmed) {
        if (verifyServiceStatus.success) {
            setShow(SHOW.NONE)
            close()
        } else {
            setShow(SHOW.FORM)
        }

    }


    switch (show) {
        case SHOW.NOTICE: {
            return <Notice message={verifyServiceStatus.message} type={verifyServiceStatus.noticeType}
                           onClose={handleNoticeResponse} show={true}/>

        }
        case SHOW.SPINNER: {
            return <Spinner show={true}/>
        }
        case SHOW.FORM: {
            return <VerifyEmailOrPhoneForm actions={actions} verifyType={verifyType} email={email}
                                           notfyEmailTo={notfyEmailTo}
                                           notfyTxtTo={notfyTxtTo} phone={phone} carrier={carrier}
            />

        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }

}

export default VerifyEmailOrPhone