import Form from "react-bootstrap/Form";
import React from "react";
import {useFormContext} from 'react-hook-form'

const AreaSelectControl = ({name, areas, selectedAreaId}) => {
    const { register } = useFormContext()

    if (areas && areas.length > 0) {
        let options = [];
        areas.forEach((area, i) => {
            options.push(<option className="areaOpen"
                    key={i}
                    value={area.id}>{area.id + " " + area.name}</option>)
        })

        if(selectedAreaId) {
            return (
                    <Form.Control as="select"
                            name={name}
                            defaultValue={selectedAreaId} ref={register}>{options}</Form.Control>
            )
        }else{
            return (
                    <Form.Control as="select"
                            name={name}  ref={register}>{options}</Form.Control>
            )
        }
    }
    else{
        return <></>
    }
}

export default AreaSelectControl