import gql from 'graphql-tag'
import {useQuery} from 'urql'

export const LEGAL_NOTICE_QUERY = gql`
query legalNotice { 
    legalNotice { 
        versionInfo copyrightYear appNameAndVersion appNameVersionBuildInfo subtitle 
        content address1 address2 phone registered products beforeVersionInfo ansos 
        wrv beforeCopyrightYear afterCopyrightYear webPage displayName id 
    }
}`

/**
 * Fetch legal notice view content
 */
export function useLegalNoticeQuery(pause) {
    console.log("useLegalNoticeQuery(pause="+pause+")...")
    return useQuery({
        query: LEGAL_NOTICE_QUERY,
        variables: {},
        requestPolicy: 'cache-and-network',
        pause: pause
    })
}
