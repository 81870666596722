import gql from 'graphql-tag'
import {useMutation, useQuery} from 'urql'
import {USER_FUNCTION_ACCESS, USER_NOTIFICATION} from "components/UserProfile/UserProfileGQL";

export const PROFILE_LIST_QUERY = gql`query profileList {
    profileList {
        currentProfileName
        id
        profiles {
            name
            primary
            id
            skill
            dcpos
            disabled
            disabledReason
            active
            hasAdministratorRole
        }
    }
}`

export function useSwitchProfileQuery(pause) {
    console.log('useSwitchProfileQuery(), pause=' + pause)
    return useQuery({
        query: PROFILE_LIST_QUERY, variables: {}, requestPolicy: "network-only", pause: pause
    })
}

const SWITCH_PROFILE_MUTATION = gql`
    ${USER_FUNCTION_ACCESS}
    ${USER_NOTIFICATION}
    mutation switchProfile ($name: String!) {
        switchProfile (name: $name) {
            ansosId wsmAcctId userName hasMultipleDcpos profileName primaryProfile defaultDisplayName customDisplayName dcpos skill email
            phone altPhone defaultRole
            areasSA {name id}
            areasEmp {name id}
            saDefaultAreaId saDefaultPage empDefaultAreaId roles
            icalendarSubscribeToken icalendarSubscriptionUrl
            ...UserFunctionAccess
            ...UserNotification
        }
    }
`

export function useSwitchProfileMutation() {
    return useMutation(SWITCH_PROFILE_MUTATION)
}
