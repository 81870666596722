import gql from 'graphql-tag'
import {useMutation, useQuery} from 'urql'
import {CALENDAR_QUERY} from "components/Calendar/CalendarGQL";

// REQUEST FORM

export const REQUEST_FORM_QUERY = gql`
query requestForm ($requestType: RequestType!, $date: Date!, $areaId: AreaId!) {
    requestForm (requestType: $requestType, date: $date, areaId: $areaId) {
        from to areaId workshifts {
            workshiftId from to workMinutes code name label category work skill format areaId requiresApproval id
        }
        selfScheduled
        allArea
        id
    }
}`

/**
 * Submit available shift, work or timeoff request
 * @param {*} requestType
 * @param {*} date
 */
export function useRequestFormQuery(requestType, date, areaId) {
    //console.log("useRequestFormQuery(" + requestType + "," + date + "," + areaId + ") ; pause = " + !requestType || !date || !areaId)
    return useQuery({
        query: REQUEST_FORM_QUERY,
        variables: {requestType, date, areaId},
        pause: !requestType || !date || !areaId,
        requestPolicy: 'network-only',
    })
}

export const SUBMIT_REQUEST_MUTATION = gql`
mutation submitRequest ($request: RequestInput!) {
    submitRequest (request: $request) {
        status assignments {
            code from to areaId name label date team task home work order format pending type status id
        } 
        areas {
            name id
        }
    }
}`

export function useSubmitRequestMutation() {
    return useMutation(SUBMIT_REQUEST_MUTATION)
}

export function updateCacheWithSubmitRequestResult(result, cache) {
    cache.updateQuery({query: CALENDAR_QUERY}, data => {
        if (result.submitRequest) {
            console.log("ADD ASSIGNMENTS TO CACHE...")
            result.submitRequest.assignments.forEach(assignment => {
                if (data.calendar.assignments.findIndex(item => item.id === assignment.id) === -1) {
                    data.calendar.assignments.push(assignment)
                }
            })
            result.submitRequest.areas.forEach(area => {
                if (data.calendar.areas.findIndex(item => item.id === area.id) === -1) {
                    data.calendar.areas.push(area)
                }

            })
            console.log("DONE...ADDING ASSIGNMENTS TO CACHE")
        }
        return data
    })
}