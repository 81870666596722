import {getRole, ROLE} from "./components/Role";
import {appUrl, loadHash, toHash, wsmContext, wsmUrl} from "./helpers/http";
import {INIT_PAGE_KEY} from "./components/View";

export const Features = {
    LAUNCH: {
        key: 'launch',
        url: '/',
        title: 'Welcome',
        icon: 'fa-rocket',
        useOffline: true,
        header: false,
        footer: false
    },
    DEFAULT: {
        key: 'default',
        url: '/default',
        title: '',
        icon: 'fa-rocket',
        useOffline: false,
        header: false,
        footer: false
    },
    CALENDAR: {
        key: 'calendar',
        url: '/calendar',
        title: 'My Calendar',
        icon: 'fa-calendar-alt',
        useOffline: true,
        header: true,
        footer: true
    },
    ASSIGNMENTS: {
        key: 'assignments',
        url: '/assignments',
        title: 'Assignments',
        icon: 'fa-list-alt',
        useOffline: true,
        header: true,
        footer: true
    },
    AVAILABLE_SHIFTS_CALENDAR: {
        key: 'available-shifts-calendar',
        url: '/shifts_calendar',
        title: 'Available Shifts',
        icon: 'fa-star',
        useOffline: false,
        header: true,
        footer: true
    },
    WORK_CALENDAR: {
        key: 'work-calendar',
        url: '/work_calendar',
        title: 'Work',
        icon: 'fa-clock',
        useOffline: false,
        header: true,
        footer: true
    },
    TIMEOFF_CALENDAR: {
        key: 'timeoff-calendar',
        url: '/timeoff_calendar',
        title: 'Time Off',
        icon: 'fa-sun',
        useOffline: false,
        header: true,
        footer: true
    },
    MY_SCHEDULE: {
        key: 'my-schedule',
        url: '/my_schedule',
        title: 'My Schedule',
        icon: 'fa-hospital-user',
        useOffline: false,
        header: true,
        footer: true
    },
    MY_AREA_SCHEDULE: {
        key: 'my-area-schedule',
        url: '/my_area_schedule',
        title: 'Area Schedule',
        icon: 'fa-hospital-alt',
        useOffline: false,
        header: true,
        footer: true
    },
    AREA_SCHEDULE: {
        key: 'area-schedule',
        url: '/area_schedule',
        title: 'Area Schedule',
        icon: 'fa-hospital-alt',
        useOffline: false,
        header: true,
        footer: true
    },
    USER_PROFILE: {
        key: 'user-profile',
        url: '/user_profile',
        title: 'My Profile',
        icon: 'fa-user',
        useOffline: false,
        header: true,
        footer: true
    },
    ABOUT: {
        key: 'about',
        url: '/about',
        title: 'About',
        icon: 'fa-info-circle',
        useOffline: true,
        header: true,
        footer: true
    },
    NOTIFICATIONS: {
        key: 'notifications',
        url: '/notifications',
        title: 'Notifications',
        icon: 'fa-bell',
        useOffline: false,
        header: true,
        footer: true
    },
    RECIPIENT_SWAPS: {
        key: 'recipient-swaps',
        url: '/recipientProposals',
        title: 'Swap Proposals',
        icon: 'fa-sync',
        useOffline: false,
        header: true,
        footer: true
    },
    SWAPS_STATUS: {
        key: 'swaps-status',
        url: '/swapStatus',
        title: 'Swap Status',
        icon: 'fa-eye',
        useOffline: false,
        header: true,
        footer: true
    },
    HELP: {
        key: 'help',
        url: wsmUrl()+'/help/app/ansos2go_help.pdf',
        title: 'Help',
        icon: 'fa-question-circle',
        useOffline: true,
        header: true,
        footer: true
    },
}

/*
 * HotBar uses the roleFeatures[role] list of features showing the first 4 the user has access to followed by menu link.
 * Menu uses the MenuFeatures list (below) showing all features the user can access.
 */

const roleFeatures = []
roleFeatures[ROLE.NONE] = [
    Features.LAUNCH,
]
roleFeatures[ROLE.EMP] = [
    Features.CALENDAR,
    Features.MY_AREA_SCHEDULE,
    Features.AVAILABLE_SHIFTS_CALENDAR,
    Features.RECIPIENT_SWAPS,
    Features.WORK_CALENDAR,
    Features.TIMEOFF_CALENDAR,
    Features.NOTIFICATIONS,
    Features.ASSIGNMENTS,
    Features.SWAPS_STATUS,
    Features.USER_PROFILE,
    Features.ABOUT,
    Features.HELP,
]
roleFeatures[ROLE.SA] = [
    Features.AREA_SCHEDULE,
    Features.USER_PROFILE,
    Features.ABOUT,
    Features.HELP,
]
roleFeatures[ROLE.BOTH] = [
    Features.CALENDAR,
    Features.MY_AREA_SCHEDULE,
    Features.AREA_SCHEDULE,
    Features.AVAILABLE_SHIFTS_CALENDAR,
    Features.RECIPIENT_SWAPS,
    Features.WORK_CALENDAR,
    Features.TIMEOFF_CALENDAR,
    Features.NOTIFICATIONS,
    Features.ASSIGNMENTS,
    Features.SWAPS_STATUS,
    Features.USER_PROFILE,
    Features.ABOUT,
    Features.HELP,
]

export const MenuFeatures = [
    Features.CALENDAR,
    Features.MY_AREA_SCHEDULE,
    Features.AREA_SCHEDULE,
    Features.ASSIGNMENTS,
    Features.AVAILABLE_SHIFTS_CALENDAR,
    Features.NOTIFICATIONS,
    Features.WORK_CALENDAR,
    Features.TIMEOFF_CALENDAR,
    Features.SWAPS_STATUS,
    Features.RECIPIENT_SWAPS,
    Features.USER_PROFILE,
    Features.ABOUT,
    Features.HELP,
]

// MenuFeatures.length - 1 (only one of the Area Schedule features is included in menu)
export const MaxMenuFeatures = 12

export function getFeatures(user) {
    const role = getRole(user)
    const unchecked = roleFeatures[role]
    const checked = []
    unchecked.forEach(feature => {
        if (allowAccess(feature, user, role)) checked.push(feature)
    })
    return checked
}

export function getFeaturesForMenu(user) {
    const features = getFeatures(user)
    const menu = []
    MenuFeatures.forEach(feature => {
        if (features.indexOf(feature) >= 0) menu.push(feature)
    })
    return menu
}

export function allowAccess(feature, user, role = getRole(user)) {
    switch (feature) {
        case Features.DEFAULT:
            return true
        case Features.LAUNCH:
            return role === ROLE.NONE
        case Features.ASSIGNMENTS:
            return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empCalendarViews
        case Features.CALENDAR:
            return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empCalendarViews
        case Features.NOTIFICATIONS:
            // TODO - Add notification specific check here
            return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empAvailableShifts
        case Features.AVAILABLE_SHIFTS_CALENDAR:
            return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empAvailableShifts
        case Features.WORK_CALENDAR:
            return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empEnterRequests
        case Features.TIMEOFF_CALENDAR:
            return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empEnterRequests
        case Features.MY_SCHEDULE:
            return false
        //return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empScheduleView
        case Features.MY_AREA_SCHEDULE:
            /* If the SA does not have the Weekly View WSM security function associated with it,
             * but the Employee role has the My Schedule security function associated with it,
             * show the Area Schedule from the Employee viewpoint.
             */
            return (role === ROLE.EMP && user.functionAccess.empScheduleView) ||
                (role === ROLE.BOTH && user.functionAccess.empScheduleView && !user.functionAccess.adminScheduleView)
        case Features.AREA_SCHEDULE:
            /* If the SA role has the Weekly View WSM security function associated with it,
             * then show the Area Schedule from the SA viewpoint.
             */
            return (role === ROLE.SA || role === ROLE.BOTH) && user.functionAccess.adminScheduleView
        case Features.USER_PROFILE:
            return (role === ROLE.EMP || role === ROLE.BOTH) || role === ROLE.SA
        case Features.RECIPIENT_SWAPS:
        case Features.SWAPS_STATUS:
            return (role === ROLE.EMP || role === ROLE.BOTH) && user.functionAccess.empAllowSwaps
        case Features.ABOUT:
        case Features.HELP:
            return (role === ROLE.EMP || role === ROLE.BOTH) || role === ROLE.SA
        default:
            // Unexpected feature
            console.log('ERROR: accessCheck(' + feature + ',' + user + ',' + role)
            return false;
    }
}

export function getDefaultFeature(user) {
    const features = getFeatures(user)
    // TODO - get user defined default page if SA; currently not in user
    return features[0]
}

/**
 * Return feature with path matching (path).
 * @param path part of url following hash (#); i.e. window.location.hash
 * @returns matching Feature or Features.LAUNCH
 */
export function getFeature(path) {
    const url = new URL(path)
    // console.log("url="+JSON.stringify(url))
    // console.log("url.pathname="+url.pathname)
    // console.log("url.hash="+url.hash)
    // console.log("url.search="+url.search)
    const keys = Object.keys(Features)
    for (let ndx = 0; ndx < keys.length; ndx++) {
        const key = keys[ndx]
        // console.log("feature = "+key+"; feature url="+Features[key].url+"; path = "+url.hash.substring(1))
        if (Features[key].url === url.hash.substring(1)) return Features[keys[ndx]]
    }
    return undefined
}

export function getFeatureRole(feature) {
    if (feature) {
        if (isIn(roleFeatures[ROLE.EMP], feature)) {
            return ROLE.EMP
        } else if (isIn(roleFeatures[ROLE.SA], feature)) {
            return ROLE.SA
        }
    }
    return undefined
}

function isIn(featureArray, feature) {
    let found = false
    featureArray.map(arrayFeature => {
        if (arrayFeature === feature) found = true
    })
    return found
}

export function loadFeature(feature) {
    loadHash(toHash(feature.url))
}

export function getFeatureURL() {
    let featureUrl
    // console.log("location="+window.location)
    // console.log("location.hostname="+window.location.hostname)
    // console.log("location.port="+window.location.port)
    // console.log("location.path="+window.location.pathname)
    // console.log("location.search="+window.location.search)


    if (window.location.search) {
        const params = new URLSearchParams(window.location.search)

        if (params) {
            const uri = params.get('uri')
            if (uri) {
                const location = new URL(decodeURI(uri))
                if (location.protocol === 'web+ansos' && location && location.pathname) {
                    featureUrl = location.search + '#/' + location.pathname.substring(2)
                }
            } else {
                featureUrl = params.get('featureUrl')
                console.log('featureUrl=' + featureUrl)
                if (featureUrl) {
                    featureUrl = appUrl() + '#' + featureUrl
                }
            }
        }
    }



    console.log('getFeatureURL() returning: ' + featureUrl)
    return featureUrl
}


export function getInitPageURL(defaultRole) {
    let initPageUrl
    const savedInitPage = window.localStorage.getItem(INIT_PAGE_KEY)
    console.log("initPage = " +  savedInitPage + "; user.defaultRole = " + JSON.stringify(defaultRole))
    // Only check for initPage when both conditions are satisfied:
    // (1) "saved initial page" exists (2)And user is logged in
    if(savedInitPage && (defaultRole !== ROLE.NONE)) {
        initPageUrl = appUrl()  + savedInitPage //note "#" is not needed on purpose. Reason being, savedInitPage
                                                //is whatever after "/app"
        window.localStorage.removeItem(INIT_PAGE_KEY)
    }
    console.log("getInitPageURL() returning:" + initPageUrl)
    return initPageUrl

}

export default Features
