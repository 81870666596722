import React from "react";
import {Cell, Row} from "@table-library/react-table-library/table";

/**
 * Render Hours row
 * @param dayHours  [day1-hours,day2-hours,...]
 * @param periodDays [day1_period_info,...]
 * @returns {JSX.Element}
 * @constructor
 */
function HoursRow({dayHours, periodDays}) {
    if (!dayHours) {
        console.error("HoursRow render attempt with no data")
        return <></>
    }
    return <Row className="hours">
        <Cell className="rowHeader" pinLeft>Actual Hours</Cell>
        <DayCells dayHours={dayHours} periodDays={periodDays}/>
    </Row>
}

function DayCells({dayHours, periodDays}) {
    return dayHours.map((hours, day) => (
        <Cell key={day} className={periodDays[day].className}>{hours}</Cell>
    ))
}

export default HoursRow