import React from "react"
import {getParamsAtStartup} from 'components/Settings/SettingsData'
import ansos2go from "images/22-HSTM-ANSOS2Go-Primary-RGB-blue-orange.svg"
import {wsmUrl} from "helpers/http"
import {closeMenu} from "./Menu"
import If from "components/If"
import './Menu.css'

const MenuHeader = ({clickLink,functions}) => {
    const nonWSMLicenseOnly = getParamsAtStartup().nonWSMLicenseOnly
    return (

    <div className='header'>
        <img className="ansos2go" src={ansos2go} alt="ansos2go(tm)"/>
        <span className='close' onClick={closeMenu}><i className={"fas fa-window-close"}></i></span>
        <If condition={() => {
            return !nonWSMLicenseOnly
        }}>

            <div className='webscheduler'
                 onClick={() => clickLink(functions.onMenuSelect, "webscheduler-link")}>
                <span>Web Scheduler  <i className={"fas fa-external-link-alt"}/></span>
            </div>
            <a id="webscheduler-link" style={{display: 'hidden'}} href={wsmUrl()+"/defaultPage"} target="_blank"
               rel="noreferrer noopener"/>
        </If>
        <If condition={() => {
            return nonWSMLicenseOnly
        }}>
            <div className='webscheduler'>
                <span>Web Scheduler</span>
            </div>
        </If>
    </div>
    )

}
export default MenuHeader;