import React, {useState} from 'react'
import Notice from "components/Notice"
import Spinner from "components/Spinner"
import ProposedSwapsListForm from "./ProposedSwapsListForm"
import ServiceStatus from "helpers/serviceStatus"
import {useAcceptSwapProposalMutation, useDeclineSwapProposalMutation} from "../SwapGQL"
import {makeAcceptSwapProposalResultStatus, makeDeclineSwapProposalResultStatus} from "./SwapsAsRecipientData"

const ProposedSwapsList = ({proposedSwapProposals, refresh}) => {

    const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})
    const [show, setShow] = useState(SHOW.FORM)
    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})

    // Assign a key to prevent "ProposedSwapsListForm" from re-rendering when unnecessary, otherwise it might lose some uncontrolled user input(such as "note")
    const [divKey, setDivKey] = useState(1)

    const [acceptSwapProposalResult, acceptSwapProposal] = useAcceptSwapProposalMutation()
    const [declineSwapProposalResult, declineSwapProposal] = useDeclineSwapProposalMutation()


    const actions = {
        acceptProposal: (swapAcceptDeclineInput) => {
            setShow(SHOW.SPINNER)
            let variables = {"swapAcceptDeclineInput": swapAcceptDeclineInput}
            acceptSwapProposal(variables).then(({fetching: fetchingASPM, data: dataASPM, error: errorASPM}) => {
                console.log("after accept swap proposal, data = " + JSON.stringify(dataASPM) + "; error = " + JSON.stringify(errorASPM))
                let acceptSwapProposalStatus = makeAcceptSwapProposalResultStatus(fetchingASPM, dataASPM, errorASPM)
                setServiceStatus(acceptSwapProposalStatus)
                setShow(SHOW.NOTICE)
            })
        },

        denyProposal: (swapAcceptDeclineInput) => {
            setShow(SHOW.SPINNER)
            let variables = {"swapAcceptDeclineInput": swapAcceptDeclineInput}
            declineSwapProposal(variables).then(({fetching: fetchingDSPM, data: dataDSPM, error: errorDSPM}) => {
                console.log("after decline swap proposal, data = " + JSON.stringify(dataDSPM) + "; error = " + JSON.stringify(errorDSPM))
                let declineSwapProposalStatus = makeDeclineSwapProposalResultStatus(fetchingDSPM, dataDSPM, errorDSPM)
                setServiceStatus(declineSwapProposalStatus)
                setShow(SHOW.NOTICE)
            })
        }
    }


    function handleNoticeResponse() {
        setShow(SHOW.FORM)
        setDivKey(prevKey => prevKey + 1)
        refresh() //trigger a refetch of "proposed swap proposals" via graphQL
    }

    switch (show) {
        case SHOW.NOTICE: {
            return (
                <><Notice message={serviceStatus.message}
                          type={serviceStatus.noticeType}
                          onClose={handleNoticeResponse}
                          show={true}/>
                    <div key={divKey}>
                        <ProposedSwapsListForm proposedSwapProposals={proposedSwapProposals} actions={actions}/>
                    </div>
                </>
            )
        }
        case SHOW.SPINNER: {
            return (
                <>
                    <Spinner show={true}/>
                    <div key={divKey}>
                        <ProposedSwapsListForm proposedSwapProposals={proposedSwapProposals} actions={actions}/>
                    </div>
                </>
            )
        }
        case SHOW.FORM: {
            return (
                <>
                    <div key={divKey}>
                        <ProposedSwapsListForm proposedSwapProposals={proposedSwapProposals} actions={actions}/>
                    </div>
                </>
            )

        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }


}


export default ProposedSwapsList