import gql from 'graphql-tag'
import {useMutation} from 'urql'
import {CALENDAR_QUERY} from "../Calendar/CalendarGQL";

export const SUBMIT_RETRACT_MUTATION = gql`
mutation submitRetract ($retraction: RetractionInput!) {
    submitRetract (retraction: $retraction){
        requestId isRetractable actionStatus actionStatusReason
    }
}`

export function useSubmitRetractionMutation() {
    return useMutation(SUBMIT_RETRACT_MUTATION)
}

export function updateCacheWithSubmitRetractResult(result, cache) {
    cache.updateQuery({query: CALENDAR_QUERY}, data => {
        if (result.submitRetract && result.submitRetract.actionStatus === true) {
            console.log("REMOVE ASSIGNMENT FROM CACHE...")
            let index = data.calendar.assignments.findIndex(item => item.id === result.submitRetract.requestId)
            if (index > -1) {
                data.calendar.assignments.splice(index, 1)
                console.log("DONE...REMOVING ASSIGNMENT FROM CACHE")
            }
        }

        return data
    })
}