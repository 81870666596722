export const ActionDefs = {
    SWITCH_PROFILE: {
        key: 'switchprofile',
        title: 'Switch Profile',
        icon: 'fa-users',
        useOffline: false,
        addToHotBar: false,
    },
    SIGN_IN: {
        key: 'signin',
        title: 'Sign In',
        icon: 'fa-sign-in-alt',
        useOffline: false,
        addToHotBar: false,
    },
    SIGN_OUT: {
        key: 'signout',
        title: 'Sign Out',
        icon: 'fa-sign-out-alt',
        useOffline: false,
        addToHotBar: false,
    },
    MENU: {
        key: 'menu',
        title: 'Menu',
        icon: 'fa-bars',
        useOffline: true,
        addToHotBar: true,
    },
}

const ActionDefKeys = [ActionDefs.SWITCH_PROFILE.key, ActionDefs.SIGN_IN.key, ActionDefs.SIGN_OUT.key, ActionDefs.MENU.key]

export function isActionDef(action) {
    return action && ActionDefKeys.indexOf(action.key) >= 0
}

export function isActionType(key) {
    return key && ActionDefKeys.indexOf(key) >= 0
}

export function actionFunction(actionDef, actions) {
    let func = null
    actions.forEach((item) => {
        if (item.key.trim() === actionDef.key.trim()) {
            func = item.function
        }
    })
    return func
}

export function findAction(actionDef, actions) {
    let action = null
    actions.forEach((item) => {
        if (item.key.trim() === actionDef.key.trim()) {
            action = item
        }
    })
    return action
}

export function getAction(actionKey, actions) {
    let action = null
    actions.forEach((item) => {
        if (item.key.trim() === actionKey.trim()) {
            action = item
        }
    })
    return action
}
