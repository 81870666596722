export function getArea(areas, areaId) {
    let area = {id: areaId}
    if (areas) {
        area = areas.find((item) => item.id === areaId)
        //console.error(areaId + " not found in areas >> " + JSON.stringify(areas))
    }
    return area

}

/**
 *Filter array of areas returning only those whose id is in id list.
 *
 * @param areas master list of areas
 * @param areaIds list of area ids to filter on
 */
export function filterAreaList(areas, areaIds) {
    return areaIds.map(areaId => areas.find((area) =>area.id == areaId))
}

export function getDefaultOrHomeArea(user) {
    if (user.areasEmp && user.areasEmp.length > 0) {
        const areaId = user.empDefaultAreaId ? user.empDefaultAreaId : user.dcpos.substring(0,2)
        const area = getArea(user.areasEmp, areaId)
        if (area) {
            return area
        } else {
            console.error("User default/home area (" + areaId + ") is missing from user.areasEmp")
            return user.areasEmp[0] // Fail back to first area in employee areas
        }
    } else {
        console.error("Unable to get default/home area; user.areasEmp is empty")
        return null
    }
}

export function isALLArea(area) {
    return area && area.id === ALLAreaId
}

export function createALLArea() {
    return {id: "  ", name: "All Areas"}
}
export const ALLAreaId = "  "
