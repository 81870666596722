import React from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {VERIFY_TYPE} from "./VerifyEmailOrPhone"
import Form from "react-bootstrap/Form";
import {useForm} from "react-hook-form";

const VerifyEmailOrPhoneForm = ({actions, verifyType, email, notfyEmailTo, notfyTxtTo, phone, carrier}) => {
    // console.log('VerifyEmailOrPhoneForm  -- verifyType = ' + verifyType + ";email= " + email +";notfyEmailTo=" + notfyEmailTo
    // +";notfyTxtTo =" + notfyTxtTo + ";phone =" + phone + ";carrier = "+ carrier)

    const {register, handleSubmit, errors} = useForm()

    const onVerifyFormSubmit = (input, e) => {
        console.log("input.verificationCode = " + input.verificationCode)
        if (verifyType === VERIFY_TYPE.email) {
            actions.verifyEmail(notfyEmailTo, email, input.verificationCode)
        } else if (verifyType === VERIFY_TYPE.phone) {
            actions.verifyPhone(notfyTxtTo, phone, carrier, input.verificationCode)

        }
    }


    const verifyEmailLine1 = 'Please check your email for a message from Web Scheduler. Enter the verification code from that message below.'
    const verifyEmailLine2 = 'If the message does not appear in your inbox, check your junk email folder. Contact your Web Scheduler administrator for assistance.'

    const verifyPhoneLine1 = 'Please check your phone for a text message from Web Scheduler. Enter the verification code from that message below.'
    const verifyPhoneLine2 = 'Contact your Web Scheduler administrator for assistance.'

    return (
        <div>

            <Modal centered show={true} backdrop="static" onHide={actions.cancel}>

                <Form id="verifyEmailOrPhoneForm">
                    <Modal.Header>
                        {VERIFY_TYPE[verifyType] === VERIFY_TYPE.email &&
                        <Modal.Title>Verify Email Address</Modal.Title>
                        }

                        {VERIFY_TYPE[verifyType] === VERIFY_TYPE.phone &&
                        <Modal.Title>Verify Phone</Modal.Title>
                        }
                    </Modal.Header>

                    <Modal.Body>

                        {VERIFY_TYPE[verifyType] === VERIFY_TYPE.email &&
                        <>
                            <Form.Group>{verifyEmailLine1}</Form.Group>
                            <Form.Group>{verifyEmailLine2}</Form.Group>
                        </>
                        }

                        {VERIFY_TYPE[verifyType] === VERIFY_TYPE.phone &&
                        <>
                            <Form.Group>{verifyPhoneLine1}</Form.Group>
                            <Form.Group>{verifyPhoneLine2}</Form.Group>
                        </>
                        }

                        <Form.Group className="Hcenter form-inline" key="verificationCode">
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control className="w-25" type="text" name="verificationCode" inputMode='numeric'
                                          ref={register({
                                              required: 'Verification code is required'
                                          })}/>
                        </Form.Group>

                        {errors.verificationCode && <p className="error">{errors.verificationCode.message}</p>}

                    </Modal.Body>


                    <Modal.Footer>

                        <Button variant="primary" value="Submit"
                                onClick={handleSubmit(onVerifyFormSubmit)}>Submit</Button>
                        <Button variant="secondary" onClick={actions.cancel}>Cancel</Button>
                    </Modal.Footer>

                </Form>

            </Modal>
        </div>
    )

}

export default VerifyEmailOrPhoneForm