import React, {useState} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import PotentialAssignmentControl from "./PotentialAssignmentControl"
import OriginalAssignmentControl from "./OriginalAssignmentControl"
import {SwapCustomToggle} from "./ProposalAsRecipientForm"
import {INITIATOR, RECIPIENT} from "./SwapStatusData"
import {useForm} from 'react-hook-form'

const SwapRequestForm = ({swapRequest, areas}) => {
    const {register, getValues} = useForm()

    const [showSwapPartnerNote, setShowSwapPartnerNote] = useState(false)

    let originalWorkshift = {
        code: swapRequest.partnerReqWsCode,
        from: swapRequest.partnerReqWsFrom,
        to: swapRequest.partnerReqWsTo,
        label: swapRequest.partnerReqWsLabel,
        date: swapRequest.partnerReqWorkdate,
        workMinutes: swapRequest.partnerReqWsWorkMinutes,
        areaId: swapRequest.partnerReqWsAreaId,
        format: swapRequest.partnerReqWsFormat,
    }


    let viewerEmployeeStatus = {
        status: swapRequest.webReqStatus,
        role: swapRequest.isInitiator ? INITIATOR : RECIPIENT,
        note: swapRequest.primaryReqNote,
        saNote: swapRequest.primaryReqSANote,
    }


    let partnerWorkshift = {
        code: swapRequest.primaryReqWsCode,
        from: swapRequest.primaryReqWsFrom,
        to: swapRequest.primaryReqWsTo,
        label: swapRequest.primaryReqWsLabel,
        date: swapRequest.primaryReqWorkdate,
        workMinutes: swapRequest.primaryReqWsWorkMinutes,
        areaId: swapRequest.primaryReqWsAreaId,
        format: swapRequest.primaryReqWsFormat,
    }

    let partnerEmployee = {
        empName: swapRequest.partnerEmpName,
        dcpos: swapRequest.partnerDcpos,
        skill: swapRequest.partnerSkill,
        jobCode: swapRequest.partnerJobCode,
        note: swapRequest.partnerReqNote,
        saNote: swapRequest.partnerReqSANote,
        status: swapRequest.webReqStatus
    }

    let disablePartnerNoteCheck = (!partnerEmployee.note) && (!partnerEmployee.saNote)
    let includeNotesSpanClassName = disablePartnerNoteCheck ? "disabledIncludeNotesSpan" : "includeNotesSpan"

    return (
        <div className={"swapProposal"}>
            <OriginalAssignmentControl originalWorkshift={originalWorkshift}
                                       viewerEmployeeStatus={viewerEmployeeStatus}
                                       areas={areas}
            />

            <Accordion defaultActiveKey="0">
                <Card className = "swapCard">
                    <Card.Header className="swapCardHeader">
                        <div className="spaceBetween">
                            <div className="spaceBetween">
                                <div className="bigLabel">Potential Assignment (1)
                                </div>

                                <div className="includeNotes">
                                    <label className="hsCheckbox">
                                        <input type="checkbox"
                                               name="showPartnerNote"
                                               disabled={disablePartnerNoteCheck}
                                               defaultChecked={false}
                                               ref={register}
                                               onChange={() => {
                                                   const showNoteChecked = getValues("showPartnerNote")
                                                   setShowSwapPartnerNote(showNoteChecked)
                                               }}
                                        />
                                        <span className="hsCheckmark"/>

                                    </label>
                                    {/*<span className={includeNotesSpanClassName}>Include notes</span>*/}

                                </div>
                                <div className={includeNotesSpanClassName}>
                                    <span>Include notes</span>
                                </div>
                            </div>

                            <SwapCustomToggle eventKey={swapRequest.sortByProposalId}>
                            </SwapCustomToggle>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={swapRequest.sortByProposalId}>
                        <Card.Body className="potentialAssignmentsCardBody">
                            <PotentialAssignmentControl partnerWorkshift={partnerWorkshift}
                                                        partnerEmployee={partnerEmployee}
                                                        showSwapPartnerNote={showSwapPartnerNote}
                                                        areas={areas}
                            />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>

        </div>
    )


}

export default SwapRequestForm