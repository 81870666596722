import React from 'react'
import If from "components/If"
import SAContactForm from "./SAContactForm"
import {ifSARoleOnly} from "../userProfileHelper"
import EmployeeContactForm from "./EmployeeContactForm"



const ContactSection = ({data: userProfileData, emailVerified, setEmailVerified, txtVerified, setTxtVerified}) => {


    return (
            <div>
                <If condition={() => {
                    return ifSARoleOnly(userProfileData.roles)
                }}>
                    <SAContactForm data={userProfileData}
                            emailVerified={emailVerified}
                            setEmailVerified={setEmailVerified}
                    />
                </If>

                <If condition={() => {
                    return !ifSARoleOnly(userProfileData.roles)
                }}>
                    <EmployeeContactForm data={userProfileData}
                            emailVerified={emailVerified}
                            setEmailVerified={setEmailVerified}
                            txtVerified={txtVerified}
                            setTxtVerified={setTxtVerified}
                    />
                </If>
            </div>



    )

}

export default ContactSection