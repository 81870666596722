/**
 * Generating the jsonSchema should be part of the build process, but until then this is a copy-n-paste
 * solution where the IntrospectionQuery is executed using Postman.
 *
 * To update jsonSchema, run the introspectionQuery in Postman and copy the resulting data object
 * __schema field to replace the assigned value of jsonSchema below.
 *
 * UPDATE jsonSchema when wsmapi graphql schema changes!
 */
import gql from "graphql-tag";

const introspectionQuery = gql`
    query IntrospectionQuery {
        __schema {

            queryType { name }
            mutationType { name }
            subscriptionType { name }
            types {
            ...FullType
            }
            directives {
                name
                description

                locations
                args {
                ...InputValue
                }
            }
        }
    }

    fragment FullType on __Type {
        kind
        name
        description

        fields(includeDeprecated: true) {
            name
            description
            args {
            ...InputValue
            }
            type {
            ...TypeRef
            }
            isDeprecated
            deprecationReason
        }
        inputFields {
        ...InputValue
        }
        interfaces {
        ...TypeRef
        }
        enumValues(includeDeprecated: true) {
            name
            description
            isDeprecated
            deprecationReason
        }
        possibleTypes {
        ...TypeRef
        }
    }

    fragment InputValue on __InputValue {
        name
        description
        type { ...TypeRef }
        defaultValue
    }

    fragment TypeRef on __Type {
        kind
        name
        ofType {
            kind
            name
            ofType {
                kind
                name
                ofType {
                    kind
                    name
                    ofType {
                        kind
                        name
                        ofType {
                            kind
                            name
                            ofType {
                                kind
                                name
                                ofType {
                                    kind
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`


export const introspectedSchema = {
    "__schema": {
        "queryType": {
            "name": "Query"
        },
        "mutationType": {
            "name": "Mutation"
        },
        "subscriptionType": null,
        "types": [
            {
                "kind": "ENUM",
                "name": "AdmDefaultPage",
                "description": null,
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "inboxPg",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "weeklyViewPg",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "periodViewPg",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "availableShiftsPg",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Area",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "show",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "AreaAvailableShiftDates",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "dates",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "AreaId",
                "description": "Custom AreaId scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "AreaSchedule",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notice",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "periods",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "PeriodInfo",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areas",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Area",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "skills",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Skill",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "schEmpTotals",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "EmployeeInfoAssignmentsAndTotalCount",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Assignment",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "code",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "totalMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "label",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "team",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "task",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "home",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "work",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "order",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "format",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "pending",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "type",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "status",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "AssignmentDetailAction",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "retractable",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapable",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "Boolean",
                "description": "Built-in Boolean",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Calendar",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "employeeId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "periods",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "PeriodAreaStatus",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "assignments",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Assignment",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areas",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Area",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "Date",
                "description": "An RFC-3339 compliant Full Date Scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "DateRange",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "DateTime",
                "description": "An RFC-3339 compliant DateTime Scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "Dcpos",
                "description": "Custom Dcpos scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Employee",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "dcpos",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "jobClass",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "skillCode",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "email",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "phone",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "EmployeeInfoAndAssignments",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "employee",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "Employee",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "assignments",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Assignment",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "EmployeeInfoAssignmentsAndTotalCount",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "skill",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "schEmps",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "EmployeeInfoAndAssignments",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "schCounts",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "ScheduleCount",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "ID",
                "description": "Built-in ID",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "Int",
                "description": "Built-in Int",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "IssueInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "username",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "userAgent",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "deviceDatetime",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "description",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "logs",
                        "description": null,
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "LegalNotice",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "versionInfo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "copyrightYear",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "appNameAndVersion",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "appNameVersionBuildInfo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "subtitle",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "content",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "address1",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "address2",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "phone",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "registered",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "products",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "beforeVersionInfo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ansos",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "wrv",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "beforeCopyrightYear",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "afterCopyrightYear",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "webPage",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "displayName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Mutation",
                "description": null,
                "fields": [
                    {
                        "name": "changePasswrd",
                        "description": null,
                        "args": [
                            {
                                "name": "currentPasswrd",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "newPasswrd",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "UserProfile",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "submitRequest",
                        "description": null,
                        "args": [
                            {
                                "name": "request",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "RequestInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "RequestResult",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "updateUserProfile",
                        "description": null,
                        "args": [
                            {
                                "name": "userProfileInput",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "UserProfileInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "UserProfile",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "submitRetract",
                        "description": null,
                        "args": [
                            {
                                "name": "retraction",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "RetractionInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "OBJECT",
                            "name": "RetractStatus",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "switchProfile",
                        "description": null,
                        "args": [
                            {
                                "name": "name",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "OBJECT",
                            "name": "UserProfile",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "verifyEmailVerificationCode",
                        "description": null,
                        "args": [
                            {
                                "name": "notfyEmailTo",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "ENUM",
                                        "name": "NotfyEmailTo",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "email",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "verificationCode",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "UserProfile",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "verifyPhoneVerificationCode",
                        "description": null,
                        "args": [
                            {
                                "name": "notfyTxtTo",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "ENUM",
                                        "name": "NotfyTxtTo",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "phone",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "carrier",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "verificationCode",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "UserProfile",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initiateSwapProposal",
                        "description": null,
                        "args": [
                            {
                                "name": "swapInitiateProposalInput",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "SwapInitiateProposalInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "acceptSwapProposal",
                        "description": null,
                        "args": [
                            {
                                "name": "swapAcceptDeclineInput",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "ProcessSwapProposalInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "declineSwapProposal",
                        "description": null,
                        "args": [
                            {
                                "name": "swapAcceptDeclineInput",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "ProcessSwapProposalInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "retractSwapProposal",
                        "description": null,
                        "args": [
                            {
                                "name": "swapRetractInput",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "ProcessSwapProposalInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "subscribeIcalendar",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "unsubscribeIcalendar",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "signout",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "status",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "submitIssue",
                        "description": null,
                        "args": [
                            {
                                "name": "issue",
                                "description": null,
                                "type": {
                                    "kind": "INPUT_OBJECT",
                                    "name": "IssueInput",
                                    "ofType": null
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "ENUM",
                "name": "NotfyAsAllAreas",
                "description": null,
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "selectedAreas",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "allAreas",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            },
            {
                "kind": "ENUM",
                "name": "NotfyAsBy",
                "description": null,
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "email",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "message",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "emailMsg",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            },
            {
                "kind": "ENUM",
                "name": "NotfyEmailTo",
                "description": null,
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "pEmail",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "oEmail",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            },
            {
                "kind": "ENUM",
                "name": "NotfyTxtTo",
                "description": null,
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "notSendText",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "yesPrimaryText",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "yesAlternateText",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "yesOtherText",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Notification",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workshift",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "Workshift",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "isAvailable",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "NotificationsAndSchedule",
                "description": null,
                "fields": [
                    {
                        "name": "notifications",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Notification",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "assignments",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Assignment",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "ParamsAtStartup",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "dateFormat",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapNearbyWarningThresholdInMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "installPromptInterval",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "PeriodAreaStatus",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "publishStatus",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "reset",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "timeOffOpen",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workOpen",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "shiftsOpen",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "home",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "DateRange",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notice",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "PeriodInfo",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "reset",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "published",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "balancing",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "ProcessSwapProposalInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "proposalId",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "note",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "assignmentChanged",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Profile",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "primary",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "skill",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "dcpos",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "disabled",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "disabledReason",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "active",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "hasAdministratorRole",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "ProfileList",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "profiles",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Profile",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "currentProfileName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Query",
                "description": null,
                "fields": [
                    {
                        "name": "legalNotice",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "LegalNotice",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "user",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "UserProfile",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "userProfile",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "UserProfile",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "profileList",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "ProfileList",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "sendEmailVerificationCode",
                        "description": null,
                        "args": [
                            {
                                "name": "notfyEmailTo",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "ENUM",
                                        "name": "NotfyEmailTo",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "email",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "sendPhoneVerificationCode",
                        "description": null,
                        "args": [
                            {
                                "name": "notfyTxtTo",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "ENUM",
                                        "name": "NotfyTxtTo",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "phone",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "carrier",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "periodAreaStatus",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "PeriodAreaStatus",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "calendar",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Calendar",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaAvailableShiftDates",
                        "description": null,
                        "args": [
                            {
                                "name": "areaId",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "AreaId",
                                    "ofType": null
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "OBJECT",
                            "name": "AreaAvailableShiftDates",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "requestForm",
                        "description": null,
                        "args": [
                            {
                                "name": "requestType",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "ENUM",
                                        "name": "RequestType",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "date",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "Date",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "areaId",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "AreaId",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "RequestForm",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaSchedule",
                        "description": null,
                        "args": [
                            {
                                "name": "areaId",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "AreaId",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "periodStartDate",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "Date",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "role",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "UserRole",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "OBJECT",
                            "name": "AreaSchedule",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "assignmentDetailAction",
                        "description": null,
                        "args": [
                            {
                                "name": "areaId",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "AreaId",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "code",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "String",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "startTime",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "String",
                                    "ofType": null
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "endTime",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "String",
                                    "ofType": null
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "workMinutes",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "Int",
                                    "ofType": null
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "totalMinutes",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "Int",
                                    "ofType": null
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "workDate",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "Date",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "label",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "String",
                                    "ofType": null
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "pending",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "Boolean",
                                    "ofType": null
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "OBJECT",
                            "name": "AssignmentDetailAction",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapInitEmpSchAndRecAreaList",
                        "description": null,
                        "args": [
                            {
                                "name": "initiatorWorkDate",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "Date",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            },
                            {
                                "name": "initiatorWorkAreaId",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "SCALAR",
                                        "name": "AreaId",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapInitEmpSchAndRecAreaList",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapRecipientAssignments",
                        "description": null,
                        "args": [
                            {
                                "name": "swapRecipientAssignmentsInput",
                                "description": null,
                                "type": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "INPUT_OBJECT",
                                        "name": "SwapRecipientAssignmentsInput",
                                        "ofType": null
                                    }
                                },
                                "defaultValue": null
                            }
                        ],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "SwapEmpAndAssignment",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notifications",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "NotificationsAndSchedule",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "proposedSwapProposals",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "SwapProposalAsRecipient",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapProposalsAndRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapProposalsAndRequests",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "hasSwapProposals",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "serverInfo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "ServerInfo",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "paramsAtStartup",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "ParamsAtStartup",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "RequestForm",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workshifts",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Workshift",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "selfScheduled",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "allArea",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "RequestInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "requestType",
                        "description": null,
                        "type": {
                            "kind": "ENUM",
                            "name": "RequestType",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "startDate",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "endDate",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workshiftId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "WorkshiftId",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workshiftName",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workshiftStartTime",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workshiftEndTime",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workshiftSkill",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "note",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "resubmit",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "RequestResult",
                "description": null,
                "fields": [
                    {
                        "name": "status",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "assignments",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Assignment",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areas",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Area",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "ENUM",
                "name": "RequestType",
                "description": null,
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "OPEN_SHIFT",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "WORK",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "TIME_OFF",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "SWAP",
                        "description": null,
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "RetractStatus",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "requestId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "isRetractable",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "actionStatus",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "actionStatusReason",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "RetractionInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "id",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "code",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "totalMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "label",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "home",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "work",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "pending",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "reason",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "comment",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "ScheduleCount",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "emps",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "minutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "ServerInfo",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "appName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "appVersion",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "buildInfo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "vendor",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "organizationName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "timezone",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "locale",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Skill",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "String",
                "description": "Built-in String",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapAreaInfo",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "area",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "Area",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "open",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "participating",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapEmpAndAssignment",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapEmployee",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapEmployee",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "code",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "wsCodeId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "WorkshiftId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "breakMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "wsLabel",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "timeSort",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "overtime",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "wsFormat",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "overlap",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapEmployee",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ansosId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "dcpos",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "skill",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "jobCode",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapInitEmpSchAndRecAreaList",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initEmpPeriodAssignmentsAndRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Assignment",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "recAreaList",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "SwapAreaInfo",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "selectedRecAreaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initEmpCompSkillSet",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initEmpPrimarySkill",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "daysFromTodayForMaxDate",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "daysFromTodayForMinDate",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "SwapInitiateProposalInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "skill",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "code",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "totalMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "label",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "note",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "recipients",
                        "description": null,
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "INPUT_OBJECT",
                                "name": "SwapProposedRecipientInput",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapInitiator",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapInitiatorInfo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapPartner",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initiateDate",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "overallStatusId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapPartner",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "proposalId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ansosId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "note",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workshift",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapWorkshift",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapProposalAsInitiator",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initiator",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapInitiator",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "recipientWsAndEmployee",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "SwapRecipientWsAndEmployee",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapProposalAsRecipient",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initiator",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapInitiator",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "recipient",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapRecipient",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initiatingEmployee",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapEmployee",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "initiatorAssignmentChanged",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "recipientAssignmentChanged",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "recipientOverTime",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapProposalsAndRequests",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapProposalsAsRecipient",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "SwapProposalAsRecipient",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapProposalsAsInitiator",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "SwapProposalAsInitiator",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "SwapRequestPair",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "SwapProposedRecipientInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "ansosId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "wsCodeId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "WorkshiftId",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "startTimeInMilitary",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "startTimeSort",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "breakMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "wsLabel",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapRecipient",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "swapRecipientInfo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapPartner",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "statusId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "SwapRecipientAssignmentsInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "primarySkill",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "compSkillSetStr",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "code",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "totalMinutes",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "label",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Date",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "recipientAreaId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapRecipientWsAndEmployee",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "recipientEmployee",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapEmployee",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "recipient",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapRecipient",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapRequest",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "webReqId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ansosId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "note",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "requestStatus",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "saResponseNote",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workshift",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapWorkshift",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "isInitiator",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapRequestPair",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "primaryRequest",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapRequest",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "partnerRequest",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapRequest",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "partnerEmployee",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "SwapEmployee",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "SwapWorkshift",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "date",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "code",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "wsLabel",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "startTimeSort",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "breakMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "wsFormat",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "Time",
                "description": "An RFC-3339 compliant Full Time Scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "TimeOfDay",
                "description": "Custom TimeOfDay scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "UserFunctionAccess",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empCalendarViews",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empAvailableShifts",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empEnterRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empScheduleView",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminInbox",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminScheduleView",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminAvailableShifts",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminPlanner",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminCalenderViews",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminWorkRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminTimeoffRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminAvailableShiftRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminRemoveAssignment",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empAllowSwaps",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminSwapRequests",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empScheduleGridViews",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empSelfSchedule",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empAllowRetractions",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminScheduleGridViews",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminScheduleGridEditArchive",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminScheduleGridEditCurrent",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "adminScheduleGridEditFuture",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "changePasswrd",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "UserNotification",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyEmailTo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyEmailTo",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyEmailOther",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyEmailVerified",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyEmailAddressVerified",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyTxtTo",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyTxtTo",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyTxtOther",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyTxtCarrier",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyTxtVerified",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyTxtPhoneNumVerified",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "carrierOptionList",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsOptIn",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsBy",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyAsBy",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsAllAreas",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyAsAllAreas",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsSelectedAreas",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsShiftsBtwnEnabled",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsShiftsBtwnStart",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsShiftsBtwnEnd",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsNoDisturbEnabled",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsNoDisturbStart",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notfyAsNoDisturbEnd",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "UserProfile",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ansosId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "wsmAcctId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Int",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "userName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "hasMultipleDcpos",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "profileName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "primaryProfile",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "defaultDisplayName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "customDisplayName",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "dcpos",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Dcpos",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "skill",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "email",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "phone",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "altPhone",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "terminateDate",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "defaultRole",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "UserRole",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areasSA",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Area",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areasEmp",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "Area",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "saDefaultAreaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "saDefaultPage",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "ENUM",
                            "name": "AdmDefaultPage",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "empDefaultAreaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "roles",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "UserRole",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "functionAccess",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "UserFunctionAccess",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "notification",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "UserNotification",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "icalendarSubscribeToken",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "icalendarSubscriptionUrl",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "INPUT_OBJECT",
                "name": "UserProfileInput",
                "description": null,
                "fields": null,
                "inputFields": [
                    {
                        "name": "customDisplayName",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "defaultRole",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "UserRole",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "saDefaultAreaId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "empDefaultAreaId",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "saDefaultPage",
                        "description": null,
                        "type": {
                            "kind": "ENUM",
                            "name": "AdmDefaultPage",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyEmailTo",
                        "description": null,
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyEmailTo",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyEmailOther",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyTxtTo",
                        "description": null,
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyTxtTo",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyTxtOther",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyTxtCarrier",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsOptIn",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsBy",
                        "description": null,
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyAsBy",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsAllAreas",
                        "description": null,
                        "type": {
                            "kind": "ENUM",
                            "name": "NotfyAsAllAreas",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsSelectedAreas",
                        "description": null,
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "AreaId",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsShiftsBtwnEnabled",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsShiftsBtwnStart",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsShiftsBtwnEnd",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsNoDisturbEnabled",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsNoDisturbStart",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "defaultValue": null
                    },
                    {
                        "name": "notfyAsNoDisturbEnd",
                        "description": null,
                        "type": {
                            "kind": "SCALAR",
                            "name": "TimeOfDay",
                            "ofType": null
                        },
                        "defaultValue": null
                    }
                ],
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "UserRole",
                "description": "Custom UserRole scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "Workshift",
                "description": null,
                "fields": [
                    {
                        "name": "id",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "ID",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workshiftId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "WorkshiftId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "on",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Date",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "from",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "to",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "DateTime",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "workMinutes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "code",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "label",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "category",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "work",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "skill",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "format",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Int",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "areaId",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "AreaId",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "requiresApproval",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "SCALAR",
                "name": "WorkshiftId",
                "description": "Custom WorkshiftId scalar",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "__Directive",
                "description": null,
                "fields": [
                    {
                        "name": "name",
                        "description": "The __Directive type represents a Directive that a server supports.",
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "description",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "isRepeatable",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "locations",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "LIST",
                                "name": null,
                                "ofType": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "ENUM",
                                        "name": "__DirectiveLocation",
                                        "ofType": null
                                    }
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "args",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "LIST",
                                "name": null,
                                "ofType": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "OBJECT",
                                        "name": "__InputValue",
                                        "ofType": null
                                    }
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "onOperation",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": true,
                        "deprecationReason": "Use `locations`."
                    },
                    {
                        "name": "onFragment",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": true,
                        "deprecationReason": "Use `locations`."
                    },
                    {
                        "name": "onField",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "Boolean",
                            "ofType": null
                        },
                        "isDeprecated": true,
                        "deprecationReason": "Use `locations`."
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "ENUM",
                "name": "__DirectiveLocation",
                "description": "An enum describing valid locations where a directive can be placed",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "QUERY",
                        "description": "Indicates the directive is valid on queries.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "MUTATION",
                        "description": "Indicates the directive is valid on mutations.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "FIELD",
                        "description": "Indicates the directive is valid on fields.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "FRAGMENT_DEFINITION",
                        "description": "Indicates the directive is valid on fragment definitions.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "FRAGMENT_SPREAD",
                        "description": "Indicates the directive is valid on fragment spreads.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "INLINE_FRAGMENT",
                        "description": "Indicates the directive is valid on inline fragments.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "VARIABLE_DEFINITION",
                        "description": "Indicates the directive is valid on variable definitions.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "SCHEMA",
                        "description": "Indicates the directive is valid on a schema SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "SCALAR",
                        "description": "Indicates the directive is valid on a scalar SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "OBJECT",
                        "description": "Indicates the directive is valid on an object SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "FIELD_DEFINITION",
                        "description": "Indicates the directive is valid on a field SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ARGUMENT_DEFINITION",
                        "description": "Indicates the directive is valid on a field argument SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "INTERFACE",
                        "description": "Indicates the directive is valid on an interface SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "UNION",
                        "description": "Indicates the directive is valid on an union SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ENUM",
                        "description": "Indicates the directive is valid on an enum SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ENUM_VALUE",
                        "description": "Indicates the directive is valid on an enum value SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "INPUT_OBJECT",
                        "description": "Indicates the directive is valid on an input object SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "INPUT_FIELD_DEFINITION",
                        "description": "Indicates the directive is valid on an input object field SDL definition.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "__EnumValue",
                "description": null,
                "fields": [
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "description",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "isDeprecated",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "deprecationReason",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "__Field",
                "description": null,
                "fields": [
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "description",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "args",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "LIST",
                                "name": null,
                                "ofType": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "OBJECT",
                                        "name": "__InputValue",
                                        "ofType": null
                                    }
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "type",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "__Type",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "isDeprecated",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "deprecationReason",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "__InputValue",
                "description": null,
                "fields": [
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "description",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "type",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "__Type",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "defaultValue",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "__Schema",
                "description": "A GraphQL Introspection defines the capabilities of a GraphQL server. It exposes all available types and directives on the server, the entry points for query, mutation, and subscription operations.",
                "fields": [
                    {
                        "name": "description",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "types",
                        "description": "A list of all types supported by this server.",
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "LIST",
                                "name": null,
                                "ofType": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "OBJECT",
                                        "name": "__Type",
                                        "ofType": null
                                    }
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "queryType",
                        "description": "The type that query operations will be rooted at.",
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "OBJECT",
                                "name": "__Type",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "mutationType",
                        "description": "If this server supports mutation, the type that mutation operations will be rooted at.",
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "__Type",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "directives",
                        "description": "'A list of all directives supported by this server.",
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "LIST",
                                "name": null,
                                "ofType": {
                                    "kind": "NON_NULL",
                                    "name": null,
                                    "ofType": {
                                        "kind": "OBJECT",
                                        "name": "__Directive",
                                        "ofType": null
                                    }
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "subscriptionType",
                        "description": "'If this server support subscription, the type that subscription operations will be rooted at.",
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "__Type",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "OBJECT",
                "name": "__Type",
                "description": null,
                "fields": [
                    {
                        "name": "kind",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "ENUM",
                                "name": "__TypeKind",
                                "ofType": null
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "name",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "description",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "fields",
                        "description": null,
                        "args": [
                            {
                                "name": "includeDeprecated",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "Boolean",
                                    "ofType": null
                                },
                                "defaultValue": "false"
                            }
                        ],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "NON_NULL",
                                "name": null,
                                "ofType": {
                                    "kind": "OBJECT",
                                    "name": "__Field",
                                    "ofType": null
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "interfaces",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "NON_NULL",
                                "name": null,
                                "ofType": {
                                    "kind": "OBJECT",
                                    "name": "__Type",
                                    "ofType": null
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "possibleTypes",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "NON_NULL",
                                "name": null,
                                "ofType": {
                                    "kind": "OBJECT",
                                    "name": "__Type",
                                    "ofType": null
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "enumValues",
                        "description": null,
                        "args": [
                            {
                                "name": "includeDeprecated",
                                "description": null,
                                "type": {
                                    "kind": "SCALAR",
                                    "name": "Boolean",
                                    "ofType": null
                                },
                                "defaultValue": "false"
                            }
                        ],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "NON_NULL",
                                "name": null,
                                "ofType": {
                                    "kind": "OBJECT",
                                    "name": "__EnumValue",
                                    "ofType": null
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "inputFields",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "LIST",
                            "name": null,
                            "ofType": {
                                "kind": "NON_NULL",
                                "name": null,
                                "ofType": {
                                    "kind": "OBJECT",
                                    "name": "__InputValue",
                                    "ofType": null
                                }
                            }
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ofType",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "OBJECT",
                            "name": "__Type",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "specifiedByUrl",
                        "description": null,
                        "args": [],
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "inputFields": null,
                "interfaces": [],
                "enumValues": null,
                "possibleTypes": null
            },
            {
                "kind": "ENUM",
                "name": "__TypeKind",
                "description": "An enum describing what kind of type a given __Type is",
                "fields": null,
                "inputFields": null,
                "interfaces": null,
                "enumValues": [
                    {
                        "name": "SCALAR",
                        "description": "Indicates this type is a scalar. 'specifiedByUrl' is a valid field",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "OBJECT",
                        "description": "Indicates this type is an object. `fields` and `interfaces` are valid fields.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "INTERFACE",
                        "description": "Indicates this type is an interface. `fields` and `possibleTypes` are valid fields.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "UNION",
                        "description": "Indicates this type is a union. `possibleTypes` is a valid field.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "ENUM",
                        "description": "Indicates this type is an enum. `enumValues` is a valid field.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "INPUT_OBJECT",
                        "description": "Indicates this type is an input object. `inputFields` is a valid field.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "LIST",
                        "description": "Indicates this type is a list. `ofType` is a valid field.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    },
                    {
                        "name": "NON_NULL",
                        "description": "Indicates this type is a non-null. `ofType` is a valid field.",
                        "isDeprecated": false,
                        "deprecationReason": null
                    }
                ],
                "possibleTypes": null
            }
        ],
        "directives": [
            {
                "name": "include",
                "description": "Directs the executor to include this field or fragment only when the `if` argument is true",
                "locations": [
                    "FIELD",
                    "FRAGMENT_SPREAD",
                    "INLINE_FRAGMENT"
                ],
                "args": [
                    {
                        "name": "if",
                        "description": "Included when true.",
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    }
                ]
            },
            {
                "name": "skip",
                "description": "Directs the executor to skip this field or fragment when the `if`'argument is true.",
                "locations": [
                    "FIELD",
                    "FRAGMENT_SPREAD",
                    "INLINE_FRAGMENT"
                ],
                "args": [
                    {
                        "name": "if",
                        "description": "Skipped when true.",
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "Boolean",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    }
                ]
            },
            {
                "name": "deprecated",
                "description": "Marks the field or enum value as deprecated",
                "locations": [
                    "FIELD_DEFINITION",
                    "ENUM_VALUE"
                ],
                "args": [
                    {
                        "name": "reason",
                        "description": "The reason for the deprecation",
                        "type": {
                            "kind": "SCALAR",
                            "name": "String",
                            "ofType": null
                        },
                        "defaultValue": "\"No longer supported\""
                    }
                ]
            },
            {
                "name": "specifiedBy",
                "description": "Exposes a URL that specifies the behaviour of this scalar.",
                "locations": [
                    "SCALAR"
                ],
                "args": [
                    {
                        "name": "url",
                        "description": "The URL that specifies the behaviour of this scalar.",
                        "type": {
                            "kind": "NON_NULL",
                            "name": null,
                            "ofType": {
                                "kind": "SCALAR",
                                "name": "String",
                                "ofType": null
                            }
                        },
                        "defaultValue": null
                    }
                ]
            }
        ]
    }
}