import React, {useState} from 'react'
import {Redirect, useHistory} from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import './View.css'
import {ROLE} from "components/Role"
import Layout from "components/Layout"
import Features, {allowAccess, getDefaultFeature, getFeatures, getFeaturesForMenu} from "features"
import AreaScheduleView from "./AreaScheduleView"
import CalendarView from "./CalendarView"
import AssignmentsView from "./AssignmentsView"
import AvailableShiftsView from "./AvailableShiftsView"
import WorkView from "./WorkView"
import TimeOffView from "./TimeOffView"
import MyScheduleView from "./MyScheduleView"
import LaunchView from "./LaunchView"
import UserProfileView from "./UserProfileView"
import SwapsAsRecipientView from "./SwapsAsRecipientView"
import SwapStatusView from "./SwapStatusView"
import Bulletin from "components/Bulletin"
import {actionFunction, getAction, isActionDef, isActionType} from "actions"
import DefaultRedirect from "./DefaultRedirect"
import Notice, {NoticeType} from "components/Notice"
import AboutView from "./AboutView"
import User, {ActiveProfile} from "components/UserProfile/User"
import SwitchProfile from "components/SwitchProfile"
import NotificationsView from "./NotificationsView"
import HotBar from "components/HotBar"
import Menu, {closeMenuIfOpen} from "components/Menu"
import {useHasSwapProposalsQuery} from "components/Swaps/SwapGQL"
import {processHasSwapProposalsResult} from "components/Swaps/SwapsAsRecipient/SwapsAsRecipientData"
import {relaunch} from "helpers/http"
import {isInStandaloneMode, setCssVariable_vh} from "helpers/visibility"
import LandscapeView from "./LandscapeView";
import Install from "components/Install";
import {checkForUpdate} from "helpers/update";


export const INIT_PAGE_KEY = "initPage"

export function useViewClasses() {
    // Check if user has swap proposals
    const [{fetching, data, error}] = useHasSwapProposalsQuery()
    const {hasSwapProposals} = processHasSwapProposalsResult(fetching, data, error)

    // Check if app was launched from Home Screen (as PWA)?
    const standaloneMode = isInStandaloneMode()

    return ((hasSwapProposals ? "hasSwapProposals" : "") + (standaloneMode ? " standaloneMode" : "")).trim()
}

function View({user, networkStatus, actions, feature, isFromSignOut}) {

    console.log("View...feature=" + feature.title + "; networkStatus=" + networkStatus.name)

    // Set css variable --vh to 1/100 of viewport height (not including navbar if present)
    setCssVariable_vh()

    let OFFLINE_HEADLINE = <div className="headline offline">{networkStatus.title}</div>

    // Dirty form check support; Confirm user wants to discard unsaved changes
    const history = useHistory()
    const currentPathName = history ? history.location.pathname : undefined
    const [formIsDirty, setFormIsDirty] = useState(undefined)
    const UNSAVED_CHANGES_MSG = 'You have unsaved changes. Are you sure you want to discard changes?'
    const [showUnsavedChangeNotice, setShowUnsavedChangeNotice] = useState(false)
    const [nextRoute, setNextRoute] = useState(undefined)

    function isLeavingForm() {
        return (currentPathName === Features.USER_PROFILE.url)
    }

    function onMenuSelect(url, event) {
        closeMenuIfOpen()

        if (isLeavingForm() && formIsDirty) {
            event.preventDefault()
            setNextRoute(url)
            setShowUnsavedChangeNotice(true)
        }
    }

    function onActionSelect(actionKey, event) {
        if (actionKey !== 'menu') {
            closeMenuIfOpen()
        }

        if (isLeavingForm() && formIsDirty) {
            setNextRoute(actionKey)
            setShowUnsavedChangeNotice(true)
        } else {
            performAction(actionKey)
        }
    }

    function performAction(actionKey) {
        const action = getAction(actionKey, actions)
        const route = action.function()
        if (route) history.push(route)
    }

    function onProfileSelect() {
        if (networkStatus.online) {
            setShowSwitchProfile(true)
        }
    }

    function profileSelect() {
        return showSwitchProfile
    }

    const functions = {}
    functions.onMenuSelect = onMenuSelect
    functions.onActionSelect = onActionSelect
    functions.onProfileSelect = onProfileSelect

    function handleUnsavedChangeNoticeResponse(confirmed) {
        setShowUnsavedChangeNotice(false)
        if (confirmed) {
            isActionType(nextRoute) ? performAction(nextRoute) : history.push(nextRoute)
        }
    }

    // ------------------------------------------------------------------------

    // SWITCH PROFILE support
    const [showSwitchProfile, setShowSwitchProfile] = useState(false)
    for (let ndx = 0; ndx < actions.length; ndx++) {
        if (actions[ndx].key === 'switchprofile') {
            actions[ndx].function = onProfileSelect
            break
        }
    }
    const switchProfileActions = {
        cancel: () => {
            setShowSwitchProfile(false)
        },
    }

    const layout = ({body, error, classes}) => {

        let header = null
        if (feature.header) {
            header = [{}, {left: "", right: ""}, {center: ""}]
            header[1].left = <span className="feature">
                <i className={"fas " + feature.icon + " feature-icon"}/>
                {" "}
                {feature.title}
            </span>
            header[1].right = <div className="userinfo">
                <ActiveProfile user={user} action={onProfileSelect}/>
                <User user={user}/>
            </div>
        }
        let footer = null
        if (feature.footer) {
            footer = feature.footer ?
                <>
                    <SwitchProfile show={profileSelect()} actions={switchProfileActions} networkStatus={networkStatus}/>
                    <HotBar
                        features={getFeatures(user)}
                        actions={actions}
                        hash={window.location.hash}
                        onMenuSelect={onMenuSelect}
                        onActionSelect={onActionSelect}/>
                </> : <SwitchProfile show={showSwitchProfile} actions={switchProfileActions}
                                     networkStatus={networkStatus}/>
        }
        // If OFFLINE and feature cannot be used offline show feature disabled bulletin
        if (!error && !networkStatus.online && !feature.useOffline) {
            error = {
                message: feature.title + " has been temporarily disabled while services are unavailable.",
                noticeType: NoticeType.NONE
            }
        }
        // If error encountered show view disabled and error bulletin on top
        if (error) {
            if (isActionDef(error.action)) {
                error.function = actionFunction(error.action, actions)
            }
            body = (<>
                <div className='disabled'>{body}</div>
                {error.function ? <Notice message={error.message} type={error.noticeType}
                                        onClose={error.function} show={true}/> :
                    <Bulletin message={error.message}/>}
            </>)
        }
        // Not ONLINE show service status message
        if (!networkStatus.online) {
            body = <>{OFFLINE_HEADLINE}{body}</>
        }

        const viewClasses = networkStatus.key + (classes ? " " + classes : "")
        const updated = checkForUpdate()
        return <div className={viewClasses}>
            <Layout header={header} body={body} footer={footer}/>
            <Menu user={user} features={getFeaturesForMenu(user)} actions={actions} functions={functions}/>
            <LandscapeView/>
            <Install updated={updated}/>
        </div>
    }

    /**
     * Get user's default area based on role.
     * @returns if EMP or BOTH role use empDefaultAreaId, if SA use saDefaultAreaId, otherwise undefined
     */
    function defaultAreaId(role) {
        switch (role) {
            case ROLE.EMP:
            case ROLE.BOTH:
                return user.dcpos.substring(0, 2)
            case ROLE.SA:
                return user.saDefaultAreaId
            default:
                console.log("ERROR: Invalid role parameter is missing")
                return null
        }
    }

    //This is to address the need to "redirect after login". Say end user taps a link on "notification email" while not logged in
    //The idea here is:
    // Step #1 Save what the initial page that user is requesting;
    // Step #2: Then later after user is signed in, redirect to initial page if any;
    //This method is to implement step #1
    function saveInitialPage() {
        //If (1) the reason access is not allowed to a feature is because the user is not signed in,
        // (2)AND it is not from signout (otherwise we would always save the last page right before signout)
        // then we need to save it as initialPage
        if (user.defaultRole === ROLE.NONE && (!isFromSignOut)) {
            let split = window.location.href.split("/app")
            //Save whatever is after "/app" of the entire href
            //Ex: if href is "https://hostname:port/context/app/?role=EMPLOYEE&notif=2221449#/notifications",
            //then initPageToSave will be "/?role=EMPLOYEE&notif=2221449#/notifications"
            let initPageToSave = split && split.length === 2 ? split[1] : ""
            window.localStorage.setItem(INIT_PAGE_KEY, initPageToSave)
            console.log("Saving initial Page into browser local storage.  initial Page= " + initPageToSave)
        }
    }

    // The goal here is to intercept an attempt to access a feature for which the user is not authorized.
    if (!allowAccess(feature, user)) {
        console.log("Access denied to feature: " + feature.title + " redirecting to " + getDefaultFeature(user).title)
        saveInitialPage()
        return <Redirect to={getDefaultFeature(user).url}/>
    }

    switch (feature) {
        case Features.LAUNCH:
            return <LaunchView layout={layout} user={user} actions={actions}/>
        case Features.ABOUT:
            return <AboutView layout={layout}/>
        case Features.DEFAULT:
            return <DefaultRedirect layout={layout} actions={actions}/>
        case Features.CALENDAR:
            return <CalendarView layout={layout} user={user}/>
        case Features.NOTIFICATIONS:
            return <NotificationsView layout={layout} areas={user.areasEmp}/>
        case Features.ASSIGNMENTS:
            return <AssignmentsView layout={layout}/>
        case Features.AVAILABLE_SHIFTS_CALENDAR:
            return <AvailableShiftsView layout={layout} user={user} actions={actions}/>
        case Features.WORK_CALENDAR:
            return <WorkView layout={layout} user={user}/>
        case Features.TIMEOFF_CALENDAR:
            return <TimeOffView layout={layout} user={user}/>
        case Features.MY_SCHEDULE:
            return <MyScheduleView layout={layout}/>
        case Features.MY_AREA_SCHEDULE:
            return <AreaScheduleView layout={layout} role={ROLE.EMP} defaultAreaId={defaultAreaId(ROLE.EMP)}/>
        case Features.AREA_SCHEDULE:
            return <AreaScheduleView layout={layout} role={ROLE.SA} defaultAreaId={defaultAreaId(ROLE.SA)}/>
        case Features.USER_PROFILE:
            return <>
                <Notice message={UNSAVED_CHANGES_MSG}
                        onClose={handleUnsavedChangeNoticeResponse}
                        labels={["Discard Changes", "Cancel"]}
                        show={showUnsavedChangeNotice}/>
                <UserProfileView layout={layout}
                                 setUserProfileDirty={setFormIsDirty}/>
            </>
        case Features.RECIPIENT_SWAPS:
            return <SwapsAsRecipientView layout={layout}/>
        case Features.SWAPS_STATUS:
            return <SwapStatusView layout={layout} areas={user.areasEmp}/>
        default:
            return <></>
    }
}

export default View
