/**
 * validates phone number
 * @param phoneNumber phone number to validate
 * @returns {boolean}
 */
export function validPhoneNumber(phoneNumber){
    //The validation makes sure that there are 1 numeric character
    //strip out all the non-numeric value
    if(phoneNumber){
        phoneNumber = phoneNumber.replace(/\D/g,'')
    }

    if(phoneNumber && phoneNumber.length > 0) {
        return true;
    } else {
        return false;
    }
}

/**
 * validated email
 * @param email Phone number to validate
 * @returns {boolean}
 */
export function validEmail(email){
    return (email && email.length > 0)
}