import {fetchDate, fetchString, removeString, storeDate, storeString} from "./storage";

export const SWStatus = {
    ACTIVE: 'ACTIVE',
    UPDATED: 'UPDATED'
}
const SW_STATUS_KEY = "SW-STATUS"
const SW_INSTALLED_KEY = "SW-INSTALLED"
const SW_UPDATED_KEY = "SW-UPDATED"

export function getSWStatus() {
    return fetchString(SW_STATUS_KEY)
}

export function setSWStatus(status) {
    storeString(SW_STATUS_KEY, status)
}

export function removeSWStatus() {
    removeString(SW_STATUS_KEY)
}

export function setSWInstalledAt() {
    storeDate(SW_INSTALLED_KEY, new Date())
}

export function getSWInstalledAt() {
    return fetchDate(SW_INSTALLED_KEY)
}

export function setSWUpdatedAt() {
    storeDate(SW_UPDATED_KEY, new Date())
}

export function getSWUpdatedAt() {
    return fetchDate(SW_UPDATED_KEY)
}

// ----------------------------------------------------------------------------------

export function checkForUpdate() {
    console.log("SW: checkForUpdate()")
    const status = getSWStatus()
    if (status === SWStatus.UPDATED) {
        return true
    } else {
        navigator.serviceWorker.getRegistration().then(function (registration) {
            if (registration) {
                console.log("SW: ServiceWorker registration found")
                if (registration.active) {
                    console.log("SW: ServiceWorker is ACTIVE")
                } else if (registration.installing) {
                    console.log("SW: ServiceWorker is INSTALLED")
                } else if (registration.wait) {
                    console.log("SW: ServiceWorker is WAITING")
                }
                // Have ServiceWorker check if there is a newer version
                registration.update()
                // If a newer version is found, registration.onupdatefound() if fired
                // which eventually calls our onUpdate() which is passed to serviceWorkerRegistration.register(config)
                // in index.js
            }
        })
        return false
    }
}