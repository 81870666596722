import {ROLE} from "components/Role"
import {NOTIFY_EMAIL_TO} from "./ContactSection/EmailNotifyForm"
import {NOTIFY_TXT_TO} from "./ContactSection/PhoneNotifyForm"


export function ifSARoleOnly(myRoles){
    let containSARole = false
    if( myRoles.length === 1) {
        myRoles.forEach(
            (role) => {
                if (ROLE[role] === ROLE.SA) {
                    containSARole = true
                }
            }
        )
    }
    return containSARole
}

export function  ifContainsSARole(myRoles) {
    let containSARole = false
    myRoles.forEach(
        (role) => {
            if (ROLE[role] === ROLE.SA){
                containSARole = true
        }
    })
    return containSARole
}

export function  ifContainsEmpRole(myRoles){
    let containEmpRole = false
    myRoles.forEach(
        (role) => {
            if (ROLE[role] === ROLE.EMP){
            containEmpRole = true
        }
    })
    return containEmpRole
}


export function ifMultipleRoles(myRoles) {
    return myRoles.length > 1
}



/**
 * Returns true when email is changed from verified to unverified, or just has changed. In turn a prompt would
 * display to end user to let her know that her unverified email/phone would not get saved.
 *
 * However some special cases:
 * (1) SA only role: an email can be changed from verified to unverified without warning. So this method returns false
 * (2)"verified other email" can be emptied(deleted) without any warning, so this method returns false
 *
 * @param input
 * @param emailVerified
 * @param data
 * @returns {boolean|*}
 */
export function ifEmailVerifyingRequired(input, emailVerified, data){
    // console.log("UserProfileForm -- input.emailNotifyTo = " + input.emailNotifyTo + "; emailVerified = " + emailVerified)
    if (emailVerified) return false

    //Special case for SA only role-- it would allow unverified email to be saved withOUT warning if email saved in database not verified
    //Somehow for SA role only, "data.notification.notfyEmailTo" is "pEmail" if not verified, but becomes "oEmail" once it is verified
    //Must be whoever coded it earlier decided to do it this way. It is too costly to change it at this point. Leave it as it is.
    if (ifSARoleOnly(data.roles) && !data.notification.notfyEmailVerified ){
        return false
    }

    //Email not verified and notify method has changed
    if(!emailVerified && (input.emailNotifyTo !== data.notification.notfyEmailTo  )  ){
        if(NOTIFY_EMAIL_TO[input.emailNotifyTo] === NOTIFY_EMAIL_TO.pEmail){
            return (data.email) //if primary email is empty, then no need to verify. Otherwise the need is there.
        }
        else if (NOTIFY_EMAIL_TO[input.emailNotifyTo] === NOTIFY_EMAIL_TO.oEmail) {
            return true
        }

    }
    //Email notify method has not changed. No need to warn user unless it is "other email" text has changed
    else{
        if(NOTIFY_EMAIL_TO[input.emailNotifyTo] === NOTIFY_EMAIL_TO.oEmail){
            //special case: (1)deleting "other email" has no need to verify. This is an escape hatch to "delete" a verified "other email"
            //(2) Or it might be "other email" empty at both database & input. No need to warn
            if( !(input.otherEmail.trim())){
                return false
            }
            else {
                return !(input.otherEmail === data.notification.notfyEmailOther)
            }
        }else{
            return false
        }
    }

}


/**
 * Returns true when phone is changed from verified to unverified, or just has changed. In turn a prompt would
 * display to end user to let her know that her unverified email/phone would not get saved.
 *
 * However a special case is that "verified other phone" can be emptied(deleted) without any warning, and this method returns false
 * @param input
 * @param txtVerified
 * @param data
 * @returns {boolean}
 */
export function ifTextVerifyingRequired(input, txtVerified, data){
    // console.log("UserProfileForm -- txtVerified = " + txtVerified + "; input.txtNotifyTo= " + input.txtNotifyTo
    //  + ";userProfileData.notification.notfyTxtTo = " + data.notification.notfyTxtTo)

    if (txtVerified) return false

    if(!txtVerified && (data.notification.notfyTxtVerified !== txtVerified)){//changed from "verified" to "not verified"
        if(NOTIFY_TXT_TO[input.txtNotifyTo] === NOTIFY_TXT_TO.notSendText){
            return false //if notification method is "Do not send text", then no need to verify before save
        }
        else if(NOTIFY_TXT_TO[input.txtNotifyTo] === NOTIFY_TXT_TO.yesOtherText && (!(input.otherPhone.trim()))){
            return false//special case: deleting "other phone" has no need to verify. This is an escape hatch to "delete" a verified "other phone"
        }
        else  {
            return true
        }
    }
    else{//not verified initially and not verified at submit time.
        if(data.notification.notfyTxtTo !== input.txtNotifyTo ){//notification method has changed. should warn
            return true
        }
        else if(NOTIFY_TXT_TO[input.txtNotifyTo] === NOTIFY_TXT_TO.yesOtherText){
            if (input.otherPhone.trim() ==="" && data.notification.notfyTxtOther === null ) return false //"other phone" empty at both database & input. No need to warn
            else return !(input.otherPhone.trim() === data.notification.notfyTxtOther)//"other phone" text has changed
        }
        else{
            return false
        }
    }

}