import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/Button"
import Notice from "components/Notice"
import {makeServiceStatus} from "helpers/serviceStatus"
import './SwitchProfile.css'
import {redirectToFeature} from "helpers/http"
import {useSwitchProfileMutation} from "./SwitchProfileGQL"
import Features from "features"
import {EMP_INACTIVE_HOME, SA_NO_AREA} from "./SwitchProfileData"

const SwitchProfileForm = ({profiles, currentProfileName, actions}) => {
    const [serviceStatus, setServiceStatus] = useState()
    const [switchProfileResult, switchProfile] = useSwitchProfileMutation()

    const Switch = (profile, event) => {

        if (currentProfileName === profile) {
            actions.cancel()
        } else {
            switchProfile({"name": profile}).then(result => {
                console.log('SWITCH PROFILE RESULT = ' + (result ? JSON.stringify(result.data) : "result was null"))
                setServiceStatus(makeServiceStatus(result.data ? result.data.switchProfile : null, result.error))
                if (!serviceStatus) {
                    actions.cancel() // close switch profile popup
                    redirectToFeature(Features.DEFAULT)
                }
            })
        }
    }


    function ifDisabledProfileExists(profiles){
        let saNoAreaAccessExists = false
        let empInactiveHome = false
        profiles.forEach((oneProfile) => {
            if(oneProfile.disabled ){
                if(oneProfile.disabledReason=== EMP_INACTIVE_HOME){
                    empInactiveHome = true
                } else if(oneProfile.disabledReason=== SA_NO_AREA) {
                    saNoAreaAccessExists = true
                }
            }
        })
        return [empInactiveHome, saNoAreaAccessExists ]
    }


    const ifDisabledProfile = ifDisabledProfileExists(profiles)

    if (serviceStatus) {
        return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                       onClose={actions.cancel} show={true}/>
    } else {
        const getControl = (profile) => {
            return (
                <>
                <Button
                    key={profile.name}
                    value={profile.name}
                    onClick={(e) => Switch(e.currentTarget.value, e)}
                    {...(profile.disabled ? {disabled: true} : {})}
                >
                    <div className="profile">
                        <span className="profile-state">
                            {profile.name === currentProfileName ? <i className="fas fa-check-circle fa-fw"></i> :
                                <i className="far fa-circle fa-fw"></i>}
                        </span>
                        <span className="profile-name">
                            {profile.name} {profile.skill} {profile.dcpos}
                            {profile.disabled && profile.disabledReason=== EMP_INACTIVE_HOME
                            && <span className="switchWarningSign"><i className="fa fa-exclamation-circle"></i></span> }
                            {profile.disabled && profile.disabledReason=== SA_NO_AREA
                                && <span className="switchWarningSign"><i className="fas fa-exclamation-triangle"></i></span> }

                        </span>
                    </div>
                </Button>

            </>
            )
        }

        return (
            <Modal id='switchProfile' centered show={true} onHide={actions.cancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {profiles.map(profile => getControl(profile))}

                    {ifDisabledProfile[0] &&
                        <div className="switchProfileWarning">
                            <i className="fa fa-exclamation-circle"></i>
                            The home area for this profile is <span className="boldFont">not currently configured to allow employee access.</span> Contact
                            your manager or Web Scheduling Administrator for assistance.
                        </div>
                    }
                    {ifDisabledProfile[1] &&
                        <div className="switchProfileWarning">
                            <i className="fas fa-exclamation-triangle"></i>
                            This profile is <span
                            className="boldFont">not configured to allow access to any areas.</span> Contact your manager or
                            Web Scheduling Administrator for assistance.
                        </div>
                    }

                </Modal.Body>
            </Modal>
        )
    }
}

export default SwitchProfileForm