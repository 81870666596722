import React from 'react';
import {toError} from "helpers/serviceStatus";
import {useViewClasses} from "./View";

const MyScheduleView = ({layout}) => {
    // fetch data here; set serviceStatus; see processCalendarData for example of best practice

    const serviceStatus = null;

    let body = (
        <div id="mySchedule">
            <h1>Coming soon ...</h1>
        </div>
    )

    return (layout({body, error: toError(serviceStatus), classes:useViewClasses()}))
}

export default MyScheduleView