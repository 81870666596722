import React from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import VerifyEmailOrPhone, {VERIFY_TYPE} from "./VerifyEmailOrPhone"
import {useFormContext} from "react-hook-form"
import If from "components/If"


const OtherPhoneVerifyForm = ({
                                  userProfileData,
                                  name,
                                  txtNotifyMethod,
                                  setTxtVerified,
                                  isOtherPhoneInitiallyVerified,
                                  isOtherPhoneCurrentlyVerified,
                                  carrierOptions,
                                  handleVerifyButtonClick,
                                  showVerifyTxtDialog,
                                  setShowVerifyTxtDialog
                              }) => {

    const {register, setError, errors, getValues} = useFormContext() // retrieve all hook methods

    //This validation should only be triggered at "Verify" button click, not at User Profile "Submit"
    const validatePhoneOnTrigger = () => {
        let phone = getValues(name)
        let nonDigitsRemoved = phone.replace(/[^\d]*/g, "")
        let isValid = nonDigitsRemoved.length >= 7
        if(!isValid) {
            setError(name, {
                type: "manual",
                message: 'Phone number does not contain enough digits'
            })
        }
        return isValid

    }

    const validateCarrierOnTrigger = () => {
        let carrier = getValues("cellCarrier")
        let isValid = false

        if (isOtherPhoneCurrentlyVerified() || (carrier !== 'Carrier')) {
            isValid =  true
        }

        if(!isValid) {
            setError("cellCarrier", {
                type: "manual",
                message: 'Please select a carrier'
            })
        }

        return isValid

    }



    return (
        <>
            <Form.Group>
                <Form.Control type="text" name={name}
                              defaultValue={userProfileData.notification.notfyTxtOther}
                              ref={register}
                              onChange={() => {
                                  let txtInputValue = getValues(name)
                                  // console.log("txtInputValue =" + txtInputValue)
                                  if (isOtherPhoneInitiallyVerified()) {
                                      if (userProfileData.notification.notfyTxtOther !== txtInputValue) {
                                          setTxtVerified(false)
                                      } else if (userProfileData.notification.notfyTxtOther === txtInputValue) {
                                          setTxtVerified(true)
                                      }
                                  }
                              }}/>
            </Form.Group>
            {errors[name] && <p className="error">{errors[name].message}</p>}


            <Form.Group>
                <Form.Control as="select"
                              name="cellCarrier"
                              ref={register}
                              onChange={() => {
                                  if (isOtherPhoneInitiallyVerified()) {
                                      setTxtVerified(false)
                                  }
                              }}
                >{carrierOptions}</Form.Control>
            </Form.Group>
            {errors.cellCarrier && <p className="error">{errors.cellCarrier.message}</p>}


            {isOtherPhoneCurrentlyVerified() &&
            (<Form.Group className="floatRight"><span className="formLabel">
                        <i className="fas fa-check"></i>&nbsp;Verified</span></Form.Group>)}

            {!isOtherPhoneCurrentlyVerified() && (
                <Form.Group className="floatRight">
                    <Button variant="primary"
                            onClick={() => {
                                //validating "phone" related fields is triggered by clicking this "verify" button,
                                // this is different than validating overall form by "submit" button
                                let res = validatePhoneOnTrigger()
                                let res1 = validateCarrierOnTrigger()
                                // console.log("validate Phone result = " + res + "; validate carrier result = " + res1)
                                if (res && res1) {
                                    handleVerifyButtonClick()
                                }
                            }}

                    >Verify...</Button>

                </Form.Group>


            )
            }

            <If condition={() => {
                return showVerifyTxtDialog
            }}>
                <VerifyEmailOrPhone
                    verifyType={VERIFY_TYPE.phone}
                    notfyTxtTo={txtNotifyMethod}
                    phone={getValues(name)}
                    carrier={getValues("cellCarrier")}
                    setTxtVerified={setTxtVerified}
                    close={() => {
                        setShowVerifyTxtDialog(false)
                    }}/>
            </If>

        </>
    )

}

export default OtherPhoneVerifyForm
