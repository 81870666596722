import React from 'react'
import {useState} from 'react'
import {useUpdateUserProfileMutation} from "./UserProfileGQL"
import ServiceStatus from "../../helpers/serviceStatus"
import UserProfileForm from "./UserProfileForm"
import Notice from "components/Notice"
import Spinner from "components/Spinner"
import {makeUpdateUserProfileResultStatus} from "./UserProfileData"


const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})

const UserProfile = ({data, setUserProfileDirty, shouldOpenContact}) => {

    const [UpdateUserProfileResult, updateUserProfile] = useUpdateUserProfileMutation()
    const [show, setShow] = useState(SHOW.FORM)
    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})
    // console.log("serviceStatus = " + JSON.stringify(serviceStatus))

    // Assign a key to prevent "UserProfileForm" from re-rendering when unnecessary.
    const [divKey, setDivKey] = useState(1)

    const actions = {
        submit: (input) => {
            setShow(SHOW.SPINNER)
            let userProfileInput = {
                customDisplayName: input.customDisplayName,
                defaultRole: input.defaultRole,
                saDefaultAreaId: input.saDefaultAreaId,
                empDefaultAreaId: (input.useEmpHomeArea ===
                        "true") ? null : input.empDefaultAreaId,
                saDefaultPage: (input.saDefaultPage) ? input.saDefaultPage : 'inboxPg',
                notfyEmailTo: input.emailNotifyTo,
                notfyEmailOther: input.otherEmail,
                notfyTxtTo: input.txtNotifyTo,
                notfyTxtOther: input.otherPhone,
                notfyTxtCarrier: input.cellCarrier,
                notfyAsOptIn: input.notfyAsOptIn,
                notfyAsBy: input.notifyBy,
                notfyAsAllAreas: input.notifyArea,
                notfyAsSelectedAreas: (input.empSelectedNotifyArea && input.empSelectedNotifyArea.length >
                        0) ? input.empSelectedNotifyArea.split(",") : [],
                notfyAsShiftsBtwnEnabled: input.notifyShiftBetween,
                notfyAsShiftsBtwnStart: input.notifyStartTime,
                notfyAsShiftsBtwnEnd: input.notifyEndTime,
                notfyAsNoDisturbEnabled: input.notifyDNDBetween,
                notfyAsNoDisturbStart: input.noDisturbStartTime,
                notfyAsNoDisturbEnd: input.noDisturbEndTime
            }
            console.log("on Profile submit, input value =" + JSON.stringify(userProfileInput))
            let variables = {"userProfileInput": userProfileInput}

            updateUserProfile(variables).then(({fetching: fetchingUUPM, data: dataUUPM, error: errorUUPM}) => {
                console.log("after the update, dataUUPM = " + JSON.stringify(dataUUPM))
                let updateUserProfileStatus = makeUpdateUserProfileResultStatus(fetchingUUPM, dataUUPM, errorUUPM)
                setServiceStatus(updateUserProfileStatus)


                if (updateUserProfileStatus) {
                    setShow(SHOW.NOTICE)
                    if (updateUserProfileStatus.success){
                        //only after a success submission, is there a need to re-render.
                        // This would get "isDirty" flag for "User Profile" form cleared out
                        setDivKey(prevKey => prevKey + 1)
                    }
                } else {
                    setShow(SHOW.NONE)
                }

            })
        }
    }


    function handleNoticeResponse(confirmed) {
        setShow(SHOW.FORM)
    }



    switch (show) {
        case SHOW.NOTICE: {
            return (
            <><Notice message={serviceStatus.message}
                    type={serviceStatus.noticeType}
                    onClose={handleNoticeResponse}
                    show={true} />
                    <div key={divKey}>
                        <UserProfileForm data={data} actions={actions}
                                setUserProfileDirty={setUserProfileDirty}/>
                    </div>
                    </>
            )
        }
        case SHOW.SPINNER: {
            return (
            <>
            <Spinner show={true} />
            <div key={divKey}>
                <UserProfileForm data={data} actions={actions}
                        setUserProfileDirty={setUserProfileDirty}/>
            </div>
            </>
            )
        }
        case SHOW.FORM: {
            return (
                    <>
                    <div key={divKey}>
                        <UserProfileForm data={data} actions={actions}
                                         setUserProfileDirty={setUserProfileDirty}
                                         shouldOpenContact={shouldOpenContact}
                        />
                     </div>
                    </>
                    )

        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }


}


export default UserProfile