import React, {useState} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import PotentialAssignmentControl from "./PotentialAssignmentControl"
import OriginalAssignmentControl from "./OriginalAssignmentControl"
import {SwapCustomToggle} from "./ProposalAsRecipientForm"
import {INITIATOR, SwapProposalOverallStatusEnum} from "./SwapStatusData"
import SwapRetract from "./SwapRetract"
import {useForm} from 'react-hook-form'


const ProposalAsInitiatorForm = ({proposalAsInitiator, areas, refresh}) => {
    const {register, getValues} = useForm()
    const [retractProposalId, setRetractProposalId] = useState(0)
    const [showSwapRetractDialog, setShowSwapRetractDialog] = useState(false)
    const [showSwapPartnerNote, setShowSwapPartnerNote] = useState(false)

    const isProposed = (proposalAsInitiator.overallStatusId === SwapProposalOverallStatusEnum.PROPOSED)

    function shouldDisablePartnerNoteCheck(){
        let findNonEmptyNote = false
        for (const swapRecipient of proposalAsInitiator.recipients){
            if (swapRecipient.recNote){
                findNonEmptyNote = true
                break;
            }
        }
        return !findNonEmptyNote
    }

    let disablePartnerNoteCheck = shouldDisablePartnerNoteCheck()
    let includeNotesSpanClassName=  disablePartnerNoteCheck ? "disabledIncludeNotesSpan" : "includeNotesSpan"

    let initiatorWorkshift = {
        code: proposalAsInitiator.initWsCode,
        from: proposalAsInitiator.initWsFrom,
        to: proposalAsInitiator.initWsTo,
        label: proposalAsInitiator.initWsLabel,
        date: proposalAsInitiator.initWorkdate,
        workMinutes: proposalAsInitiator.initWsWorkMinutes,
        areaId: proposalAsInitiator.initWsAreaId,
        format:  proposalAsInitiator.initWsFormat,
    }


    let viewerEmployeeStatus = {
        status: proposalAsInitiator.viewerStatus,
        role: INITIATOR,
        note: proposalAsInitiator.initNote,
    }

    const RecipientList = () => {
        return (
            proposalAsInitiator.recipients.map((swapRecipient) => {
                let recipientWorkshift = {
                    code: swapRecipient.recWsCode,
                    from: swapRecipient.recWsFrom,
                    to: swapRecipient.recWsTo,
                    label: swapRecipient.recWsLabel,
                    date: swapRecipient.recWorkdate,
                    workMinutes: swapRecipient.recWsWorkMinutes,
                    areaId: swapRecipient.recWsAreaId,
                    format: swapRecipient.recWsFormat,
                }

                let partnerEmployee = {
                    empName: swapRecipient.empName,
                    dcpos: swapRecipient.dcpos,
                    skill: swapRecipient.skill,
                    jobCode: swapRecipient.jobCode,
                    note: swapRecipient.recNote,
                    status: swapRecipient.partnerStatus
                }
                return (
                    <div key={swapRecipient.recProposalId}>
                        <PotentialAssignmentControl partnerWorkshift={recipientWorkshift}
                                                    partnerEmployee={partnerEmployee}
                                                    showSwapPartnerNote={showSwapPartnerNote}
                                                    areas={areas}
                        />
                        <br/>
                    </div>

                )

            })

        )

    }

    function swapShiftToRetractSelected() {
        setRetractProposalId(proposalAsInitiator.initProposalId)
        setShowSwapRetractDialog(true)
    }

    return (
        <div className={"swapProposal"}>

            {isProposed && showSwapRetractDialog
            && <SwapRetract retractProposalId={retractProposalId}
                            close={() => {
                                setShowSwapRetractDialog(false)
                            }}
                            refresh={refresh}
            />
            }


            <OriginalAssignmentControl originalWorkshift={initiatorWorkshift}
                                       viewerEmployeeStatus={viewerEmployeeStatus}
                                       isProposed={isProposed}
                                       onClick={swapShiftToRetractSelected}
                                       areas={areas}
            />

            <Accordion defaultActiveKey="0">
                <Card className = "swapCard">
                    <Card.Header className = "swapCardHeader">
                        <div className="spaceBetween">
                            <div className="spaceBetween">
                                <div className="bigLabel">Potential Assignment ({proposalAsInitiator.recipients.length})
                                </div>

                                <div className="includeNotes">
                                    <label className="hsCheckbox">
                                        <input type="checkbox"
                                               name="showPartnerNote"
                                               disabled={disablePartnerNoteCheck}
                                               defaultChecked={false}
                                               ref={register}
                                               onChange={() => {
                                                   const showNoteChecked = getValues("showPartnerNote")
                                                   setShowSwapPartnerNote(showNoteChecked)
                                               }}
                                        />
                                        <span className="hsCheckmark"/>

                                    </label>
                                    {/*<span className={includeNotesSpanClassName}>Include notes</span>*/}

                                </div>

                                <div className={includeNotesSpanClassName}>
                                    <span>Include notes</span>
                                </div>

                            </div>


                            <SwapCustomToggle eventKey={proposalAsInitiator.sortByProposalId}>
                            </SwapCustomToggle>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={proposalAsInitiator.sortByProposalId}>
                        <Card.Body className="potentialAssignmentsCardBody">
                            {proposalAsInitiator.recipients && proposalAsInitiator.recipients.length > 0
                            && <RecipientList/>
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>
        </div>


    )
}

export default ProposalAsInitiatorForm