import gql from 'graphql-tag'
import {useQuery} from 'urql'

// CALENDAR

export const CALENDAR_QUERY = gql`
query Calendar {
    calendar {
        employeeId from to 
        periods {
            areaId from to publishStatus reset timeOffOpen workOpen shiftsOpen 
            home { from to id } 
            notice id
        } 
        assignments {
            code from to workMinutes totalMinutes areaId name label date team task 
            home work order format pending type status id
        } 
        areas {name id} 
        id 
    }
}`

export function useCalendarQuery(pause) {
    console.log("useCalendarQuery()")
    return useQuery({
        query: CALENDAR_QUERY,
        variables: {},
        requestPolicy: "cache-and-network",
        pause: pause,
    })
}

export function clearCachedCalendarQuery(result, cache) {
    console.log("REMOVE CALENDAR FROM CACHE...")
    const data = cache.readQuery({query: CALENDAR_QUERY, variables: {}})
    if (data && data.calendar) cache.invalidate({__typename: 'Calendar', id: data.calendar.id});
}