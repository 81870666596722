export function toHash(url) {
    return "#" + url;
}

export function toUrl(hash) {
    return '/' + hash.toString().replace(/^#\//, '')
}

function toUri(href) {
    let url = new URL(href)
    return href.replace(url.protocol + "//" + url.host, '')
}

export function hasSearchParams() {
    const search = window.location.search
    console.log("hasSearchParams() search = ["+search+"]")
    return search !== null && search.length > 0
}

export function getSearchParam(paramName) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(paramName)
}

export function reload() {
    window.location.reload()
}

export function relaunch() {
    const url = appUrl()
    console.log("relaunching....url=" + url)
    window.location.assign(url)
}

export function loadHash(hash) {
    const WSM_CONTEXT = wsmContext(window.location.href)
    const url = WSM_CONTEXT + "/app/" + hash
    console.log("loading....url=" + url)
    window.location.assign(url)
}

export function redirectToSignin() {
    window.location.assign("./signin.do")
}

export function redirectToSignout() {
    window.location.assign("./signin.do?SubAction=signout")
}

export function redirectToFeature(feature) {
    const url = appUrl() + '?featureUrl=' + feature.url
    window.location.assign(url)
}

export function wsmContext(href) {
    let uri = toUri(href)
    let match = uri.match("(.*)\\/app")
    return match && match.length === 2 ? match[1] : ""
}

export function wsmUrl() {
    let url = new URL(window.location.href)
    return url.protocol + "//" + url.host + wsmContext(window.location.href)
}

export function appUrl() {
    return wsmUrl() + "/app"
}

export function getQueryStringValue(parameter) {
    const currentPageURL = window.location.search.substring(1);
    const queryString = currentPageURL.split('&');
    for (let i = 0; i < queryString.length; i++) {
        let getParameter = queryString[i].split('=');
        if (getParameter[0] === parameter) {
            return getParameter[1] === undefined ? true : decodeURIComponent(getParameter[1]);
        }
    }
}
