import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {WorkshiftFormControl} from "./RequestControl"
import Form from "react-bootstrap/Form"
import {RequestTypeTitle} from "./Request"
import Button from "react-bootstrap/Button"
import {formatDate, stringToDate, dateToString} from 'helpers/date'
import {useForm} from "react-hook-form"
import AreaFormControl from "components/AreaFormControl"
import DatePicker from "react-datepicker"
import {getParamsAtStartup} from 'components/Settings/SettingsData'
import './Request.css'



const RequestForm = ({request, areas, selectedArea, actions, requestForm, requestAreaId, onSubmit, onAreaChange, isOpen}) => {

    const {register, handleSubmit, errors, getValues} = useForm()
    const [endDate,setEndDate] = useState(stringToDate(request.endDate))

    //There are 2 formats in html native datepicker (input type="date"):
    // (1) wire format--The one used when doing an ordinary form submission. Spec has it as YYYY-MM-DD
    // (2) The format displayed by browser and accepted as user input. This is dictated by browser locale
    // html5 spec does not have any means to override or manually change any of the above.
    //https://stackoverflow.com/questions/7372038/is-there-any-way-to-change-input-type-date-format
    //Now our intention here is to have the 2nd format to honor ent opt 40.
    //ex: when ent opt 40 has a value of 9, we want it to display YYYY/MM/DD
    //The trick here is:
    //(1) add an attribute "data-date", whose value stores formatted date based on Ansos ent opt 40
    //(2) Then css would fetch value from attribute "data-date", and display accordingly
    const onEndDateChange = e => {
        //store the formatted date per ent option 40 into attribute "data-date", so that css can display that
        let endDateISOStr = getValues("endDate")
        let formattedEndDate = formatDate(stringToDate(endDateISOStr))
        e.target.setAttribute("data-date",  formattedEndDate)
    }

    return (
        <Modal centered show={true} backdrop="static" onHide={actions.cancel} >
            <Form id='requestForm' onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>{RequestTypeTitle[request.requestType]} Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="requestArea">
                        <Form.Label>Area</Form.Label>
                        <AreaFormControl name="areaId" areas={areas} selectedArea={selectedArea}
                                         isOpen={isOpen} onChange={onAreaChange}/>
                    </Form.Group>
                    <Form.Group controlId="requestStartDate" >
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control name="startDateDisplay" type="text"  readOnly
                                      defaultValue={formatDate(stringToDate(request.startDate))}
                                      />

                    </Form.Group>
                    <Form.Group controlId="requestEndDate" >
                        <Form.Label className="endDateLbl">End Date</Form.Label>
                        <div className="datePickr" >
                            <DatePicker
                                selected={endDate}
                                onChange={(dateChange)=>setEndDate(dateChange)}
                                minDate={stringToDate(request.startDate)}
                                maxDate={stringToDate((requestForm.to)?requestForm.to:request.startDate)}
                                dateFormat={getParamsAtStartup().dateFormat}
                            />
                        </div>

                    </Form.Group>
                    {errors.endDate && <p className="error">End date can not be before start date</p>}
                    <Form.Group controlId="requestWorkshiftId">
                        <Form.Label>Workshift</Form.Label>
                        <WorkshiftFormControl areaId={requestAreaId}
                                              workshifts={requestForm.workshifts}
                                              register={register}
                        >
                        </WorkshiftFormControl>
                    </Form.Group>
                    {errors.workshiftId && <p className="error">You must select a workshift</p>}
                    <Form.Group controlId="requestNote">
                        <Form.Label>Note</Form.Label>
                        <Form.Control as="textarea" name="note" placeholder="Enter a note (optional)"
                                      defaultValue={request.note}

                                      ref={register}/>
                    </Form.Group>


                    {/* ref={register} is then passed to that hidden input. This way "react-hooks-form" would have the startDate ISO value */}
                    {/* to submit, while ""startDateDisplay" is only a readonly display per ent option 40  */}

                    {/* 1. Now the initial iteration was to use input[date] readOnly. Then using BrowserStack & my real phone, */}
                    {/* I happened to notice that at least in iOS Safari & Chrome, readOnly is not respected: the date picker  */}
                    {/* would still come up on tap. Then having tried a couple of tricks to change the behavior but no avail.  */}
                    {/* 2. Then the next attempt to have the input[text] display differently than its value using css :before  */}
                    {/* But then this does not work because input[text] are "replaced elements"   https://webplatform.news/issues/2020-08-26  */}
                    {/* 3. Hence we took below approach here, and it works out using BrowserStack & a real phone               */}
                    <input  type="hidden" name="startDate" ref={register}  defaultValue={request.startDate}/>
                    <input  type="hidden" name="endDate" ref={register}  defaultValue={dateToString(endDate)}/>

                </Modal.Body>

                <Modal.Footer>
                    <Button type="submit" value="Submit">Submit</Button>
                    <Button variant="secondary" onClick={actions.cancel}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}


export default RequestForm