import React from 'react'
import './View.css'
import ansos2go from "images/22-HSTM-ANSOS2Go-Primary-RGB.png";

/**
 * Render a blue screen with ANSOS2Go image centered.
 * @returns {JSX.Element}
 */
const LandscapeView = () => {
    return <div id="landscapeView">
        <div className="banner">
            <img className="ansos2go" src={ansos2go} alt="ansos2go(tm)"/>
        </div>
    </div>
}

export default LandscapeView