import {getParamsAtStartup, getServerInfo} from "components/Settings/SettingsData";
import moment from "moment-timezone";

/**
 * Check if DOW is a weekend day
 * @param dow as capitalized abbreviation or full name of day of week
 * @returns {boolean}
 */
export function isWeekend(dow) {
    return dow === 'Saturday' || dow === 'Sat' || dow === "Sunday" || dow === "Sun"
}

/**
 * Format time using server's timezone
 * @param {ISO date string} time
 */
export function formatInServerTZ(time, format) {
    if (time && format) {
        const serverInfo = getServerInfo()
        const m = moment(time);
        return m.tz(serverInfo.timezone).format(format)
    } else {
        throw Error("Missing arguments (" + time + "," + format)
    }
}

/**
 * Format time as HHmm
 * @param {ISO date string} time
 */
export function formatInServerTZ_24hour(time) {
    return formatInServerTZ(time, 'HHmm')
}

/**
 * Format time as hh:mm a
 * @param {ISO date string} time
 */
export function formatInServerTZ_12hour(time) {
    return formatInServerTZ(time, 'hh:mm a')
}

/**
 * Format time as YYYY-MM-DD
 * Use DD instead of dd from day of month when formatting a moment.
 * @param {ISO date string} time
 */
export function formatInServerTZ_Date(time) {
    return formatInServerTZ(time, 'YYYY-MM-DD')
}

/**
 * Format time as YYYY-MM-DDTHH:mm
 * Use DD instead of dd from day of month when formatting a moment.
 * @param {ISO date string} time
 */
export function formatInServerTZ_DateTime(time) {
    return formatInServerTZ(time, 'YYYY-MM-DDTHH:mm')
}

/**
 * Format time as YYYY-MM-DD HH:mm:ss using server's timezone
 * @param time
 * @returns {string}
 */
export function formatInServerTZ_TimeStamp(time) {
    return formatInServerTZ(time, 'YYYY-MM-DD HH:mm:ss')
}


/**
 * Format time as dddd (day of week)
 * Use DD instead of dd from day of month when formatting a moment.
 * @param {ISO date string} time
 */
export function formatInServerTZ_DOW(time) {
    return formatInServerTZ(time, 'dddd')
}


/**
 * This method returns weekday, comma, format date per Ansos setting.
 * e.g. it would return "Tuesday, 03/22/2022"
 * @param date  JS date object
 * @returns {string}
 */
export function formatInServerTZ_DowDate(time) {
    return formatInServerTZ(time, 'dddd, YYYY/MM/DD')
}


/**
 * This method returns weekday, comma, format date per Ansos setting.
 * e.g. it would return below values based on different value for ent option 40:
 *    value 0:   Tuesday, 03/22/2022
 *    value 1:   Tuesday, 22/03/2022
 *    value 9:   Tuesday, 2022/03/22
 * @param dateAsString ISO date string ex: 2022-03-22
 * @returns {string}
 */
export function formatDowDate(dateAsString) {
    //Timezone becomes an issue when server side data contains timezone-awareness info while client side
    //is on a different timezone. But there are cases that a date string can be looked as if it not associated
    // with timezone. But if we can avoid associating timezone on a date for either end,
    // then it would work too.
    let ansosDateFormat = getParamsAtStartup().dateFormat.toUpperCase()
    let dateFormat = 'dddd, ' + ansosDateFormat
    const m = moment(dateAsString)
    return m.format(dateFormat)
}





// WARNING - The functions below this line may be using client's timezone
// which may be incorrect for how we use dates and times in ANSOS2Go.
// Formatted time as a date using the client's timezone could differ from
// using the server's timezone. The point is timezone matters for dates
// as well as times.

export function parseISOString(s) {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export function initTimer() {
    return new Date(0)
}

export function checkpoint(date) {
    return date.toISOString().substr(11, 8);
}

/**
 * Return date as string formatted 'YYYY-MM-dd'
 * @param {} date
 */
export function dateToString(date) {
    if (!(date instanceof Date)) {
        return (date instanceof String) ? date : ''
    }

    const options = {day: '2-digit', month: '2-digit', year: 'numeric'}
    const locale = 'en-US'
    let str = date.toLocaleDateString(locale, options)
    return str.substring(6, 10) + "-" + str.substring(0, 2) + "-" + str.substring(3, 5)
}

/**
 * Parse date string 'YYYY-MM-dd' into date object
 * @param dateAsString in 'YYYY-MM-dd' format
 * @returns {Date}
 */
export function stringToDate(dateAsString) {
    const parts = dateAsString.split(/\D+/)
    const date = new Date(parts[0], parts[1] - 1, parts[2])
    return date
}

/**
 * converts the String value of DateTime or DateTimeOffset in '1995-12-17T03:24:00' or '2021-03-05T15:00:00-05:00'
 * to date.s
 * @param dateTimeAsString
 * @returns {Date}
 */
export function stringToDateTime(dateTimeAsString) {
    return new Date(dateTimeAsString)
}

export function isValidRange(startDate, endDate) {
    const from = (typeof (startDate) === 'string') ? stringToDate(startDate) : startDate
    const to = (typeof (endDate) === 'string') ? stringToDate(endDate) : endDate
    return from < to || from.setHours(0, 0, 0, 0) === to.setHours(0, 0, 0, 0)
}

/**
 * Return moment as string formatted 'YYYY-MM-DD'
 * Use DD instead of dd from day of month when formatting a moment.
 * @param {} moment
 */
export function momentToDateString(moment) {
    if (!moment) return ''
    return moment.format("YYYY-MM-DD")
}




export function weekday(date, weekday = 'long') {
    if (!date) return ''
    return new Intl.DateTimeFormat(undefined, {weekday: weekday}).format(date.getTime())
}

/**
 * This method returns weekday and Month and day of the month.
 * eg today is Thursday March 4 2021, it would return as [Thursday, Mar 4] if format is 1,
 * or "Thursday, Mar 4" if format is 0
 * @param date
 * @param format
 * @returns {*[]}
 */
export function weekdayAndDayOfMonth(date, format = 1) {
    var options = {weekday: 'long', month: 'short', day: 'numeric'};

    var dateParts = new Intl.DateTimeFormat(undefined, options).formatToParts(date);

    var weekday, month, dayOfMonth

    dateParts.forEach((each) => {
        if (each.type !== 'literal') {
            switch (each.type) {
                case "weekday":
                    weekday = each.value;
                    break;
                case "month":
                    month = each.value;
                    break;
                case "day":
                    dayOfMonth = each.value;
                    break;
            }
        }
    })
    switch (format) {
        case 0:
            return `${weekday}, ${month} ${dayOfMonth}`
        case 1:
        default:
            return [weekday, `${month} ${dayOfMonth}`]
    }

}

/**
 * format a JS date object and return this format: Friday, April 1, 2022
 * @param date JS date object
 * @returns {string}
 */
export function weekdayAndDayOfMonthAndYear(date) {
    var options = {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'};

    var dateParts = new Intl.DateTimeFormat(undefined, options).formatToParts(date);

    var weekday, month, dayOfMonth, year

    dateParts.forEach((each) => {
        if (each.type !== 'literal') {
            switch (each.type) {
                case "weekday":
                    weekday = each.value;
                    break;
                case "month":
                    month = each.value;
                    break;
                case "day":
                    dayOfMonth = each.value;
                    break;
                case "year":
                    year = each.value;
                    break;
            }
        }
    })

    return `${weekday}, ${month} ${dayOfMonth}, ${year}`
}

export function day(date) {
    return new Intl.DateTimeFormat(undefined, {day: 'numeric'}).format(date)
}


export function equal(date1, date2) {
    const d1 = new Date(date1.valueOf())
    d1.setHours(0, 0, 0, 0)
    const d2 = new Date(date2.valueOf())
    d2.setHours(0, 0, 0, 0)
    return d1.getTime() === d2.getTime()
}

/**
 * Accepts Date object or date as string, e.g. '2012-01-26T13:51:50.417-07:00' and return a new Date object
 *
 * @param date Date object or date string
 * @returns {Date}
 */
export function mkDate(date) {
    if (!(date instanceof Date)) {
        return new Date(Date.parse(date))
    } else {
        return new Date(date)
    }
}

/**
 * Format time portion of a date or date string
 * @param date Can be Date object or date string, e.g. '2012-01-26T13:51:50.417-07:00'
 * @returns {string}
 */
export function formatTime(date) {
    return mkDate(date).toLocaleTimeString('en-US').replace(":00 ", " ")
}


/**
 * Format minutes into hours in the format of "x days y hours z minutes" when applicable
 * Ex: 480 would return "8 hours"; 510 would return "8 hours 30 minutes"; 1500 would return "1 day 1 hour"
 * @param minutes minutes in number
 * @returns {string}
 */
export function duration(minutes) {
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(minutes / (24 * 60))
    minutes = minutes - (days * 24 * 60) - hours * 60

    return (days > 0 ? days + " day" + (days > 1 ? "s " : "") : "") +
        (hours > 0 ? hours + " hour" + (hours > 1 ? "s " : "") : "") +
        (minutes > 0 ? minutes + " minute" + (minutes > 1 ? "s" : "") : "")
}

Date.prototype.addDays = function (days) {
    var dat = new Date(this.valueOf())
    dat.setDate(dat.getDate() + days);
    return dat;
}

/**
 * returns an array of dates between begin and end date
 * @param begin
 * @param end
 * @returns {any[]}
 */
export function getDates(begin, end) {
    var dateArray = [];
    var currentDate = begin;
    while (currentDate <= end) {
        dateArray.push(currentDate)
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

/**
 * returns the days between from and to date
 * @param from date
 * @param to date
 * @returns {number}
 */
export function daysBetween(from, to) {
    var diffTime = Math.abs(from - to);
    return Math.round(diffTime / (1000 * 60 * 60 * 24));
}

/**
 * Format minutes of time length into "hh:mm"
 * ex: 570 minutes would in turn returns "9:30"
 * @param minutes
 * @returns {string}
 */
export function minutesToHHMM(minutes) {
    let hours = Math.floor(minutes / 60)
    let min = minutes - hours * 60
    if (min < 10) {
        return `${hours}:0${min}`
    } else return `${hours}:${min}`
}

/**
 * Format minutes into hours in numbers
 * Ex: 480 min would return 8; 495 min would return 8.25; 510min would return 8.5;
 * @param minutes
 * @returns {number}
 */
export function minutesToDurationInHours(minutes) {
    let hours = minutes / 60
    // round to at most 2 decimal places, only if necessary.
    // e.g. 8.33333333 would return 8.33, while 8 still returns 8.
    return Math.round(hours * 100) / 100
}


/**
 * Format date per Ansos setting, which would be one of the three:
 *      Enterprise Option 40 with value 0: MM/DD/YYYY
 *      Enterprise Option 40 with value 1: DD/MM/YYYY
 *      Enterprise Option 40 with value 9: YYYY/MM/DD
 *  Please note caller does not need knowledge of the Ansos setting.
 * @param date  the JS date object to be formatted
 * @returns {string}
 */
export function formatDate(date) {
    const paramsAtStartup = getParamsAtStartup()
    const m = moment(date)
    return m.format(paramsAtStartup.dateFormat.toUpperCase())
}

export function formatDateSimple(date, format) {
    const m = moment(date)
    return m.format(format)
}

/**
 * Parse Ansos setting formatted date string back into a JS date object.
 * dateStr can be one of the following format: MM/DD/YYYY, DD/MM/YYYY, YYYY/MM/DD
 * @param dateStr the string to be parsed into a JS date object
 * @returns {Date}
 */
export function parseDate(dateStr) {
    const paramsAtStartup = getParamsAtStartup()
    const dateFormat = paramsAtStartup.dateFormat.toUpperCase()
    let day, month, year

    switch (dateFormat) {
        case "MM/DD/YYYY":
            [month, day, year] = dateStr.split("/")
        case "DD/MM/YYYY":
            [day, month, year] = dateStr.split("/")
        case "YYYY/MM/DD":
        default:
            [year, month, day] = dateStr.split("/")
    }
    return new Date(year, month - 1, day)


}

