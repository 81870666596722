import React from 'react'
import Notice, {NoticeType} from "components/Notice";
import {processProfileListQueryResult} from "../SwitchProfile/SwitchProfileData";
import SwitchProfileForm from "./SwitchProfileForm";
import {useSwitchProfileQuery} from "./SwitchProfileGQL";

const SwitchProfile = ({show, actions, networkStatus}) => {
    const [{fetching: fetchingCD, data: dataCD, error: errorCD}] = useSwitchProfileQuery(!show || !networkStatus.online)
    if (!show) return <></>
    let [profilesList, serviceStatus] = processProfileListQueryResult(fetchingCD, dataCD, errorCD)
    if (!networkStatus.online) {
        serviceStatus = {
            message: "Switch Profile has been temporarily disabled while your device is offline",
            noticeType: NoticeType.OK,
        }
    }

    if (serviceStatus) {
        return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                       onClose={actions.cancel} show={true}/>
    } else {
        return (
            <SwitchProfileForm profiles={profilesList.profiles} currentProfileName={profilesList.currentProfileName} actions={actions}/>
        )
    }
}

export default SwitchProfile