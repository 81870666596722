import React from 'react'
import Form from 'react-bootstrap/Form'
import AreaSelectControl from '../AreaSelectControl'
import {useFormContext} from 'react-hook-form'

const EmployeeRoleForm = ({data: userProfileData}) => {

    const { register } = useFormContext() // retrieve all hook methods

    // console.log("userProfileData.empDefaultAreaId= "  + JSON.stringify(userProfileData.empDefaultAreaId))
    // console.log("All the areas that she has access to = "  + JSON.stringify(userProfileData.areasEmp))

    const ifUseEmployeeHomeArea = () => {
        return  (userProfileData.empDefaultAreaId)? false : true
    }

    const getEmployeeSelectArea = () => {
        return (userProfileData.empDefaultAreaId)?
                userProfileData.empDefaultAreaId : userProfileData.dcpos.substring(0, 2)

    }


        return (

            <Form.Group>
                <fieldset className="fieldsetClass">

                    <legend className="formLabel">Employee Role Default Area</legend>

                    <label className="hsRadio">
                        <input type="radio"
                               name='useEmpHomeArea'
                               defaultChecked = {ifUseEmployeeHomeArea()}
                               value='true'
                               ref={register}
                        />
                        <span className="radioCheckMark"> </span>
                        <span className="hsCheckboxLabel">Home Area</span>
                    </label>

                    <label className="hsRadio">
                        <input type="radio"
                               name='useEmpHomeArea'
                               defaultChecked = {!ifUseEmployeeHomeArea()}
                               value='false'
                               ref={register}
                        />
                        <span className="radioCheckMark"> </span>
                        <span className="hsCheckboxLabel">Selected Area</span>
                    </label>


                    <AreaSelectControl name="empDefaultAreaId"
                            areas={userProfileData.areasEmp}
                            selectedAreaId={getEmployeeSelectArea()}
                            register={register}
                    />


                </fieldset>
            </Form.Group>



    )


}

export default EmployeeRoleForm
