import gql from 'graphql-tag'
import {useMutation, useQuery} from 'urql'

export const USER_FIELDS = gql`
    fragment UserFields on UserProfile {
        ansosId wsmAcctId userName hasMultipleDcpos profileName primaryProfile defaultDisplayName customDisplayName dcpos skill email
        phone altPhone terminateDate defaultRole
        areasSA {name id}
        areasEmp {name id}
        saDefaultAreaId saDefaultPage empDefaultAreaId roles
        icalendarSubscribeToken icalendarSubscriptionUrl id
    }`
export const USER_FUNCTION_ACCESS = gql`
    fragment UserFunctionAccess on UserProfile {
        functionAccess {
            empCalendarViews empAvailableShifts empEnterRequests empScheduleView adminInbox
            adminScheduleView adminAvailableShifts adminPlanner adminCalenderViews adminWorkRequests
            adminTimeoffRequests adminAvailableShiftRequests adminRemoveAssignment empAllowSwaps
            adminSwapRequests empScheduleGridViews empSelfSchedule empAllowRetractions
            adminScheduleGridViews adminScheduleGridEditArchive adminScheduleGridEditCurrent
            adminScheduleGridEditFuture changePassword id
        }
    }`
export const USER_NOTIFICATION = gql`
    fragment UserNotification on UserProfile {
        notification {
            notfyEmailTo notfyEmailOther notfyEmailVerified notfyEmailAddressVerified notfyTxtTo
            notfyTxtOther notfyTxtCarrier notfyTxtVerified notfyTxtPhoneNumVerified carrierOptionList
            notfyAsOptIn notfyAsBy notfyAsAllAreas notfyAsSelectedAreas notfyAsShiftsBtwnEnabled
            notfyAsShiftsBtwnStart notfyAsShiftsBtwnEnd notfyAsNoDisturbEnabled notfyAsNoDisturbStart
            notfyAsNoDisturbEnd id
        }
    }`

export const USER_QUERY = gql`
    query user {
        userProfile {
            ...UserFields
            ...UserFunctionAccess
            ...UserNotification
        }
    } ${USER_FIELDS} ${USER_FUNCTION_ACCESS} ${USER_NOTIFICATION}`
export const TINY_USER_QUERY = gql`
    query user {
        userProfile {
            ansosId wsmAcctId userName defaultDisplayName
        }
    }`

export function useUserQuery(requestPolicy) {
    console.log('useUserQuery...')
    //console.log('USER_QUERY=' + JSON.stringify(USER_QUERY))
    return useQuery({
        query: USER_QUERY, variables: {}, requestPolicy: requestPolicy,
    })
}

export function useTinyUserQuery() {
    console.log('useTinyUserQuery...')
    console.log('TINY_USER_QUERY=' + JSON.stringify(TINY_USER_QUERY))
    return useQuery({
        query: TINY_USER_QUERY, variables: {}, requestPolicy: "network-only",
    })
}

export const USER_PROFILE_QUERY = gql`
    ${USER_FIELDS}
    ${USER_FUNCTION_ACCESS}
    ${USER_NOTIFICATION}
    query userProfile {
        userProfile {
            ...UserFields
            ...UserFunctionAccess
            ...UserNotification
        }
    }`

export function useUserProfileQuery() {
    console.log('useUserProfileQuery...')
    return useQuery({
        query: USER_PROFILE_QUERY, variables: {}, requestPolicy: "cache-and-network",
    })
}

const CHANGE_PASSWORD_MUTATION = gql`
    ${USER_FIELDS}
    ${USER_FUNCTION_ACCESS}
    ${USER_NOTIFICATION}
    mutation changePasswrd ($currentPasswrd: String!, $newPasswrd: String!) {
        changePasswrd (currentPasswrd: $currentPasswrd, newPasswrd: $newPasswrd) {
            ...UserFields
            ...UserFunctionAccess
            ...UserNotification
        }
    }`

export function useChangePasswordMutation() {
    return useMutation(CHANGE_PASSWORD_MUTATION)
}

const UPDATE_USER_PROFILE_MUTATION = gql`
    ${USER_FIELDS}
    ${USER_FUNCTION_ACCESS}
    ${USER_NOTIFICATION}
    mutation updateUserProfile ($userProfileInput: UserProfileInput!) {
        updateUserProfile (userProfileInput: $userProfileInput) {
            ...UserFields
            ...UserFunctionAccess
            ...UserNotification
        }
    }`

export function useUpdateUserProfileMutation() {
    return useMutation(UPDATE_USER_PROFILE_MUTATION)
}

const SEND_EMAIL_VERIFICATION_CODE_QUERY = gql`
    query sendEmailVerificationCode ($notfyEmailTo: NotfyEmailTo!, $email: String!) {
        sendEmailVerificationCode (notfyEmailTo: $notfyEmailTo, email: $email)
    }`

/**
 * Sends a generated code to the provided email.
 * @param {*} notfyEmailTo
 * @param {*} email
 */
export function useSendEmailVerificationCodeQuery(notfyEmailTo, email) {
    console.log("useSendEmailVerificationCodeQuery(" + notfyEmailTo + "," + email + ") ; pause = " + !notfyEmailTo || !email)
    return useQuery({
        query: SEND_EMAIL_VERIFICATION_CODE_QUERY,
        variables: {notfyEmailTo: notfyEmailTo, email: email},
        pause: !notfyEmailTo || !email,
        requestPolicy: 'network-only',
    })
}

const SEND_PHONE_VERIFICATION_CODE_QUERY = gql`
    query sendPhoneVerificationCode ($notfyTxtTo: NotfyTxtTo!, $phone: String!, $carrier: String!) {
        sendPhoneVerificationCode (notfyTxtTo: $notfyTxtTo, phone: $phone, carrier: $carrier)
    }`

/**
 * Sends a generated code to the provided phone/carrier.
 * @param {*} notfyTxtTo
 * @param {*} phone
 * @param {*} carrier
 */
export function useSendPhoneVerificationCodeQuery(notfyTxtTo, phone, carrier) {
    console.log("useSendPhoneVerificationCodeQuery(" + notfyTxtTo + "," + phone + "," + carrier + ") ; pause = " + !notfyTxtTo || !phone || !carrier)
    return useQuery({
        query: SEND_PHONE_VERIFICATION_CODE_QUERY,
        variables: {notfyTxtTo: notfyTxtTo, phone: phone, carrier: carrier},
        pause: !notfyTxtTo || !phone || !carrier,
        requestPolicy: 'network-only',
    })
}

const VERIFY_EMAIL_VERIFICATION_CODE_MUTATION = gql`
    ${USER_FIELDS}
    ${USER_FUNCTION_ACCESS}
    ${USER_NOTIFICATION}
    mutation verifyEmailVerificationCode ($notfyEmailTo: NotfyEmailTo!, $email: String!,
        $verificationCode: String!) {
        verifyEmailVerificationCode (notfyEmailTo: $notfyEmailTo, email: $email,
            verificationCode: $verificationCode) {
            ...UserFields
            ...UserFunctionAccess
            ...UserNotification
        }
    }`

export function useVerifyEmailVerificationCodeMutation() {
    return useMutation(VERIFY_EMAIL_VERIFICATION_CODE_MUTATION)
}

const VERIFY_PHONE_VERIFICATION_CODE_MUTATION = gql`
    ${USER_FIELDS}
    ${USER_FUNCTION_ACCESS}
    ${USER_NOTIFICATION}
    mutation verifyPhoneVerificationCode ($notfyTxtTo: NotfyTxtTo!, $phone: String!, $carrier: String!,
        $verificationCode: String!) {
        verifyPhoneVerificationCode (notfyTxtTo: $notfyTxtTo, phone: $phone, carrier: $carrier,
            verificationCode: $verificationCode) {
            ...UserFields
            ...UserFunctionAccess
            ...UserNotification
        }
    }`

export function useVerifyPhoneVerificationCodeMutation() {
    return useMutation(VERIFY_PHONE_VERIFICATION_CODE_MUTATION)
}

const SIGN_OUT_MUTATION = gql`mutation signout {signout}`

export function useSignOutMutation() {
    return useMutation(SIGN_OUT_MUTATION)
}

const STATUS_MUTATION = gql`mutation status {status}`

export function useStatusMutation() {
    return useMutation(STATUS_MUTATION)
}

export const ICALENDAR_SUBSCRIPTION_MUTATION = gql`
    mutation subscribeIcalendar  {
        subscribeIcalendar
    }`

export function useIcalendarSubscriptionMutation() {
    return useMutation(ICALENDAR_SUBSCRIPTION_MUTATION)
}

export const ICALENDAR_UNSUBSCRIPTION_MUTATION = gql`
    mutation unsubscribeIcalendar  {
        unsubscribeIcalendar
    }`

export function useIcalendarUnsubscriptionMutation() {
    return useMutation(ICALENDAR_UNSUBSCRIPTION_MUTATION)
}

export function clearCachedUserQuery(result, cache) {
    console.log("REMOVE USER FROM CACHE...")
    const data = cache.readQuery({query: USER_QUERY, variables: {}})
    if (data && data.userProfile) cache.invalidate({__typename: 'UserProfile', id: data.userProfile.id});
}

export function updateCachedUserQueryWithSwitchProfileResult(result, cache) {
    console.log("UPDATE USER IN CACHE...")
    cache.updateQuery({query: USER_QUERY}, data => {
        if (result && result.switchProfile) {
            data.userProfile = result.switchProfile
        }
        return data
    })
}