import React from 'react'
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import bootstrapPlugin from '@fullcalendar/bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'fontawesome-free/css/all.css'
import "./Calendar.css"
import {createIndicatorEvents, createPublishedStatusEvents} from './CalendarEvents'
import useWindowDimensions from "helpers/window"
import {dateToString} from "helpers/date";
import {getDay} from "./CalendarData";
import moment from "moment";

const Calendar = ({date, days, area, mode, onDateChange, onMonthChange, user}) => {
    console.log("trace...Calendar")
    const {height, width} = useWindowDimensions()

    let contentHeight = height * (width / (height * .95))
    contentHeight = Math.max(385, contentHeight)

    function handleDateSet(dateInfo) {
        let nextDate = dateInfo.start ? dateInfo.start : dateInfo.date
        if (nextDate.getDate() !== 1) {
            let year = nextDate.getFullYear()
            let month = nextDate.getMonth()
            nextDate = new Date(year, month + 1, 1)
        }
        let focusDate
        const today = new Date()
        if (nextDate.getMonth() === today.getMonth()) {
            nextDate = today
            // document.getElementsByClassName('fc-today-button')[0].removeAttribute('disabled')
        }
        if (nextDate.getMonth() !== date.getMonth()) {
            focusDate = nextDate
            onDateChange(nextDate)
        } else {
            focusDate = date
        }
        focusDate = dateToString(focusDate)
        moveFocusTo(document.querySelectorAll('[data-date="' + focusDate + '"]')[0])
    }

    function handleDateChange(dateInfo) {
        moveFocusTo(dateInfo.dayEl)
        let toDate = dateInfo.start ? dateInfo.start : dateInfo.date
        goToDate(toDate)
    }

    function handleEventClick(eventInfo) {
        moveFocusTo(eventInfo.el.parentElement.parentElement.parentElement)
        let toDate = eventInfo.event.date ? eventInfo.event.date : eventInfo.event.start
        goToDate(toDate)
    }

    function goToDate(toDate) {
        // let month = date.getMonth()
        // let toMonth = toDate.getMonth()
        //
        // if (month < toMonth || (month === 11 && toMonth === 0)) {
        //     calendarRef.current.getApi().next()
        // } else if (month > toMonth || (month === 0 && toMonth === 11)) {
        //     calendarRef.current.getApi().prev()
        // }
        onDateChange(toDate)
    }

    function moveFocusTo(element) {
        const previousSelectedDate = document.getElementsByClassName('selected-date')[0];
        if (previousSelectedDate) previousSelectedDate.classList.remove('selected-date')
        if (element) element.classList.add('selected-date')
    }

    function createEvents(days, area, mode, date, terminateDate) {
        if (!days) days = new Map()
        // also sets selected date
        let publishedStatusEvents = createPublishedStatusEvents(days, date)
        let availableShiftDates = area ? area.shiftDates : []
        let indicatorEvents = createIndicatorEvents(days, availableShiftDates, mode, terminateDate)
        return [...publishedStatusEvents, ...indicatorEvents]
    }

    const events = (() => {
        if (days) {
            console.log("creating events...date is " + date)
            return createEvents(days, area, mode, date, user.terminateDate)
        } else {
            return []
        }
    })()

//    aspectRatio={.9}

    const range = getDay(days, date).range
    const validRange = range ? {start: range.from, end: moment(range.to).add(1,"day").toDate()} : {}

    const calendarRef = React.createRef()

    return <FullCalendar ref={calendarRef}
        id="calendarMonth"
        customButtons={{
            today: {
                text: 'Today',
                click: function () {
                    calendarRef.current.getApi().today()
                    onMonthChange()
                }
            },
            prev: {
                text: '<',
                click: function () {
                    calendarRef.current.getApi().prev()
                    onMonthChange()
                }
            },
            next: {
                text: '>',
                click: function () {
                    calendarRef.current.getApi().next()
                    onMonthChange()
                }
            }
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
        headerToolbar={{
            left: 'title',
            center: '',
            right: 'today prev,next'
        }}
        themeSystem='bootstrap'
        buttonText={{today: 'Today'}}
        buttonIcons={{
            prev: 'fa-arrow-left',
            next: 'fa-arrow-right'
        }}
        initialView='dayGridMonth'
        initialDate={date}
        validRange={validRange}
        editable={false}
        selectable={false}
        dayMaxEvents={true}
        weekends={true}
        displayEventEnd={true}
        events={events}
        eventClassNames='monthViewEvent'
        dateClick={handleDateChange}
        datesSet={handleDateSet}
        eventClick={handleEventClick}
        contentHeight={contentHeight}
        showNonCurrentDates={false}
    />
}

export default Calendar