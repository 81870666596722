import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export const NoticeType = Object.freeze({OK: 0, YN: 1, OKCANCEL: 2, NONE: 3})

const messageStyle = {
    textAlign: "center",
    whiteSpace: "pre-wrap"
}

const Notice = ({message, type = NoticeType.OKCANCEL, labels, onClose, show}) => {

    function handleClose(confirmed) {
        onClose(confirmed)
    }

    function buttons(type) {
        switch (type) {
            case NoticeType.OK:
                if (!labels) labels = ["OK"]
                return <>
                    <Button variant="primary" onClick={() => handleClose(false)}>{labels[0]}</Button>
                </>
            case NoticeType.YN:
                if (!labels) labels = ["Yes", "No"]
                return <>
                    <Button variant="primary" onClick={() => handleClose(true)}>{labels[0]}</Button>
                    <Button variant="secondary" onClick={() => handleClose(false)}>{labels[1]}</Button>
                </>
            case NoticeType.OKCANCEL:
                if (!labels) labels = ["OK", "Cancel"]
                return <>
                    <Button variant="primary" onClick={() => handleClose(true)}>{labels[0]}</Button>
                    <Button variant="secondary" onClick={() => handleClose(false)}>{labels[1]}</Button>
                </>
            case NoticeType.NONE:
            default:
                return <></>
        }
    }

    if (show) {
        return <Modal id='notice' show={true} backdrop="static" centered>
            <Modal.Body>
                <div style={messageStyle}>
                    <span>{message}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {buttons(type)}
            </Modal.Footer>
        </Modal>
    } else {
        return <></>
    }
}

export default Notice