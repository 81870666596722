import React from 'react'
import 'components/Layout/Layout.css'

const Layout = ({header, body, footer}) => {

    function addHeader(header) {
        if (header) {
            return <header>
                <div className="column-left">{header[1].left}</div>
                <div className="column-right">{header[1].right}</div>
            </header>
        } else return <div className='no-header'></div>
    }

    function addFooter(footer) {
        if (footer) {
            return <footer>{footer}</footer>
        } else {
            return <div className='no-footer'></div>
        }
    }

    function bodyClassNames(header, footer) {
        return 'body' + (header ? ' header' : '') + (footer ? ' footer' : '')

    }

    return <div id="layout">
        {addHeader(header)}
        <div className={bodyClassNames(header, footer)}>{body}</div>
        {addFooter(footer)}
    </div>
}

export default Layout