import React from 'react';
import Table from 'react-bootstrap/Table'
import Assignment from 'components/Assignment'
import DayBanner from './DayBanner';
import './CalendarDay.css'
import {useStoreActions} from "Store";
import Retract from "components/Retract/Retract";
import Swap from "components/Swaps";
import AssignmentFormatter from "components/Assignment/AssignmentFormatter";

const CalendarDay = ({day, dayBannerActions}) => {
    console.log("trace...CalendarDay")
    const actions = useStoreActions()

    const rows = (function (assignments) {
        if (assignments.length > 0) {
            return assignments.map((assignment, index) => (
                <tr key={index} onClick={() => actions.updateAssignment(assignment)}>
                    <td colSpan='2'><AssignmentFormatter assignment={assignment}/></td>
                </tr>
            ))
        } else {
            return (
                <tr key="no-assignments">
                    <td>
                        <div className="assignment"><span className="assignmentTitle">No Assignments</span></div>
                    </td>
                </tr>
            )
        }
    })(day.assignments)

    return <div id="calendarDay">
        <Assignment/>
        <Retract/>
        <Swap/>
        <Table striped>
            <thead>
            <tr>
                <th>
                    <DayBanner day={day} actions={dayBannerActions}/>
                </th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    </div>
}

export default CalendarDay