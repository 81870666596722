import {makeServiceStatus} from "../../helpers/serviceStatus";

export function processAssignOptionsData(fetchingCD, dataCD, errorCD) {

    if (fetchingCD) {
        return defaultAssignDetailAction()
    }

    if (errorCD) {
        const serviceStatus = makeServiceStatus(dataCD ? dataCD.assignmentDetailAction : null, errorCD)
        if (serviceStatus && !serviceStatus.success) {
            return defaultAssignDetailAction()
        }
    }

    if (dataCD && dataCD.assignmentDetailAction) {
        let assignmentDetailAction = dataCD.assignmentDetailAction
        assignmentDetailAction.openAccordian=true
        assignmentDetailAction.count=1
        return assignmentDetailAction
    } else {
        let assignmentDetailAction={
            retractable:false,
            swapable:false,
            openAccordian:false,
            count:0,
            retractSelected:false,
            swapListSelected:false,
            swapReviewSelected: false,
            swapNearbyWorkshiftsSelected: false
        }
        return assignmentDetailAction
    }

    function defaultAssignDetailAction(){
        let assignmentDetailAction={
            retractable:false,
            swapable:false,
            openAccordian:null,
            count:0,
            retractSelected:false,
            swapListSelected:false,
            swapReviewSelected: false,
            swapNearbyWorkshiftsSelected: false
        }

        return assignmentDetailAction
    }

}