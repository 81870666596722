import React from 'react'
import {formatWorkshift} from "helpers/workshift";
import {
    duration,
    formatInServerTZ_24hour,
    formatInServerTZ_DOW,
    formatDowDate,
} from "helpers/date";
import NameValueList from "components/NameValueList";

const NotificationDetail = ({notification, style = 'list'}) => {
    if (notification && notification.workshift && notification.workshift.on) {
        const workshift = notification.workshift
        let map = new Map()
        let title = ''
        switch (style) {
            case "fill":
                title = "Loading ..."
                break;
            case "list":
                map.set('Date', {text: formatDowDate(workshift.on)})
                map.set('Workshift', {text: formatWorkshift(workshift)})
                break
            case 'form':
            default:
                map.set('Work Date', {text: formatDowDate(workshift.on)})
                map.set('Workshift', {text: formatWorkshift(workshift)})
                map.set('Start', {text: formatInServerTZ_24hour(workshift.from) + " " + formatInServerTZ_DOW(workshift.from)})
                map.set('End', {text: formatInServerTZ_24hour(workshift.to) + " " + formatInServerTZ_DOW(workshift.to)})
        }
        
        map.set('Duration', {text: duration(workshift.workMinutes)})
        map.set('Category', {text: workshift.category})
        map.set('Comp Skill', {text: workshift.skill})
        map.set('Area', {text: notification.areaId ? notification.areaId + " " + notification.areaName : ""})
        map.set('Approval Required', {text: workshift.requiresApproval ? "Yes" : "No"})

        return (
            <div className="notificationDetail">
                <div className="boldFont">Selected Available Shift:</div>
                <div className="title">{title}</div>
                <NameValueList map={map} size={'medium'} striped={false} borders={false}/>
            </div>
        )

    } else {
        return <></>
    }
}

export default NotificationDetail