import React, {useState} from 'react';
import {calendarModes, getAreas, getDay, processCalendarData} from 'components/Calendar/CalendarData'
import Calendar from "components/Calendar"
import CalendarDay from "components/CalendarDay"
import Request, {RequestType} from 'components/Request'
import If from "components/If"
import {useCalendarQuery} from 'components/Calendar/CalendarGQL';
import {toError} from "helpers/serviceStatus";
import {useViewClasses} from "./View";
import {getDefaultOrHomeArea} from "../../helpers/area";


const WorkView = ({layout, user}) => {
    const [{fetching: fetchingCD, data: dataCD, error: errorCD}, reExecuteCalendarQuery] = useCalendarQuery()
    const {days, serviceStatus} = processCalendarData(fetchingCD, dataCD, errorCD, user.terminateDate)
    const [date, setDate] = useState(new Date())
    const [showRequest, setShowRequest] = useState(false)
    const onMonthChange = () => {
        reExecuteCalendarQuery({requestPolicy: 'network-only'})
    }
    const dayBannerActions = (day) => {
        return (
            <>
                <If condition={() => {
                    return showRequest
                }}>
                    <Request requestType={RequestType.WORK} date={date} areas={getAreas(getDay(days, date))}
                             areaId={getDefaultOrHomeArea(user).id} close={() => {
                        setShowRequest(false)
                    }}/>
                </If>
                <div className={"request" + (!day || !day.workOpen ? " disabled" : "")}
                     onClick={() => setShowRequest(true)}>Request to Work <i
                    className={"fas fa-chevron-circle-right"}/></div>
            </>
        )
    }
    let body = (
        <div id="workView">
            <Calendar date={date} days={days} mode={calendarModes.WORK}
                      onDateChange={(date) => {
                          setDate(date)
                      }}
                      onMonthChange={onMonthChange}
                      user={user}/>
            <CalendarDay day={getDay(days, date)} dayBannerActions={dayBannerActions}/>
        </div>
    )

    return layout({body, error: toError(serviceStatus), classes: useViewClasses()})
}

export default WorkView