import React from 'react'
import SwapStatusControl from "./SwapStatusControl"


const SwapStatusList  = ({swapProposalsAndRequests, areas, refresh}) => {

    return (
        <div>
            {swapProposalsAndRequests && swapProposalsAndRequests.length >0
            && swapProposalsAndRequests.map((swapProposalOrRequest) => {
                return (
                    <div key={swapProposalOrRequest.divKey}>
                        <SwapStatusControl swapProposalOrRequest={swapProposalOrRequest} areas={areas} refresh={refresh} />
                    </div>
                )

            })
            }

        </div>
    )

}

export default SwapStatusList