import React from "react";
import {Cell, Row} from "@table-library/react-table-library/table";

/**
 * Render Counts row
 * @param dayCounts [day1-count,day2-count,...]
 * @param periodDays ...
 * @returns {JSX.Element}
 * @constructor
 */
function CountRow({dayCounts, periodDays}) {
    if (!dayCounts || !(dayCounts instanceof Array)) {
        console.error("CountRow render attempt no data")
        return <></>
    }
    return <Row className="counts">
        <Cell className="rowHeader" pinLeft>Employee Count</Cell>
        <DayCells dayCounts={dayCounts} periodDays={periodDays}/>
    </Row>
}

function DayCells({dayCounts, periodDays}) {
    return dayCounts.map((count, day) => (
        <Cell key={day} className={periodDays[day].className}>{count}</Cell>
    ))
}

export default CountRow