import React, {useRef, useState} from 'react'
import {formatWorkshift} from 'helpers/workshift'
import {formatDate, minutesToDurationInHours} from "helpers/date"
import {getStatuStyleClass} from "./SwapStatusData"
import Collapse from 'react-bootstrap/Collapse'
import {findAreaName} from "../InitiateProposal/swapInitiateProposalHelper"
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'

const PotentialAssignmentControl = ({partnerWorkshift, partnerEmployee,showSwapPartnerNote, areas}) => {
    let partnerWsText = formatWorkshift(partnerWorkshift)
    let statusClass = getStatuStyleClass(partnerEmployee.status)
    let areaName  = findAreaName(areas, partnerWorkshift.areaId)
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipTarget = useRef(null);


    return (
        <>
            <div className={"marginBottomTen"}>
                <div className="swapEmployeeName boldFont">
                    {partnerEmployee.empName}
                </div>

                <div className="swapGeneralInfo">
                    <span className="boldFont">DCPOS:</span>&nbsp;{partnerEmployee.dcpos}&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="boldFont">Skill:</span>&nbsp;{partnerEmployee.skill}&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="boldFont">Job Class:</span>&nbsp;{partnerEmployee.jobCode}
                </div>
            </div>


            <div className="swapPartner lightBlueBg">
                <table className="swapPartnerTable">
                    <tbody>
                    <tr>
                        <td className="dateCol">
                            <span className="boldFont">Date:</span> {formatDate(partnerWorkshift.date)}
                        </td>
                        <td>
                            <div className="flexDisplay">
                                <span className="boldFont">{partnerWsText.substring(0,2)}</span>&nbsp;{partnerWsText.substring(2)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="dateCol">
                            <span className="boldFont">Area:&nbsp;</span>

                            <span onClick={() => setShowTooltip(!showTooltip)}  ref={tooltipTarget}>{partnerWorkshift.areaId}...</span>

                            <Overlay
                                show={showTooltip}
                                target={tooltipTarget.current}
                                placement="bottom"
                                rootClose={true}
                                trigger={["click"]}
                                onHide={() => setShowTooltip(false)}
                            >


                                <Popover   className="hsTooltip">
                                    <Popover.Content>
                                        {partnerWorkshift.areaId}:{areaName}
                                    </Popover.Content>
                                </Popover>
                            </Overlay>


                        </td>
                        <td>
                            <span className="boldFont">Dur:</span> {minutesToDurationInHours(partnerWorkshift.workMinutes)}hrs
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className="spaceBetween marginTen">
                <div className={statusClass}>
                    {partnerEmployee.status}
                </div>
            </div>
            {/* (Collapse + an extra layer of div) is for smooth animation */}
            <Collapse in={(showSwapPartnerNote && !!partnerEmployee.note)}>
                <div>
                    <div className="notes">
                        <span className="boldFont">From my Swap Partner:</span> {partnerEmployee.note}
                    </div>
                </div>
            </Collapse>

            <Collapse in={(showSwapPartnerNote && !!partnerEmployee.saNote)}>
                <div>
                    <div className="notes">
                        <span className="boldFont">From SA processing my partner�??s Swap:</span> {partnerEmployee.saNote}
                    </div>
                </div>
            </Collapse>

        </>

    )


}


export default PotentialAssignmentControl
