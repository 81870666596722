import React, {useState} from 'react';
import './Launch.css'
import {makeServiceStatus} from "helpers/serviceStatus";
import {isEMP, isSA, ROLE as Role} from "components/Role";
import {ActionDefs, findAction} from "actions";
import Button from "react-bootstrap/Button";
import {activeProfileClassName, ActiveProfileName} from "components/UserProfile/User";
import Notice from "components/Notice";
import Banner from "components/Banner";

const Launch = ({user, actions}) => {
    const [serviceStatus, setServiceStatus] = useState(null)
    const [showLegalNotice, setShowLegalNotice] = useState(false)
    const onClickLegalNotice = (e) => {
        e.preventDefault()
        setShowLegalNotice(true)
    }
    const handleError = (error) => {
        console.log("call makeServiceStatus() error=" + JSON.stringify(error))
        const serviceStatus = makeServiceStatus(null, error)
        setServiceStatus(serviceStatus)
    }
    const handleNoticeResponse = () => {
        setServiceStatus(null)
    }
    let userSection = <></>
    let options = <></>
    if (user && user.defaultRole !== Role.NONE) {
        const signOutButton = () => {
            const signOutAction = findAction(ActionDefs.SIGN_OUT, actions)
            return (
                <Button variant="secondary" size="sm"
                        onClick={(e) => {
                            e.preventDefault();
                            signOutAction.function(handleError);
                            return false
                        }}
                        disabled={signOutAction.disabled}>
                    <i className={"fas " + signOutAction.icon}/>
                    <span>{signOutAction.title}</span>
                </Button>
            )
        }
        const switchProfileButton = (user) => {
            const switchProfileAction = user.hasMultipleDcpos ? findAction(ActionDefs.SWITCH_PROFILE, actions) : null
            if (switchProfileAction) {
                return (
                    <Button variant="secondary" onClick={(e) => {
                        switchProfileAction.function(handleError)
                        e.preventDefault()
                        return false
                    }}
                            disabled={switchProfileAction.disabled}>
                        <i className={"fas " + switchProfileAction.icon}/>
                        <span>{switchProfileAction.title}</span>
                    </Button>
                )
            } else {
                return <></>
            }
        }
        const userButton = (user) => {
            const userName = (user.customDisplayName && user.customDisplayName.length > 0) ? user.customDisplayName : user.defaultDisplayName
            let profile = ""
            if (isEMP(user)) profile += user.skill + " " + user.dcpos
            if (isSA(user) && isEMP(user)) profile += " / "
            if (isSA(user)) profile += "Administrator"
            const changeRoleAction = findAction(ActionDefs.CHANGE_ROLE, actions)
            return (
                <Button onClick={() => changeRoleAction.function()}>
                    <div>{userName}<br/>{profile}</div>
                    <i className={"fas fa-forward"}/>
                </Button>
            )
        }
        const profileLabel = () => {
            if (user.hasMultipleDcpos) {
                console.log(JSON.stringify(user))
                return (
                    <div className={"profile " + activeProfileClassName(user)}>
                        <ActiveProfileName user={user}/>
                    </div>
                )
            } else {
                return <></>
            }
        }

        userSection = (
            <div>
                <div id="launchUserProfile" className="Hcenter">
                    {profileLabel()}
                </div>
                <div id="launchUser" className="Hcenter">
                    {userButton(user)}
                </div>
            </div>
        )
        options = (
            <div id="launchOptions" className="Hcenter">
                {signOutButton()}
                {switchProfileButton(user)}
            </div>
        )
    } else {
        const signInButton = () => {
            const signInAction = findAction(ActionDefs.SIGN_IN, actions)
            return (
                <Button variant="primary" onClick={(e) => {
                    e.preventDefault()
                    signInAction.function(handleError)
                    return false
                }} disabled={signInAction.disabled}>
                    {signInAction.title} <i className={"fas " + signInAction.icon}/>
                </Button>
            )
        }
        options = (
            <div id="launchOptions" className="Hcenter">
                {signInButton()}
            </div>
        )
    }
    let notice = <></>
    if (serviceStatus) {
        console.log("Setting notice")
        notice = (
            <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                    onClose={handleNoticeResponse} show={true}/>
        )
    }

    return <div id="launch">
        <Banner/>
        {userSection}
        {options}
        {notice}
    </div>
}


export default Launch