import {useStoreActions, useStoreState} from "Store";
import {Form, Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import SwapRecAssignmentControl from './SwapRecAssignmentControl'
import {useForm} from "react-hook-form"
import {buildArrFromSelectedChkboxValues, buildIniateProposalInput, SwapUIMode} from "./swapInitiateProposalHelper"
import SwapInitAssignmentControl from "./SwapInitAssignmentControl"

const SwapReviewForm = ({
                            selectedSwapRecipient,
                            setSelectedSwapRecipient,
                            availableSwapRecipients,
                            initiateSwapProposalActions,
                            initiatorPrimarySkillRef,
                            swapNote,
                            setSwapNote,
                            nearbyInitEmpAsgnForRecipientsMap,
                            overlapInitEmpAsgnForRecipientsMap
                        }) => {

    //The reason for alias names (xxx2) is we want to distinguish this useFrom hook from the one in "SwapListForm"
    const {
        register: register2,
        getValues: getValues2,
        setValue: setValue2,
        handleSubmit: handleSubmit2,
    }
        = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    })
    const state = useStoreState()
    const actions = useStoreActions()
    const assignment = {...state.assignment}
    const selectedSwapRecipientControlName = "submitSwapRecipient"
    const swapNoteControlName = "swapProposalNote"

    const selectedRecipientKeyArr = [...selectedSwapRecipient]

    const [enableSubmitButton, setEnableSubmitButton] = useState(selectedRecipientKeyArr && selectedRecipientKeyArr.length > 0)

    function handleReviewCheckChange() {
        let selectionsAtReview = getValues2(selectedSwapRecipientControlName)
        let selectionsAtReviewArr = buildArrFromSelectedChkboxValues(selectionsAtReview)
        //1. Update "selected swap recipients"
        setSelectedSwapRecipient(selectionsAtReviewArr)
        //2. enable or disable "Submit Proposal" button based on if any recipients checked
        setEnableSubmitButton(selectionsAtReviewArr && selectionsAtReviewArr.length > 0)
    }


    const onSwapReviewFormSubmit = (input) => {
        //submit swap proposal mutation
        initiateSwapProposalActions.submit(buildIniateProposalInput(input, assignment,
            availableSwapRecipients, initiatorPrimarySkillRef.current))

    }

    function onBack() {
        //In order to navigate back, update the store to move back to "Swap List" screen
        actions.updateAssignActionWithSwapListSelected(true)
    }

    function handleSwapNoteBlur() {
        setSwapNote(getValues2(swapNoteControlName))
    }


    const MAX_NOTE_LEN = 254

    function handleSwapNoteChange() {

        let noteContent = getValues2(swapNoteControlName)
        if (noteContent.length > MAX_NOTE_LEN) {
            noteContent = noteContent.slice(0, MAX_NOTE_LEN);
        }
        setValue2(swapNoteControlName, noteContent)

    }


    function close() {
        initiateSwapProposalActions.close()
    }


    return (
        <Modal show={true} onHide={close} centered={true} backdrop="static">
            <Form>
                <Modal.Header className={"swapModalHeader"} closeButton>
                    <Modal.Title>
                        <span className={"swapLeftArrow"}><i className="fa fa-chevron-left" onClick={onBack}></i></span>
                        <span>Review Swap Proposal</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={"swapModalBody"}>
                    <SwapInitAssignmentControl swapAssignment={assignment}/>
                    <div className="initSwapLabel boldFont">
                        You are proposing to swap with the following:
                    </div>
                    <div id={"submitSwapProposalDiv"}>
                        {selectedRecipientKeyArr.map((selectedRecipientKey) => {
                            let swapRecAssignment = availableSwapRecipients.get(selectedRecipientKey)

                            return (
                                <SwapRecAssignmentControl swapRecAssignment={swapRecAssignment}
                                                          register={register2}
                                                          key={swapRecAssignment.uniqueKey}
                                                          mode={SwapUIMode.REVIEW}
                                                          name={selectedSwapRecipientControlName}
                                                          initAreaId={assignment.areaId}
                                                          onCheckChange={handleReviewCheckChange}
                                                          nearbyInitEmpAsgn={nearbyInitEmpAsgnForRecipientsMap.get(swapRecAssignment.uniqueKey)}
                                />
                            )
                        })

                        }
                    </div>


                    <div className={'swapNote'}>
                        <span className="boldFont">Note</span><span className="italicOption">&nbsp;(optional)</span>
                        <br/>
                        <Form.Control
                            as="textarea"
                            name={swapNoteControlName}
                            className={"swapReviewNote"}
                            ref={register2}
                            defaultValue={swapNote}
                            onBlur={handleSwapNoteBlur}
                            onChange={handleSwapNoteChange}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmit2(onSwapReviewFormSubmit)}
                            disabled={!enableSubmitButton}
                    >Submit Proposal</Button>
                </Modal.Footer>
            </Form>
        </Modal>


    )

}

export default SwapReviewForm