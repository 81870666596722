import gql from 'graphql-tag'
import {useMutation} from 'urql'

/**
 * Send issue to server to be logged.
 * Returns submitIssue: {id assigned to issue}
 */
export const SUBMIT_ISSUE_MUTATION = gql`
    mutation submitIssue ($issue: IssueInput!) {
        submitIssue (issue: $issue)
    }`

export function useSubmitIssueMutation() {
    return useMutation(SUBMIT_ISSUE_MUTATION)
}
