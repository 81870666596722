import React, {useState} from 'react';
import {calendarModes, getDay, processCalendarData} from 'components/Calendar/CalendarData'
import Calendar from "components/Calendar"
import CalendarDay from "components/CalendarDay"
import Request, {RequestType} from "components/Request"
import If from "components/If"
import {useCalendarQuery} from 'components/Calendar/CalendarGQL';
import {toError} from "helpers/serviceStatus";
import {useViewClasses} from "./View";
import moment from "moment";

const TimeOffView = ({layout, user}) => {
    const [{fetching: fetchingCD, data: dataCD, error: errorCD}, reExecuteCalendarQuery] = useCalendarQuery()
    const {days, serviceStatus} = processCalendarData(fetchingCD, dataCD, errorCD)
    const [date, setDate] = useState(new Date())
    const [showRequest, setShowRequest] = useState(false)
    const onMonthChange = () => {
        reExecuteCalendarQuery({requestPolicy: 'network-only'})
    }
    const dayBannerActions = (day) => {

        let isOnOrAfterTerminateDate = user.terminateDate &&  (!moment(user.terminateDate).isAfter(moment(day.date)))
        return (
            <>
                <If condition={() => {
                    return showRequest
                }}>
                    <Request requestType={RequestType.TIME_OFF} date={date} areas={[day.homeArea]}
                             areaId={day.homeArea.id} close={() => {
                        setShowRequest(false)
                    }}/>
                </If>
                <div className={"request" + (!day || !day.homeArea || !day.homeArea.timeOffOpen || isOnOrAfterTerminateDate ? " disabled" : "")}
                     onClick={() => setShowRequest(true)}>Request Time Off <i
                    className={"fas fa-chevron-circle-right"}/></div>
            </>
        )
    }

    let body = (
        <div id="timeOffView">
            <Calendar date={date} days={days} mode={calendarModes.TIMEOFF}
                      onDateChange={(date) => {
                          setDate(date)
                      }}
                      onMonthChange={onMonthChange}
                      user={user} />
            <CalendarDay day={getDay(days, date)} dayBannerActions={dayBannerActions}/>
        </div>
    )

    return layout({body, error: toError(serviceStatus), classes: useViewClasses()})
}

export default TimeOffView