import gql from 'graphql-tag'
import {useQuery} from 'urql'

// AREA AVAILABLE SHIFT DATES

export const AREA_AVAILABLE_SHIFT_DATES_QUERY = gql`
    query AreaAvailableShiftDates ($areaId: AreaId) {
        areaAvailableShiftDates (areaId: $areaId) {
            areaId dates id
        }
    }`

export function useAreaAvailableShiftDatesQuery(area) {
    const areaId = area ? area.id : null
    console.log("trace...useAreaAvailableShiftDatesQuery(" + areaId + ")")
    return useQuery({
        query: AREA_AVAILABLE_SHIFT_DATES_QUERY,
        variables: {areaId},
        requestPolicy: "network-only",
    })
}
