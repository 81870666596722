import React, {useState} from "react"
import {formatWorkshift} from 'helpers/workshift'
import {weekdayAndDayOfMonth, minutesToDurationInHours} from "helpers/date"
import {amITargetRecipient} from "./SwapInitiateProposalData"
import {SwapUIMode, SwapWarningMode} from "./swapInitiateProposalHelper"
import {useStoreActions} from "../../../Store"
import {getParamsAtStartup} from "../../../components/Settings/SettingsData"

const SwapRecAssignmentControl = ({swapRecAssignment, scrollToRef, name, targetRecipientToPosition, register,
                                      selectedSwapRecipient, mode, initAreaId, onCheckChange, nearbyInitEmpAsgn}) => {
    const actions = useStoreActions()
    const wstext = formatWorkshift(swapRecAssignment)

    const shouldCheck = isChecked()

    const uncheckedSwapShiftClassName = "swapShift flexDisplay"
    const checkedSwapShiftClassName = "swapShift flexDisplay lightBlueBg"
    const initialSwapShiftClassName = (shouldCheck === true) ? checkedSwapShiftClassName : uncheckedSwapShiftClassName
    const [swapShiftClassName, setSwapShiftClassName] = useState(initialSwapShiftClassName)

    const nearbyHours = (getParamsAtStartup()).swapNearbyWarningThresholdInMinutes/60

    let divSwapWarningMode = swapRecAssignment.overlap ? SwapWarningMode.OVERLAP : SwapWarningMode.NEARBY


    let shouldScrollToTop = false
    if(amITargetRecipient(swapRecAssignment, targetRecipientToPosition)){
        shouldScrollToTop = true
    }

    function isChecked() {
        let keyForSwapRecipient =swapRecAssignment.uniqueKey
        //"list" screen only pre-check those that has been selected before
        if (mode === SwapUIMode.LIST || mode === SwapUIMode.NEARBY ) {
            return selectedSwapRecipient && selectedSwapRecipient.includes(keyForSwapRecipient)
        }
        else if (mode === SwapUIMode.REVIEW){//"review" screen should pre-check all checkboxes
            return true
        }
        else return false
    }



    function swapShiftSelected(event, swapWarningMode) {
        //Only at "List" mode, tapping this recipient should bring up a dialog
        if (mode !== SwapUIMode.LIST){
            return false
        }
        //no overlap and no nearby; Then Tapping this recipient should do nothing. No dialog
        if ( (!swapRecAssignment.overlap) && (!(nearbyInitEmpAsgn && nearbyInitEmpAsgn.length > 0))){
            return false
        }
        //Now a "My schedule" dialog should come up
        actions.updateAssignActionWithSwapNearbyWorkshiftsSelected(true)
        actions.updateSwapSelectedRecipient(
            {
                from: mode,
                selectedSwapRecAssignmentKey: swapRecAssignment.uniqueKey,
                swapWarning:  swapWarningMode
            })
        event.stopPropagation()
    }



    function onRecipientCheckClick(event){
        //Step #1: apply css style for swap recipient workshift based on if it is checked or not
        if(event.target.checked) {
            setSwapShiftClassName(checkedSwapShiftClassName)
        }else{
            setSwapShiftClassName(uncheckedSwapShiftClassName)
        }

        //Step #2: when checkbox is tapped, this event should be prevented from propagating to parent div.
        //Otherwise when checkbox is tapped, then parent div would fire the onClick event which would then bring up the
        //"nearby workshift" dialog. Then end user would not be able to check/uncheck the checkbox.
        event.stopPropagation()
    }

    return (

        <div  className={swapShiftClassName}
              {...(shouldScrollToTop ? {ref: scrollToRef} : {})}
              onClick={(e)=>swapShiftSelected(e, divSwapWarningMode)}
        >
            <div className="swapCheck"  >
                <label className="bigCheckbox">
                    <input type="checkbox"  name={name} value={swapRecAssignment.uniqueKey}
                           defaultChecked={shouldCheck}
                           ref={register}
                           onChange={onCheckChange}
                           onClick={onRecipientCheckClick}
                    />
                    <span className="checkmark"
                          onClick={onRecipientCheckClick}
                    >
                    </span>
                </label>

            </div>

            <div className={"swapDetail"}>
                <span className="boldFont">{weekdayAndDayOfMonth(swapRecAssignment.date, 0)}</span>

                <br/>
                {wstext}
                &nbsp;({minutesToDurationInHours(swapRecAssignment.workMinutes)}hrs)
                <br/>

                <div className={"swapEmployee"}>
                    <div className="boldFont">
                        {swapRecAssignment.empName}:
                    </div>

                    <div>
                        {swapRecAssignment.dcpos}&nbsp;&nbsp;&nbsp;&nbsp;Skill: {swapRecAssignment.skill}&nbsp;&nbsp;&nbsp;&nbsp;Job Class:{swapRecAssignment.jobCode}
                    </div>
                </div>
                {mode === SwapUIMode.REVIEW && initAreaId !== swapRecAssignment.areaId
                &&
                <> <span className="swapEmployeeChecked">
                            <span className="boldFont">Area:</span> {swapRecAssignment.areaId}&nbsp;{swapRecAssignment.areaName}
                        </span> <br/></>
                }

                {swapRecAssignment.overtime && (mode === SwapUIMode.LIST || mode === SwapUIMode.REVIEW ) &&
                    <div className="swapWarning boldFont">
                        <i className="fas fa-exclamation-triangle"></i>
                        <span>You will be in overtime if you swap with this shift.</span>
                    </div>

                }

                {swapRecAssignment.overlap && mode === SwapUIMode.LIST &&
                <div className="swapWarning boldFont">
                    <i className="fas fa-exclamation-triangle"></i>
                    <span>You will have </span>
                    <span className="black underline" onClick={(e)=>swapShiftSelected(e, SwapWarningMode.OVERLAP)} >Overlapping Shifts</span>
                    <span> if you swap with this shift.</span>
                </div>

                }

                {swapRecAssignment.overlap && mode === SwapUIMode.REVIEW &&
                <div className="swapWarning boldFont">
                    <i className="fas fa-exclamation-triangle"></i>
                    <span>You will have Overlapping Shifts if you swap with this shift.</span>
                </div>

                }

                {nearbyInitEmpAsgn && nearbyInitEmpAsgn.length > 0 && mode === SwapUIMode.LIST &&
                    <div className="swapWarning boldFont">
                        <i className="fas fa-exclamation-triangle"></i>
                        <span>This shift is within {nearbyHours} hours of a </span>
                        <span className="black underline" onClick={(e)=>swapShiftSelected(e, SwapWarningMode.NEARBY)}>Nearby Workshift</span>
                    </div>
                }

                {nearbyInitEmpAsgn && nearbyInitEmpAsgn.length > 0 && mode === SwapUIMode.REVIEW &&
                <div className="swapWarning boldFont">
                        <i className="fas fa-exclamation-triangle"></i>
                        <span>This shift is within {nearbyHours} hours of a Nearby Workshift</span>
                    </div>
                }

            </div>

        </div>

    )

}


export default SwapRecAssignmentControl