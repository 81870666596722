import React, {useState} from 'react'
import Notice from "components/Notice"
import Spinner from "components/Spinner"
import ServiceStatus, {makeServiceStatus} from "helpers/serviceStatus"
import ChangePasswordForm from "./ChangePasswordForm"
import {useChangePasswordMutation} from "../UserProfileGQL"

const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})

const ChangePassword = ({data, close}) => {

    const [show, setShow] = useState(SHOW.FORM)
    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})

    const [submitChangePasswordResult, submitChangePassword] = useChangePasswordMutation()

    const actions = {
        submit: (currentPasswrd, newPasswrd) => {
            setShow(SHOW.SPINNER)
            const variables = {"currentPasswrd": currentPasswrd, "newPasswrd": newPasswrd}

            submitChangePassword(variables).then(result => {
                let updatePasswordSuccessStatusWrapper = result.data ? {status: "SUCCESS_PASSWORD_CHANGED"} : null
                let changePasswordStatus = makeServiceStatus(result.data ? updatePasswordSuccessStatusWrapper : null, result.error)
                setServiceStatus(changePasswordStatus)
                if (changePasswordStatus) {
                    setShow(SHOW.NOTICE)
                } else {
                    setShow(SHOW.NONE)
                    actions.cancel()
                }

            })

        },

        cancel: () => {
            close()
        }
    }

    function handleNoticeResponse(confirmed) {
        if (confirmed) {
            actions.cancel()
            setShow(SHOW.NONE)
        } else {
            if (serviceStatus.success) {
                actions.cancel()
                setShow(SHOW.NONE)
            } else {
                setShow(SHOW.FORM)
            }
        }
    }

    switch (show) {
        case SHOW.NOTICE: {
            return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                           onClose={handleNoticeResponse} show={true}/>
        }
        case SHOW.SPINNER: {
            return <Spinner show={true}/>
        }
        case SHOW.FORM: {
            return <ChangePasswordForm actions={actions} userName={data.userName}/>

        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }

}

export default ChangePassword