import React from "react";
import './AreaSchedule.css'
import {Body, Header, Table,} from "@table-library/react-table-library/table";
import PeriodHeaderRow from "./table/PeriodHeaderRow";
import SkillGroup from "./table/SkillGroup";

const THEME = {
    Table: `
        height: 100%;
        border: 0;
        margin: 0;
        padding: 0;
        font-size: .05vw;
        font-weight: normal;
        color: var(--color-text);
        background-color: white;
    `,
    BaseCell: `
        border: 0;
        border-right: .1rem solid var(--hs-blue-60);
        &:nth-of-type(1) {
            left: 0;
            min-width: 30vw;
            width: 30vw;
        }
        &:nth-of-type(n+2) {
            min-width: 22vw;
            width: 22vw;
        }
        &:nth-of-type(28) {
            border: 0;
            min-width: 22vw;
            width: 22vw;
        }
    `,
    HeaderRow: `
        font-size: 1rem;
        font-weight: bold;
        color: var(--color-text);
    `,
    HeaderCell: `
        padding: .5rem .5rem;
        font-weight: bold;
        background-color: var(--hs-blue-40);
        color: var(--color-text);
        border-right: .1rem solid var(--hs-blue-60);
        height: 3.5em;
        text-align: center;
    `,
    Row: `
        font-size: 1rem;
        font-weight: normal;
        color: var(--color-text);
        border-bottom: .1rem solid var(--hs-blue-60);
    `,
    Cell: `
        padding: .5rem .5rem;
    `,
}

const AreaSchedule = ({selectedAreadId, selectedDate, skillGroups, periodDays, onEmployeeClick, format}) => {
    //console.log("AREASCHEDULE..."+JSON.stringify([selectedAreadId, selectedDate, data]))
    if (!skillGroups) skillGroups = []
    return <div id="areaSchedule">
        <Table data={{nodes: skillGroups}} theme={THEME} layout={{custom: true, horizontalScroll: true}}>
            {(tableList) => (
                <>
                    <Header>
                        <PeriodHeaderRow periodDays={periodDays}/>
                    </Header>
                    <Body>
                        {tableList.map((skillGroup) => (
                            <SkillGroup key={skillGroup.id} skillGroup={skillGroup} periodDays={periodDays}
                                        onEmployeeClick={onEmployeeClick} format={format}/>
                        ))}
                    </Body>
                </>
            )}
        </Table>
    </div>
}

export default AreaSchedule;