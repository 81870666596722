import React from "react";
import './Menu.css'
import User, {ActiveProfile} from "components/UserProfile/User";
import {Button, Nav} from "react-bootstrap";
import {toHash, toUrl, wsmUrl} from "helpers/http";
import {ActionDefs, getAction} from "actions";
import {MaxMenuFeatures} from "features";
import MenuHeader from "./MenuHeader";

export function openMenu() {
    // Disable view
    document.getElementById('layout').classList.add('disabled')
    // Show menu
    document.getElementById('menu').classList.add('active')
}

export function closeMenu() {
    // Hide menu
    document.getElementById('menu').classList.remove('active')
    // Enable view
    document.getElementById('layout').classList.remove('disabled')
}

export function closeMenuIfOpen() {
    if (document.getElementById('menu').classList.contains('active')) {
        closeMenu()
    }
}

export function toggleMenu() {
    if (document.getElementById('menu').classList.contains('active')) {
        closeMenu()
    } else {
        openMenu()
    }
}

function clickLink(onMenuSelect, linkId) {
    onMenuSelect()
    let element = document.getElementById(linkId)
    if (element) {
        const clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        })
        element.dispatchEvent(clickEvent)
    }
}

function mkFeatureLink(feature, active, onMenuSelect) {
    if (feature.url.startsWith('http')) {
        return <div key={feature.key} className={feature.key}>
            <Nav.Link href="" className={feature.key} onClick={() => clickLink(onMenuSelect, feature.key + "-link")}
                      disabled={feature.disabled}>
                <i className={"fas " + feature.icon + " nav-icon"}/>
                <span className="nav-name">{feature.title} <i className={"fas fa-external-link-alt"}/></span>
            </Nav.Link>
            <a id={feature.key + '-link'} style={{display: 'hidden'}} href={feature.url} target="_blank"
               rel="noreferrer noopener"/>
        </div>
    } else {
        return <div key={feature.key} className={feature.key}>
            <Nav.Link href={toHash(feature.url)}
                      className={feature.key + (active ? " active" : "")}
                      eventKey={feature.url} onSelect={onMenuSelect}
                      disabled={feature.disabled}>
                <i className={"fas " + feature.icon + " nav-icon"}/>
                <span className="nav-name">{feature.title}</span>
            </Nav.Link>
        </div>
    }
}

function mkSpacers(count) {
    if (count <= 0) return ""

    const arr = Array.from({length: count}, (_, index) => index + 1);
    return <>
        {arr.map((num) => {
            return <div key={"spacer" + num} className="spacer"/>
        })}
    </>
}

const mkFeatureLinks = (features, hash, onMenuSelect) => {
    if (!features || !hash || !onMenuSelect) return <></>
    const url = toUrl(hash)
    return <>
        {features.map((feature) => {
            return mkFeatureLink(feature, feature.url === url, onMenuSelect)
        })}
    </>
}

function Menu({user, features, actions, functions}) {

    return (
        <div id="menu">
            <MenuHeader clickLink={clickLink} functions={functions}/>
            <div className="banner">
                <User user={user}/>
                <ActiveProfile user={user} action={functions.onProfileSelect}/>
            </div>
            <div className='body'>
                {mkFeatureLinks(features, window.location.hash, functions.onMenuSelect)}
                {mkSpacers(MaxMenuFeatures - features.length)}
                <div className='signout'>
                    <Button className='signout' onClick={getAction(ActionDefs.SIGN_OUT.key, actions).function}>
                        Sign Out <i className={"fas fa-sign-out-alt"}></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Menu
