import React from "react";
import Button from "react-bootstrap/Button";

const Bulletin = ({message, action}) => {
    if (action) {
        return <div id='bulletin'>
            {message}
            <hr/>
            <Button variant='primary' onClick={() => action()}>OK</Button>
        </div>
    } else {
        return <div id='bulletin'>{message}</div>
    }
}

export default Bulletin