import React, {useEffect, useState} from "react"
import {useFormContext} from "react-hook-form"
import PrimaryAltPhoneVerifyForm from "./PrimaryAltPhoneVerifyForm"
import {NOTIFY_TXT_TO} from "../PhoneNotifyForm"
import {makeSendPhoneVerificationCodeStatus} from "../../UserProfileData"
import {useSendPhoneVerificationCodeQuery} from "../../UserProfileGQL"
import ServiceStatus from "../../../../helpers/serviceStatus";


const PrimaryAltPhoneVerifyControl = ({data: userProfileData, isPhoneVerified, txtNotifyMethod, getCellCarrier, setTxtVerified}) => {


    const { getValues } = useFormContext() // retrieve all hook methods

    const [showVerifyTxtDialog, setShowVerifyTxtDialog] = useState(false)

    //"txtTo" is query input variable for "SendPhoneVerificationCodeQuery" useQuery.
    //having query input variable value initialized to "undefined" on purpose to pause the execution of useQuery hook
    //This query should only be executed when "verify" button is clicked
    const [txtToQueryLatch, setTxtToQueryLatch] = useState(undefined)


    const [{fetching: fetchingPVQ, data: dataPVQ, error: errorPVQ}]
            = useSendPhoneVerificationCodeQuery(txtToQueryLatch,
            NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.yesPrimaryText ? userProfileData.phone : userProfileData.altPhone,
            getValues("cellCarrier"))

    let serviceStatus = makeSendPhoneVerificationCodeStatus(fetchingPVQ, dataPVQ, errorPVQ)
    // console.log("requestEmailVerifyCode-- serviceStatus = " + JSON.stringify(serviceStatus))

    let carrierOptions = [];
    for (let [key, value] of getCellCarrier()) {
        carrierOptions.push(<option key={key} value={key}>{value}</option>)
    }

    useEffect(() => {
        // console.log("PrimaryAltPhoneVerifyControl useEffect reset TxtToQueryLatch to undefined")
        setTxtToQueryLatch(undefined) //reset latch back to "undefined" as soon as fetch query returns result
    }, [dataPVQ])

    if( (serviceStatus !== ServiceStatus.SUCCESS) && (serviceStatus !== ServiceStatus.UNKNOWN))  {
        console.log("Error out when generating verification code: " + serviceStatus)
        return <></>
    }

    const handleVerifyButtonClick  = () => {
        //Step 1. Changing query input variable value to trigger useQuery hook executing queries
        setTxtToQueryLatch(txtNotifyMethod)

        //Step 2. bring up "Verify" dialog
        setShowVerifyTxtDialog(true)

    }


    return (
                 <PrimaryAltPhoneVerifyForm  userProfileData={userProfileData} isPhoneVerified={isPhoneVerified}
                        txtNotifyMethod={txtNotifyMethod}  carrierOptions={carrierOptions}
                        setTxtVerified={setTxtVerified} handleVerifyButtonClick={handleVerifyButtonClick}
                        showVerifyTxtDialog={showVerifyTxtDialog}  setShowVerifyTxtDialog={setShowVerifyTxtDialog}
                />


    )
}

export default PrimaryAltPhoneVerifyControl