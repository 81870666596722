import {formatInServerTZ_TimeStamp, dateToString, stringToDateTime,  formatInServerTZ_24hour, formatInServerTZ_DateTime} from "helpers/date"
import moment from "moment"
import {getParamsAtStartup} from "components/Settings/SettingsData"



export const workshiftFormat = Object.freeze({CODE_TIMES_NAME: 1, CODE_NAME: 2, CODE_TIMES: 3, CODE_AMPM_NAME: 4, CODE_LABEL : 5})


export const SwapUIMode = Object.freeze({LIST: 0, REVIEW: 1, NEARBY: 2,})

export const SwapWarningMode = Object.freeze({OVERLAP: 0, NEARBY: 1, })

export const PENDING_STATUS = "Pending"
/*
 * Initially positioned to show the first (if any) workshifts on the same date as the one to be swapped,
 * plus, at the top of the list, show the nearest workshift that occurs before that date (if any)
 */
export function positionToTargetRecipient(scrollToRef) {
    if(scrollToRef.current) {
        scrollToRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
}

export function calculateAreaId(areas, areaId) {
    if (areas) {
        let match = areas.find((item) => {
            return item.id === areaId && isOpen(item)
        })
        //"not available" area would be returning an empty string instead of areaId
        //This way query would not be issued due to the "pause" option. That is what we want
        return (match) ? match.id : ""
    } else {
        return areaId
    }
}

export function isOpen(area) {
    return (area.open && area.participating)
}

export function findAreaName(allAreas, targetAreaId){
    if(allAreas) {
        let resultArea = allAreas.find(
            area => area.id === targetAreaId
        )
        return resultArea ? resultArea.name : ""
    }else return ""
}


export function shouldProcessSwapRecipients(fetchingSRQ, swapInput ) {
    let shouldProcess = ((!fetchingSRQ) && !!swapInput
        && swapInput.recipientAreaId && !!swapInput.primarySkill)
    return shouldProcess
}


//This helper function would wrap up checkboxes selection into an array no matter what( undefined/false, a single js object, or an array)
export function buildArrFromSelectedChkboxValues(mySelections){

    let selectionsArr = []
    //Case #1: if nothing is selected. Short-circuit and an empty array would be returned
    if (mySelections) {
        //Case #2: >1 selections
        if (Array.isArray(mySelections)) {
            selectionsArr = [...mySelections]
        }
        //Case #3: Exactly 1
        else {
            selectionsArr.push(mySelections)
        }
    }
    return selectionsArr
}


export function buildIniateProposalInput(input, assignment, availableSwapRecipients, skill) {
    let swapProposalInput = {
        skill: skill,
        code: assignment.code,
        from: formatInServerTZ_TimeStamp(assignment.from),
        workMinutes: assignment.workMinutes,
        totalMinutes: assignment.totalMinutes,
        label: assignment.label,
        date: assignment.date,
        areaId: assignment.areaId,
        note: input.swapProposalNote,
    }
    let selectionsAtReview =input.submitSwapRecipient
    let selectionsAtReviewArr =  buildArrFromSelectedChkboxValues(selectionsAtReview)
    let recipientsArr = []
    if(selectionsAtReviewArr && selectionsAtReviewArr.length > 0 ) {
        selectionsAtReviewArr.forEach((uniqueKey) => {
            let recipientAssignment = availableSwapRecipients.get(uniqueKey)
            //compose a js object as swap recipient
            let singleRecipient ={
                ansosId: recipientAssignment.ansosId,
                wsCodeId: recipientAssignment.wsCodeId,
                startTimeInMilitary: formatInServerTZ_24hour(stringToDateTime(recipientAssignment.from)),
                startTimeSort: recipientAssignment.timeSort,
                workMinutes: recipientAssignment.workMinutes,
                breakMinutes: recipientAssignment.breakMinutes,
                areaId: recipientAssignment.areaId,
                wsLabel: recipientAssignment.label,
                date: dateToString(recipientAssignment.date)
            }
            recipientsArr.push(singleRecipient)
        })
    }

    swapProposalInput.recipients = recipientsArr
    return swapProposalInput


}


//if format is "1" (workshiftFormat.CODE_TIMES_NAME), then change it to "4" (CODE_AMPM_NAME);
export function translateFormat(format){
    if(format === workshiftFormat.CODE_TIMES_NAME) {
        return workshiftFormat.CODE_AMPM_NAME
    }
    else return format

}

/**
 * If this initiator assignment ends after or equal to "recipient code start time - N hours", while N is a value
 * configured as "swap.nearbyWorkshift.warningThreshold" in "wsm.properties"
 *
 * ex: Let's say N is 4. recipient code is 1pm-5pm;  initiator assignment 5am-10am same day would return true
 *      since its end time 10am is within 4 hours of the recipient code start time
 *
 * @param initAsgn
 * @param recipientAssignment
 * @returns true or false
 */
function isInitAsgnEndsNearby(initAsgn, recipientAssignment){//initAsgn ends after or equal to "recipient code start time - 4 hours"
    //rec.starttime - init.endtime <=  paramsAtStartup.swapNearbyWarningThresholdInMinutes
    let recipientStartTime = moment(stringToDateTime(recipientAssignment.from))
    let initEndTime = moment(initAsgn.to)

    let duration = moment.duration(recipientStartTime.diff(initEndTime))
    let durationInMinutes = duration.asMinutes()
    let threshold =  getParamsAtStartup().swapNearbyWarningThresholdInMinutes
    let ifEndsNearby =  (durationInMinutes>= 0 &&  durationInMinutes<= threshold)

    // console.log("computing isInitAsgnEndsNearby: recipientStartTime " + formatInServerTZ_DateTime(recipientStartTime)
    //     + ";initAsgn.to = " + formatInServerTZ_DateTime(initEndTime)
    //     +";initAsgn.date =" + dateToString(initAsgn.date)
    //     + "; initAsgn.code =" + initAsgn.code + ";duration = " + durationInMinutes + "; ifEndsNearby=" + ifEndsNearby)

    return ifEndsNearby

}

/**
 * If initiator assignment starts before or equal to "recipient code end time + N hours" same day, while N is a value
 * configured as "swap.nearbyWorkshift.warningThreshold" in "wsm.properties"
 *
 * ex: Let's say N is 4. recipient code is 1pm-5pm; initiator assignment 9pm-6am same day would return true
 *      since tts start time is within 4 hours of the recipient code end time
 *
 * @param initAsgn
 * @param recipientAssignment
 * @returns {boolean}
 */
function isInitAsgnStartsNearby(initAsgn, recipientAssignment){//initAsgn starts before or equal to "recipient code end time + 4 hours"
    //init.starttime - rec.endtime <= paramsAtStartup.swapNearbyWarningThresholdInMinutes
    let initStartTime = moment(initAsgn.from)
    let recipientEndTime = moment(stringToDateTime(recipientAssignment.to))


    let duration = moment.duration(initStartTime.diff(recipientEndTime))
    let durationInMinutes = duration.asMinutes()
    let threshold =  getParamsAtStartup().swapNearbyWarningThresholdInMinutes
    let ifStartsNearby =   (durationInMinutes>= 0 &&  durationInMinutes <= threshold)

    // console.log("computing isInitAsgnStartsNearby: initStartTime " + formatInServerTZ_DateTime(initStartTime)
    //     + ";recipientEndTime = " + formatInServerTZ_DateTime(recipientEndTime)
    //     +";initAsgn.date =" + dateToString(initAsgn.date)
    //     + "; initAsgn.code =" + initAsgn.code + ";duration = " + durationInMinutes + "; ifStartsNearby=" + ifStartsNearby)

    return  ifStartsNearby

}


/**
 * If initiator assignment overlaps recipient assignment
 * When any one of the following condition is met, then it is an overlap
 * 1. init code start time between [rec code start time,  rec code end time)
 * 2. Or rec code start time between [int code start time,  int code end time)
 * @param initAsgn
 * @param recipientAssignment
 * @returns {boolean} true if overlap
 */
function isInitAsgnOverlaps(initAsgn, recipientAssignment){

    let initStartTime = moment(initAsgn.from)
    let initEndTime = moment(initAsgn.to)

    let recipientStartTime = moment(stringToDateTime(recipientAssignment.from))
    let recipientEndTime = moment(stringToDateTime(recipientAssignment.to))

    let initStartBetweenRecipientDur  = initStartTime.isBetween(recipientStartTime, recipientEndTime, undefined, '[)')
    let initRecBetweenInitDur  =recipientStartTime.isBetween( initStartTime, initEndTime, undefined, '[)')

    let isOverlap =  (initStartBetweenRecipientDur || initRecBetweenInitDur)

    // console.log("computing isInitAsgnOverlaps: initStartTime = " + formatInServerTZ_DateTime(initStartTime)
    //     +"; initEndTime =" +  formatInServerTZ_DateTime(initEndTime)
    //     +"; recipientStartTime =" +  formatInServerTZ_DateTime(recipientStartTime)
    //     + ";recipientEndTime = " + formatInServerTZ_DateTime(recipientEndTime)
    //     +";initAsgn.date =" + dateToString(initAsgn.date)
    //     + "; initAsgn.code =" + initAsgn.code  + "; isOverlap=" + isOverlap)


    return isOverlap
}

/**
 * For every recipient assignment, find a list of initiating employee assignment/request that is within N hours;
 * If any, then put it into the map "nearbyInitEmpAsgnMap" (keyed by recipient assignment uniqueKey, value is the array)
 *
 * @param recipientAssignment
 * @param initEmpAssignmentsAndRequests
 * @param nearbyInitEmpAsgnMap
 */
export function findNearbyWorkshiftsForSingleRecipient(recipientAssignment, initEmpAssignmentsAndRequests, nearbyInitEmpAsgnMap){

    let nearbyInitAsgnArr = []
    initEmpAssignmentsAndRequests.forEach((initAsgn) => {
        if(isInitAsgnEndsNearby(initAsgn, recipientAssignment) ) {//initAsgn ends after or equal to "recipient code start time - N hours"
            let asgnCopy = {...initAsgn}
            asgnCopy.endsNearby = true
            nearbyInitAsgnArr.push(asgnCopy)
        }
        else if(isInitAsgnStartsNearby(initAsgn, recipientAssignment) ){//initAsgn starts before or equal to "recipient code end time + N hours"
            let asgnCopy = {...initAsgn}
            asgnCopy.startsNearby = true
            nearbyInitAsgnArr.push(asgnCopy)
        }
    })

    if (nearbyInitAsgnArr.length > 0) {
        nearbyInitEmpAsgnMap.set(recipientAssignment.uniqueKey, nearbyInitAsgnArr)
    }

}

/**
 * For every recipient assignment, find a list of initiating employee assignment that overlaps;
 * If any, then put it into the map "overlapInitEmpAsgnMap" (keyed by recipient assignment uniqueKey, value is the array)
 *
 * @param recipientAssignment
 * @param initEmpAssignmentsAndRequests
 * @param overlapInitEmpAsgnMap
 */
export function findOverlapWorkshiftsForSingleRecipient(recipientAssignment, initEmpAssignmentsAndRequests, overlapInitEmpAsgnMap){

    let overlapInitAsgnArr = []
    initEmpAssignmentsAndRequests.forEach((initAsgn) => {
        if(isInitAsgnOverlaps(initAsgn, recipientAssignment) ) {//initAsgn ends after or equal to "recipient code start time - 4 hours"
            let asgnCopy = {...initAsgn}
            overlapInitAsgnArr.push(asgnCopy)
        }
    })

    if (overlapInitAsgnArr.length > 0) {
        overlapInitEmpAsgnMap.set(recipientAssignment.uniqueKey, overlapInitAsgnArr)
    }

}



/**
 * This function will filter out any pending requests from incoming parameter
 * @param initEmpAssignmentsAndRequests
 * @returns assignments only
 */
export function filterOutPendingRequests(initEmpAssignmentsAndRequests){
    return   initEmpAssignmentsAndRequests.filter(
        (asgn) => {
            return (
                asgn.pendingStatus !== PENDING_STATUS /*"Pending" */
            )
        })
}