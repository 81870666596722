import React from 'react'
import Card from "react-bootstrap/Card"
import './Install.css'
import ShareIcon from "./ShareIcon";
import {Button} from "react-bootstrap";

const InstallIcon = () => {
    return <img
        className="mx-auto"
        style={{
            borderRadius: '25%',
            backgroundColor: '#fff',
            marginTop: '-50px'
        }}
        width="100px"
        src="images/icons/icon-192x192.png"
        alt="Icon"
    />
}

export const InstalliOSPromotionCard = ({onContinue, onClose}) => {
    return <Card>
        <InstallIcon/>
        <Card.Body>
            <Card.Title className="text-center">
                <h1>Install ANSOS2Go</h1>
            </Card.Title>
            <div className="content">
                <p>Install ANSOS2Go to enjoy key functionality of Web Scheduler on a mobile device</p>
                <ul>
                    <li>Employees may view their schedules, submit Work, Time Off and Available Shift Requests as well
                        as Swap assignments with other employees
                    </li>
                    <li>Scheduling Administrators may view their Area schedules and contact employees with the touch of
                        a button
                    </li>
                </ul>
                <p>To install ANSOS2Go, you must access the ANSOS2Go URL from <b>Safari</b>.</p>
                <p>If you are using Safari, tap <b>Continue</b> and follow the instructions for installing the app.</p>
                <p>If you are not using Safari, tap <b>Remind Me Later</b>. Then open <b>Safari</b>, and enter the
                    ANSOS2Go URL
                    and tap <b>Continue</b> when prompted to install ANSOS2Go. </p>
            </div>
            <div className="controls text-center">
                <Button onClick={onContinue}> Continue </Button>
                <Button onClick={onClose}> Remind Me Later </Button>
            </div>
        </Card.Body>
    </Card>
}

export const InstallAndroidPromotionCard = ({onContinue, onClose}) => {
    return <Card>
        <InstallIcon/>
        <Card.Body>
            <Card.Title className="text-center">
                <h1>Install ANSOS2Go</h1>
            </Card.Title>
            <div className="content">
                <p>Install ANSOS2Go to enjoy key functionality of Web Scheduler on a mobile device</p>
                <ul>
                    <li>Employees may view their schedules, submit Work, Time Off and Available Shift Requests as well
                        as Swap assignments with other employees
                    </li>
                    <li>Scheduling Administrators may view their Area schedules and contact employees with the touch of
                        a button
                    </li>
                </ul>
                <p>To install ANSOS2Go, you must access the ANSOS2Go URL from <b>Google Chrome</b>.</p>
                <p>If you are using Google Chrome, tap <b>Continue</b> and follow the instructions for installing the
                    app.</p>
                <p>If you are not using Google Chrome, tap <b>Remind Me Later</b>. Then open Google Chrome, enter the
                    ANSOS2Go URL
                    and tap <b>Continue</b> when prompted to install ANSOS2Go.</p>
            </div>
            <div className="controls text-center">
                <Button onClick={onContinue}> Continue </Button>
                <Button onClick={onClose}> Remind Me Later </Button>
            </div>
        </Card.Body>
    </Card>
}

export const InstalliOSStepsCard = ({onClose}) => {
    return <Card>
        <button type="button" className="close" onClick={onClose} aria-label="Close">
            <span>&times;</span>
        </button>
        <InstallIcon/>
        <Card.Body>
            <div className="content">
                <ol>
                    <li>In Safari, tap <ShareIcon className="pwaPromptShareIcon"/> to open the
                        Safari menu.</li>
                    <li>Select <b>Add to Home Screen</b>.</li>
                    <li>Tap <b>Add</b> when prompted.</li>
                    <li>Safari will close automatically.</li>
                    <li>Open ANSOS2Go from the Home Screen.</li>
                    <li>Sign into ANSOS2Go.</li>
                </ol>
            </div>
        </Card.Body>
    </Card>
}

export const InstallAndroidStepsCard = ({onClose}) => {
    return <Card>
        <button type="button" className="close" onClick={onClose} aria-label="Close">
            <span>&times;</span>
        </button>
        <InstallIcon/>
        <Card.Body>
            <div className="content">
                <ol>
                    <li>In Google Chrome, tap <i className="fas fa-ellipsis-v"></i> to open the Chrome menu.</li>
                    <li>Select <b>Install App</b> or <b>Add to Home Screen</b>, if <b>Install App</b> is not an option.</li>
                    <li>Follow the prompts to install ANSOS2Go.</li>
                    <li>Close ANSOS2Go in Google Chrome.</li>
                    <li>Open ANSOS2Go from the Home Screen.</li>
                    <li>Sign into ANSOS2Go.</li>
                </ol>
            </div>
        </Card.Body>
    </Card>
}

export const UpdatedCard = ({onContinue}) => {
    return <Card>
        <InstallIcon/>
        <Card.Body>
            <Card.Title className="text-center">
                <h1>Update ANSOS2Go</h1>
            </Card.Title>
            <div className="content">
                A new version of ANSOS2Go is available
            </div>
            <div className="controls text-center">
                <Button onClick={onContinue}> Continue </Button>
            </div>
        </Card.Body>
    </Card>
}

const copyLocationToClipboard = () => {
    navigator.clipboard.writeText(window.location).then(function () {
        /* clipboard successfully set */
        console.log("Writing URL to clipboard was successful")
        alert(window.location + " copied to clipboard")
    }, function () {
        /* clipboard write failed */
        console.log("ERROR: Writing URL to clipboard FAILED")
    });
}

export const InstallNotSupportedCard = ({browser, supportedBrowser}) => {
    return <Card>
        <InstallIcon/>
        <Card.Body>
            <div className="text-center">
                <div> You are currently using the {browser} browser. This browser does not support app installation
                    (i.e.
                    Add to Home Screen).
                </div>
                <ol>
                    <li>Tap <i className="fas fa-copy" onClick={copyLocationToClipboard}></i> to copy URL</li>
                    <li>Close this page</li>
                    <li>Open the {supportedBrowser} browser</li>
                    <li>Paste in the URL copied above</li>
                </ol>
            </div>
        </Card.Body>
    </Card>
}
