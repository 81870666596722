import * as React from "react"
import {Cell, Row,} from "@table-library/react-table-library/table"
import {RowTypes} from "../AreaScheduleData"
import EmployeeRow from "./EmployeeRow"
import CountRow from "./CountRow"
import HoursRow from "./HoursRow"

const renderSkillGroupRow = (skill, row, periodDays, onEmployeeClick, format) => {
    switch (row.type) {
        case RowTypes.EMPLOYEE:
            return <EmployeeRow key={row.employee.id} employee={row.employee} dayAssignments={row.dayAssignments}
                                periodDays={periodDays} onEmployeeClick={onEmployeeClick} format={format}/>
        case RowTypes.COUNT:
            return <CountRow key={"count"} dayCounts={row.dayCounts} periodDays={periodDays}/>
        case RowTypes.HOURS:
            return <HoursRow key={"hours"} dayHours={row.dayHours} periodDays={periodDays}/>
        default:
            return <></>
    }
}

/**
 * Renders Skill banner followed by one line for each employee with skill including assignments.
 * After rendering all employees, count and hours lines are rendered.
 *
 * @param data = {id:skill, name:skillName, rows: [EMPLOYEE_ROW(s), COUNT_ROW, HOURS_ROW]}
 *      EMPLOYEE_ROW= {type: EMPLOYEE, employee:employee, dayAssignments: [[assignment,...],...28]}
 *      COUNT_ROW= {type:COUNT, dayCounts: [count,...28]}
 *      HOURS_ROW= {type:HOURS, dayHours: [hours,...28]}
 * @param periodDays [{day: day, date: eachDate, dow: "Saturday", mmmd: "Mar 20", className: className},...28]
 * @param onEmployeeClick callback function for when employee name cell is clicked
 * @returns {JSX.Element}
 * @constructor
 */
function SkillGroup({skillGroup, periodDays, onEmployeeClick, format}) {

    return <>
        <Row className="skill">
            <Cell className="rowHeader" pinLeft>{skillGroup.id}:{skillGroup.name}</Cell>
            {periodDays.map((periodDay, day) => (
                <Cell key={day} className="day"/>
            ))}
        </Row>
        {skillGroup.rows.map(row => renderSkillGroupRow(skillGroup.id, row, periodDays, onEmployeeClick, format))}
    </>
}

export default SkillGroup
