import React  from 'react'
import {SWAPTYPE} from "./SwapStatusData"
import ProposalAsRecipientForm from "./ProposalAsRecipientForm"
import ProposalAsInitiatorForm from "./ProposalAsInitiatorForm"
import SwapRequestForm from "./SwapRequestForm"
import "./swapStatus.css"
import "../swaps.css"

const SwapStatusControl = ({swapProposalOrRequest, areas, refresh}) => {

    let show = swapProposalOrRequest.type

    switch (show) {
        case SWAPTYPE.proposalAsRecipient: {
            return (
                <ProposalAsRecipientForm proposalAsRecipient={swapProposalOrRequest}  areas={areas} />
            )
        }
        case SWAPTYPE.proposalAsInitiator: {
            return (
                <ProposalAsInitiatorForm  proposalAsInitiator={swapProposalOrRequest} areas={areas} refresh={refresh} />
            )
        }
        case SWAPTYPE.swapRequest: {
            return (
                   <SwapRequestForm swapRequest={swapProposalOrRequest} areas={areas} />
            )

        }
        default: {
            return <></>
        }
    }


}



export default SwapStatusControl