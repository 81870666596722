import React from "react"
import Modal from 'react-bootstrap/Modal'
import Form from "react-bootstrap/Form";
import {RequestTypeTitle} from "./Request";
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";
import {formatWorkshift} from 'helpers/workshift'
import {minutesToDurationInHours,  stringToDate, weekdayAndDayOfMonthAndYear} from "helpers/date"
import {getArea} from "helpers/area"

const AvailshiftControl = ({workshift, areas, allArea, register, onSelect}) => {
    let wsText = formatWorkshift(workshift)
    let compSkillExists = !!workshift.skill

    let wsArea = getArea(areas, workshift.areaId)

    return (
        <div className="avlShift flexDisplay" onClick={()=> onSelect(workshift)}>
            <div className="avlShiftRadio">
                <label className="hsRadio">
                    <input type="radio"
                           name="workshiftId"
                           value={workshift.workshiftId}
                           ref={register({required: true})}
                           onClick={(event) => onSelect(workshift)}
                    />
                    <span className="radioCheckMark" onClick={()=> onSelect(workshift)}> </span>

                </label>
            </div>

            <div className="avlShiftDetail">
                <table>
                    <tbody>
                    <tr>
                        <td colSpan={"2"}>
                            <span
                                className="boldFont">{wsText.substring(0, 2)}</span>&nbsp;{wsText.substring(2)} &nbsp;({minutesToDurationInHours(workshift.workMinutes)}hrs)
                        </td>
                    </tr>

                    <tr>
                        <td className={"catCol"}>
                            <span className="boldFont">Category:&nbsp;</span>
                            <span>{workshift.category}</span>
                        </td>
                        {compSkillExists &&
                        <td>
                            <span className="boldFont">Skill:&nbsp;</span>
                            <span>{workshift.skill}</span>
                        </td>
                        }

                        {!compSkillExists && <td>&nbsp;</td>}

                    </tr>


                    {allArea &&
                    <>
                        <tr>
                            <td colSpan={"2"}>
                                <span className="boldFont">Area:&nbsp;</span>
                                <span>{wsArea && wsArea.id+" "+wsArea.name}</span>
                            </td>

                        </tr>
                        <tr>
                            <td colSpan={"2"}>
                                <span className="boldFont">Approval Required:&nbsp;</span>
                                <span>{workshift.requiresApproval ? "Yes" : "No"}</span>
                            </td>
                        </tr>
                    </>
                    }
                    </tbody>
                </table>
            </div>
        </div>


    )
}

const AvailshiftListForm = ({request, areas, selectedArea, actions, requestForm, onSubmit}) => {

    const {register, handleSubmit, errors, setValue} = useForm()
    let workshifts = requestForm.workshifts
    let areaName = requestForm.allArea ? "All Areas" : selectedArea.name

    const onSelect = (workshift) => {
        setValue("selectedWsAreaId", workshift.areaId)
        setValue("workshiftId", workshift.workshiftId)
    }

    return (
        <Modal centered show={true} backdrop="static" onHide={actions.cancel}>
            <Form id='avlRequestForm' onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>{RequestTypeTitle[request.requestType]} Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="avlBigLabel alignCenter">{areaName}<br/>
                        {weekdayAndDayOfMonthAndYear(stringToDate(request.startDate))}<br/>
                        {(!requestForm.allArea) && (!requestForm.selfScheduled) && <span>Approval Required</span>}
                    </div>

                    <span className="avlBigLabel">Select a workshift to submit:</span>
                    <div id="avlWorkshiftDiv">

                        {workshifts && workshifts.length > 0
                        && workshifts.map((workshift, idx) => {
                            return (
                                <AvailshiftControl workshift={workshift} areas={areas} allArea={requestForm.allArea}
                                                   key={idx} register={register} onSelect={onSelect}/>
                            )
                        })
                        }
                    </div>
                    {errors.workshiftId && <><p className="error">You must select a workshift</p></>}

                    <Form.Group controlId="requestNote">
                        <Form.Label>Note</Form.Label>
                        <Form.Control as="textarea" name="note" placeholder="Enter a note (optional)"
                                      defaultValue={request.note}
                                      ref={register}/>
                    </Form.Group>


                    <input type="hidden" name="startDate" ref={register} defaultValue={request.startDate}/>
                    <input type="hidden" name="endDate" ref={register} defaultValue={request.endDate}/>
                    <input type="hidden" name="selectedWsAreaId" ref={register} defaultValue=""/>


                </Modal.Body>

                <Modal.Footer>
                    <Button type="submit" value="Submit">Submit</Button>
                    <Button variant="secondary" onClick={actions.cancel}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AvailshiftListForm