import moment from "moment";

export function getPeriod(periods, periodFrom) {
    let period = null
    if (periods) period = periods.find((item) => {
        return item.from === periodFrom
    })
    if (period) {
        //console.log("getPeriod(" + periodFrom + ") >> " + JSON.stringify(period))
    } else {
        console.log(periodFrom + " not found in periods >> " + JSON.stringify(periods))
    }
    return period
}

export function isPeriodArchived(period) {
    let today = moment()
    let periodEnd = moment(period.to)
    return periodEnd.isBefore(today)
}

export function isPeriodPublished(period) {
    return period.published && !period.balancing
}
export function periodsPublished(periods) {
    let count = 0
    periods.forEach(period => {
        count += isPeriodPublished(period)
    })
    return count
}