import React, {useState} from 'react'
import UserProfile from "components/UserProfile"
import {useUserProfileQuery} from "components/UserProfile/UserProfileGQL"
import {emptyUserProfile, processUserProfileQueryResult} from "components/UserProfile/UserProfileData";
import {toError} from "helpers/serviceStatus";
import {useViewClasses} from "./View";
import {redirectToFeature} from "helpers/http";
import Features, {getDefaultFeature} from "features"
import Notice from "../Notice"



const UserProfileView = ({layout, setUserProfileDirty}) => {
    console.log('UserProfileView...')
    const [{fetching: fetchingUPQ, data: dataUPQ, error: errorUPQ}] = useUserProfileQuery()
    const [userProfile, serviceStatus] = processUserProfileQueryResult(fetchingUPQ, dataUPQ, errorUPQ)

    console.log(userProfile)


    let shouldPromptEmail = shouldPrompt()
    const [showEmailPromptNotice, setEmailPromptNotice] = useState(shouldPromptEmail)

    const EMAIL_PROMPT_MSG = "Please take a moment to set and verify an email address for your account. This will allow you to reset your password if you forget it someday."

    function shouldPrompt(){
        const PROMPT_EMAIL_QUERY_STR = "?isItTimeToAskUserForValidEmail=true"
        return window.location.hash.includes(PROMPT_EMAIL_QUERY_STR)
    }

    function handleEmailPromptResponse(confirmed) {
        setEmailPromptNotice(false)
        if (!confirmed) {
            //redirect to default feature
            // redirectToFeature(Features.DEFAULT)
            let feature = getDefaultFeature(userProfile)
            redirectToFeature(feature)
        }
        //otherwise stay in "User Profile" screen

    }

    let body = (
        <div id="userView">
            <Notice message={EMAIL_PROMPT_MSG}
                    onClose={handleEmailPromptResponse}
                    labels={["OK", "Remind me later"]}
                    show={showEmailPromptNotice}/>
            <UserProfile data={userProfile?userProfile:emptyUserProfile()}
                         setUserProfileDirty={setUserProfileDirty}
                         shouldOpenContact={showEmailPromptNotice}
            />
        </div>
    )

    return layout({body, error: toError(serviceStatus), classes:useViewClasses()})
}

export default UserProfileView