import React, {useEffect, useState} from 'react'
import {useFormContext} from "react-hook-form"
import {useSendPhoneVerificationCodeQuery} from "../../UserProfileGQL"
import OtherPhoneVerifyForm from "./OtherPhoneVerifyForm"
import {makeSendPhoneVerificationCodeStatus} from "../../UserProfileData"
import ServiceStatus from "../../../../helpers/serviceStatus"


const OtherPhoneVerifyControl = ({data: userProfileData, name, txtNotifyMethod, setTxtVerified, isOtherPhoneInitiallyVerified,
    isOtherPhoneCurrentlyVerified, getCellCarrier}) => {

    const { getValues } = useFormContext()

    const [showVerifyTxtDialog, setShowVerifyTxtDialog] = useState(false)

    //"txtToQueryLatch" is a latch so that "SendPhoneVerification" useQuery only gets executed on "verify" button click.
    //useQuery hook accepts a pause option that temporarily freezes all changes and stops requests.
    // React Hooks has to be top-level and could not be put under "if" condition. "pause" is how we get around it.
    //This way, this query should only be executed when "verify" button is clicked
    const [txtToQueryLatch, setTxtToQueryLatch] = useState(undefined)

    const [{fetching: fetchingPVQ, data: dataPVQ, error: errorPVQ}]
            = useSendPhoneVerificationCodeQuery(txtToQueryLatch,
            getValues(name),
            getValues("cellCarrier"))

    let serviceStatus = makeSendPhoneVerificationCodeStatus(fetchingPVQ, dataPVQ, errorPVQ)
    // console.log("requestEmailVerifyCode-- serviceStatus = " + JSON.stringify(serviceStatus))


    let carrierOptions = [];
    for (let [key, value] of getCellCarrier()) {
        carrierOptions.push(<option key={key} value={key}>{value}</option>)
    }

    useEffect(() => {
        // console.log("OtherPhoneVerifyControl useEffect reset TxtToQueryLatch to undefined")
        setTxtToQueryLatch(undefined) //reset latch back to "undefined" as soon as fetch query returns result
    }, [dataPVQ])

    if( (serviceStatus !== ServiceStatus.SUCCESS) && (serviceStatus !== ServiceStatus.UNKNOWN))  {
        console.log("Error out when generating verification code: " + serviceStatus)
        return <></>
    }

    const handleVerifyButtonClick  = () => {
        //Step 1. Changing query input variable value to trigger useQuery hook executing queries
        setTxtToQueryLatch(txtNotifyMethod)

        //Step 2. bring up "Verify" dialog
        setShowVerifyTxtDialog(true)

    }


    return (
                <OtherPhoneVerifyForm  userProfileData={userProfileData} name={name}
                        txtNotifyMethod={txtNotifyMethod}  carrierOptions={carrierOptions}
                        isOtherPhoneInitiallyVerified={isOtherPhoneInitiallyVerified}
                        isOtherPhoneCurrentlyVerified={isOtherPhoneCurrentlyVerified}
                        setTxtVerified={setTxtVerified} handleVerifyButtonClick={handleVerifyButtonClick}
                        showVerifyTxtDialog={showVerifyTxtDialog}  setShowVerifyTxtDialog={setShowVerifyTxtDialog}
                />
    )

}

export default OtherPhoneVerifyControl