import React, {useEffect} from 'react'
import {createAssignmentEvents} from "components/Calendar/CalendarEvents"
import FullCalendar from "@fullcalendar/react"
import listPlugin from "@fullcalendar/list"
import bootstrapPlugin from '@fullcalendar/bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'fontawesome-free/css/all.css'
import "components/Calendar/Calendar.css"
import Assignment from "components/Assignment"
import {useCalendarQuery} from "components/Calendar/CalendarGQL"
import {processCalendarData} from "components/Calendar/CalendarData"
import {useStoreActions} from "Store"
import {getSearchParam} from "helpers/http"
import {getServerInfo} from "components/Settings/SettingsData"
import './Assignments.css'
import Retract from "../Retract/Retract";
import Swap from "../Swaps";

const Assignments = () => {
    const [{fetching: fetchingCD, data: dataCD, error: errorCD}] = useCalendarQuery()
    const {days, serviceStatus} = processCalendarData(fetchingCD, dataCD, errorCD)
    const storeActions = useStoreActions()

    // Setting startDate to include previous 3 days of assignments to
    // allow current to date to positioned somewhere close to the middle of
    // first page.
    const startDate = new Date().addDays(-3)
    startDate.setHours(0, 0, 0, 0) // make start of day
    const endDate = startDate.addDays(999) // big number to get all future assignments

    function createEvents(days) {
        if (!days) days = new Map()
        return createAssignmentEvents(days, startDate)
    }

    const assignmentEvents = (() => {
        if (days) {
            console.log("creating assignment events...date is " + startDate)
            return createEvents(days)
        } else {
            return []
        }
    })()

    function handleEventClick(eventInfo) {
        storeActions.updateAssignment(eventInfo.event.extendedProps.assignment)
    }

    function getAssignmentEvent(assignmentEvents, id) {
        return assignmentEvents.find((event) => event && event.assignment && event.assignment.id === id)
    }

    useEffect(() => {
        console.log("Check for assignment id parameter...")
        if (assignmentEvents) {
            const assignmentId = getSearchParam('id')
            console.log(assignmentId ? "id=" + assignmentId : "No id parameter found")
            if (assignmentId) {
                const event = getAssignmentEvent(assignmentEvents, assignmentId)
                console.log(event ? "Found assignment" : "Did not find assignment in assignmentEvents[" + assignmentEvents.length + "]")
                if (event) storeActions.updateAssignment(event.assignment)
            }
        }
    }, [assignmentEvents])

    return <div id="assignments" style={{border: 0, margin: 0, padding: 0}}>
        <Assignment/>
        <Retract/>
        <Swap/>
        <FullCalendar
            plugins={[listPlugin, bootstrapPlugin]}
            initialView='listYear'
            visibleRange={{start: startDate, end: endDate}}
            headerToolbar={{
                left: '',
                center: '',
                right: ''
            }}
            allDayText=''
            listDayFormat={{ // will produce something like "Tuesday, September 18, 2018"
                month: 'long',
                year: 'numeric',
                day: 'numeric',
                weekday: 'long'
            }}
            listDaySideFormat={false}
            themeSystem='bootstrap'
            bootstrapFontAwesome={{
                prev: 'arrow-circle-left',
                next: 'arrow-circle-right',
                today: 'circle'
            }}
            initialDate={startDate}
            timeZone={getServerInfo().timezone}
            events={assignmentEvents}
            noEventsContent='No Assignments'
            eventClick={handleEventClick}
            height="auto"
        />
    </div>
}

export default Assignments