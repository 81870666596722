import React, {useEffect, useRef} from "react";
import {useSwapRecAssignmentsQuery} from "../SwapGQL";
import {findTargetRecipientToPosition, processSwapRecAssignmentsData} from "./SwapInitiateProposalData"
import {formatInServerTZ_TimeStamp} from "helpers/date"
import {
    filterOutPendingRequests,
    findAreaName,
    findNearbyWorkshiftsForSingleRecipient,
    findOverlapWorkshiftsForSingleRecipient,
    positionToTargetRecipient,
    shouldProcessSwapRecipients,
    SwapUIMode
} from "./swapInitiateProposalHelper"
import SwapRecAssignmentControl from "./SwapRecAssignmentControl"
import {useStoreState} from "../../../Store"
import {toError} from "helpers/serviceStatus"
import {relaunch} from "helpers/http"
import Notice from "components/Notice"
import Spinner from "../../Spinner";


const SwapListRecipients = ({
                                primarySkill, compSkill, recipientAreaId, recipientAreas,
                                selectedSwapRecipient,
                                availableSwapRecipients, setAvailableSwapRecipients,
                                initEmpAssignmentsAndRequests, nearbyInitEmpAsgnForRecipientsMap,
                                setNearbyInitEmpAsgnForRecipientsMap, handleListCheckChange,
                                overlapInitEmpAsgnForRecipientsMap, setOverlapInitEmpAsgnForRecipientsMap
                            }) => {

    const state = useStoreState()
    const assignment = {...state.assignment}
    const selectedSwapRecipientControlName = "selectedSwapRecipient"

    const scrollToRef = useRef()//this is for scroll into a recipient that is closest to initiating assignment

    //at overlapping calculation time, only consider assignments, not pending requests. Let's filter out pending requests
    let initEmpAssignments = filterOutPendingRequests(initEmpAssignmentsAndRequests)

    //Step #1: fetch swappable recipient assignments for (ONE area, one period)(GraphQL Query #2)
    let fetchSwapRecipientsInput = {
        "primarySkill": primarySkill,
        "compSkillSetStr": compSkill,
        "code": assignment.code,
        "from": formatInServerTZ_TimeStamp(assignment.from),
        "to": formatInServerTZ_TimeStamp(assignment.to),
        "workMinutes": assignment.workMinutes,
        "totalMinutes": assignment.totalMinutes,
        "label": assignment.label,
        "date": assignment.date,
        "areaId": assignment.areaId,
        "recipientAreaId": recipientAreaId
    }

    let swapInputVariables = {"swapRecipientAssignmentsInput": fetchSwapRecipientsInput}
    const [{fetching: fetchingSRQ, data: dataSRQ, error: errorSRQ}] = useSwapRecAssignmentsQuery(swapInputVariables)

    //Step #2: Process results from GraphQL Query #2
    //"swapRecAssignmentMapByDay" is a map keyed by date string, value is an array of assignments for this day
    let swapRecAssignmentMapByDay = null
    //"swapRecAssignmentMapByUniqueKey" is a map keyed by a unique string to identify this swap assignment, value is this assignment
    let swapRecAssignmentMapByUniqueKey = null
    let fetchRecipientsServiceStatus = null
    let recipientAreaName = findAreaName(recipientAreas, recipientAreaId)

    if (shouldProcessSwapRecipients(fetchingSRQ, fetchSwapRecipientsInput)) {
        ({
            swapRecAssignmentMapByDay,
            swapRecAssignmentMapByUniqueKey,
            fetchRecipientsServiceStatus
        } = processSwapRecAssignmentsData(fetchingSRQ, dataSRQ, errorSRQ, recipientAreaName))
    }

    let allRecipients = swapRecAssignmentMapByDay ? [...swapRecAssignmentMapByDay.values()].flat() : []

    //Step #3: this "useEffect" is to position the swappable recipients relative to initiating ws. Run only ONCE
    useEffect(() => {
        //Initially positioned to show the first (if any) workshifts on the same date as the one to be swapped,
        // plus, at the top of the list, show the nearest workshift that occurs before that date (if any)
        positionToTargetRecipient(scrollToRef)
    }, [fetchRecipientsServiceStatus])

    //Step #4: set "AvailableSwapRecipients" & nearbyWorkshifts & overlapWorkshifts; It only runs when "fetchRecipientsServiceStatus" changes
    useEffect(() => {
        //a) append recipient for this area to existing "AvailableSwapRecipients" (so we keep recipients for other areas around)
        updateAvailableSwapRecipients()

        //b) For every recipient assignment, find nearBy initiating employee assignments/requests
        updateNearbyWorkshiftsForRecipients()

        //c) For every recipient assignment, find overlapping initiating employee assignments
        updateOverlapWorkshiftsForRecipients()

    }, [fetchRecipientsServiceStatus])


    //For every recipient assignment (in this area), find nearBy initiating employee assignments/requests,
    //then set state "nearbyInitEmpAsgnForRecipientsMap" accordingly
    function updateNearbyWorkshiftsForRecipients() {
        //We should only append. This way we keep calculations  from other areas around (we need those in "review")
        let nearbyInitEmpAsgnMapForRecipientsOneArea = findNearbyWorkshiftsForRecipientsOneArea()

        let prevNearbyInitEmpAsgnForRecipientsMap = nearbyInitEmpAsgnForRecipientsMap
        let currNearbyInitEmpAsgnMapForRecipients = new Map(prevNearbyInitEmpAsgnForRecipientsMap)
        //now append "nearbyInitEmpAsgnMapForRecipientsOneArea" to current Map
        if (nearbyInitEmpAsgnMapForRecipientsOneArea && nearbyInitEmpAsgnMapForRecipientsOneArea.size > 0) {
            let keys = [...nearbyInitEmpAsgnMapForRecipientsOneArea.keys()]
            keys.forEach((key) => {
                    currNearbyInitEmpAsgnMapForRecipients.set(key, nearbyInitEmpAsgnMapForRecipientsOneArea.get(key))
                }
            )
        }
        setNearbyInitEmpAsgnForRecipientsMap(currNearbyInitEmpAsgnMapForRecipients)
    }

    //For every recipient assignment (in this area), find overlapping initiating employee assignments/requests,
    //then set state "overlapInitEmpAsgnForRecipientsMap" accordingly
    function updateOverlapWorkshiftsForRecipients() {
        //We should only append. This way we keep calculations  from other areas around (we need those in "review")
        let overlapInitEmpAsgnMapForRecipientsOneArea = findOverlapWorkshiftsForRecipientsOneArea()


        let prevOverlapInitEmpAsgnForRecipientsMap = overlapInitEmpAsgnForRecipientsMap
        let currOverlapInitEmpAsgnMapForRecipients = new Map(prevOverlapInitEmpAsgnForRecipientsMap)

        //now append "overlapInitEmpAsgnMapForRecipientsOneArea" to current Map
        if (overlapInitEmpAsgnMapForRecipientsOneArea && overlapInitEmpAsgnMapForRecipientsOneArea.size > 0) {
            let keys = [...overlapInitEmpAsgnMapForRecipientsOneArea.keys()]
            keys.forEach((key) => {
                    currOverlapInitEmpAsgnMapForRecipients.set(key, overlapInitEmpAsgnMapForRecipientsOneArea.get(key))
                }
            )
        }
        setOverlapInitEmpAsgnForRecipientsMap(currOverlapInitEmpAsgnMapForRecipients)

    }

    function findOverlapWorkshiftsForRecipientsOneArea() {
        //key: recipient Unique Key (which represents one rec assignment); value: array of initiator assignment that overlaps corresponding rec assignment
        let overlapInitEmpAsgnMapForRecipientsOneArea = new Map()

        allRecipients.forEach((recipientAssignment) => {
            findOverlapWorkshiftsForSingleRecipient(recipientAssignment,
                initEmpAssignments,
                overlapInitEmpAsgnMapForRecipientsOneArea)
        })

        return overlapInitEmpAsgnMapForRecipientsOneArea

    }

    function findNearbyWorkshiftsForRecipientsOneArea() {
        //key: recipient Unique Key(which represents one rec assignment); value: array of initiator assignment/request that is within N hours
        let nearbyInitEmpAsgnMapForRecipientsOneArea = new Map()

        allRecipients.forEach((recipientAssignment) => {
            findNearbyWorkshiftsForSingleRecipient(recipientAssignment,
                initEmpAssignmentsAndRequests,
                nearbyInitEmpAsgnMapForRecipientsOneArea)
        })

        return nearbyInitEmpAsgnMapForRecipientsOneArea
    }


    //append all the recipients in this area to existing "available swap recipients"
    function updateAvailableSwapRecipients() {
        //We should only append to "available swap recipients". This way we keep recipients from other areas around
        let prevAvailableSwapRecipients = availableSwapRecipients
        let currAvailableSwapRecipients = new Map(prevAvailableSwapRecipients)
        if (swapRecAssignmentMapByUniqueKey && swapRecAssignmentMapByUniqueKey.size > 0) {
            let keys = [...swapRecAssignmentMapByUniqueKey.keys()]
            keys.forEach((key) => {
                    currAvailableSwapRecipients.set(key, swapRecAssignmentMapByUniqueKey.get(key))
                }
            )
        }
        setAvailableSwapRecipients(currAvailableSwapRecipients)
    }


    // If error encountered, display an error notice
    if (fetchRecipientsServiceStatus) {
        const error = toError(fetchRecipientsServiceStatus)
        if (error) {
            return <Notice message={error.message} type={error.noticeType}
                           onClose={relaunch} show={true}/>

        }
    }


    //Step #5: render
    return (
        <div id={"swapRecipientsDiv"}>
            {swapRecAssignmentMapByDay && allRecipients.length > 0
            && allRecipients.map((swapRecAssignment) => {
                return <SwapRecAssignmentControl swapRecAssignment={swapRecAssignment}
                                                 scrollToRef={scrollToRef}
                                                 name={selectedSwapRecipientControlName}
                                                 key={swapRecAssignment.uniqueKey}
                                                 selectedSwapRecipient={selectedSwapRecipient}
                                                 targetRecipientToPosition={findTargetRecipientToPosition(assignment.date, swapRecAssignmentMapByDay)}
                                                 mode={SwapUIMode.LIST}
                                                 onCheckChange={handleListCheckChange}
                                                 nearbyInitEmpAsgn={nearbyInitEmpAsgnForRecipientsMap.get(swapRecAssignment.uniqueKey)}
                />
            })
            }

            {(swapRecAssignmentMapByDay && allRecipients.length < 1) &&
            <div className={"initSwapLabel"}> No workshifts can be found. Please change Work Date or Work Area.</div>
            }

            {!swapRecAssignmentMapByDay &&
            <div className="initSwapFetching"><Spinner show={true}/></div>
            }
        </div>
    )


}


export default SwapListRecipients