
export const PLATFORM = Object.freeze({
    IOS: "iOS",
    ANDROID: "Android",
    BLACKBERRY: "BlackBerry",
    SYMBIAN: "Symbian",
    WEBOS: "webOS",
    WINDOWS: "Windows Phone",
    OTHER: "Other",
    UNKNOWN: "Unknown"
})

export function isIPad() {
    return !!window.navigator.userAgent.match(/iPad/i)
}

export function isIPhone() {
    return !!window.navigator.userAgent.match(/iPhone/i)
}

export function isIOS() {
    return isIPad() || isIPhone()
}

/**
 * For now just return either IOS or ANDROID
 * @returns {string}
 */
export function platformIs() {
    return isIOS()?PLATFORM.IOS:PLATFORM.ANDROID
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function platformOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

    if(platform && platform.toUpperCase().indexOf('WIN')>=0){
        return PLATFORM.WINDOWS
    }

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return PLATFORM.WINDOWS;
    }

    if (/android/i.test(userAgent)) {
        return PLATFORM.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return PLATFORM.IOS;
    }

    return PLATFORM.UNKNOWN;
}

export function isIOSOrWindows(){
    const os = platformOS();
    if(os===PLATFORM.IOS || os===PLATFORM.WINDOWS){
        return true;
    }

    return false;
}
