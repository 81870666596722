import React from "react"
import {NOTIFY_TXT_TO} from "../PhoneNotifyForm"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import VerifyEmailOrPhone, {VERIFY_TYPE} from "./VerifyEmailOrPhone"
import {useFormContext} from "react-hook-form"
import If from "components/If"


const PrimaryAltPhoneVerifyForm = ({
                                       userProfileData,
                                       isPhoneVerified,
                                       txtNotifyMethod,
                                       carrierOptions,
                                       setTxtVerified,
                                       handleVerifyButtonClick,
                                       showVerifyTxtDialog,
                                       setShowVerifyTxtDialog
                                   }) => {

    const {register, setError, errors, getValues} = useFormContext() // retrieve all hook methods

    //This validation should only be triggered at "Verify" button click, not at User Profile "Submit"
    const validateCarrierOnTrigger = () => {
        let carrier = getValues("cellCarrier")
        let isValid = false

        if (isPhoneVerified() || (carrier !== 'Carrier')) {
            isValid =  true
        }

        if(!isValid) {
            setError("cellCarrier", {
                type: "manual",
                message: 'Please select a carrier'
            })
        }

        return isValid

    }


    return (

        <>
            {NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.yesPrimaryText &&
            <Form.Group>
                <Form.Label>{userProfileData.phone}</Form.Label>
            </Form.Group>
            }

            {NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.yesAlternateText &&
            <Form.Group>
                <Form.Label>{userProfileData.altPhone}</Form.Label>
            </Form.Group>
            }


            <Form.Group>
                <Form.Control as="select"
                              name="cellCarrier"
                              ref={register}
                              onChange={() => {
                                  if (isPhoneVerified()) {
                                      setTxtVerified(false)
                                  }
                              }}
                >{carrierOptions}</Form.Control>
            </Form.Group>
            {errors.cellCarrier && <p className="error">{errors.cellCarrier.message}</p>}


            {isPhoneVerified() &&
            (<Form.Group className="floatRight"><span className="formLabel">
                        <i className="fas fa-check"></i>&nbsp;Verified</span></Form.Group>)}

            {!isPhoneVerified() && (
                <Form.Group className="floatRight">
                    <Button
                        variant="primary"
                        onClick={ () => {
                            //validating "phone" related fields is triggered by clicking this "verify" button,
                            // this is different than validating overall form by "submit" button
                            let res = validateCarrierOnTrigger()
                            // console.log("validate result = " + res)
                            if (res) {
                                handleVerifyButtonClick()
                            }
                        }}

                    >Verify...</Button>
                </Form.Group>
            )
            }


            <If condition={() => {
                return showVerifyTxtDialog
            }}>
                <VerifyEmailOrPhone
                    verifyType={VERIFY_TYPE.phone}
                    notfyTxtTo={txtNotifyMethod}
                    phone={NOTIFY_TXT_TO[txtNotifyMethod] === NOTIFY_TXT_TO.yesPrimaryText ? userProfileData.phone : userProfileData.altPhone}
                    carrier={getValues("cellCarrier")}
                    setTxtVerified={setTxtVerified}
                    close={() => {
                        setShowVerifyTxtDialog(false)
                    }}/>
            </If>
        </>


    )

}


export default PrimaryAltPhoneVerifyForm