import {makeServiceStatus} from "helpers/serviceStatus";

export function processProfileListQueryResult(fetchingUQ, dataUQ, errorUQ) {
    if (fetchingUQ) {
        console.log(`The Switch Profile Data: Still fetching.....`)
        return [emptyProfileList()]
    }
    if (errorUQ) {
        console.log(`The Switch Profile Data: Error.....`)
        const serviceStatus = makeServiceStatus(dataUQ ? dataUQ.profileList : null, errorUQ)
        if (serviceStatus && !serviceStatus.success) {
            return [emptyProfileList(), serviceStatus]
        }
    }

    console.log(`The Switch Profile Data: ${JSON.stringify(dataUQ)}`)

    if (dataUQ && dataUQ.profileList) {
        return [dataUQ.profileList]
    } else {
        return [emptyProfileList()]
    }
}

export function emptyProfileList() {
    console.log("return empty userProfile")
    return {
        currentProfileName: "",
        profiles: [{
            "id": "",
            "name": "Loading...",
            "skill": " ",
            "dcpos": "",
            "disabled": true,
            "disabledReason": "",
            "active": true
        }],
    }
}
export const EMP_INACTIVE_HOME = "inactiveHome"
export const SA_NO_AREA = "noAreas"