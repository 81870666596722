import React from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import VerifyEmailOrPhone, {VERIFY_TYPE} from "./VerifyEmailOrPhone"
import If from "components/If"

const PrimaryEmailVerifyForm = ({
                                    userProfileData, emailNotifyMethod, isPrimaryEmailVerified, handleVerifyButtonClick,
                                    showVerifyEmailDialog, setShowVerifyEmailDialog, setEmailVerified
                                }) => {


    return (
        <>
            <Form.Group>
                <Form.Label>{userProfileData.email}</Form.Label>
            </Form.Group>

            {!(userProfileData.email) &&
            <></>
            }

            {(userProfileData.email) && isPrimaryEmailVerified() &&
            (<Form.Group className="floatRight"><span className="formLabel">
                        <i className="fas fa-check"></i>&nbsp;Verified</span></Form.Group>)}

            {(userProfileData.email) && !isPrimaryEmailVerified() && (
                <Form.Group className="floatRight">
                    <Button variant="primary"
                            onClick={() => handleVerifyButtonClick()}>Verify...</Button>
                </Form.Group>
            )
            }

            <If condition={() => {
                return showVerifyEmailDialog
            }}>
                <VerifyEmailOrPhone
                    verifyType={VERIFY_TYPE.email}
                    email={userProfileData.email}
                    notfyEmailTo={emailNotifyMethod}
                    setEmailVerified={setEmailVerified}
                    close={() => {
                        setShowVerifyEmailDialog(false)
                    }}/>
            </If>


        </>
    )

}


export default PrimaryEmailVerifyForm