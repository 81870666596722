import React, {useState, useRef} from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import {SwapCustomToggle} from "./ProposalAsRecipientForm"
import {formatWorkshift} from 'helpers/workshift'
import {formatDate, minutesToDurationInHours} from "helpers/date"
import {getStatuStyleClass} from "./SwapStatusData"
import {INITIATOR, RECIPIENT} from "./SwapStatusData"
import {findAreaName} from "../InitiateProposal/swapInitiateProposalHelper"
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'


const OriginalAssignmentControl = ({originalWorkshift, viewerEmployeeStatus, areas, isProposed, onClick}) => {

    let originalWsText = formatWorkshift(originalWorkshift)
    let statusClass = getStatuStyleClass(viewerEmployeeStatus.status)
    let workshiftClass = isProposed ? "flexDisplay underline" : "flexDisplay"
    let areaName  = findAreaName(areas, originalWorkshift.areaId)
    let showViewEmployeeNoteAccordion = (!!viewerEmployeeStatus.note) || (!!viewerEmployeeStatus.saNote)

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipTarget = useRef(null);


    const ViewEmployeeNoteAccordion = () => {
        return (
            <Accordion defaultActiveKey="0">
                <Card className={"displayNoteCard"}>
                    <Card.Header className={"displayNoteCardHeader"}>
                        <div className="spaceBetween">
                            <div>
                                <div className={statusClass}>
                                    {viewerEmployeeStatus.status}
                                </div>
                            </div>
                            <SwapCustomToggle eventKey={"1"}>
                                <div className={"displayNotes"}>Display Note</div>
                            </SwapCustomToggle>
                        </div>

                    </Card.Header>
                    <Accordion.Collapse eventKey={"1"} >
                        <Card.Body>
                            {viewerEmployeeStatus.note &&
                                <div className="notes">
                                    <span className="boldFont">To my Swap Partner:</span> {viewerEmployeeStatus.note}
                                </div>
                            }

                            {viewerEmployeeStatus.saNote &&
                                <div className="notes">
                                    <span className="boldFont">From SA processing my Swap:</span> {viewerEmployeeStatus.saNote}
                                </div>
                            }

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>
        )

    }

    return (

        <div>
            <div className="spaceBetween flexAlignCenter marginTen">
                <div className="bigLabel boldFont">
                    Original Assignment
                </div>

                {viewerEmployeeStatus.role === RECIPIENT
                && <div className="recipientLabel statusLabel">{RECIPIENT}</div>

                }

                {viewerEmployeeStatus.role === INITIATOR
                && <div className="initiatorLabel statusLabel">{INITIATOR}</div>
                }

            </div>
            <div className="swapPartner marginTen darkblueBg"  >
                <table className="swapPartnerTable">
                    <tbody>
                    <tr>
                        <td className="dateCol">
                            <span className="boldFont">Date:</span> {formatDate(originalWorkshift.date)}
                        </td>
                        <td>
                            <div className={workshiftClass}  onClick={onClick}>
                                <span className="boldFont">{originalWsText.substring(0,2)}</span>&nbsp;{originalWsText.substring(2)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="dateCol">
                            <span className="boldFont">Area:&nbsp;</span>
                            <span onClick={() => setShowTooltip(!showTooltip)}  ref={tooltipTarget}>{originalWorkshift.areaId}...</span>

                            <Overlay
                                show={showTooltip}
                                target={tooltipTarget.current}
                                placement="bottom"
                                rootClose={true}
                                trigger={["click"]}
                                onHide={() => setShowTooltip(false)}
                            >

                                <Popover   className="hsTooltip">
                                    <Popover.Content>
                                        {originalWorkshift.areaId}:{areaName}
                                    </Popover.Content>
                                </Popover>
                            </Overlay>

                        </td>
                        <td>
                            <span className="boldFont">Dur:</span> {minutesToDurationInHours(originalWorkshift.workMinutes)}hrs
                        </td>
                    </tr>
                    </tbody>

                </table>

            </div>

            {!showViewEmployeeNoteAccordion &&
                <div className="spaceBetween marginTen">
                    <div className={statusClass}>
                        {viewerEmployeeStatus.status}
                    </div>

                </div>
            }

            {showViewEmployeeNoteAccordion &&
                <ViewEmployeeNoteAccordion/>
            }

        </div>

    )

}


export default OriginalAssignmentControl