import React, {useState, useEffect} from 'react'
import EnumSelectControl from '../EnumSelectControl'
import OtherEmailVerifyControl from './VerifyEmailOrPhone/OtherEmailVerifyControl'
import PrimaryEmailVerifyControl from './VerifyEmailOrPhone/PrimaryEmailVerifyControl'
import Form from "react-bootstrap/Form"
import {useFormContext} from "react-hook-form"
import {getParamsAtStartup} from "components/Settings/SettingsData"

export const NOTIFY_EMAIL_TO  = Object.freeze( {
    pEmail: 'Primary Email',
    oEmail: 'Other Email',
})

export const NOTIFY_EMAIL_PRIMARY_ONLY  = Object.freeze( {
    pEmail: 'Primary Email'
})



const EmailNotifyForm = ({data: userProfileData, emailVerified, setEmailVerified }) => {

    const isPrimaryEmailOnlyNotification = getParamsAtStartup().primaryEmailOnlyNotification



    const { register } = useFormContext()

    //with async nature of useQuery, userProfileData (which is result of useQuery hook) is not available at initial render time
    //useState initial state only set at the 1st render time. In order to initialize based on query result,
    //we will take advantage of combined power of (useEffect + setState) to achieve our intention.
    // const [emailVerified, setEmailVerified] = useState( false) //promoted to "UserProfileForm" level
    const [emailNotifyMethod, setEmailNotifyMethod] = useState( '')
    // console.log("Logging state value: emailVerified = " + emailVerified + "; emailNotifyMethod= " + emailNotifyMethod)


    useEffect(() => {
        // Only set state when "userProfileData changes", which would be then be when "useQuery" hook result fetching completes
        // With the dependencies of this useEffect, it would only run once, exactly what we want.
        //Here is the sequence of events:
        //1. when this form initially renders, email verified related info is initialized to false
        //2. useQuery hook result returns, and a re-render would happen.
        //3. Now useEffect runs, and do this setTxtXXX, which would bring in a re-render
        //4. This time, "emailVerified" & "emailNotifyMethod" have the correct initial value based on query result. All good
        setEmailNotifyMethod(userProfileData.notification.notfyEmailTo)
    }, [userProfileData.notification.notfyEmailTo])


    const isPrimaryEmailVerified = () => {
        return (emailVerified
                && userProfileData.notification.notfyEmailTo ==='pEmail'
                && userProfileData.notification.notfyEmailAddressVerified === userProfileData.email)
    }



    const isOtherEmailInitiallyVerified = () => {
        return (userProfileData.notification.notfyEmailVerified && userProfileData.notification.notfyEmailTo ==='oEmail')
    }

    const isOtherEmailCurrentlyVerified = () => {
        return (emailVerified && userProfileData.notification.notfyEmailTo ==='oEmail')
    }


    const handleEmailDropdownChange = (selectedMethod) => {
        if(emailVerified){
            setEmailVerified(false)
        }else{
            if(userProfileData.notification.notfyEmailVerified
                    && selectedMethod === userProfileData.notification.notfyEmailTo){
                setEmailVerified(true)

            }
        }
        setEmailNotifyMethod(selectedMethod)

    }



    return (


            <Form.Group>
                <fieldset className="fieldsetClass">
                    <legend className="formLabel">Send Email Notifications to</legend>
                    {/*div key is to force "EmumSelectControl" to re-render and take the newer defaultValue when appropriate*/}
                    <div key={userProfileData.notification.notfyEmailTo}>
                        <Form.Group>
                            <EnumSelectControl name="emailNotifyTo"
                                    enumEntries = {Object.entries((isPrimaryEmailOnlyNotification===true)?NOTIFY_EMAIL_PRIMARY_ONLY:NOTIFY_EMAIL_TO)}
                                    selectedOption={userProfileData.notification.notfyEmailTo}
                                    onChange={handleEmailDropdownChange}
                                    register={register}
                            />
                        </Form.Group>
                    </div>

                    {/*dropdown selected "Primary email" */}
                    {NOTIFY_EMAIL_TO[emailNotifyMethod] === NOTIFY_EMAIL_TO.pEmail &&
                    <PrimaryEmailVerifyControl data={userProfileData}
                            // emailType={'pEmail'}
                            emailNotifyMethod={emailNotifyMethod}
                            isPrimaryEmailVerified={isPrimaryEmailVerified}
                            setEmailVerified={setEmailVerified}
                    />

                    }
                    {/*dropdown selected "Other email" */}
                    {NOTIFY_EMAIL_TO[emailNotifyMethod] === NOTIFY_EMAIL_TO.oEmail &&
                    <OtherEmailVerifyControl data={userProfileData}
                            name="otherEmail"
                            // emailType={'oEmail'}
                            emailNotifyMethod={emailNotifyMethod}
                            setEmailVerified={setEmailVerified}
                            isOtherEmailInitiallyVerified={isOtherEmailInitiallyVerified}
                            isOtherEmailCurrentlyVerified={isOtherEmailCurrentlyVerified}

                    />
                    }

                </fieldset>
            </Form.Group>


    )

}

export default EmailNotifyForm