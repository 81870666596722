import React from 'react'
import {InputGroup,Modal,Form, FormText,FormControl} from "react-bootstrap"
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";
import {formatInServerTZ_TimeStamp} from "helpers/date";
import {useStoreState,useStoreActions} from "Store";

const RetractForm = ({retractActions}) => {
    const {register, handleSubmit} = useForm()
    const state = useStoreState()
    const actions = useStoreActions()

    const onFormSubmit = input =>{
        const assignment = {
            "id": state.assignment.id,
            "code": state.assignment.code,
            "areaId": state.assignment.areaId,
            "from": state.assignment.from ? formatInServerTZ_TimeStamp(state.assignment.from) : '',
            "to": state.assignment.to ? formatInServerTZ_TimeStamp(state.assignment.to) : '',
            "date": state.assignment.date,
            "workMinutes": state.assignment.workMinutes,
            "totalMinutes": state.assignment.totalMinutes,
            "label": state.assignment.label,
            "pending": state.assignment.pending,
            "comment": input.comment
        }
        const variables = {"retraction": assignment}
        console.log(`Variables ${variables}`)
        retractActions.submit(variables)
    }

    function hide(){
        actions.updateAssignActionWithRetractSelected(false)
    }

    return (
        (
            <>

                <Modal show={true} onHide={hide} centered={true} style={{maxHeight: "600"}}>
                    <Form onSubmit={handleSubmit(onFormSubmit)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormText>Retract Assignment</FormText>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Comment<br/>(Optional):</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="textarea" name="comment" ref={register}/>
                            </InputGroup>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" value="Submit">Retract</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        ))

}
export default RetractForm;