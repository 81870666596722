import gql from 'graphql-tag'
import {useQuery} from 'urql'

export const ASSIGN_OPTIONS_QUERY = gql`
query assignmentDetailAction ($areaId: AreaId!, $code: String!, $startTime: String, $endTime: String, 
    $workMinutes: Int, $totalMinutes: Int, $workDate: Date!, $label: String, $pending: Boolean) {
        assignmentDetailAction (areaId: $areaId, code: $code, startTime: $startTime, endTime: $endTime, 
            workMinutes: $workMinutes, totalMinutes: $totalMinutes, workDate: $workDate, label: $label, 
            pending: $pending) {
                retractable id swapable
            }
        }`

export function useAssignOptionsQuery(areaId, code, startTime, endTime, workMinutes, totalMinutes, workDate, label, pending, openAccordian, count) {
    // console.log("useAssignOptionsQuery(Area Id: " + areaId + ",Workshift Code: " + code +
    //     "StartTime: " + startTime + ",EndTime: " + endTime +
    //     "WorkMinutes: " + workMinutes + ",TotalMinutes: " + totalMinutes +
    //     "WorkDate: " + workDate + ",label: " + label +
    //     "Pending: " + pending + ",openAccordian: " + openAccordian + "Count: " + count
    // )
    return useQuery({
        query: ASSIGN_OPTIONS_QUERY,
        variables: {areaId, code, startTime, endTime, workMinutes, totalMinutes, workDate, label, pending},
        pause: !areaId || !code || !workDate || (!openAccordian || openAccordian === false) || count === 0,
        requestPolicy: "cache-and-network"
    })
}