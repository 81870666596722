import React from 'react'
import Form from "react-bootstrap/Form"
import {useFormContext} from "react-hook-form"

const  NotifyMeByForm = ({data: userNotificationData}) => {
    const { register} = useFormContext() // retrieve all hook methods

    const initialNotifyBy = userNotificationData.notfyAsBy

    const NOTIFY_BY  = Object.freeze( {
        email: 'email',
        message: 'message',
        emailMsg: 'emailMsg',
    })


    return (

            <Form.Group>
                <fieldset className="fieldsetClass">
                    <legend className="formLabel">Notify me by</legend>
                    {/*div key is to force below checkboxes to re-render and take the newer defaultValue (selectedOption) once userProfile fetched */}
                    <div key={userNotificationData.id}>

                        <label className="hsRadio">
                            <input type="radio"
                                   name='notifyBy'
                                   defaultChecked={NOTIFY_BY[initialNotifyBy] === NOTIFY_BY.email}
                                   value='email'
                                   ref={register}
                            />
                             <span className="radioCheckMark"> </span>
                            <span className="hsCheckboxLabel">Email</span>
                        </label>

                        <label className="hsRadio">
                            <input type="radio"
                                   name='notifyBy'
                                   defaultChecked={NOTIFY_BY[initialNotifyBy] === NOTIFY_BY.message}
                                   value='message'
                                   ref={register}
                            />
                            <span className="radioCheckMark"> </span>
                            <span className="hsCheckboxLabel">Text</span>
                        </label>


                        <label className="hsRadio">
                            <input type="radio"
                                   name='notifyBy'
                                   defaultChecked={NOTIFY_BY[initialNotifyBy] === NOTIFY_BY.emailMsg}
                                   value='emailMsg'
                                   ref={register}
                            />
                            <span className="radioCheckMark"> </span>
                            <span className="hsCheckboxLabel">Both</span>
                        </label>

                    </div>
                </fieldset>
            </Form.Group>


    )


}

export default NotifyMeByForm