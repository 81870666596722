/**
 * Move array element from {old_indx} to {new_index} position. Move is perform in-place, so the {arr}
 * element order is changed.
 * @param arr array of objects
 * @param old_index current position of element to move
 * @param new_index new position of element after the move
 */
export function moveElement(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export function mapToSortedArray(map) {
    const sortedMap = new Map([...map.entries()].sort());
    let sortedAreas = []
    for (const area of sortedMap.values()) {
        sortedAreas.push(area)
    }
    return sortedAreas
}
