import {createOvermind} from "overmind"
import {createActionsHook, createStateHook} from "overmind-react"

export const useStoreState = createStateHook()
export const useStoreActions = createActionsHook()

export const Store = createOvermind({
    state: {
        assignment: null,
        assignAction: {
            retractable: false,
            swapable: false,
            openAccordian: null,
            count: 0,
            retractSelected: false,
            swapListSelected: false,
            swapReviewSelected: false,
            swapNearbyWorkshiftsSelected: false,
            swapSelectedRecipient: {
                from: null,
                selectedSwapRecAssignmentKey: null,
                swapWarning: null,
            }
        },
        online: true,
    },
    actions: {
        updateAssignment({state}, assignment) {
            state.assignment = assignment
        },
        updateAssignAction({state}, assignAction) {
            state.assignAction = assignAction
        },
        updateAssignActionWithRetractSelected({state}, selected) {
            state.assignAction.retractSelected = selected
            state.assignAction.count = 1
        },
        updateAssignActionWithSwapListSelected({state}, selected) {
            state.assignAction.swapListSelected = selected
            state.assignAction.swapReviewSelected = !selected
            state.assignAction.swapNearbyWorkshiftsSelected = !selected
            state.assignAction.count = 1
        },
        updateAssignActionWithSwapReviewSelected({state}, selected) {
            state.assignAction.swapReviewSelected = selected
            state.assignAction.swapListSelected = !selected
            state.assignAction.swapNearbyWorkshiftsSelected = !selected
            state.assignAction.count = 1
        },
        updateAssignActionWithSwapNearbyWorkshiftsSelected({state}, selected) {
            state.assignAction.swapNearbyWorkshiftsSelected = selected
            state.assignAction.swapReviewSelected = !selected
            state.assignAction.swapListSelected = !selected
            state.assignAction.count = 1
        },
        updateAssignActionWithNoSwap({state}){
            state.assignAction.swapListSelected = false
            state.assignAction.swapReviewSelected = false
            state.assignAction.swapNearbyWorkshiftsSelected = false
            state.assignAction.count = 0
        },
        updateSwapSelectedRecipient({state}, selectedRecipient){
            state.assignAction.swapSelectedRecipient = selectedRecipient
        },
        setOnline({state}, online) {
            state.online = online
        }
    }
});