import React, {useState} from 'react'
import './UserProfile.css'
import NameValueList from "components/NameValueList"
import ChangePassword from "./ChangePassword"
import If from "components/If"
import Button from "react-bootstrap/Button"
import Form from 'react-bootstrap/Form'
import {useFormContext} from 'react-hook-form'


const AccountSection = ({data: userProfileData}) => {
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)

    const {register, getValues, setValue} = useFormContext() // retrieve all hook methods

    let acctInfoMap = new Map()
    acctInfoMap.set('Name', {text:userProfileData.defaultDisplayName })
    acctInfoMap.set('User Name', {text: userProfileData.userName})

    return (
        <div>
            <div className={'userInfo'}>
                <NameValueList map={acctInfoMap} size={"large"}/>
            </div>

            <Form.Group>
                <div>
                    <div>
                        <label className="hsCheckbox">
                            <input type="checkbox"
                                   name="welcomeBanner"
                                   defaultChecked={userProfileData.customDisplayName}
                                   ref={register}
                                   onChange={() => {
                                       const welcomeBannerChecked = getValues("welcomeBanner")
                                       if (!welcomeBannerChecked) {
                                           //clear text input value if checkbox is unchecked
                                           setValue("customDisplayName", "");
                                       }
                                   }}

                            />
                            <span className="hsCheckmark"/>

                        </label>
                    </div>

                    <div className="hsCheckboxLabel boldFont">
                        <span>Name in welcome banner</span>
                    </div>

                </div>



                <Form.Control type="text" name="customDisplayName"
                              defaultValue={userProfileData.customDisplayName}
                              maxLength="30"
                              ref={register}
                              className="customNameText"
                              onKeyDown={() => {
                                  //enable checkbox if it is not already enabled
                                  const welcomeBannerChecked = getValues("welcomeBanner");
                                  if (!welcomeBannerChecked) {
                                      setValue("welcomeBanner", true);
                                  }
                              }}/>


            </Form.Group>

            <Form.Group className="floatRight">
                <If condition={() => {
                    return showChangePasswordDialog
                }}>
                    <ChangePassword data={userProfileData} close={() => {
                        setShowChangePasswordDialog(false)
                    }}/>
                </If>
                {userProfileData.functionAccess.changePassword && (
                    <Button variant="primary"
                            onClick={() => setShowChangePasswordDialog(
                                true)}>Change Password</Button>

                )
                }


            </Form.Group>

        </div>
    );

}


export default AccountSection