import {
    daysBetween,
    formatDate,
    formatDateSimple,
    getDates,
    isWeekend,
    stringToDate,
    weekdayAndDayOfMonth
} from "helpers/date";
import {makeServiceStatus} from "helpers/serviceStatus";
import React from "react";
import {ROLE} from "components/Role";
import {getArea} from "helpers/area";
import {getPeriod} from "helpers/period";

export const DAYS_IN_PERIOD = 28

export const RowTypes = {
    EMPLOYEE: 'EMPLOYEE',
    COUNT: 'COUNT',
    HOURS: 'HOURS'
}

/*
 * Returns [SKILLGROUP,...]
 *
 * SKILLGROUP= {id:skill, name:skillName, rows: [EMPLOYEE_ROW(s), COUNT_ROW, HOURS_ROW]}
 * EMPLOYEE_ROW= {type: EMPLOYEE, employee:employee, dayAssignments: [[assignment,...],...(28)]}
 * COUNT_ROW= {type:COUNT, dayCounts: [count,...(28)]}
 * HOURS_ROW= {type:HOURS, dayHours: [hours,...(28)]}
 *
 */
export function processAreaScheduleData(fetching, data, error, role) {

    if (fetching) {
        const selectedArea = {id: '  ', name: 'Loading area...'}
        const selectedPeriod = {id: '  ', from: formatDate(new Date()), reset: true, published: true, balancing: false}
        return {
            skillGroups: buildLoadingSkillGroups(role),
            periodDays: buildLoadingPeriodDays(),
            areas: [selectedArea],
            periods: [selectedPeriod],
            selectedArea: selectedArea,
            selectedPeriod: selectedPeriod,
            serviceStatus: ''
        }
    }

    if (error) {
        const serviceStatus = makeServiceStatus(data ? data.areaSchedule : null, error)
        if (serviceStatus && !serviceStatus.success) {
            return {skillGroups: new Map(), periodDays: [], areas: [], periods: [], serviceStatus: serviceStatus}
        }
    }

    if (data && data.areaSchedule) {
        const area = getArea(data.areaSchedule.areas, data.areaSchedule.areaId)
        const areas = (role === ROLE.SA?data.areaSchedule.areas:[area])
        const period = getPeriod(data.areaSchedule.periods, data.areaSchedule.from)
        const periodDays = buildPeriodDays(data.areaSchedule.from, data.areaSchedule.to)
        let skillGroups
        if (!(role === ROLE.EMP) || (period && period.published && !period.balancing)) {
            skillGroups = buildSkillGroups(area, data.areaSchedule)
        }
        return {
            skillGroups: skillGroups,
            periodDays: periodDays,
            areas: areas,
            periods: data.areaSchedule.periods,
            selectedArea: area,
            selectedPeriod: {...period, notice: data.areaSchedule.notice},
        }
    } else {
        return {
            skillGroups: new Map(),
            periodDays: [],
            areas: [],
            periods: [],
            selectedArea: {},
            selectedPeriod: {},
        }
    }

}

/**
 * Builds array of period days using period begin and end dates
 * containing date, dow, dom and className (weekday/weekend & today).
 *
 * Eg: Begin - 03/03/2021 End - 03/04/2021 would return
 * Array[{day: dayOfPeriod, date: date,dow: "Wednesday", mmmd: "Mar 3", className: "weekday"],...]
 *
 * @param begin String value
 * @param end String value
 * @returns {Array}
 */
export function buildPeriodDays(begin, end) {
    let dates = getDates(stringToDate(begin), stringToDate(end));
    const today = formatDateSimple(new Date(), "MMM D")

    let periodDays = []
    dates.forEach((eachDate, day) => {
        const formatted = weekdayAndDayOfMonth(eachDate)
        const className = "day " + (formatted[1] === today ? "today " : "") + (isWeekend(formatted[0]) ? "weekend" : "weekday")
        periodDays.push({day: day, date: eachDate, dow: formatted[0], mmmd: formatted[1], className: className})
    })
    return periodDays
}

/**
 * This is called when the AreaSchedule is still being fetched.
 * @returns {Array}
 */
function buildLoadingPeriodDays() {

    const DOW = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const titles = [...DOW, ...DOW, ...DOW, ...DOW]
    let periodDays = []

    titles.map((dow, day) => {
        const className = "day " + (isWeekend(dow) ? "weekend" : "weekday")
        periodDays.push({day: day, date: null, dow: dow, mmmd: "Day " + day, className: className})
    })

    return periodDays
}

/**
 * Builds an array of days where each day can contain an array of assignments.
 * Also, set assignment.float to true if assignment in different area.
 * @param assignments assignments from one employee for a period
 * @param periodBeginDate period begin date
 * @returns day array containing assignments
 */
function buildDayAssignments(area, assignments, periodBeginDate) {
    let days = []
    if (assignments) {
        assignments.map((assignment) => {
            assignment.float = (assignment.areaId !== area.id)
            let day = daysBetween(stringToDate(assignment.date), stringToDate(periodBeginDate))
            days[day] = days[day] || []
            days[day].push(assignment)
        })
    }
    return days
}

/**
 * This is called when AreaSchedule is still being fetched.
 * @param role
 * @returns {Map}
 */
function buildLoadingSkillGroups(role) {
    let skill = 'Loading...'
    let employee = {
        id: 1,
        name: 'Loading...',
        dcpos: '',
        jobClass: '',
        skillCode: '',
        email: '',
        phone: ''
    }
    let rows = []
    let employeeRow = {
        type: RowTypes.EMPLOYEE,
        employee: employee,
        dayAssignments: []
    }
    rows.push(employeeRow)

    if (role === ROLE.SA) {

        let countRow = {
            type: RowTypes.COUNT,
            dayCounts: []
        }
        rows.push(countRow)

        let hoursRow = {
            type: RowTypes.HOURS,
            dayHours: []
        }
        rows.push(hoursRow)
    }

    return [{id: skill, rows: rows}]
}

export function buildSkillGroups(area, areaSchedule) {
    const skillGroups = []

    if (areaSchedule.schEmpTotals) {
        areaSchedule.schEmpTotals.forEach((schEmpTotal, i) => {
            const skill = schEmpTotal.skill
            const skillName = areaSchedule.skills[i].name

            let rows = []
            //loop through schedule employee total to get employee and assignments for the employee
            if (schEmpTotal.schEmps) {
                //Assignments for each employee
                schEmpTotal.schEmps.forEach((schEmp) => {
                    //Assignments for an employee for a period
                    let dayAssignments = buildDayAssignments(area, schEmp.assignments, areaSchedule.from)
                    const employee = {...schEmp.employee, skill: skill+":"+skillName}
                    rows.push({
                        type: RowTypes.EMPLOYEE,
                        employee: employee,
                        dayAssignments: dayAssignments
                    })
                })
                //Employee count and actual hours
                if (schEmpTotal.schCounts && schEmpTotal.schCounts.length > 0) {
                    let empTotalArrayBySkill = []
                    let minutesTotalArrayBySkill = []
                    schEmpTotal.schCounts.forEach((schCount) => {
                        empTotalArrayBySkill.push(schCount.emps)
                        minutesTotalArrayBySkill.push((Math.round(schCount.minutes * 10 / 60) / 10).toFixed(1))
                    })
                    let empTotal = {type: RowTypes.COUNT, dayCounts: empTotalArrayBySkill}
                    rows.push(empTotal)

                    let minutesTotal = {type: RowTypes.HOURS, dayHours: minutesTotalArrayBySkill}
                    rows.push(minutesTotal)
                }
            }
            skillGroups.push({id: skill, name: skillName, rows: rows})
        })

    }
    return skillGroups
}
