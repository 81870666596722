import React, {useEffect, useState} from 'react'
import {useFormContext} from "react-hook-form"
import OtherEmailVerifyForm from "./OtherEmailVerifyForm"
import {useSendEmailVerificationCodeQuery} from "../../UserProfileGQL"
import {makeSendEmailVerificationCodeStatus} from "../../UserProfileData"
import ServiceStatus from "helpers/serviceStatus"

const OtherEmailVerifyControl = ({
                                     data: userProfileData,
                                     name,
                                     emailNotifyMethod,
                                     setEmailVerified,
                                     isOtherEmailInitiallyVerified,
                                     isOtherEmailCurrentlyVerified
                                 }) => {

    const {getValues} = useFormContext()

    const [showVerifyEmailDialog, setShowVerifyEmailDialog] = useState(false)

    //"emailToQueryLatch" is a latch so that "SendEmailVerification" useQuery only gets executed on "verify" button click.
    //useQuery hook accepts a pause option that temporarily freezes all changes and stops requests.
    // React Hooks has to be top-level and could not be put under "if" condition. "pause" is how we get around it.
    //This way, this query should only be executed when "verify" button is clicked
    const [emailToQueryLatch, setEmailToQueryLatch] = useState(undefined)

    const [{fetching: fetchingEVQ, data: dataEVQ, error: errorEVQ}]
        = useSendEmailVerificationCodeQuery(emailToQueryLatch, getValues(name))

    let serviceStatus = makeSendEmailVerificationCodeStatus(fetchingEVQ, dataEVQ, errorEVQ)

    // console.log("+++++++++++++++++++++++++++++++OtherEmailVerifyControl re-render: EmailToQueryLatch= " +  emailToQueryLatch )
    // console.log(dataEVQ)

    function handleVerifyButtonClick() {

        //Step 1. Changing query input variable value to trigger useQuery hook executing queries
        setEmailToQueryLatch(emailNotifyMethod)

        //Step 2. bring up "Verify" dialog
        setShowVerifyEmailDialog(true)

    }

    useEffect(() => {
        // console.log("+++++++++++++++++++++++++++++++OtherEmailVerifyControl useEffect reset EmailToQueryLatch to undefined")
        setEmailToQueryLatch(undefined) //reset latch back to "undefined" as soon as fetch query returns result
    }, [dataEVQ])


    if ((serviceStatus !== ServiceStatus.SUCCESS) && (serviceStatus !== ServiceStatus.UNKNOWN)) {
        console.log("Error out when generating verification code: " + serviceStatus)
        return <></>
    }

    return (
        <OtherEmailVerifyForm userProfileData={userProfileData} name={name} emailNotifyMethod={emailNotifyMethod}
                              isOtherEmailInitiallyVerified={isOtherEmailInitiallyVerified}
                              isOtherEmailCurrentlyVerified={isOtherEmailCurrentlyVerified}
                              handleVerifyButtonClick={handleVerifyButtonClick}
                              showVerifyEmailDialog={showVerifyEmailDialog}
                              setShowVerifyEmailDialog={setShowVerifyEmailDialog} setEmailVerified={setEmailVerified}/>
    )
}

export default OtherEmailVerifyControl