import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HotBar.css"
import {toHash, toUrl} from "helpers/http";
import 'fontawesome-free/css/all.css'

function clickLink(linkId) {
    let element = document.getElementById(linkId)
    if (element) {
        const clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        })
        element.dispatchEvent(clickEvent)
    }
}

function mkNavLink(feature, active, onMenuSelect, onActionSelect) {
    if (feature.url) {
        if (feature.url.startsWith('http')) {
            return <div key={feature.key} className={feature.key}>
                <Nav.Link href="" className={feature.key} onClick={() => clickLink(feature.key + "-link")}
                          disabled={feature.disabled}>
                    <i className={"fas " + feature.icon + " nav-icon"}/>
                    <span className="nav-name">{feature.title} <i className={"fas fa-external-link-alt"}/></span>
                </Nav.Link>
                <a id={feature.key + '-link'} style={{display: 'hidden'}} href={feature.url} target="_blank"
                   rel="noreferrer noopener"/>
            </div>
        } else {
            return (
                <Nav.Link key={feature.key} href={toHash(feature.url)}
                          className={feature.key + (active ? " active" : "")}
                          eventKey={feature.url} onSelect={onMenuSelect} disabled={feature.disabled}>
                    <i className={"fas " + feature.icon + " nav-icon"}/>
                    <span className="nav-name">{feature.title}</span>
                </Nav.Link>
            )
        }
    } else {
        // Create nav link for an action; onActionSelect handles performing action function
        return (
            <Nav.Link key={feature.key} eventKey={feature.key}
                      className={feature.key}
                      onClick={(event) => onActionSelect(feature.key, event)}
                      disabled={feature.disabled}>
                <i className={"fas " + feature.icon + " nav-icon"}/>
                <span className="nav-name">{feature.title}</span>
            </Nav.Link>
        )
    }
}

/**
 * Returns up to maxVisible features/actions with menu always being the last entry on the menu.
 * @param features array of features and actions
 * @param maxVisible maximum number of items to appear on Hotbar
 * @returns {*[]|*}
 */
function mkVisible(features, maxVisible) {
    if (features.length > maxVisible) {
        return [...features.slice(0, maxVisible - 1), features[features.length - 1]]
    } else {
        return features
    }
}

const HotBar = ({features, actions, maxVisible = 5, hash, onMenuSelect, onActionSelect}) => {

    if (!features || !actions || !maxVisible || !hash) return <></>

    actions.map((action) => {
        if (action.addToHotBar) features = [...features, action]
    })

    const url = toUrl(hash)

    let visibleFeatures = mkVisible([...features], maxVisible)

    const className = "navbar-" + visibleFeatures.length
    return <div id='hotbar'>
        <Navbar fixed="bottom" collapseOnSelect className={className} expand={false} bg="white" variant="light">
            {visibleFeatures.map((feature) => {
                return mkNavLink(feature, feature.url === url, onMenuSelect, onActionSelect)
            })}
        </Navbar>
    </div>
}

export default HotBar
