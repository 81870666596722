import React from 'react'
import Form from 'react-bootstrap/Form'
import AreaSelectControl from '../AreaSelectControl'
import {useFormContext} from 'react-hook-form'
import EnumSelectControl from '../EnumSelectControl'
import {ifSARoleOnly, ifContainsSARole, ifMultipleRoles} from "../userProfileHelper"

const ADM_DEFAULT_PAGE  = Object.freeze( {
    inboxPg: 'Inbox',
    weeklyViewPg: 'Weekly View',
    periodViewPg: 'Period View',
    availableShiftsPg: 'Available Shifts Report',
})




const SARoleForm = ({data: userProfileData}) => {
    const { register } = useFormContext()

    return (

            <Form.Group>
                <fieldset className="fieldsetClass">
                    <legend className="formLabel">Scheduling Administrator Role</legend>
                    <Form.Group>
                    <Form.Label className="formLabel">Default Area</Form.Label>
                    <AreaSelectControl name="saDefaultAreaId"
                            areas={userProfileData.areasSA}
                            selectedAreaId={userProfileData.saDefaultAreaId}
                            register={register}
                    />
                    </Form.Group>

                    <Form.Group>
                    <Form.Label className="formLabel">Default Screen</Form.Label>
                        {ifContainsSARole(userProfileData.roles)  &&<span>*</span> }
                    <EnumSelectControl name="saDefaultPage"
                            enumEntries = {Object.entries(ADM_DEFAULT_PAGE)}
                            selectedOption={userProfileData.saDefaultPage}
                            register={register}

                    />
                    </Form.Group>


                    {ifSARoleOnly(userProfileData.roles)  &&
                        <div className="alertFont">
                            *ANSOS2Go always defaults to the Area Schedule.
                        </div>
                    }

                    {ifMultipleRoles(userProfileData.roles)&&
                    <div className="alertFont">
                        *ANSOS2Go always defaults to My Calendar.
                    </div>
                    }


                </fieldset>
            </Form.Group>



    )

}

export default SARoleForm