import React, {useState} from 'react'
import Form from 'react-bootstrap/Form'
import {useRequestFormQuery} from './RequestGQL';
import {formatWorkshift} from 'helpers/workshift'
import {RequestType} from "./Request";
import {processRequestFormData} from "./RequestData";
import Notice from "components/Notice";
import ServiceStatus from "helpers/serviceStatus";
import {isEqual} from "helpers/object";
import {getArea} from "helpers/area";
import RequestForm from "./RequestForm"
import AvailshiftListForm from "./AvailshiftListForm"

export const WorkshiftFormControl = ({areaId, workshifts, register}) => {
    if (areaId && workshifts) {
        console.log("Creating WorkshiftFormControl for areaId=" + areaId)
        let options = [];
        options.push(<option key="-1" value="-1" disabled>Select a workshift</option>)
        workshifts.map((workshift, i) =>
            options.push(<option key={i} value={workshift.workshiftId}>{formatWorkshift(workshift)}
                {workshift.skill ? " (" + workshift.skill + ")" : ""}</option>)
        )
        return (
            <Form.Control as="select" name="workshiftId" defaultValue={"-1"}
                          ref={register({validate: value => value !== "-1"})}>
                {workshifts && options}
            </Form.Control>
        )
    } else {
        return (
            <Form.Control name="workshiftId" readOnly value="No workshifts available"
                          ref={register({validate: value => false})}/>
        )
    }
}

const RequestControl = ({request, areas, actions, areaId}) => {
    const [requestAreaId, setRequestAreaId] = useState(request.areaId)
    //We should use "areaId" to do below query(not "requestAreaId"). Reason being, with "all areas" introduced,
    //we need to make sure "areaId" is what is selected in "avl shift calendar view", not the specific request just submitted
    let fetchAreaId = (request.requestType === RequestType.OPEN_SHIFT) ? areaId : requestAreaId
    // console.log("useRequestFormQuery params: request.requestType = " + request.requestType+ ";fetchAreaId= " + fetchAreaId)
    let [{fetching, data, error}] = useRequestFormQuery(request.requestType, request.startDate, fetchAreaId)
    if (fetching) return <></>
    let {requestForm, serviceStatus} = processRequestFormData(fetching, data, error)

    const onSubmit = input => {
        request.areaId = requestForm.allArea? input.selectedWsAreaId : requestAreaId
        request.startDate = input.startDate
        request.endDate = input.endDate
        request.note = input.note


        if (input.workshiftId && data.requestForm.workshifts) {
            for (let workshift of data.requestForm.workshifts) {
                //The way comp skill works-- for the same area, if a code is available for both primary skill & comp skill,
                //then backend would only return code for primary skill.
                // Hence, we can use (wsId, areaId) to determine a match
                if (workshift.workshiftId === input.workshiftId
                    && (requestForm.allArea ? input.selectedWsAreaId === workshift.areaId : true)
                ) {
                    request.workshiftId = input.workshiftId
                    request.workshiftName = workshift.name
                    request.workshiftStartTime = workshift.from
                    request.workshiftEndTime = workshift.to
                    request.workshiftSkill = workshift.skill
                    break
                }
            }
        }
        actions.submit(request)
    }

    const isOpen = (area) => {
        return (request.requestType === RequestType.WORK && area.workOpen) || (request.requestType ===RequestType.TIME_OFF && area.timeOffOpen)
    }
    const onAreaChange = (areaId) => {
        if (request.requestType === RequestType.WORK) {
            setRequestAreaId(areaId)
        }
    }




    if (serviceStatus && !isEqual(serviceStatus, ServiceStatus.NO_AVAILABLE_WORKSHIFT)) {
        console.log("loading requestForm error: " + error)
        return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                       onClose={actions.cancel} show={true}/>
    } else {
        console.log("data from useRequestFormQuery=" + data)
    }


    const selectedArea = areas ? getArea(areas, requestAreaId) : {}




    switch (request.requestType) {
        case RequestType.OPEN_SHIFT: {
            return (
                <AvailshiftListForm   request={request}  areas={areas} selectedArea={selectedArea}   actions={actions} requestForm={requestForm}
                                      onSubmit={onSubmit}
                />
            )
        }
        case RequestType.WORK:
        case RequestType.TIME_OFF:
        default:{
            return (
                <RequestForm  request={request}  areas={areas} selectedArea={selectedArea}   actions={actions} requestForm={requestForm}
                              requestAreaId={requestAreaId} onSubmit={onSubmit} onAreaChange={onAreaChange} isOpen={isOpen}
                />
            )
        }

    }

}

export default RequestControl