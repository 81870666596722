import React from 'react'

/**
 * This is a solution to the issue with updating state from an event handler.
 *
 * For example,
 * const [state, setState] = useState("online")
 * The value of state from within an event handler function will always be the value of state
 * at the moment the event handler was initialized and calls to setState will update that instance of
 * state.
 *
 * So, this solution creates a reference to state and setter that updates the current value of state.
 *
 * const [stateRef, setStateRef] = useReferredState(initialValue)
 * console.log(stateRef.current)
 *
 * https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
 *
 * @param initialValue
 * @returns {[reference, setter]}
 */
export default function useReferredState(initialValue) {
    const [state, setState] = React.useState(initialValue);
    const reference = React.useRef(state);

    const setReferredState = value => {
        reference.current = value;
        setState(value);
    };

    return [reference, setReferredState];
}
