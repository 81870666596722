import React from 'react'
import NameValueList from "components/NameValueList"
import EmailNotifyForm from './EmailNotifyForm'
import PhoneNotifyForm from "./PhoneNotifyForm"

const EmployeeContactForm = ({
                                 data: userProfileData,
                                 emailVerified,
                                 setEmailVerified,
                                 txtVerified,
                                 setTxtVerified,
                                 txtNotifyMethod,
                                 setTxtNotifyMethod
                             }) => {
    // console.log("userProfile.notification = " + JSON.stringify(userProfileData.notification))

    let contactInfoMap = new Map()
    contactInfoMap.set('Prim. Email', {text: userProfileData.email, })
    contactInfoMap.set('Prim. Phone', {text: userProfileData.phone,})
    contactInfoMap.set('Alt. Phone', {text: userProfileData.altPhone, })

    return (<>
        <div className={'userInfo'}>
            <NameValueList map={contactInfoMap} size={"large"}/>
        </div>
        <EmailNotifyForm data={userProfileData}
                         emailVerified={emailVerified}
                         setEmailVerified={setEmailVerified}/>
        <PhoneNotifyForm data={userProfileData}
                         txtVerified={txtVerified}
                         setTxtVerified={setTxtVerified}
        />
    </>)
}

export default EmployeeContactForm