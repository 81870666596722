import React from 'react'
import SelectDefaultRoleForm from "./SelectDefaultRoleForm"
import EmployeeRoleForm from "./EmployeeRoleForm"
import SARoleForm from "./SARoleForm"
import {ifContainsSARole, ifContainsEmpRole, ifMultipleRoles} from "../userProfileHelper"

const RoleSection = ({data: userProfileData}) => {


    return(
            <div>
                {ifMultipleRoles(userProfileData.roles) &&
                    <SelectDefaultRoleForm data={userProfileData}/>
                }

                {ifContainsEmpRole(userProfileData.roles) &&
                    < EmployeeRoleForm data={userProfileData} />
                }

                {ifContainsSARole(userProfileData.roles) &&
                    <SARoleForm data={userProfileData}/>
                }

            </div>
    )

}





export default RoleSection