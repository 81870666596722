import {calendarModes, publishStatusClass, publishStatuses} from './CalendarData'
import {dateToString, equal, formatInServerTZ_Date} from 'helpers/date'
import {formatWorkshift} from 'helpers/workshift'
import moment from "moment";

export function createLoadingEvents() {
    let events = []
    let event = {}
    event.id = 1
    event.title = "Loading"
    event.allDay = true
    event.start = new Date()
    event.end = ''
    event.display = 'block'
    events.push(event)
    return events
}

function allDayEvent(day) {
    let event = {}
    event.title = ''
    event.start = day.date
    event.allDay = true
    event.day = day
    return event
}

export function createPublishedStatusEvents(days, date) {
    let events = []

    if (days) {
        try {
            new Map([...days.entries()].sort()).forEach(day => {
                day.publishStatus = day.period.archived ? publishStatuses.ARCHIVED : (day.homeArea ? day.homeArea.publishStatus : publishStatuses.NOT_PUBLISHED)
                //background color based on publish status
                let event = allDayEvent(day)
                event.display = 'background'
                event.className = ["publishStatus"]
                event.className.push(publishStatusClass(day.publishStatus))
                if (equal(day.date, date)) {
                    event.className.push("selected-date")
                }
                events.push(event)
            })
        } catch (error) {
            console.log(error)
        }
    }
    return events
}

export function createIndicatorEvents(days, availableShiftDates, mode, terminateDate) {
    let events = []
    // Use midnight - 3 hours; assignments starting before are in the past
    let startOfDay = moment().startOf('day')
    if (days && mode && availableShiftDates) {
        try {
            new Map([...days.entries()].sort()).forEach(day => {
                let event = allDayEvent(day)
                event.display = 'auto'

                let className = ''
                let past = false
                if (mode === calendarModes.WORK && day.workOpen) {
                    className += '-work'
                } else if (mode === calendarModes.TIMEOFF && day.homeArea && day.homeArea.timeOffOpen) {
                    if(terminateDate) {
                        if (moment(terminateDate).isAfter(moment(day.date))){
                            className += '-timeOff'
                        }
                    }else{
                        className += '-timeOff'
                    }
                } else if (mode === calendarModes.AVAILABLE_SHIFTS && availableShiftDates.includes(dateToString(day.date))) {
                    className += '-availableShifts'
                } else if (moment(day.date).isBefore(startOfDay)) {
                    past = true
                }

                if (day.assignments && day.assignments.length > 0) {
                    if (past) className += '-past'
                    if (day.assignments[0].home) {
                        className += '-homeAssignment'
                    } else {
                        className += '-floatAssignment'
                    }
                }

                if (className.length > 0) {
                    event.className = ['day' + className]
                    events.push(event)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    return events
}

export function createAssignmentEvents(days, startDate) {
    let assignments = []

    if (days) {
        try {
            new Map([...days.entries()].sort()).forEach(day => {
                if (day.date >= startDate) {
                    if (day.assignments.length > 0) {
                        for (let assignment of day.assignments) {
                            let event = {}
                            event.title = formatWorkshift(assignment, (assignment.format === 2 && !assignment.home)?5:assignment.format) +
                                (assignment.pending ? " (pending)" : "") + "\n" +
                                (!assignment.home ? "(float) " + assignment.areaId + " " + assignment.areaName : "") +
                                (assignment.team ? "Team:" + assignment.team + " ":"") +
                                (assignment.task ? "Task:" + assignment.task : "")
                            event.start = assignment.from
                            // Truncate assignment period at midnight so it only appears on the workdate
                            if (formatInServerTZ_Date(assignment.from) === formatInServerTZ_Date(assignment.to)) {
                                event.end = assignment.to
                            } else {
                                event.end = assignment.start
                            }
                            event.allDay = false
                            event.day = day
                            event.display = 'block'
                            event.assignment = assignment
                            assignments.push(event)
                        }
                    } else {
                        let event = allDayEvent(day);
                        event.title = ''
                        assignments.push(event)
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    return assignments
}
