import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import 'fontawesome-free/css/all.css'
import "components/Calendar/Calendar.css"
import Assignments from "components/Assignments";
import {useViewClasses} from "./View";

const AssignmentsView = ({layout}) => {

    let body = <Assignments/>

    return layout({body, classes:useViewClasses()})
}

export default AssignmentsView