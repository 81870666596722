import React from 'react';
import './Banner.css'
import ansos2go from "images/22-HSTM-ANSOS2Go-Primary-RGB.png";
import Version from "components/Version"
//
const Banner = () => {
    return <div id="banner">
        <div className="Hcenter">
            <img className="ansos2go" src={ansos2go} alt="ansos2go(tm)"/>
        </div>
    </div>
}

export default Banner