import React from 'react'
import Form from 'react-bootstrap/Form'


const EnumSelectControl = ({name, enumEntries,  selectedOption, onChange, register}) => {
    let options = [];

    for (let [key, value] of enumEntries) {
        // console.log("AdmDefaultPage key =" + key+  ", value =" + value);
        options.push(<option key={key} value={key}>{value}</option>)
    }
    if(selectedOption) {
                if(onChange) {
                    return (<Form.Control as="select"
                            name={name}
                            defaultValue={selectedOption}
                            onChange={e => onChange(e.target.value)}
                            ref={register}
                            >
                            {options}
                            </Form.Control>)
                } else{
                    return (<Form.Control as="select"
                            name={name}
                            defaultValue={selectedOption}
                            ref={register}
                            >
                            {options}
                            </Form.Control>    )
                }
    }else{

        if(onChange) {
            return (<Form.Control as="select"
                            name={name}
                            onChange={
                                e => onChange(e.target.value)}
                            ref={register}
                    >
                        {options}</Form.Control>
            )
        }else{
            return (<Form.Control as="select"
                            name={name}
                            ref={register}
                            >{options}</Form.Control>
            )

        }
    }
}

export default EnumSelectControl



