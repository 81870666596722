import {NoticeType} from "components/Notice";
import {relaunch, reload} from "./http";
import {ActionDefs} from "actions"

const PLEASE_TRY_AGAIN = " Please try again.\nIf the problem persists, contact your technical support staff and provide the following reference number: {0}"

export const ServiceStatus = {
    SUCCESS: {message: "placeholder", success: true},
    SUCCESS_BUT_MUST_ACCEPT_TERMS: {
        message: "You must accept the Terms of Use agreement.\nClick OK to sign in and accept Terms of Use.",
        noticeType: NoticeType.OK,
        success: false,
        action: ActionDefs.SIGN_OUT
    },
    SUCCESS_WORKSHIFT_SCHEDULED: {
        message: "Your request has been scheduled.",
        noticeType: NoticeType.OK,
        success: true
    },
    SUCCESS_WORKSHIFT_SUBMITTED: {
        message: "Your request has been submitted to the Scheduling Administrator for processing."
        , noticeType: NoticeType.OK
        , success: true
    },
    SERVER_ERROR: {
        message: "Unable to complete your request at this time.\n\nIf the problem persists, contact your technical support staff."
        , noticeType: NoticeType.OK
        , success: false
    },
    NOT_AUTHORIZED: {
        message: "You have not been granted access to this feature.\n\nContact your manager or Web Scheduler administrator for assistance."
        , noticeType: NoticeType.OK
        , success: false
    },
    USER_NOT_FOUND: {
        message: "Your username and/or password are no longer valid. Please Sign Out and then Sign In again."
        , noticeType: NoticeType.OK
        , success: false
    },
    PASSWORD_INVALID: {
        message: "Your username and/or password are no longer valid. Please Sign Out and then Sign In again."
        , noticeType: NoticeType.OK
        , success: false
    },
    ACCOUNT_LOCKED: {
        message: "Your account has been deactivated.\n\nContact your manager or Web Scheduler administrator for assistance."
        , noticeType: NoticeType.OK
        , success: false
    },
    INVALID_ARGUMENT: {message: "{0}", noticeType: NoticeType.OK},
    AUTHENTICATION_FAILURE: {
        message: "Your username and/or password are no longer valid. Please Sign In again."
        , noticeType: NoticeType.OK
        , success: false
        , action: ActionDefs.SIGN_OUT
    },
    COMPLEXITY_FAILURE: {message: "{0}", noticeType: NoticeType.OK, success: false},
    NEW_MATCHES_CURRENT: {
        message: "Your new password must not be the same as your current password. Please enter a different password.",
        noticeType: NoticeType.OK, success: false
    },
    NEW_MATCHES_HISTORY: {message: "{0}", noticeType: NoticeType.OK},
    NO_AVAILABLE_WORKSHIFT: {
        message: "There are no available workshifts. Please select a different date or area."
        , noticeType: NoticeType.OK, success: false
    },
    WORKSHIFT_NOT_AVAILABLE: {
        message: "The selected workshift is no longer available.", noticeType: NoticeType.OK,
        success: false
    },
    SELF_SCHEDULER_OVERLAP_OR_SLOT_NOT_AVAILABLE: {
        message: "Your request conflicts with a previously scheduled workshift or has an issue that requires processing by the Scheduling Administrator.\nWould you like to submit the request to the Scheduling Administrator for approval?",
        confirm: true
        , noticeType: NoticeType.YN, success: false
    },
    OVERLAP_OR_SLOT_NOT_AVAILABLE: {
        message: "There are no available workshifts. Please select a different date or area."
        , noticeType: NoticeType.OK, success: false
    },
    NO_AREAS_OPEN_FOR_REQUEST_TYPE: {
        message: "There are no available workshifts. Please select a different date or area."
        , noticeType: NoticeType.OK, success: false
    },
    LOCKED_BY_ANOTHER_USER: {
        message: "Your request could not be processed at this time. The data is being modified by another user. Please wait a few minutes and try again."
        , noticeType: NoticeType.OK, success: false
    },
    DATA_UPDATED_BY_ANOTHER_USER: {
        message: "Your request could not be processed at this time. The data is being modified by another user. Please wait a few minutes and try again."
        , noticeType: NoticeType.OK, success: false
    },
    WORKSHIFT_ERROR_IN_DATE_RANGE: {
        message: "Unable to schedule your request!",
        noticeType: NoticeType.OK,
        success: false
    },
    MINIMUM_LEAD_TIME_RESTRICTION: {
        message: "It is too late to submit your request using ANSOS2Go. Requests for this area must be submitted at least {0} hours before the workshift start time."
        , noticeType: NoticeType.OK, success: false
    },
    WORKSHIFT_EXPIRED: {
        message: "It is too late to submit your request using ANSOS2Go. The workshift has already started."
        , noticeType: NoticeType.OK, success: false
    },
    SUCCESS_BUT_PASSWORD_CHANGE_REQUIRED: {
        message: "Password change is required. Click OK to sign in and change your password.",
        noticeType: NoticeType.OK,
        success: false,
        action: ActionDefs.SIGN_OUT
    },
    SUCCESS_WITH_PASSWORD_EXPIRATION_WARNING: {
        message: "{0} You can change your password via My Profile.",
        noticeType: NoticeType.OK,
        success: false
    },
    PASSWORD_EXPIRED: {message: "placeholder", noticeType: NoticeType.OK, success: false},
    SUCCESS_BUT_NO_ROLE_ACCESS: {
        message: "Your home area is not configured to allow access to Web Scheduler.\n\nContact your manager or Web Scheduler administrator for assistance."
        , noticeType: NoticeType.OK, success: false
    },
    VERSION_NOT_SUPPORTED: {
        message: "There is a newer version available. Tap OK to reload app.",
        noticeType: NoticeType.OK,
        success: false,
        function: () => {
            relaunch()
        }
    },
    SERVICE_DISABLED: {
        message: "ANSOS2Go is not available. Contact your technical support staff for assistance.",
        noticeType: NoticeType.NONE,
        success: false
    },
    TIMEOFF_ALREADY_FILLED: {
        message: "Currently there is no Time Off available to satisfy your request.\n\nWould you like to submit the request to the Scheduling Administrator for consideration anyway?",
        confirm: true
        , noticeType: NoticeType.YN, success: false
    },
    SERVICE_LOCKOUT: {
        message: "Service is temporarily unavailable while system maintenance is performed.\n\nPlease try again later."
        , noticeType: NoticeType.OK, success: false
    },
    WORKSHIFT_OVERTIME: {
        message: "Selecting this shift will result in overtime and requires manager approval.\n\nDo you wish to continue with submitting this request?",
        confirm: true
        , noticeType: NoticeType.YN, success: false
    },
    SUCCESS_PASSWORD_CHANGED: {
        message: "Your password has been changed successfully.",
        noticeType: NoticeType.OK,
        success: true
    },
    USER_PROFILE_CHANGED: {
        message: "Your user profile was changed. Press OK to reload the app."
        , noticeType: NoticeType.OK
        , success: false
        , function: () => {
            relaunch()
        }
    },
    SUCCESS_RETRACTED: {
        message: "Your retraction was successful.",
        noticeType: NoticeType.OK,
        success: true
    },
    SCHEDULE_CHANGED_AFTER_REQUEST_APPROVED: {
        message: "The schedule has changed since this request was approved. Please review your schedule in the calendar for your actual schedule assignments.",
        noticeType: NoticeType.OK,
        success: false,
        function: () => {
            reload()
        }
    },
    INELIGIBLE_FOR_RETRACT: {
        message: "Selected workshift can no longer be retracted.",
        noticeType: NoticeType.OK,
        success: false,
        function: () => {
            reload()
        }
    },
    SUCCESS_EMAIL_NOTIFICATION_SAVED: {
        message: "Your email notification setting has been saved.",
        noticeType: NoticeType.OK,
        success: true
    },
    SUCCESS_TEXT_NOTIFICATION_SAVED: {
        message: "Your text notification setting has been saved.",
        noticeType: NoticeType.OK,
        success: true
    },
    SUCCESS_USER_PROFILE_UPDATED: {
        message: "Your profile has been changed successfully.",
        noticeType: NoticeType.OK,
        success: true
    },
    SUCCESS_SWAP_PROPOSAL_INITIATED: {
        message: "Your swap proposal has been initiated.",
        noticeType: NoticeType.OK,
        success: true
    },
    SWAP_IN_PROGRESS: {
        message: "Your swap proposal cannot be initiated. One or more of your colleague's workshifts are involved in a swap proposal.",
        noticeType: NoticeType.OK,
        success: false
    },
    SWAP_MINIMUM_LEAD_TIME_RESTRICTION: {
        message: "Request being submitted is less than Min time required.",
        noticeType: NoticeType.OK,
        success: false
    },
    SWAP_WORKSHIFT_EXPIRED: {
        message: "Selected workshift has expired.",
        noticeType: NoticeType.OK,
        success: false
    },
    SWAP_SLOT_NOT_AVAILABLE: {
        message: "Your swap proposal cannot be processed due to No Slots Available.\n\nPlease contact your Scheduling Administrator to resolve the issue.",
        noticeType: NoticeType.OK,
        success: false
    },
    SUCCESS_SWAP_PROPOSAL_DECLINED: {
        message: "Swap proposal is declined.",
        noticeType: NoticeType.OK,
        success: true
    },
    SUCCESS_SWAP_REQUEST_SUBMITTED: {
        message: "Your swap request has been submitted to the Scheduling Administrator for processing.",
        noticeType: NoticeType.OK,
        success: true
    },
    SUCCESS_ASSIGNMENT_SWAPPED: {
        message: "Your swap proposal is accepted. Your assignments have been swapped successfully.",
        noticeType: NoticeType.OK,
        success: true
    },
    API_ERROR: {
        message: "An error was encountered while processing your last action." + PLEASE_TRY_AGAIN,
        noticeType: NoticeType.OK, success: false
    },
    SERVER_INFORMATION: {message: "placeholder", noticeType: NoticeType.OK, success: false},
    SERVER_REDIRECT: {message: "placeholder", noticeType: NoticeType.OK, success: false},
    SERVER_NOT_FOUND: {message: "placeholder", noticeType: NoticeType.OK, success: false},
    RESOURCE_NOT_FOUND: {message: "placeholder", noticeType: NoticeType.OK, success: false},
    RESULT_FORMAT_ERROR: {message: "placeholder", noticeType: NoticeType.OK, success: false},
    ERROR: {message: "An error was encountered." + PLEASE_TRY_AGAIN, noticeType: NoticeType.OK, success: false},
    NETWORK_ERROR: {
        message: "Unable to connect to server." + PLEASE_TRY_AGAIN,
        noticeType: NoticeType.OK,
        success: false
    },
    VALIDATION_ERROR: {
        message: "A validation error occurred." + PLEASE_TRY_AGAIN, noticeType: NoticeType.OK, success: false
    },
    APPLICATION_ERROR: {
        message: "An application error was encountered." + PLEASE_TRY_AGAIN,
        noticeType: NoticeType.OK,
        success: false
    },
    SYSTEM_ERROR: {
        message: "Server was unable to process request." + PLEASE_TRY_AGAIN,
        noticeType: NoticeType.OK, success: false
    },
    GRAPHQL_ERROR: {
        message: "Server was unable to process GraphQL request." + PLEASE_TRY_AGAIN,
        noticeType: NoticeType.OK, success: false
    },
    UNKNOWN: {
        message: "Server encountered fatal error." + PLEASE_TRY_AGAIN,
        noticeType: NoticeType.OK, success: false
    },
    LOADING: {
        message: "Loading ...", noticeType: NoticeType.OK, success: false
    },
    VERIFICATION_CODE_MISMATCH: {
        message: "The verification code you entered does not match the verification code you were sent. Try entering the verification code again",
        noticeType: NoticeType.OK,
        success: false
    },
    RUNTIME_ERROR: {
        message: "ANSOS2Go was unable to complete your request at this time. Please close ANSOS2Go and try again.\n" +
            "\n" +
            "\n" +
            "If the problem persists, contact your technical support staff and provide the\n" +
            "following reference number: \n\n{0}",
        noticeType: NoticeType.NONE, success: false,
        submitIssue: true
    },
    OPERATION_FAILURE: {
        message: "ANSOS2Go was unable to complete this operation.\n\nPlease try again later.",
        noticeType: NoticeType.OK, success: false
    },
}

/**
 * Find matching ServiceStatus
 * @param name ServiceStatus as a string
 * @returns ServiceStatus
 */
export function getServiceStatusCopy(name) {
    if (!name) {
        console.log("status name is required; returning null")
        return null
    }
    const serviceStatus = ServiceStatus[name]
    if (serviceStatus) {
        return {...serviceStatus}
    } else {
        console.log("ServiceStatus '" + name + "' not found!")
        return null
    }
}

/**
 * Given a result from a graphql query or mutation, find the "status" field. Status field could be in data or error.
 * Get ServiceStatus matching "status" field value and fill message placeholders (e.g. {0}) with variables in
 * result.
 *
 * "status" found in data or in result.error.graphQLErrors[0].extensions.status
 * "variables" found in result.error.graphQLErrors[0].extensions.variables
 *
 * @param data from a GraphQL query or mutation
 * @param error or if error encountered
 * @returns serviceStatus matching code received in data or error
 */
export function makeServiceStatus(data, error) {
    console.log(` Data in makeservicestatus: ${JSON.stringify(data)}`)
    console.log(` Error in makeservicestatus: ${JSON.stringify(error)}`)
    if (data && data.status) return getServiceStatusCopy(data.status)
    if (error) {
        let serviceStatusCode, variables
        if (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].extensions) {
            switch (error.graphQLErrors[0].extensions.classification) {
                case "DataFetchingException": {
                    serviceStatusCode = error.graphQLErrors[0].extensions.status
                    if (error.graphQLErrors[0].extensions.variables) {
                        variables = error.graphQLErrors[0].extensions.variables
                    } else {
                        variables = [error.graphQLErrors[0].message]
                    }
                    break
                }
                case "ValidationError": {
                    serviceStatusCode = "VALIDATION_ERROR"
                    variables = [error.graphQLErrors[0].message]
                    break
                }
                default: {
                    serviceStatusCode = "ERROR"
                    variables = [error.graphQLErrors[0].message]
                    break
                }
            }
        } else if (error.networkError || error.message.equals("[Network] Failed to fetch")) {
            serviceStatusCode = "NETWORK_ERROR"
        } else {
            serviceStatusCode = "ERROR"
            variables = [error.message]
        }

        const serviceStatus = getServiceStatusCopy(serviceStatusCode)
        console.log(` Service Status makeservicestatus: ${JSON.stringify(serviceStatus)}`)
        if (serviceStatus) {
            const copy = {...serviceStatus}
            copy.message = fillMessage(serviceStatus.message, variables)
            //console.log(` Message in makeservicestatus: ${JSON.stringify(copy)}`)
            return copy
        }
    }
    return null
}

/**
 * Replace message placeholders, e.g. {0}, with values from variables array.
 *
 * @param message string with {#} placeholders to be replaced by variables[#]
 * @param variables array of string values
 * @returns copy of message with {#} replaced with variables
 */
function fillMessage(message, variables) {
    if (variables && variables.length > 0) {
        let copy = message
        for (let ndx = 0; ndx < variables.length; ndx++) {
            copy = copy.replace("{" + ndx + "}", variables[ndx])
        }
        return copy
    } else {
        return message
    }
}

export function toError(serviceStatus) {
    if (serviceStatus && serviceStatus.success === false) {
        //return {message: serviceStatus.message, action: serviceStatus.action}
        return serviceStatus
    } else {
        return null
    }
}

export function operationFailureError() {
    return {
        "name": "CombinedError",
        "message": "[GraphQL] path=/operationFailureException>OPERATION_FAILURE",
        "graphQLErrors": [
            {
                "message": "path=/operationFailureException>OPERATION_FAILURE",
                "path": ["*"],
                "extensions":
                    {
                        "status": "OPERATION_FAILURE",
                        "cause": "OperationFailureException",
                        "classification": "DataFetchingException"
                    }
            }
        ]
        , "response": {}
    }
}

export default ServiceStatus