import React from "react"
import ErrorView from "./components/View/ErrorView";

class AppErrorBoundary extends React.Component {
    state = {
        errorMessage: '',
        callStack: ''
    }

    static getDerivedStateFromError(error) {
        return {errorMessage: error.toString()}
    }

    componentDidCatch(error, info) {
        console.log("CAUGHT ERROR: "+error+info.componentStack)
        this.state.errorMessage = error.toString()
        this.state.callStack = info.componentStack
    }

    render() {
        if (this.state.errorMessage) {
            return <ErrorView errorMessage={this.state.errorMessage} callstack={this.state.callStack}/>
        }
        return this.props.children;
    }
}

export default AppErrorBoundary;