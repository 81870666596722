import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import {Client, dedupExchange, fetchExchange, Provider as URQLProvider} from 'urql'
import {offlineExchange} from '@urql/exchange-graphcache'
import {makeDefaultStorage} from "@urql/exchange-graphcache/default-storage"
import {introspectedSchema} from "./helpers/schema"
import {updateCacheWithSubmitRequestResult} from "./components/Request/RequestGQL"
import {Provider as StoreProvider} from "overmind-react"
import {Store} from "./Store"
import {hasSearchParams, wsmContext} from "./helpers/http"
import {updateCacheWithSubmitRetractResult} from "./components/Retract/RetractGQL"
import {
    clearCachedUserQuery,
    updateCachedUserQueryWithSwitchProfileResult
} from "./components/UserProfile/UserProfileGQL"
import {clearCachedCalendarQuery} from "./components/Calendar/CalendarGQL"
import {clearCachedParamsAtStartupQuery} from "./components/Settings/SettingsGQL"
import {pipe, tap} from 'wonka'
import {requestPolicyExchange} from "@urql/exchange-request-policy"
import {refocusExchange} from "@urql/exchange-refocus"
import {retryExchange} from "@urql/exchange-retry"
import ServiceStatus, {operationFailureError, makeServiceStatus} from "./helpers/serviceStatus"
import {isEqual} from "./helpers/object"
import AppErrorBoundary from "./AppErrorBoundary";
import {logsInit} from "./helpers/logging";
import {didShowInstallPrompt, isInstalled} from "./helpers/install";

// Initialize logging so logs are captured in sessionStorage and can be submitted with issue report
logsInit()

const WSM_CONTEXT = wsmContext(window.location.href)
const WSM_URL = WSM_CONTEXT + "/api/gql"
console.log("WSM_URL set to: " + WSM_URL)
console.log('public url: ', process.env.PUBLIC_URL)

// Delay prompting for install if app opened to view notifications (i.e., with a search parameter)
if (isInstalled()) {
    console.log("App launched from HomeScreen")
} else if (hasSearchParams()) {
    console.log("App launched from email link to open in browser a specific feature like notifications")
    // Prevent prompting user to install
    didShowInstallPrompt()
} else {
    console.log("App opened in browser; Prompt user to install on HomeScreen")
}

const graphCache = makeDefaultStorage({
    idbName: 'graphcache-v3', // The name of the IndexedDB database
    maxAge: 7, // The maximum age of the persisted data in days
})

/**
 * The OfflineExchange allows for using only cache when the device is offline. If a query is configured to
 * use the requestPolicy 'cache-and-network', the cache result will be fetched and when the network fails due
 * to no internet connectivity, the resulting Network Error will be caught so that it is not returned to view.
 * @type {Exchange}
 */
const offlineCache = offlineExchange({
    schema: introspectedSchema,
    storage: graphCache,
    updates: {
        Mutation: {
            submitRequest: (result, args, cache, info) => {
                updateCacheWithSubmitRequestResult(result, cache)
            },
            submitRetract: (result, args, cache, info) => {
                updateCacheWithSubmitRetractResult(result, cache)
            },
            switchProfile: (result, args, cache, info) => {
                updateCachedUserQueryWithSwitchProfileResult(result, cache)
                clearCachedCalendarQuery(result, cache)
            },
            signout: (result, args, cache, info) => {
                clearCachedUserQuery(result, cache)
                clearCachedCalendarQuery(result, cache)
                clearCachedParamsAtStartupQuery(result, cache)
            },
        },
    },
    optimistic: {
        /* https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#optimistic-updates */
    },
})

/**
 * This is a custom exchange that handles Network Errors by firing service_offline events which
 * are listened for by a function in App.jsx. On each successful GraphQL request a service_online event
 * is fired. App.jsx maintains the state of the network and a banner is added to the view when the device
 * has no internet and when unable to connect with WSMAPI which should be temporary resulting from one of
 * the following:
 *  WSM host can not be reached by the device
 *  WSM application is down or starting
 *  WSM users are locked out while maintenance is performed
 */
export const serviceStateExchange = ({forward}) => ops$ => {
    console.log('SERVICE STATE EXCHANGE ...')
    return pipe(
        forward(ops$),
        tap(result => {
                let event
                if (result && result.error && result.error.networkError) {
                    if (isMutation(result)) {
                        console.error("Operation failure due to Network Error")
                        result.error = operationFailureError()
                    } else {
                        event = "service_offline"
                    }
                } else if (result && result.error && isEqual(makeServiceStatus(result.data, result.error), ServiceStatus.SERVICE_LOCKOUT)) {
                    if (isMutation(result)) {
                        console.error("Operation failure due to Service Lockout")
                        result.error = operationFailureError()
                    } else {
                        event = "service_lockout"
                        result.error.networkError = true
                    }
                } else {
                    event = "service_online"
                }
                if (event) {
                    console.debug("serviceStateExchange FIRING EVENT - " + event)
                    window.dispatchEvent(new Event(event))
                }
            }
        )
    )
}

function isMutation(result) {
    return result.operation.query.definitions[0].operation === 'mutation'
}

// None of these options have to be added, these are the default values.
const retryOptions = {
    initialDelayMs: 15000,
    maxDelayMs: 60000,
    randomDelay: true,
    maxNumberAttempts: 99,
    retryIf: err => err && err.networkError,
}

function renderApp(headers) {

    const client = new Client({
        url: WSM_URL,
        fetchOptions: {
            headers: headers,
        },
        exchanges: [
            dedupExchange,
            refocusExchange(),
            requestPolicyExchange({}),
            offlineCache,
            retryExchange(retryOptions),
            serviceStateExchange,
            fetchExchange],
    })

    console.log("headers=" + JSON.stringify(headers))

    ReactDOM.render(
        <URQLProvider value={client}>
            <StoreProvider value={Store}>
                <AppErrorBoundary>
                    <App/>
                </AppErrorBoundary>
            </StoreProvider>
        </URQLProvider>,
        document.getElementById('root')
    )
}

renderApp({})

// Register Service Worker
const config = {
    onUpdate: (registration) => {
        console.log("SW: sending SKIP_WAITING message to get updated serviceworker")
        // Instruct updated SW to skip waiting on reload, but that only eliminates one reload
        registration.waiting.postMessage({type: 'SKIP_WAITING'})
        // We still need to reload to get updated app code to take effect.
        // For that, we wait on SW to be activated and have SW broadcast a message saying SW has been ACTIVATED.
        // The App.jsx listens for the broadcast message and stores key=SW-STATUS and value=UPDATED.
        // The checkForUpdate() call in View.jsx looks for the UPDATED status and if found notifies user that a new
        // version is available with continue button. When button is tapped app is relaunched the new version is running.
    }
}
serviceWorkerRegistration.register(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
