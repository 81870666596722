import React from 'react';
import './PeriodFormControl.css'
import Form from "react-bootstrap/Form";
import {ROLE} from "../Role";
import {formatDate, stringToDate} from "helpers/date"

const PeriodFormControl = ({name, periods, selectedPeriod, onChange, role}) => {

    if (!periods || periods.length === 0) {
        return <Form.Control className="periodText" type="text" readOnly name={name}
                             value={""}/>
    }

    // Set defaults
    let selectedPeriodFrom = selectedPeriod ? selectedPeriod.from : ""
    onChange = onChange ? onChange : () => {
    }
    const placeholder = "Select a Period"
    const notOpenTag = role === ROLE.EMP ? " (Not Published)" : " (Not Reset)"

    let options = [];
    options.push(<option key="-1" value="" disabled>{placeholder}</option>)

    periods.forEach((period, i) => {

        if ((role === ROLE.EMP && period.published && !period.balancing) ||
            (role === ROLE.SA && period.reset)) {
            options.push(<option className="periodReset" key={i}
                                 value={period.from}>{format(period)}</option>)
        } else {
            options.push(<option className="periodNotReset" key={i} value={period.from}>
                {format(period)}{notOpenTag}
            </option>)
        }
    })

    console.log("PeriodFormControl: selectedPeriodFrom=(" + selectedPeriodFrom +")")

    return (
        //One issue being reported is that -- at re-render, the newer dropdown does not reflect the changed "defaultValue". Reason:
        // "Changing the value of defaultValue attribute after a component has mounted will not cause any update of the value in the DOM."
        // "rerender means calling render for all components, it doesn�??t mean React will unmount and remount them. It will only apply the differences following the rules stated in the previous sections."
        // https://reactjs.org/docs/uncontrolled-components.html
        // https://reactjs.org/docs/reconciliation.html
        //So defaultValue is only for the initial load, and it may not get modified at subsequent re-render. To get around this, technique "key" is employed here
         <div key={selectedPeriodFrom} className="areaScheduleSelect" >
            <Form.Control className="periodSelect" as="select" name={name}
                             defaultValue={selectedPeriodFrom}
                             onChange={e => onChange(e.target.value)} disabled={!periods.length}>
              {options}
            </Form.Control>
         </div>

    )

}

function format(period, placeholder = "") {
    if (period && period.from && period.to) {
        return formatDate(stringToDate(period.from)) + " to " + formatDate(stringToDate(period.to))
    } else {
        return ""
    }
}

export default PeriodFormControl