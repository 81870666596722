import {ServiceStatus, makeServiceStatus} from "helpers/serviceStatus";
import {ROLE} from "../Role";

function emptyUser() {
    return {defaultRole: ROLE.NONE, roles: [], functionAccess: {},
        notification: {
            notfyEmailVerified: false,
        }}
}

export function processUserQueryResult(fetchingUQ, dataUQ, errorUQ) {
    if (errorUQ) {
        const serviceStatus = makeServiceStatus(dataUQ ? dataUQ.userProfile : null, errorUQ)
        if (serviceStatus && !serviceStatus.success) {
            return [emptyUser(), serviceStatus]
        }
    }

    if (dataUQ && dataUQ.userProfile) {
        return [dataUQ.userProfile]
    } else {
        return [emptyUser()]
    }
}

export function emptyUserProfile() {
    console.log("return empty userProfile")
    return {
        defaultRole: ROLE.NONE,
        areasSA: [],
        areasEmp: [],
        roles: [], functionAccess: {},
        notification: {
            carrierOptionList: [],
            notfyAsSelectedAreas: [],
        }}
}

export function processUserProfileQueryResult(fetchingUPQ, dataUPQ, errorUPQ) {
    console.log("processUserProfileQueryResult...")
    if (errorUPQ) {
        const serviceStatus = makeServiceStatus(dataUPQ ? dataUPQ.userProfile : null, errorUPQ)
        if (serviceStatus && !serviceStatus.success) {
            return [emptyUserProfile(), serviceStatus]
        }
    }

    if (dataUPQ && dataUPQ.userProfile) {
        return [dataUPQ.userProfile]
    } else {
        return [emptyUserProfile()]
    }
}
export function makeUpdateUserProfileResultStatus(fetchingUUPM, dataUUPM, errorUUPM) {
    if(errorUUPM){
        let serviceStatus = makeServiceStatus(null, errorUUPM)
        return serviceStatus
    }
    if(dataUUPM && dataUUPM.updateUserProfile) {
        return ServiceStatus.SUCCESS_USER_PROFILE_UPDATED
    }
    else return ServiceStatus.UNKNOWN
}

export function makeSendEmailVerificationCodeStatus(fetchingEVQ, dataEVQ, errorEVQ) {
    if (errorEVQ) {
        const serviceStatus = makeServiceStatus(null, errorEVQ)
        return serviceStatus
    }
    if(dataEVQ && dataEVQ.sendEmailVerificationCode) {
        return ServiceStatus.SUCCESS
    }
    else return ServiceStatus.UNKNOWN

}

export function makeSendPhoneVerificationCodeStatus(fetchingPVQ, dataPVQ, errorPVQ) {
    if (errorPVQ) {
        const serviceStatus = makeServiceStatus(null, errorPVQ)
        return serviceStatus
    }
    if(dataPVQ && dataPVQ.sendPhoneVerificationCode) {
        return ServiceStatus.SUCCESS
    }
    else return ServiceStatus.UNKNOWN

}


export function makeVerifyEmailVerificationCodeStatus(fetchingVEVM, dataVEVM, errorVEVM) {
    if(errorVEVM){
        let serviceStatus = makeServiceStatus(null, errorVEVM)
        return serviceStatus
    }
    if(dataVEVM && dataVEVM.verifyEmailVerificationCode) {
        return ServiceStatus.SUCCESS_EMAIL_NOTIFICATION_SAVED
    }
    else return ServiceStatus.UNKNOWN //This is when "pause" is true and this query is not really sent
}


export function makeVerifyPhoneVerificationCodeStatus(fetchingVEVM, dataVEVM, errorVEVM) {
    if(errorVEVM){
        let serviceStatus = makeServiceStatus(null, errorVEVM)
        return serviceStatus
    }
    if(dataVEVM && dataVEVM.verifyPhoneVerificationCode) {
        return ServiceStatus.SUCCESS_TEXT_NOTIFICATION_SAVED
    }
    else return ServiceStatus.UNKNOWN //This is when "pause" is true and this query is not really sent
}


