import React from 'react';
import AreaFormControl from "components/AreaFormControl";
import PeriodFormControl from "components/PeriodFormControl";
import {ROLE} from "components/Role";
import './AreaScheduleControls.css'

const AreaScheduleControls = ({
                                  areas = [],
                                  selectedArea = {},
                                  onAreaChange,
                                  periods = [],
                                  selectedPeriod = {},
                                  onPeriodChange,
                                  role
                              }) => {
    const notice = (selectedPeriod &&
        ((role === ROLE.SA && !selectedPeriod.balancing) ||
            (role === ROLE.EMP && selectedPeriod.published && !selectedPeriod.balancing))) ?
        selectedPeriod.notice : ""
    return <div id="areaScheduleControls">
        <div className="area">
            <AreaFormControl name="areaId" areas={areas} selectedArea={selectedArea} onChange={onAreaChange}/>
        </div>
        <div className="period">
            <PeriodFormControl name="periodId" periods={periods} selectedPeriod={selectedPeriod}
                               onChange={onPeriodChange} role={role}/>
            <div className="periodNotice">{notice}</div>
        </div>
    </div>
}

export default AreaScheduleControls