import React from 'react'
import ProposedSwapProposalControl from "./ProposedSwapProposalControl"
import "./proposedSwaps.css"
import "../swaps.css"

const ProposedSwapsListForm = ({proposedSwapProposals, actions}) => {



    return (
        <div>
            {proposedSwapProposals && proposedSwapProposals.length >0
            && proposedSwapProposals.map((proposedSwap) => {
                return (
                    <ProposedSwapProposalControl
                        proposedSwap={proposedSwap}
                        actions={actions}
                        key={proposedSwap.recProposalId}
                    />
                )
            })
            }

        </div>
    )

}


export default ProposedSwapsListForm