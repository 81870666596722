import React from "react";
import {default as BootstrapSpinner} from 'react-bootstrap/Spinner'

const Spinner = ({ show, variant="primary" }) => {
    if (show) {
        const style = {position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"};
        return (
            <div style={style}>
                <BootstrapSpinner animation="border" variant={variant}/>
            </div>
        )
    } else {
        return <></>
    }
}

export default Spinner