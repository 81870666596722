import Button from "react-bootstrap/Button"
import React from "react"

const SwapInitiateButtonControl = ({onSwapListFormSubmit, selectedSwapRecipient, enableSubmitButton}) => {

    const selectedRecipientKeyArr = [...selectedSwapRecipient]

    return (
        <div>
            <Button onClick={onSwapListFormSubmit} disabled={!enableSubmitButton}>
                <div className="flexDisplay flexAlignCenter">
                    <div>Review Selections</div>
                    <div className="numberCircle">{selectedRecipientKeyArr.length}</div>
                </div>
            </Button>
        </div>
    )

}

export default SwapInitiateButtonControl
