import React from 'react'
import './Role.css'
import {ActionDefs as ActionDef, findAction} from "actions";

export const ROLE = {
    NONE: 'NONE',
    EMP: 'EMP',
    SA: 'SA',
    BOTH: 'BOTH'
}

const Role = ({user, role, actions}) => {
    if (role === ROLE.SA || role === ROLE.EMP) {
        if (user.roles.length > 1) {
            const changeRole = findAction(ActionDef.CHANGE_ROLE, actions).function
            if (role === ROLE.SA) {
                return <div className="role" onClick={changeRole}><span className="active-role">SA</span><span
                    className="inactive-role"> | EMP</span></div>
            } else {
                return <div className="role" onClick={changeRole}><span className="inactive-role">SA | </span><span
                    className="active-role">EMP</span></div>
            }
        }
        //return <span className="active-role">{role.valueOf()}</span>
        return <></>
    } else {
        return <></>
    }
}

export const getRole = (user) => {
    return isBoth(user) ? ROLE.BOTH : isEMP(user) ? ROLE.EMP : isSA(user)? ROLE.SA : ROLE.NONE
}

export const isBoth = (user) => {
    return isEMP(user) && isSA(user)
}

export const isSA = (user) => {
    let found = false
    user.roles.map((role) => {
            if (role === ROLE.SA) found = true
        }
    )
    return found
}

export const isEMP = (user) => {
    let found = false
    user.roles.map((role) => {
            if (role === ROLE.EMP) found = true
        }
    )
    return found
}

export default Role