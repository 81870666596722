import {makeServiceStatus} from "helpers/serviceStatus";

export function processLegalNoticeData(fetching, data, error) {
    console.log("processLegalNoticeData() fetching=" + fetching + ";data=" + JSON.stringify(data + ";error=" + JSON.stringify(error)))
    if (error) {
        const serviceStatus = makeServiceStatus(data ? data.legalNotice : null, error)
        if (serviceStatus && !serviceStatus.success) {
            return {legalNotice: {}, serviceStatus: serviceStatus}
        }
    }

    if (data && data.legalNotice) {
        return {legalNotice: data.legalNotice, serviceStatus: null}
    } else {
        return {legalNotice: {}, serviceStatus: null}
    }
}
