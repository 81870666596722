import * as React from "react"
import {HeaderCell, HeaderRow,} from "@table-library/react-table-library/table"

function PeriodHeaderRow({periodDays}) {

    return <HeaderRow>
        <HeaderCell className="rowHeader" pinLeft></HeaderCell>
        {periodDays.map((day) => (
            <HeaderCell key={day.day} className={day.className}>
                <div className="dow">{day.dow}</div>
                <div className="mmmd">{day.mmmd}</div>
            </HeaderCell>
        ))}
    </HeaderRow>
}

export default PeriodHeaderRow;
