import React from 'react'
import './User.css'
import {ROLE as Role} from "components/Role";
import {Link} from "react-router-dom";

export const PRIMARY_PROFILE = 'Primary'

const customDisplayName = (user) => {
    if (user && user.defaultRole !== Role.NONE) {
        return (user.customDisplayName && user.customDisplayName.length > 0) ? user.customDisplayName : user.defaultDisplayName
    } else {
        return null
    }
}

const User = ({user}) => {
    if (user && user.defaultRole !== Role.NONE) {
        const userName = customDisplayName(user)
        return (
            <div className="user">{userName?userName.substring(0,16).trim():userName} <span className="user-skill-dcpos">{user.skill} {user.dcpos}</span></div>
        )
    } else {
        return <></>
    }
}

export function hasMultipleProfiles(user) {
    return (user && user.defaultRole !== Role.NONE && user.hasMultipleDcpos)
}

export const ActiveProfile = ({user, action}) => {
    if (hasMultipleProfiles(user)) {
        return <div className="profile">Profile: <Link className="profile-link" to=""
                                                       onClick={() => action()}>{user.profileName}</Link></div>
    } else {
        return ""
    }
}

export const ActiveProfileName = ({user}) => {
    if (hasMultipleProfiles(user)) {
        return "Profile: " + user.profileName
    } else {
        return ""
    }
}

export const activeProfileClassName = (user) => {
    console.log(`Acvtive Profile Class Name - ${user.profileName}`)
    console.log(`Acvtive Profile Is Primary profile - ${user.primaryProfile ? "Primary" : "Secondary"}`)
    return (user.primaryProfile ? "profilePrimary" : "profileOther")
}

export default User