import React, {useState, useEffect} from 'react'
import Form from "react-bootstrap/Form"
import {useFormContext} from "react-hook-form"
import AreaMultiSelectDropDown from "./AreaMultiSelectDropDown"

export const selectAllOptionValue = '*'



const  NotifyAreaForm = ({data: userProfileData}) => {
    const NOTIFY_AREAS_TYPE  = Object.freeze( {
        allAreas: 'allAreas',
        selectedAreas: 'selectedAreas',
    })

    const { register, getValues, setValue} = useFormContext() // retrieve all hook methods

    const initialNotifyAsAllAreas= userProfileData.notification.notfyAsAllAreas

    const getAccessibleAreaOptions = (userProfileData) => {

        let options = []
        let areas = userProfileData.areasEmp

        if (areas && areas.length > 0) {
            areas.forEach((area) => {
                let areaOption = {}
                areaOption.value = area.id
                areaOption.label =  area.id + " " + area.name
                options.push(areaOption)
            })
        }
        return options;

    }

    const notifyAreaOptions = getAccessibleAreaOptions(userProfileData)


    const selectAllOption = {
        value: selectAllOptionValue,
        label: `Select All (${notifyAreaOptions.length})`
    }


    // An example of initiallySelectedAreas:  [{value: "AH", label: "AH WHIDDEN:ED"}, {value: "At", label: "At WHIDDEN:TRAV"}]
    const getInitiallySelectedAreas = (userProfileData) => {
        let initiallySelectedAreas = [];
        let allAccessibleAreas = userProfileData.areasEmp
        let selectedAreaIds = userProfileData.notification.notfyAsSelectedAreas

        if (NOTIFY_AREAS_TYPE[initialNotifyAsAllAreas] === NOTIFY_AREAS_TYPE.selectedAreas) {
            if (selectedAreaIds && selectedAreaIds.length > 0) {
                selectedAreaIds.forEach((areaId) => {
                    let areaOption = {}
                    areaOption.value = areaId
                    //find area name for this "areaId"
                    let areaFound = allAccessibleAreas.find(
                            area => area.id === areaId)
                    //There is a possibility that areaFound could be undefined when saved "notify area" is no longer accessible.
                    //Hence let's check for "undefined"
                    if(areaFound) {
                        areaOption.label = areaId + " " + areaFound.name
                        initiallySelectedAreas.push(areaOption)
                    }
                })

            }
        }
        else if(NOTIFY_AREAS_TYPE[initialNotifyAsAllAreas] === NOTIFY_AREAS_TYPE.allAreas) {
            initiallySelectedAreas = notifyAreaOptions
        }

        // console.log("initiallySelectedAreas = " + JSON.stringify(initiallySelectedAreas))
        return userProfileData.areasEmp.length === initiallySelectedAreas.length
                ? [selectAllOption, ...initiallySelectedAreas] : initiallySelectedAreas


    }

    //with async nature of useQuery, userProfileData (which is result of useQuery hook) is not available at initial render time
    //useState initial state only set at the 1st render time. In order to initialize based on query result,
    //we will take advantage of combined power of (useEffect + setState) to achieve our intention.
    const [optionSelected, setOptionSelected] = useState(undefined /*(getInitiallySelectedAreas(userProfileData)*/)

    function checkSelectedArea() {
        setValue('notifyArea', 'selectedAreas')
    }

    function setSelectedMultiArea(optionSelected, shouldDirty){
        setOptionSelected(optionSelected)
        setValue("empSelectedNotifyArea", getEmpSelectedAreaIdArr(optionSelected), { shouldDirty: shouldDirty })
    }

    const  onOptionSelected = (optionSelected) => {
        setSelectedMultiArea(optionSelected, true)
    }



    useEffect(() => {
        // Only set state when "userProfileData changes", which would be then be when "useQuery" hook result fetching completes
        // With the dependencies of this useEffect, it would only run once, exactly what we want.
        //Here is the sequence of events:
        //1. when this form initially renders, "multiple area select dropdown" has nothing selected
        //2. useQuery hook result returns, and a re-render would happen.
        //3. Now useEffect runs, and do this setOptionSelected, which would bring in a re-render
        //4. This time, "optionSelected" has the correct initial value based on query result.
        //   "multiple area select dropdown" would display properly
        let initiallySelectedAreas = getInitiallySelectedAreas(userProfileData)
        setSelectedMultiArea(initiallySelectedAreas, false) //"shouldDirty" has to be false. We are syncing up the view with db

    }, [userProfileData.areasEmp, userProfileData.notification.notfyAsSelectedAreas])


    const getEmpSelectedAreaIdArr =  (optionSelected) => {
        // console.log("optionSelected" + JSON.stringify(optionSelected))
        let selectedAreaIdArr = []

        if (optionSelected && optionSelected.length > 0) {
            optionSelected.forEach((selected) => {
                if(selected.value !== selectAllOption.value) {
                    selectedAreaIdArr.push(selected.value)
                }
            })
        }
        // console.log("selectedAreaIdArr" + JSON.stringify(selectedAreaIdArr))
        return selectedAreaIdArr
    }



    return (

            <>
                <Form.Group>
                    <fieldset className="fieldsetClass">
                        <legend className="formLabel">Include Shifts scheduled for</legend>
                        {/*div key is to force below checkboxes to re-render and take the newer defaultValue (selectedOption) once userProfile fetched */}
                        <div key={userProfileData.ansosId}>
                            <Form.Group>

                                <label className="hsRadio">
                                    <input type="radio"
                                           name='notifyArea'
                                           defaultChecked = {NOTIFY_AREAS_TYPE[initialNotifyAsAllAreas] === NOTIFY_AREAS_TYPE.allAreas}
                                           value='allAreas'
                                           ref={register}
                                           onChange={()=>{
                                               let notifyAsAllAreas = getValues('notifyArea')
                                               if(NOTIFY_AREAS_TYPE[notifyAsAllAreas] === NOTIFY_AREAS_TYPE.allAreas) {
                                                   let allAreaOptions = [selectAllOption, ...notifyAreaOptions]
                                                   setSelectedMultiArea(allAreaOptions, true)
                                               }

                                           }
                                           }
                                    />
                                    <span className="radioCheckMark"> </span>
                                    <span className="hsCheckboxLabel">All Areas</span>
                                </label>

                                <label className="hsRadio">
                                    <input type="radio"
                                           name='notifyArea'
                                           defaultChecked = {NOTIFY_AREAS_TYPE[initialNotifyAsAllAreas] === NOTIFY_AREAS_TYPE.selectedAreas}
                                           value='selectedAreas'
                                           ref={register}
                                    />
                                    <span className="radioCheckMark"> </span>
                                    <span className="hsCheckboxLabel">Selected Areas</span>
                                </label>

                            </Form.Group>

                        </div>

                        <AreaMultiSelectDropDown
                                areaOptions={notifyAreaOptions}
                                selectAllOption={selectAllOption}
                                optionSelected={optionSelected}
                                setOptionSelected={onOptionSelected}
                                checkSelectedArea={checkSelectedArea}
                                />


                        {/*That hidden input's value is the state of the react-select component that is bound to it.*/}
                        {/* ref={register} is then passed to that hidden input. This way "react-hooks-form" would have the selected value */}

                        {/* Now My initial iteration was to use React-hook-form to integrate with React-select.*/}
                        {/* But it turns out that there is an integration bug in React-hook-form: it breaks at "onChange" */}
                        {/* This bug has been reported by other folks https://github.com/react-hook-form/react-hook-form/issues/1214*/}
                        {/* So the alternative is to  the react-select component to a "twin" hidden input. This works well*/}
                        {/* We need to (1) make sure the value is always up-to-date. This is done by setting the value whenever multi-select widget changes value  */}
                        {/* (2)have form "check dirty" work. This is done by passing "shouldDirty" when call react-hook-form's setValue  */}
                        <input  type="hidden" name="empSelectedNotifyArea" ref={register} />

                    </fieldset>
                </Form.Group>

            </>


    )



}

export default NotifyAreaForm