import React, {useState, useEffect} from 'react'
import Form from "react-bootstrap/Form"
import Col from 'react-bootstrap/Col'
import {useFormContext} from "react-hook-form"
import "react-datepicker/dist/react-datepicker.css"
import TimePicker from "./TimePicker"
import {parseDateFromHHMM, parseDateFromHHColonMM, formatDateTimeToHHMM} from "helpers/time"


const  NotifyTimeRangeForm = ({data: userNotificationData}) => {
    const { register , setValue} = useFormContext() // retrieve all hook methods
    // console.log("userNotificationData = " + JSON.stringify(userNotificationData))

    //JS new Date() is using client browser's local time. But it would not cause timezone issue here.
    // 1. "today" here is just an initial value to initialize those state.
    //2. At "useEffect" the hhmm part of "today" would be replaced by whatever is saved in database.
    //3. "date" part of "today" never matters in our case.
    let today= new Date()


    //Below states are for "TimePicker" widget
    //with async nature of useQuery, userProfileData (which is result of useQuery hook) is not available at initial render time
    //useState initial state only set at the 1st render time. In order to initialize based on query result,
    //we will take advantage of combined power of (useEffect + setState) to achieve our intention.
    const [notifyStartTime, setNotifyStartTime] = useState(today)
    const [notifyEndTime, setNotifyEndTime] = useState(today)

    const [noDisturbStartTime, setNoDisturbStartTime] = useState(today)
    const [noDisturbEndTime, setNoDisturbEndTime] = useState(today)


    useEffect(() => {
        // Only set state when "userProfileData changes", which would be then be when "useQuery" hook result fetching completes
        // With the dependencies of this useEffect, it would only run once, exactly what we want.
        //Here is the sequence of events:
        //1. when this form initially renders, 4 time pickers just got initialized to today
        //2. useQuery hook result returns, and a re-render would happen.
        //3. Now useEffect runs, and do this setNotifyXXX, which would bring in a re-render
        //4. This time, 4 time pickers have the correct initial value based on query result. All good
        let initialNotifyStartTime = parseDateFromHHMM(today,userNotificationData.notfyAsShiftsBtwnStart )
        let initialNotifyEndTime = parseDateFromHHMM(today,userNotificationData.notfyAsShiftsBtwnEnd )
        let initialNoDisturbStartTime = parseDateFromHHMM(today,userNotificationData.notfyAsNoDisturbStart )
        let initialNoDisturbEndTime = parseDateFromHHMM(today,userNotificationData.notfyAsNoDisturbEnd )

        setNotifyStartTimePicker(initialNotifyStartTime, false) //"shouldDirty" has to be false. We are syncing up the view with db
        setNotifyEndTimePicker(initialNotifyEndTime, false)
        setNoDisturbStartTimePicker(initialNoDisturbStartTime, false)
        setNoDisturbEndTimePicker(initialNoDisturbEndTime, false)


    }, [userNotificationData.notfyAsShiftsBtwnStart,
        userNotificationData.notfyAsShiftsBtwnEnd,
        userNotificationData.notfyAsNoDisturbStart,
        userNotificationData.notfyAsNoDisturbEnd])

    //"shouldDirty" parameter would be true in order to set field to dirty.
    function setNotifyStartTimePicker(date, shouldDirty){
        setNotifyStartTime(date)
        setValue("notifyStartTime", formatDateTimeToHHMM(date), { shouldDirty: shouldDirty })
    }

    function setNotifyEndTimePicker(date, shouldDirty){
        setNotifyEndTime(date)
        setValue("notifyEndTime", formatDateTimeToHHMM(date), { shouldDirty: shouldDirty  })
    }

    function setNoDisturbStartTimePicker(date, shouldDirty){
        setNoDisturbStartTime(date)
        setValue("noDisturbStartTime", formatDateTimeToHHMM(date), { shouldDirty: shouldDirty  })
    }

    function setNoDisturbEndTimePicker(date, shouldDirty){
        setNoDisturbEndTime(date)
        setValue("noDisturbEndTime", formatDateTimeToHHMM(date), { shouldDirty: shouldDirty } )
    }

    //onChangeXXXTime is handling when end user pick a time (interval by 30) from the picklist
    //onChangeXXXTimeRaw is handling when end user types into text input directly
    const onChangeNotifyStartTime = (date) => {
        setNotifyStartTimePicker(date, true)
    }
    const onChangeNotifyEndTime = (date) => {
        setNotifyEndTimePicker(date, true)
    }
    const onChangeNoDisturbStartTime = (date) => {
        setNoDisturbStartTimePicker(date, true)
    }
    const onChangeNoDisturbEndTime = (date) => {
        setNoDisturbEndTimePicker(date, true)
    }

    //onChangeXXXTimeRaw would swallow any invalid chars from text input if any
    const onChangeNotifyStartTimeRaw = (e) => {
        let date = parseDateFromHHColonMM(today, e.target.value)
        setNotifyStartTimePicker(date, true)
    }

    const onChangeNotifyEndTimeRaw = (e) => {
        let date = parseDateFromHHColonMM(today, e.target.value)
        setNotifyEndTimePicker(date, true)
    }

    const onChangeNoDisturbStartTimeRaw = (e) => {
        let date = parseDateFromHHColonMM(today, e.target.value)
        setNoDisturbStartTimePicker(date, true)
    }

    const onChangeNoDisturbEndTimeRaw = (e) => {
        let date = parseDateFromHHColonMM(today, e.target.value)
        setNoDisturbEndTimePicker(date, true)
    }


    return (
            <div>
                <Form.Group>
                    <fieldset className="fieldsetClass">
                        <Form.Group>
                            <div>
                                <label className="hsCheckbox">
                                    <input type="checkbox"
                                           name='notifyShiftBetween'
                                           defaultChecked = {userNotificationData.notfyAsShiftsBtwnEnabled}
                                           ref={register}
                                    />
                                    <span className="hsCheckmark"/>

                                </label>
                            </div>

                            <div className="hsCheckboxLabel boldFont">
                                <span>Only include shifts scheduled</span>
                            </div>

                        </Form.Group>

                        <Form.Row className='mt-2'>
                            <Col>
                                <Form.Label>between</Form.Label>
                            </Col>
                            <Col>

                                <TimePicker
                                        selected={notifyStartTime}
                                        onChange={onChangeNotifyStartTime}
                                        onChangeRaw={onChangeNotifyStartTimeRaw}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mt-2'>
                            <Col>
                                <Form.Label>and</Form.Label>
                            </Col>
                            <Col>

                                <TimePicker
                                        selected={notifyEndTime}
                                        onChange={onChangeNotifyEndTime}
                                        onChangeRaw={onChangeNotifyEndTimeRaw}
                                />
                            </Col>
                        </Form.Row>


                    </fieldset>
                </Form.Group>

                <Form.Group>
                    <fieldset className="fieldsetClass">
                        <Form.Group>
                            <div>
                                <label className="hsCheckbox">
                                    <input type="checkbox"
                                           name='notifyDNDBetween'
                                           defaultChecked = {userNotificationData.notfyAsNoDisturbEnabled}
                                           ref={register}
                                    />
                                    <span className="hsCheckmark"/>

                                </label>
                            </div>

                            <div className="hsCheckboxLabel boldFont">
                                <span>Do not send me notifications</span>
                            </div>

                        </Form.Group>


                        <Form.Row className='mt-2'>
                            <Col>
                                <Form.Label>between</Form.Label>
                            </Col>
                            <Col>

                                <TimePicker
                                        selected={noDisturbStartTime}
                                        onChange={onChangeNoDisturbStartTime}
                                        onChangeRaw={onChangeNoDisturbStartTimeRaw}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row className='mt-2'>
                            <Col>
                                <Form.Label>and</Form.Label>
                            </Col>
                            <Col>

                                <TimePicker
                                        selected={noDisturbEndTime}
                                        onChange={onChangeNoDisturbEndTime}
                                        onChangeRaw={onChangeNoDisturbEndTimeRaw}
                                />
                            </Col>
                        </Form.Row>

                    </fieldset>

                    {/*That hidden input's value is the state of the timepicker component that is bound to it.*/}
                    {/* ref={register} is then passed to that hidden input. This way "react-hooks-form" would have the value from timepicker */}
                    {/* This is far easier and more robust than integrating "react-hooks-form" with our customized timepicker */}
                    {/* We need to (1) make sure the value is always up-to-date. This is done by setting the value whenever picker changes value  */}
                    {/* (2)have form "check dirty" work. This is done by passing "shouldDirty" when call react-hook-form's setValue  */}
                    <input type="hidden" name="notifyStartTime"  ref={register} />
                    <input type="hidden" name="notifyEndTime" ref={register} />
                    <input type="hidden" name="noDisturbStartTime" ref={register} />
                    <input type="hidden" name="noDisturbEndTime" ref={register} />

                </Form.Group>
            </div>

    )


}

export default NotifyTimeRangeForm