import gql from 'graphql-tag'
import {useQuery} from 'urql'

export const NOTIFICATIONS_QUERY = gql`
    query Notifications {
        notifications {
            notifications {
                areaId
                areaName
                workshift {
                    workshiftId
                    on
                    from
                    to
                    workMinutes
                    code
                    name
                    label
                    category
                    work
                    skill
                    format
                    requiresApproval
                    id
                }
                isAvailable
                id
            }         
            assignments {
                code
                from
                to
                areaId
                name
                label
                date
                format
                id
            }
        }
    }`

export function useNotificationsQuery() {
    console.log("useNotificationsQuery()")
    return useQuery({
        query: NOTIFICATIONS_QUERY,
        variables: {},
        requestPolicy: "cache-and-network",
    })
}
