import {getParamsAtStartup} from "../components/Settings/SettingsData";

const INSTALL_PROMPT_DATE_KEY = "lastInstallPromptAt"

export function hideInstallPrompt() {
    console.log("trace...hideInstallPrompt()")
    const dateString = window.localStorage.getItem(INSTALL_PROMPT_DATE_KEY)
    console.log("dateString=" + dateString)
    if (dateString) {
        const installPromptDate = new Date(dateString)
        const now = new Date()
        const difference_In_Time = now.getTime() - installPromptDate.getTime();
        const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
        const installPromptInterval = parseInt(getParamsAtStartup().installPromptInterval)
        console.log("installPromptInterval=" + installPromptInterval + "; diff=" + difference_In_Days)
        return difference_In_Days < installPromptInterval
    } else {
        console.log("FIRST TIME")
        return false
    }
}

export function didShowInstallPrompt() {
    const dateString = new Date().toISOString()
    window.localStorage.setItem(INSTALL_PROMPT_DATE_KEY, dateString)
}

export function isInstalled() {
    const displayMode = getPWADisplayMode()
    return displayMode === "twa" || displayMode === "standalone"
}

function getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
        return 'twa';
    } else if (navigator.standalone || isStandalone) {
        return 'standalone';
    }
    return 'browser';
}