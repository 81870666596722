import React from 'react'
import MultiSelect from "./MultiSelect"
import makeAnimated from "react-select/animated"
import {components} from "react-select"
import {selectAllOptionValue} from "./NotifyAreaForm"



const Option = props => {
    // console.log("Option props = " + JSON.stringify(props))

    return (
            <div>
                <components.Option {...props}>

                    <span className="hsCheckbox" style={{top: "0.3rem"}}>
                        <input type="checkbox"
                               checked={props.isSelected}
                               onChange={() => {
                                   // console.log("logging props in Option : " + JSON.stringify(props))
                                   return null
                               }
                               }
                        />
                        <span className="hsCheckmark"/>

                    </span>

                    <span className={"areaLabelSpan"}>{props.label}</span>

                </components.Option>

            </div>
    )
}

const animatedComponents = makeAnimated()

const MultiValue = props => {
    // let labelToBeDisplayed = `${props.data.value}`
    let labelToBeDisplayed = `${props.data.label}`
    return (
            <components.MultiValue {...props}>
                <span>{labelToBeDisplayed}</span>
            </components.MultiValue>
    )
}

//max count displayed in the menu placeholder
const MAX_DISPLAY = 5

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue()
    let toBeRendered = children;

    // If "Select all" is a selected option, We do not want to render it in placeholder. Let's exclude it(children[0][0])
    if (currentValues.some(val => val.value === selectAllOptionValue)) {
        let selectedOptionsLen = children[0].length
        let optionsExcludingFirst = children[0].slice(1, selectedOptionsLen)
        toBeRendered = [optionsExcludingFirst, children[1]]
    }
    let itemCount = toBeRendered[0].length
    let extra
    //We want to display up to MAX_DISPLAY in the menu placeholder
    if(itemCount> MAX_DISPLAY) {

        let optionsWithCap = toBeRendered[0].slice(0, MAX_DISPLAY)
        let leftoverCount = itemCount - MAX_DISPLAY
        extra = `and ${leftoverCount} more areas...`
        toBeRendered = [optionsWithCap, children[1]]

    }

    return (
            <components.ValueContainer {...props}>
                {toBeRendered}
                { (itemCount> MAX_DISPLAY) &&
                        <span>{extra}</span>

                }
            </components.ValueContainer>
    )
}




const AreaMultiSelectDropDown = ({areaOptions, selectAllOption, optionSelected, setOptionSelected, checkSelectedArea}) =>{

    return (
    <>
        <MultiSelect
                options={areaOptions}
                selectAllOption={selectAllOption}
                isMulti
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
                // menuIsOpen={true} //Default is false; Uncomment for Debugging only. So that developer can inspect the dropdown menu when needed
                components={{ Option, MultiValue, ValueContainer, animatedComponents }}
                onChange={(selected)=>{
                    setOptionSelected(selected)
                    if (selected && selected.length < (areaOptions.length + 1)){
                        checkSelectedArea()
                    }
                }}
                allowSelectAll={true}
                value={optionSelected}
                menuPosition={'fixed'}
                maxMenuHeight={'38rem'}
                menuPlacement={'top'}

        />



    </>
    )
}

export default AreaMultiSelectDropDown