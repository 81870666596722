import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import MaskedInput from "react-text-mask"
import React, {forwardRef} from "react";

const TimePicker = props => {

    const CustomInput = forwardRef(({onChange, onBlur, value, onClick, onChangeRaw}, _ref) => {
        // console.log("props.onChange = " +(onChange.toString()))
        // console.log("props.onBlur = " +(onBlur.toString()))
        // console.log("props.onChangeRaw = " +(onChangeRaw))
        // console.log("props.onClick = " +(onClick.toString()))

        let handleInputBlur = event => {
            onChangeRaw(event)
            //Below is to address a corner case: when end user types in from input box, then clicks the icon
            //Then icon would not respond by bring up the picklist. This is because onBlur is overriding onClick here:
            //https://github.com/facebook/react/issues/4210
            // onBlur is triggering a re-render, which would then lead the browser to do not follow up with the onClick
            // 1. One workaround for us is to detect when the onBlur is actually triggered by the onClick and chain
            // whatever we need to do.

            if(event.relatedTarget && event.relatedTarget.type === "button"){
                event.relatedTarget.click()
            }
        }

        // let handleClick = e => {
        //     console.log("enter handleClick")
        //     onClick()
        // }

        return (
                <div className="timeControlDiv">
                    <div>
                        <MaskedInput
                                mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                                value={value}
                                onBlur ={handleInputBlur}
                                // onBlur ={onChange}
                                className="timeControlInput"
                                inputMode='numeric'

                        />

                    </div>

                    <button  className="toggleButton"
                            type="button"
                            // onClick={handleClick}
                            onClick={onClick}
                    >
                        <div><i className="fa fa-clock" ></i></div>
                    </button>

                </div>
        )
    })

    return (
            <DatePicker
                    selected={props.selected}
                    onChange={props.onChange}
                    onChangeRaw={props.onChangeRaw}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    popperPlacement="top-start"
                    customInput={<CustomInput onChangeRaw={props.onChangeRaw}/>}
                    popperModifiers={[
                        {
                            name: "offset",
                            options: {
                                offset: [5, -5],
                            },
                        },
                    ]}
            />
            )

}


export default TimePicker