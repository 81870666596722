import React from 'react'

const DayBanner = ({day, actions}) => {
    console.log("DayBanner(" + formattedDate(day.date) + ")")

    function weekday(date) {
        if (!date) return ''
        return new Intl.DateTimeFormat(undefined, {weekday: 'long'}).format(date.getTime())
    }

    function formattedDate(date) {
        if (!date) return ''
        return new Intl.DateTimeFormat(undefined, {
            month: "short",
            day: "numeric"
        }).format(date.getTime())
    }

    return (
        <div id='dayBanner'>
            <div className="title">{formattedDate(day.date)} {weekday(day.date)}</div>
            <div className="controls">
                {actions(day)}
            </div>
        </div>
    )
}
export default DayBanner