import {Accordion, AccordionContext, Card, useAccordionToggle} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import {useAssignOptionsQuery} from "./AssignOptionsGQL";
import {processAssignOptionsData} from "./AssignOptionsData";
import {useStoreActions, useStoreState} from "../../Store";


const AssignOptions = ({areaId, code, startTime, endTime, workMinutes, totalMinutes, workDate, label, pending}) => {

    /*const [showReactButton, setShowReactButton] = useState(false)
    const [showSwapButton, setShowSwapButton] = useState(false)
    const [openAccordian, setOpenAccordian] = useState(null)
    */

    const state = useStoreState()
    const actions = useStoreActions()

    const [{
        fetching: fetchingCD,
        data: dataCD,
        error: errorCD
    }] = useAssignOptionsQuery(areaId, code, startTime, endTime, workMinutes, totalMinutes, workDate, label, pending, state.assignAction.openAccordian, state.assignAction.count)

    const assignmentDetailAction = processAssignOptionsData(fetchingCD, dataCD, errorCD)

    function handleOpenAccordian() {
        actions.updateAssignAction({
            retractable: false,
            swapable: false,
            openAccordian: true,
            count: 1,
            retractSelected: false,
            swapListSelected: false,
            swapReviewSelected: false,
            swapNearbyWorkshiftsSelected: false
        })
    }

    function retractSelect() {
        console.log('retractSelect on click')
        actions.updateAssignActionWithRetractSelected(true)
    }

    function swapListSelect() {
        console.log('swapListSelect on click')
        actions.updateAssignActionWithSwapListSelected(true)
    }

    function CustomToggle({children, eventKey, callback, stateValue}) {
        const currentEventKey = React.useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );
        const isCurrentEventKey = currentEventKey === eventKey;

        if (isCurrentEventKey && assignmentDetailAction.openAccordian === false && assignmentDetailAction.count === 0) {
            stateValue()
        }
        return (
            <Button
                variant="primary"
                onClick={decoratedOnClick}
            >
                {isCurrentEventKey ? <i className="fas fa-chevron-up"/> : <i className="fas fa-chevron-down"/>}
                <span> Swap or Retract</span>
            </Button>
        );
    }

    if (!(state.assignAction.retractSelected === true) || !(state.assignAction.swapListSelected === true)) {

        return (<>
            <div>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <CustomToggle eventKey="0" stateValue={handleOpenAccordian}/>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className="btn-toolbar">
                                    <Button variant="secondary mr-2" disabled={!assignmentDetailAction.retractable}
                                            onClick={retractSelect}>
                                        Retract
                                    </Button>

                                    <Button variant="secondary" disabled={!assignmentDetailAction.swapable}
                                            onClick={swapListSelect}>
                                        Swap
                                    </Button>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </>)
    } else {
        return <></>
    }
}

export default AssignOptions;