export function putServerInfoInGlobalScope(data) {
    if (data && data.serverInfo) {
        window.serverInfo = data.serverInfo
        window.serverInfo.locale = window.serverInfo.locale.replace('_', '-')
        console.log("ServerInfo=" + JSON.stringify(window.serverInfo))
    }
}

export function getServerInfo() {
    if (window.serverInfo) {
        return window.serverInfo
    } else {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const locale = 'en-US'
        return {timezone: timezone, locale: locale}
    }
}


export function putParamsAtStartupInGlobalScope(data) {
    if (data && data.paramsAtStartup) {
        window.paramsAtStartup = data.paramsAtStartup
        console.log("paramsAtStartup=" + JSON.stringify(window.paramsAtStartup))
    }
}


export function getParamsAtStartup() {
    if (window.paramsAtStartup) {
        return window.paramsAtStartup
    } else {
        console.error("ParamsAtStartup has not been set...using default values")
        return {dateFormat: "MM/dd/yyyy",
            swapNearbyWarningThresholdInMinutes: "240",
            installPromptInterval: "14",
            nonWSMLicenseOnly:true,
            primaryEmailOnlyNotification:false
        }
    }
}



