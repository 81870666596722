import React, {useState} from 'react'
import Notice from "components/Notice"
import Spinner from "components/Spinner"
import ServiceStatus from "helpers/serviceStatus"
import SwapRetractForm from "./SwapRetractForm"
import {useRetractSwapProposalMutation} from "../SwapGQL"
import {makeRetractSwapProposalResultStatus} from "./SwapStatusData"

const SHOW = Object.freeze({FORM: 0, SPINNER: 1, NOTICE: 2, NONE: 3})

const SwapRetract = ({retractProposalId, close, refresh}) => {

    const [show, setShow] = useState(SHOW.FORM)
    const [serviceStatus, setServiceStatus] = useState({...ServiceStatus.UNKNOWN})

    const [retractSwapProposalResult, retractSwapProposal] = useRetractSwapProposalMutation()

    //TODO: other higher level components have "actions". We might need to remove those

    const actions = {
        retractProposal: () => {
            setShow(SHOW.SPINNER)
            let swapRetractInput = {
                proposalId: retractProposalId,

            }
            let variables = {"swapRetractInput": swapRetractInput}
            retractSwapProposal(variables).then(({fetching: fetchingASPM, data: dataASPM, error: errorASPM}) => {
                console.log("after retract swap proposal, data = " + JSON.stringify(dataASPM) + "; error = " + JSON.stringify(errorASPM))
                let retractSwapProposalStatus = makeRetractSwapProposalResultStatus(fetchingASPM, dataASPM, errorASPM)
                setServiceStatus(retractSwapProposalStatus)
                setShow(SHOW.NOTICE)
            })
        },

        cancel: () => {
            close()
        }
    }


    function handleNoticeResponse() {
        setShow(SHOW.NONE)
        //need to refresh status screen
        refresh()  //trigger a refetch of "swap proposals & requests status" via graphQL
    }


    switch (show) {
        case SHOW.NOTICE: {
            return <Notice message={serviceStatus.message} type={serviceStatus.noticeType}
                           onClose={handleNoticeResponse} show={true}/>
        }
        case SHOW.SPINNER: {
            return <Spinner show={true}/>
        }
        case SHOW.FORM: {
            return <SwapRetractForm actions={actions}/>
        }
        case SHOW.NONE:
        default: {
            return <></>
        }
    }


}


export default SwapRetract
