export const NetworkStatus = {
    ONLINE: {
        key: "online",
        title: "Services are available",
        message: "Scheduling services are now available.",
        online: true,
    },
    OFFLINE: {
        key: "offline",
        title: "NO INTERNET",
        message: "Your device does not have an internet connection.",
        online: false,
    },
    SERVICE_OFFLINE: {
        key: "service-offline",
        title: "SERVICES ARE UNAVAILABLE",
        message: "Scheduling services are currently unavailable. Try again later.",
        online: false,
    },
    SERVICE_LOCKOUT: {
        key: "service-lockout",
        title: "Services scheduled maintenance in progress",
        message: "Scheduled maintenance is being performed. Try again later.",
        online: false,
    }
}

export default NetworkStatus