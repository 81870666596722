import React from "react";
import {Cell, Row} from "@table-library/react-table-library/table";

/**
 * Render employee row with name cell followed by 28 day cells.
 *
 * @param employee { id, dcpos, ...}
 * @param dayAssignments [day1-assignments, ...(28)]
 * @param periodDays [{day: day, date: eachDate, dow: "Saturday", mmmd: "Mar 20", className: className},...(28)]
 * @param onEmployeeClick handler for employee name Cell click
 * @returns {JSX.Element}
 * @constructor
 */
function EmployeeRow({employee, dayAssignments, periodDays, onEmployeeClick, format}) {
    if (!employee || !dayAssignments) {
        console.error("EmployeeRow render attempt with no data")
        return <></>
    }

    function onCLick(employee) {
        if (onEmployeeClick) {
            onEmployeeClick(employee)
        }
    }

    return <Row className="employee">
        <Cell className="rowHeader" pinLeft onClick={() => onCLick(employee)}>
            <span className="dcpos">{employee.dcpos}</span>
            <span className="arrow">{onEmployeeClick ? <i className="fas fa-chevron-circle-right"/> : ""}</span>
            <div className="name">{employee.name.replaceAll(",", ",\u200b")}</div>
        </Cell>
        <DayCells dayAssignments={dayAssignments} periodDays={periodDays} formatAssignment={format.formatAssignment}/>
    </Row>
}

function DayCells({dayAssignments, periodDays, formatAssignment}) {
    return periodDays.map((periodDay, day) => (
        <Cell key={day} className={periodDay.className}>
            {dayAssignments[day] && dayAssignments[day].map((assignment, ndx) => (
                <div key={ndx} className={"assignment"}>
                    {formatAssignment(assignment)?.map((row, rndx) => (
                        <div key={rndx} className={row.classname}>{row.content}</div>
                    ))}
                </div>
            ))}
        </Cell>
    ))
}

export default EmployeeRow