import {buildSwapProposalAsRecipient, fillSwapRecipient} from "../SwapsAsRecipient/SwapsAsRecipientData"
import {stringToDate, stringToDateTime} from "helpers/date"
import {makeServiceStatus, ServiceStatus} from "../../../helpers/serviceStatus"


export const SWAPTYPE = Object.freeze({
    proposalAsRecipient: 0,
    proposalAsInitiator: 1,
    swapRequest: 2
})


export const SwapProposalOverallStatusEnum = Object.freeze({
    PROPOSED: 1,
    ACCEPTED: 2,
    RETRACTED: 3,
    NOTACCEPTED: 4
})


export const SwapProposalRecipientStatusEnum = Object.freeze({
    PROPOSED: 1,
    ACCEPTED: 2,
    DECLINED: 3,
    EXPIRED: 4,
    RETRACTED: 5
})

export const RequestStatusEnum = Object.freeze({
    PENDING: 1,
    APPROVED: 2,
    DENIED: 3,
    EXPIRED: 4,
    RETRACTED: 5
})

export const INITIATOR = "initiator"
export const RECIPIENT = "recipient"

export function processSwapProposalsAndRequestsResult(fetchingSRQ, dataSRQ, errorSRQ) {

    if (fetchingSRQ) {
        return {swapProposalsAndRequests: null}
    }
    if (errorSRQ) {
        const serviceStatus = makeServiceStatus(dataSRQ ? dataSRQ.proposedSwapProposals : null, errorSRQ)
        if (serviceStatus && !serviceStatus.success) {
            return {
                swapProposalsAndRequests: null,
                serviceStatus: serviceStatus
            }
        }
    }
    if (dataSRQ && dataSRQ.swapProposalsAndRequests) {

        //this is copied from "SwapStatusAction.java". Not that I like it, we just have to make it work consistently
        let max_initProposalId = 0
        let swapProposalsAndRequests = []

        //1. step 1: process Proposals As Recipient
        let swapProposalsAsRecipient = dataSRQ.swapProposalsAndRequests.swapProposalsAsRecipient
        if (swapProposalsAsRecipient) {
            swapProposalsAsRecipient.forEach((swapProposal) => {
                let singleProposal = buildSingleSwapProposalAsRecipient(swapProposal)
                swapProposalsAndRequests.push(singleProposal)
                if (singleProposal.initProposalId > max_initProposalId) {
                    max_initProposalId = singleProposal.initProposalId
                }
            })
        }

        //2. step 2: process Proposals As Initiator
        let swapProposalsAsInitiator = dataSRQ.swapProposalsAndRequests.swapProposalsAsInitiator
        if (swapProposalsAsInitiator) {
            swapProposalsAsInitiator.forEach((swapProposal) => {
                let singleProposal = buildSingleSwapProposalAsInitiator(swapProposal)
                // singleProposal.sortByDate = stringToDateTime(singleProposal.initWsFrom)
                swapProposalsAndRequests.push(singleProposal)
                if (singleProposal.initProposalId > max_initProposalId) {
                    max_initProposalId = singleProposal.initProposalId
                }
            })
        }

        //3. step 3: process requests
        let swapRequests = dataSRQ.swapProposalsAndRequests.swapRequests
        if (swapRequests) {
            swapRequests.forEach((swapRequest) => {
                max_initProposalId++ //swapRequest does not have an initProposal Id. We are making one up for it
                let singleRequest = buildSingleSwapRequest(swapRequest, max_initProposalId)
                swapProposalsAndRequests.push(singleRequest)
            })
        }

        //4. sort by the workshift from the viewing employee's perspective
        let sortedSwapProposalsAndRequests = sortSwapProposalsAndRequests(swapProposalsAndRequests)
        return {swapProposalsAndRequests: sortedSwapProposalsAndRequests}

    }

}

function sortSwapProposalsAndRequests(swapProposalsAndRequests) {
    return swapProposalsAndRequests.sort(function (a, b) {
        //1. First sort by date in ascending order
        let dateA = a.sortByDate.getTime()
        let dateB = b.sortByDate.getTime()
        if (dateA < dateB) return -1
        else if (dateA > dateB) return 1
        //2. Then sort by initProposalId in ascending order
        else {
            let proposalIdA = a.sortByProposalId
            let proposalIdB = b.sortByProposalId
            return proposalIdA < proposalIdB ? -1 : 1
        }
    });

}

export function fillSwapStatus(key) {
    let singleProposal = fillSwapRecipient(key)
    singleProposal.type = SWAPTYPE.proposalAsRecipient
    singleProposal.sortByDate = singleProposal.initWsFrom
    singleProposal.sortByProposalId = key
    singleProposal.divKey = key
    singleProposal.viewerStatus = 1
    singleProposal.partnerStatus = 1
    return singleProposal
}


function buildSingleSwapProposalAsRecipient(swapProposal) {
    let singleProposal = buildSwapProposalAsRecipient(swapProposal)
    singleProposal.type = SWAPTYPE.proposalAsRecipient
    singleProposal.sortByDate = stringToDateTime(singleProposal.recWsFrom)
    singleProposal.sortByProposalId = singleProposal.initProposalId
    singleProposal.divKey = singleProposal.recProposalId

    //"viewerStatus" is what the viewer employee will see in her end (recipient in this case).
    // "partnerStatus" is what the swap partner(initiator in this case) will see in her end
    let calculatedRecipientStatusId = findOverridingRecipientStatusId(singleProposal.recStatusId, singleProposal.overallStatusId)
    singleProposal.viewerStatus = getSwapProposalStatus(calculatedRecipientStatusId, true)
    singleProposal.partnerStatus = getSwapProposalStatus(singleProposal.overallStatusId, false)
    return singleProposal
}


function buildSingleSwapProposalAsInitiator(swapProposal) {

    //"viewerStatus" is what the viewer employee will see in her end (viewer is an initiator in this case) .
    // "partnerStatus" is what the swap partner(recipient in this case) will see in her end
    let proposal = {
        type: SWAPTYPE.proposalAsInitiator,
        sortByDate: stringToDateTime(swapProposal.initiator.swapInitiatorInfo.workshift.from),
        sortByProposalId: swapProposal.initiator.swapInitiatorInfo.proposalId,
        initProposalId: swapProposal.initiator.swapInitiatorInfo.proposalId,
        divKey: swapProposal.initiator.swapInitiatorInfo.proposalId,
        initNote: swapProposal.initiator.swapInitiatorInfo.note,
        initiateDate: stringToDateTime(swapProposal.initiator.initiateDate),
        overallStatusId: swapProposal.initiator.overallStatusId,
        viewerStatus: getSwapProposalStatus(swapProposal.initiator.overallStatusId, false),
        initWsCode: swapProposal.initiator.swapInitiatorInfo.workshift.code,
        initWorkdate: stringToDate(swapProposal.initiator.swapInitiatorInfo.workshift.date),
        initWsFrom: swapProposal.initiator.swapInitiatorInfo.workshift.from,
        initWsTo: swapProposal.initiator.swapInitiatorInfo.workshift.to,
        initWsWorkMinutes: swapProposal.initiator.swapInitiatorInfo.workshift.workMinutes,
        initWsBreakMinutes: swapProposal.initiator.swapInitiatorInfo.workshift.breakMinutes,
        initWsLabel: swapProposal.initiator.swapInitiatorInfo.workshift.wsLabel,
        initWsFormat: swapProposal.initiator.swapInitiatorInfo.workshift.wsFormat,
        initWsAreaId: swapProposal.initiator.swapInitiatorInfo.workshift.areaId,
    }
    let recipientsArr = []

    if (swapProposal.recipientWsAndEmployee && swapProposal.recipientWsAndEmployee.length > 0) {
        swapProposal.recipientWsAndEmployee.forEach((swapRecipient) => {
            let calculatedRecipientStatusId = findOverridingRecipientStatusId(swapRecipient.recipient.statusId, swapProposal.initiator.overallStatusId)
            //compose a js object as swap recipient
            let singleRecipient = {
                empName: swapRecipient.recipientEmployee.empName,
                dcpos: swapRecipient.recipientEmployee.dcpos,
                skill: swapRecipient.recipientEmployee.skill,
                jobCode: swapRecipient.recipientEmployee.jobCode,
                ansosId: swapRecipient.recipient.swapRecipientInfo.ansosId,
                recProposalId: swapRecipient.recipient.swapRecipientInfo.proposalId,
                recNote: swapRecipient.recipient.swapRecipientInfo.note,
                recWsCode: swapRecipient.recipient.swapRecipientInfo.workshift.code,
                recWorkdate: stringToDate(swapRecipient.recipient.swapRecipientInfo.workshift.date),
                recWsFrom: swapRecipient.recipient.swapRecipientInfo.workshift.from,
                recWsTo: swapRecipient.recipient.swapRecipientInfo.workshift.to,
                recWsWorkMinutes: swapRecipient.recipient.swapRecipientInfo.workshift.workMinutes,
                recWsBreakMinutes: swapRecipient.recipient.swapRecipientInfo.workshift.breakMinutes,
                recWsLabel: swapRecipient.recipient.swapRecipientInfo.workshift.wsLabel,
                recWsFormat: swapRecipient.recipient.swapRecipientInfo.workshift.wsFormat,
                recWsAreaId: swapRecipient.recipient.swapRecipientInfo.workshift.areaId,
                partnerStatus: getSwapProposalStatus(calculatedRecipientStatusId, true),
            }
            recipientsArr.push(singleRecipient)
        })
    }

    proposal.recipients = recipientsArr
    return proposal

}


function buildSingleSwapRequest(swapRequest, max_initProposalId) {

    //1. "webReqStatus" applies to both viewer and her partner.
    //   Once a swap proposal has become a request, an employee would see same status for herself and her partner (while SA might see different status)
    //2. Note: partner request contains "original assignment", and primary request contains "potential assignment"
    let request = {
        type: SWAPTYPE.swapRequest,
        sortByDate: stringToDateTime(swapRequest.partnerRequest.workshift.from),
        sortByProposalId: max_initProposalId,
        divKey: swapRequest.primaryRequest.webReqId,
        primaryReqId: swapRequest.primaryRequest.webReqId,
        primaryReqNote: swapRequest.primaryRequest.note,
        primaryReqSANote: swapRequest.primaryRequest.saResponseNote,
        isInitiator: swapRequest.primaryRequest.isInitiator,
        primaryReqWsCode: swapRequest.primaryRequest.workshift.code,
        primaryReqWorkdate: stringToDate(swapRequest.primaryRequest.workshift.date),
        primaryReqWsFrom: swapRequest.primaryRequest.workshift.from,
        primaryReqWsTo: swapRequest.primaryRequest.workshift.to,
        primaryReqWsWorkMinutes: swapRequest.primaryRequest.workshift.workMinutes,
        primaryReqWsBreakMinutes: swapRequest.primaryRequest.workshift.breakMinutes,
        primaryReqWsLabel: swapRequest.primaryRequest.workshift.wsLabel,
        primaryReqWsFormat: swapRequest.primaryRequest.workshift.wsFormat,
        primaryReqWsAreaId: swapRequest.primaryRequest.workshift.areaId,
        partnerEmpName: swapRequest.partnerEmployee.empName,
        partnerAnsosId: swapRequest.partnerEmployee.ansosId,
        partnerDcpos: swapRequest.partnerEmployee.dcpos,
        partnerSkill: swapRequest.partnerEmployee.skill,
        partnerJobCode: swapRequest.partnerEmployee.jobCode,
        partnerReqId: swapRequest.partnerRequest.webReqId,
        partnerReqNote: swapRequest.partnerRequest.note,
        partnerReqSANote: swapRequest.partnerRequest.saResponseNote,
        partnerReqWsCode: swapRequest.partnerRequest.workshift.code,
        partnerReqWorkdate: stringToDate(swapRequest.partnerRequest.workshift.date),
        partnerReqWsFrom: swapRequest.partnerRequest.workshift.from,
        partnerReqWsTo: swapRequest.partnerRequest.workshift.to,
        partnerReqWsWorkMinutes: swapRequest.partnerRequest.workshift.workMinutes,
        partnerReqWsBreakMinutes: swapRequest.partnerRequest.workshift.breakMinutes,
        partnerReqWsLabel: swapRequest.partnerRequest.workshift.wsLabel,
        partnerReqWsFormat: swapRequest.partnerRequest.workshift.wsFormat,
        partnerReqWsAreaId: swapRequest.partnerRequest.workshift.areaId,
        webReqStatus: getWebRequestStatus(swapRequest.primaryRequest.requestStatus, swapRequest.partnerRequest.requestStatus, swapRequest.primaryRequest.isInitiator),
    }
    return request
}


function getSwapProposalStatus(statusId, isRecipient) {
    if (statusId) {

        if (isRecipient) {
            if (statusId === SwapProposalRecipientStatusEnum.ACCEPTED) {
                return "Accepted"
            }
            if (statusId === SwapProposalRecipientStatusEnum.DECLINED) {
                return "Declined"
            }
            if (statusId === SwapProposalRecipientStatusEnum.EXPIRED) {
                return "Expired"
            }
            if (statusId === SwapProposalRecipientStatusEnum.RETRACTED) {
                return "Retracted"
            }
            return "Pending"
        }

        if (statusId === SwapProposalOverallStatusEnum.ACCEPTED) {
            return "Accepted"
        }
        if (statusId === SwapProposalOverallStatusEnum.RETRACTED) {
            return "Retracted"
        }
        if (statusId === SwapProposalOverallStatusEnum.NOTACCEPTED) {
            return "Not Accepted"
        }
        return "Proposed";
    }
    return "Pending";
}

function findOverridingRecipientStatusId(recipientStatusId, initiatorStatusId) {

    if (initiatorStatusId === SwapProposalOverallStatusEnum.RETRACTED) {
        return SwapProposalRecipientStatusEnum.RETRACTED
    }
    return recipientStatusId
}


function getWebRequestStatus(primaryReqStatusId, partnerReqStatusId, isPrimaryReqInitiator) {

    //Step 1. because swap partners may have different SA approvers, need to check for some lesser, not approved status
    let statusId = findOverridingRequestStatusId(primaryReqStatusId, partnerReqStatusId, isPrimaryReqInitiator)

    //Step 2. Now translate this number into a string
    if (statusId === RequestStatusEnum.APPROVED) {
        return "Approved";
    }
    if (statusId === RequestStatusEnum.DENIED) {
        return "Denied";
    }
    if (statusId === RequestStatusEnum.EXPIRED) {
        return "Expired";
    }
    if (statusId === RequestStatusEnum.RETRACTED) {
        return "Retracted";
    }
    if (statusId === RequestStatusEnum.PENDING) {
        return "Pending";
    }
    return "Pending";
}


function findOverridingRequestStatusId(primaryReqStatusId, partnerReqStatusId, isPrimaryReqInitiator) {
    let initiatorStatusId = isPrimaryReqInitiator ? primaryReqStatusId : partnerReqStatusId
    let recipientStatusId = isPrimaryReqInitiator ? partnerReqStatusId : primaryReqStatusId
    if (initiatorStatusId !== RequestStatusEnum.APPROVED) {
        return initiatorStatusId
    }
    return recipientStatusId
}

//style status string based on its text content
export function getStatuStyleClass(status) {
    let generalClass = "statusLabel"
    let statusClass = ""
    if (status === "Denied") {
        statusClass = "denied"
    } else if (status === "Declined") {
        statusClass = "declined"
    } else if (status === "Proposed") {
        statusClass = "proposed"
    } else if (status === "Pending") {
        statusClass = "pending"
    } else if (status === "Retracted") {
        statusClass = "retracted"
    } else if (status === "Accepted") {
        statusClass = "accepted"
    } else if (status === "Not Accepted") {
        statusClass = "notAccepted"
    } else if (status === "Expired") {
        statusClass = "expired"
    } else if (status === "Approved") {
        statusClass = "approved"
    } else {
        statusClass = "proposed"
    }
    return generalClass + " " + statusClass
}

export function makeRetractSwapProposalResultStatus(fetchingASPM, dataASPM, errorASPM) {
    if (errorASPM) {
        return makeServiceStatus(null, errorASPM)
    }
    if (dataASPM && dataASPM.retractSwapProposal) {
        return {...ServiceStatus[dataASPM.retractSwapProposal]}
    } else return ServiceStatus.UNKNOWN
}



