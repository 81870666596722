import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'



const SwapRetractForm  = ({actions}) => {

    const onRetractFormSubmit = () => {
        actions.retractProposal()
    }

    return (
        <div id='SwapRetractForm'>

            <Modal centered show={true} backdrop="static"  onHide={actions.cancel} >
                <Form>
                    <Modal.Header>
                        <Modal.Title>Retract Swap Proposal</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className={"retractMsg"}>Would you like to retract this swap proposal?</div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary"  value="Submit"  onClick={onRetractFormSubmit}>Retract</Button>
                        <Button variant="secondary" onClick={actions.cancel} >Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}


export default SwapRetractForm