import React from 'react';
import './AreaFormControl.css'
import Form from "react-bootstrap/Form";
import {createALLArea} from "helpers/area";
import {isOpen} from "../Swaps/InitiateProposal/swapInitiateProposalHelper";

const open = () => {
    return true
}

const show = (area) => {
    if (area.show !== undefined && area.show === false) { //(1)property "area.show" exists and has a value of false
        return false
    } else {//(2) Either property "area.show" has a value of "true", (3)or property "area.show" does NOT exist.
        return true
    }

}

const AreaFormControl = ({
                             name, areas, selectedArea = {id:'',name:''}, isOpen = open, onChange,
                             label = " (Not Open)",
                             className = "form-control",
                             addAllAreaOption = false
                         }) => {
    console.log("trace...AreaFormControl")

    let options = []
    let formControlClassName = "areaSelect"

    if (areas && areas.length > 1 && onChange) {
        options.push(<option key="-1" value="" disabled>Select an area</option>)

        if (addAllAreaOption) {
            const allArea = createALLArea()
            options.push(<option key="all" value={allArea.id}>{allArea.name}</option>)
        }

        areas.forEach((area, i) => {
            if (area && show(area)) {
                const optionClass = isOpen(area) ? "areaOpen" : "areaNotOpen"
                const text = area.id + " " + area.name + (!isOpen(area) ? label : "")
                options.push(<option className={optionClass} key={i} value={area.id}>{text}</option>)
            }
        })
    } else {
        formControlClassName += " readonly"
        const open = (areas && areas.length > 0)?isOpen(areas[0]):true
        const optionClass = open ? "areaOpen" : "areaNotOpen"
        const text = selectedArea.id + " " + selectedArea.name + (!open ? label : "")
        options.push(<option className={optionClass} key={0} value={selectedArea.id}>{text}</option>)
    }

    console.log("AreaFormControl: selectedArea=" + JSON.stringify(selectedArea))

    return (
        // One issue being reported is that -- at re-render, the newer dropdown does not reflect the changed "defaultValue". Reason:
        // "Changing the value of defaultValue attribute after a component has mounted will not cause any update of the value in the DOM."
        // "rerender means calling render for all components, it does not mean React will unmount and remount them. It will only apply the differences following the rules stated in the previous sections."
        // https://reactjs.org/docs/uncontrolled-components.html
        // https://reactjs.org/docs/reconciliation.html
        // So defaultValue is only for the initial load, and it may not get modified at subsequent re-render. To get around this, technique "key" is employed here
        <div key={selectedArea.id} className="areaScheduleSelect">
            <Form.Control className={formControlClassName}
                          as="select" name={name}
                          defaultValue={selectedArea.id}
                          bsPrefix={className}
                          onChange={e => onChange(e.target.value)}>{options}</Form.Control>
        </div>
    )
}

export default AreaFormControl