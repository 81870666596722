import React from "react";
import {formatWorkshift} from 'helpers/workshift'
import {stringToDate, weekdayAndDayOfMonth, minutesToDurationInHours} from "helpers/date"
import {translateFormat} from "./swapInitiateProposalHelper"


const SwapInitAssignmentControl =({swapAssignment}) => {
    const assignment = {
        id: swapAssignment.id,
        code: swapAssignment.code,
        areaId: swapAssignment.areaId,
        areaName: swapAssignment.areaName,
        date: stringToDate(swapAssignment.date),
        from: swapAssignment.from,
        to: swapAssignment.to,
        workMinutes: swapAssignment.workMinutes,
        totalMinutes:swapAssignment.totalMinutes,
        label: swapAssignment.label,
        format: translateFormat(swapAssignment.format)

    }


    const wstext = formatWorkshift(assignment)

    return (
        <div className="swapInitShift">
            <div className="swapLeftLabel boldFont">
                Shift to Swap
            </div>

            <div className="swapInitDetail">
                <div>
                    <span className="boldFont">&nbsp;{weekdayAndDayOfMonth(assignment.date, 0)}</span>
                    <br/>
                    &nbsp;{wstext}&nbsp;({minutesToDurationInHours(assignment.workMinutes)}hrs)
                    <br/>
                    <span className="swapInitEmployee">
                              &nbsp;<span className="boldFont">Area:</span> {assignment.areaId}&nbsp;{assignment.areaName}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SwapInitAssignmentControl

