import setMinutes from "date-fns/setMinutes"
import setHours from "date-fns/setHours"


//This file contains a collection of utility methods used by date picker widget in "User Profile"
//The datetime picker widget we used("React-DatePicker" 3.8) has a dependency on date-fns
//That is why the time manipulation methods here are sitting on top of date-fns.

/**
 * Set the hour and minutes to the given date and return the new date
 * Ex: if date is 4/26/2022 10:05, then hhmm is "1230", then this method would return
 * a JS date object representing  4/26/2022 12:30
 * @param date
 * @param hhmm
 * @returns {string|Date} JS date object
 */
export function parseDateFromHHMM(date, hhmm) {
    if (hhmm) {
        return setMinutes(setHours(date, hhmm.substring(0, 2)), hhmm.substring(2))
    } else {
        return ""
    }
}


/**
 * Set the hour and minutes to the given date and return the new date
 * Ex: if date is 4/26/2022 10:05, then timestr is "12:30", then this method would return
 * a JS date object representing  4/26/2022 12:30
 * @param date
 * @param timeStr   it takes the format of hh:mm
 * @returns {string|Date} JS date object
 */
export function parseDateFromHHColonMM(date, timeStr) {
    if (timeStr) {
        //Now with an input mask, the only invalid char is "_"
        if (timeStr.includes("_")) {
            timeStr = timeStr.replaceAll('_', '0')
        }
        return setMinutes(setHours(date, timeStr.split(":")[0]), timeStr.split(":")[1])
    } else {
        return ""
    }
}


/**
 * Format a datetime to "HHmm"
 * Ex: if date is 4/26/2022 10:05, then this method would return "1005"
 * @param datetime
 * @returns {string}
 */
export function formatDateTimeToHHMM(datetime){
    if(datetime) {
        const {format} = require('date-fns')
        return format(datetime, 'HHmm')
    }
    else return ""

}