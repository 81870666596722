import gql from 'graphql-tag'
import {useMutation, useQuery} from 'urql'

const INIT_EMP_SCHD_REC_AREA_QUERY = gql`
query swapInitEmpSchAndRecAreaList ($initiatorWorkDate: Date!, $initiatorWorkAreaId: AreaId!) {
    swapInitEmpSchAndRecAreaList (initiatorWorkDate: $initiatorWorkDate, initiatorWorkAreaId: $initiatorWorkAreaId) {
        initEmpPeriodAssignmentsAndRequests {
            code
            from
            to
            workMinutes
            totalMinutes
            areaId
            name
            label
            date
            team
            task
            home
            work
            order
            format
            pending
            id
        }
        recAreaList {
            area {
                name
                id
            }
            open
            participating
            id
        }
        initEmpCompSkillSet
        initEmpPrimarySkill
        daysFromTodayForMaxDate
        daysFromTodayForMinDate
        selectedRecAreaId
        id
    }
}
`


const SWAP_REC_ASSIGNMENTS_QUERY = gql`
query swapRecipientAssignments ($swapRecipientAssignmentsInput: SwapRecipientAssignmentsInput!) {
    swapRecipientAssignments (swapRecipientAssignmentsInput: $swapRecipientAssignmentsInput) {
        swapEmployee {
            empName
            ansosId
            dcpos
            skill
            jobCode
            id
        }
        code
        wsCodeId
        from
        to
        workMinutes
        breakMinutes
        areaId
        wsLabel
        date
        timeSort
        overtime
        wsFormat
        overlap
        id
    }
}
`

const INITIATE_SWAP_PROPOSAL_MUTATION =gql`
mutation initiateSwapProposal ($swapInitiateProposalInput: SwapInitiateProposalInput!) {
    initiateSwapProposal (swapInitiateProposalInput: $swapInitiateProposalInput)
}
`


const PROPOSED_SWAP_PROPOSALS_QUERY = gql`
query proposedSwapProposals {
    proposedSwapProposals {
        initiator {
            swapInitiatorInfo {
                proposalId
                note
                workshift {                  
                    code
                    wsLabel
                    startTimeSort
                    workMinutes
                    breakMinutes
                    from
                    to
                    areaId
                    wsFormat
                    date
                    id
                }
                id
            }
            initiateDate
            overallStatusId
            id
        }
        recipient {
            swapRecipientInfo {
                proposalId
                note
                workshift {
                    code
                    wsLabel
                    startTimeSort
                    workMinutes
                    breakMinutes
                    from
                    to
                    areaId
                    wsFormat
                    date
                    id
                }
                id
            }
            statusId
            id
        }
        initiatingEmployee {
            empName
            ansosId
            dcpos
            skill
            jobCode
            id
        }
        initiatorAssignmentChanged
        recipientAssignmentChanged
        recipientOverTime
        id
    }
}
`

export const HAS_SWAP_PROPOSALS_QUERY = gql`
	query hasSwapProposals {
		hasSwapProposals
	}
`


export const ACCEPT_SWAP_PROPOSAL_MUTATION = gql`
mutation acceptSwapProposal ($swapAcceptDeclineInput: ProcessSwapProposalInput!) {
    acceptSwapProposal (swapAcceptDeclineInput: $swapAcceptDeclineInput)
}
`

const DECLINE_SWAP_PROPOSAL_MUTATION =gql`
mutation declineSwapProposal ($swapAcceptDeclineInput: ProcessSwapProposalInput!) {
    declineSwapProposal (swapAcceptDeclineInput: $swapAcceptDeclineInput)
}
`

const RETRACT_SWAP_PROPOSAL_MUTATION =gql`
mutation retractSwapProposal ($swapRetractInput: ProcessSwapProposalInput!) {
    retractSwapProposal (swapRetractInput: $swapRetractInput)
}
`


const SWAP_PROPOSALS_REQUESTS_QUERY = gql`
query swapProposalsAndRequests {
    swapProposalsAndRequests {
        swapProposalsAsRecipient {
            initiator {
                swapInitiatorInfo {
                    proposalId
                    ansosId
                    note
                    workshift {
                        code
                        wsLabel     
                        workMinutes
                        breakMinutes
                        from
                        to
                        areaId
                        wsFormat
                        date
                        id
                    }
                    id
                }
                initiateDate
                overallStatusId
                id
            }
            recipient {
                swapRecipientInfo {
                    proposalId
                    ansosId
                    note
                    workshift {                      
                        code
                        wsLabel
                        workMinutes
                        breakMinutes
                        from
                        to
                        areaId
                        wsFormat
                        date
                        id
                    }
                     id
                }
                statusId
                id
            }
            initiatingEmployee {
                empName
                ansosId
                dcpos
                skill
                jobCode
                id
            }
            id
        }
        swapProposalsAsInitiator {
            initiator {
                swapInitiatorInfo {
                    proposalId
                    ansosId
                    note
                    workshift {                     
                        code
                        wsLabel
                        workMinutes
                        breakMinutes
                        from
                        to
                        areaId
                        wsFormat
                        date
                        id
                    }
                    id
                }
                initiateDate
                overallStatusId
                id
            }
            recipientWsAndEmployee {
                recipientEmployee {
                    empName
                    dcpos
                    skill
                    jobCode
                    id
                }
                recipient {
                    swapRecipientInfo {
                        proposalId
                        ansosId
                        note
                        workshift {                          
                            code
                            wsLabel                            
                            workMinutes
                            breakMinutes
                            from
                            to
                            areaId
                            wsFormat
                            date
                            id
                        }
                        id
                    }
                    statusId
                    id
                }
                id
            }
             id
        }
        swapRequests {
            primaryRequest {
                webReqId
                note
                requestStatus
                saResponseNote
                isInitiator
                workshift {              
                    code
                    wsLabel
                    workMinutes
                    breakMinutes
                    from
                    to
                    areaId
                    wsFormat
                    date
                    id
                }
                id
            }
            partnerRequest {
                webReqId
                ansosId
                note
                requestStatus
                saResponseNote
                workshift {               
                    code
                    wsLabel
                    workMinutes
                    breakMinutes
                    from
                    to
                    areaId
                    wsFormat
                    date
                    id
                }
                id
            }
            partnerEmployee {
                empName
                ansosId
                dcpos
                skill
                jobCode
                id
            }
            id
        }
        id
    }
}
`




export function useSwapInitEmpSchAndRecAreaQuery(initiatorWorkDate, initiatorWorkAreaId) {
    console.log("useSwapInitEmpSchAndRecAreaQuery(" + initiatorWorkDate + "," + initiatorWorkAreaId + ") ; pause = " + !initiatorWorkDate || !initiatorWorkAreaId)
    return useQuery({
        query: INIT_EMP_SCHD_REC_AREA_QUERY ,
        variables: {initiatorWorkDate, initiatorWorkAreaId},
        pause: !initiatorWorkDate || !initiatorWorkAreaId,
        requestPolicy: "network-only"
    })
}



export function useSwapRecAssignmentsQuery(swapInput) {
    console.log(`useSwapRecAssignmentsQuery(swapInput: ${JSON.stringify(swapInput)}); pause = ${!swapInput.swapRecipientAssignmentsInput.recipientAreaId || !swapInput.swapRecipientAssignmentsInput.primarySkill}`)
    return useQuery({
        query: SWAP_REC_ASSIGNMENTS_QUERY,
        variables: swapInput,
        pause: (!swapInput.swapRecipientAssignmentsInput.recipientAreaId|| !swapInput.swapRecipientAssignmentsInput.primarySkill),
        requestPolicy: "network-only"
    })
}


export function useInitiateSwapProposalMutation() {
    return useMutation(INITIATE_SWAP_PROPOSAL_MUTATION)
}

export function useProposedSwapProposalsQuery() {
    console.log('useProposedSwapProposalsQuery...')
    return useQuery({
        query: PROPOSED_SWAP_PROPOSALS_QUERY,
        variables: {},
        requestPolicy: "network-only"
    })
}

    
export function useAcceptSwapProposalMutation() {
    return useMutation(ACCEPT_SWAP_PROPOSAL_MUTATION)
}



export function useDeclineSwapProposalMutation() {
    return useMutation(DECLINE_SWAP_PROPOSAL_MUTATION)
}


export function useSwapProposalsRequestsQuery() {
    console.log('useSwapProposalsRequestsQuery...')
    return useQuery({
        query: SWAP_PROPOSALS_REQUESTS_QUERY,
        variables: {},
        requestPolicy: "network-only"
    })
}




export function useRetractSwapProposalMutation() {
    return useMutation(RETRACT_SWAP_PROPOSAL_MUTATION)
}


export function useHasSwapProposalsQuery() {
    console.log('useHasSwapProposalsQuery...')
    return useQuery({
        query: HAS_SWAP_PROPOSALS_QUERY,
        variables: {},
        requestPolicy: "cache-and-network"
    })
}



