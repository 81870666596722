import React from 'react'
import {ROLE} from "components/Role";
import Form from 'react-bootstrap/Form'
import {useFormContext} from 'react-hook-form'


const SelectDefaultRoleForm = ({data: userProfileData}) => {

    const { register } = useFormContext();

    const ifEmpDefaultRole = () => {
        let myDefaultRole = userProfileData.defaultRole
        return (ROLE[myDefaultRole] === ROLE.EMP)
    }

    const ifSADefaultRole = () => {
        let myDefaultRole = userProfileData.defaultRole
        return (ROLE[myDefaultRole] === ROLE.SA)
    }

    return (

            <Form.Group>
                <fieldset className="fieldsetClass">

                    {/*<Form.Label className="formLabel">Default Role</Form.Label>*/}
                    <legend className="formLabel">Default Role</legend>

                    <label className="hsRadio">
                        <input type="radio"
                               name='defaultRole'
                               defaultChecked = {ifSADefaultRole()}
                               value='SA'
                               ref={register}

                        />
                        <span className="radioCheckMark"> </span>
                        <span className="hsCheckboxLabel">Scheduling Administrator</span>
                    </label>
                    
                    <label className="hsRadio">
                        <input type="radio"
                               name='defaultRole'
                               defaultChecked = {ifEmpDefaultRole()}
                               value='EMP'
                               ref={register}

                        />
                        <span className="radioCheckMark"> </span>
                        <span className="hsCheckboxLabel">Employee</span>
                    </label>


                </fieldset>
            </Form.Group>
    )


}


export default SelectDefaultRoleForm