import gql from 'graphql-tag'
import {useQuery} from 'urql'

export const AREA_SCHEDULE_QUERY = gql`
query areaSchedule ($areaId: AreaId!, $periodStartDate: Date!,$role:UserRole!) {
    areaSchedule (areaId: $areaId, periodStartDate: $periodStartDate, role: $role) {
        areaId id from to notice
        periods {from id to reset published balancing} 
        areas {name show id}
        skills {name id}
        schEmpTotals {
            skill id
            schCounts {minutes id emps date}
            schEmps {
                employee {name id dcpos jobClass skillCode email phone}
                assignments {code id from to areaId name label date team task home work order format pending type status} 
                id
            }
        }
    }
}`

export function useAreaScheduleQuery(areaId, periodStartDate, role) {//console.log("useAreaScheduleQuery(" + areaId + "," + periodStartDate)
    return useQuery({
        query: AREA_SCHEDULE_QUERY,
        variables: {areaId, periodStartDate, role},
        pause: !areaId || !periodStartDate || !role,
        requestPolicy: "cache-and-network"
    })
}