import {makeServiceStatus, ServiceStatus} from "helpers/serviceStatus";
import {stringToDate, stringToDateTime} from "helpers/date"

export function processHasSwapProposalsResult(fetching, data, error) {

    if (fetching) {
        return {hasSwapProposals: false}
    }
    if (error) {
        const serviceStatus = makeServiceStatus(data ? data.hasSwapProposals : null, error)
        if (serviceStatus && !serviceStatus.success) {
            return {
                hasSwapProposals: false,
                serviceStatus: serviceStatus
            }
        }
    }
    if (data && data.hasSwapProposals) {
        return {hasSwapProposals: data.hasSwapProposals}
    } else {
        return {hasSwapProposals: false}
    }
}

export function processProposedSwapProposalsResult(fetchingSRQ, dataSRQ, errorSRQ) {

    if (fetchingSRQ) {
        return {proposedSwapProposals: null}
    }
    if (errorSRQ) {
        const serviceStatus = makeServiceStatus(dataSRQ ? dataSRQ.proposedSwapProposals : null, errorSRQ)
        if (serviceStatus && !serviceStatus.success) {
            return {
                proposedSwapProposals: null,
                serviceStatus: serviceStatus
            }
        }
    }
    if (dataSRQ && dataSRQ.proposedSwapProposals) {
        let proposedSwapProposals = []
        dataSRQ.proposedSwapProposals.forEach((swapProposal) => {
            let singleProposal = buildSwapProposalAsRecipient(swapProposal)
            proposedSwapProposals.push(singleProposal)
        })
        return {proposedSwapProposals: proposedSwapProposals}

    }

}

export function buildSwapProposalAsRecipient(swapProposal) {

    let proposal = {
        initEmpName: swapProposal.initiatingEmployee.empName,
        initAnsosId: swapProposal.initiatingEmployee.ansosId,
        initDcpos: swapProposal.initiatingEmployee.dcpos,
        initSkill: swapProposal.initiatingEmployee.skill,
        initJobCode: swapProposal.initiatingEmployee.jobCode,
        initProposalId: swapProposal.initiator.swapInitiatorInfo.proposalId,
        initNote: swapProposal.initiator.swapInitiatorInfo.note,
        initiateDate: stringToDateTime(swapProposal.initiator.initiateDate),
        overallStatusId: swapProposal.initiator.overallStatusId,
        initWsCode: swapProposal.initiator.swapInitiatorInfo.workshift.code,
        initWorkdate: stringToDate(swapProposal.initiator.swapInitiatorInfo.workshift.date),
        initWsFrom: swapProposal.initiator.swapInitiatorInfo.workshift.from,
        initWsTo: swapProposal.initiator.swapInitiatorInfo.workshift.to,
        initWsWorkMinutes: swapProposal.initiator.swapInitiatorInfo.workshift.workMinutes,
        initWsBreakMinutes: swapProposal.initiator.swapInitiatorInfo.workshift.breakMinutes,
        initWsLabel: swapProposal.initiator.swapInitiatorInfo.workshift.wsLabel,
        initWsAreaId: swapProposal.initiator.swapInitiatorInfo.workshift.areaId,
        initWsFormat: swapProposal.initiator.swapInitiatorInfo.workshift.wsFormat,
        initWsStartTimeSort: swapProposal.initiator.swapInitiatorInfo.workshift.startTimeSort,
        recProposalId: swapProposal.recipient.swapRecipientInfo.proposalId,
        recNote: swapProposal.recipient.swapRecipientInfo.note,
        recStatusId: swapProposal.recipient.statusId,
        recWsCode: swapProposal.recipient.swapRecipientInfo.workshift.code,
        recWorkdate: stringToDate(swapProposal.recipient.swapRecipientInfo.workshift.date),
        recWsFrom: swapProposal.recipient.swapRecipientInfo.workshift.from,
        recWsTo: swapProposal.recipient.swapRecipientInfo.workshift.to,
        recWsWorkMinutes: swapProposal.recipient.swapRecipientInfo.workshift.workMinutes,
        recWsBreakMinutes: swapProposal.recipient.swapRecipientInfo.workshift.breakMinutes,
        recWsLabel: swapProposal.recipient.swapRecipientInfo.workshift.wsLabel,
        recWsAreaId: swapProposal.recipient.swapRecipientInfo.workshift.areaId,
        recWsFormat: swapProposal.recipient.swapRecipientInfo.workshift.wsFormat,
        recWsStartTimeSort: swapProposal.recipient.swapRecipientInfo.workshift.startTimeSort,
        initiatorAssignmentChanged: swapProposal.initiatorAssignmentChanged,
        recipientAssignmentChanged: swapProposal.recipientAssignmentChanged,
        recipientOverTime: swapProposal.recipientOverTime,

    }
    return proposal
}


export function makeDeclineSwapProposalResultStatus(fetchingDSPM, dataDSPM, errorDSPM) {
    if (errorDSPM) {
        let serviceStatus = makeServiceStatus(null, errorDSPM)
        return serviceStatus
    }
    if (dataDSPM && dataDSPM.declineSwapProposal) {
        return ServiceStatus.SUCCESS_SWAP_PROPOSAL_DECLINED
    } else return ServiceStatus.UNKNOWN
}


export function makeAcceptSwapProposalResultStatus(fetchingASPM, dataASPM, errorASPM) {
    if (errorASPM) {
        return makeServiceStatus(null, errorASPM)
    }
    if (dataASPM && dataASPM.acceptSwapProposal) {
        return {...ServiceStatus[dataASPM.acceptSwapProposal]}
    } else return ServiceStatus.UNKNOWN
}


export function fillSwapRecipient(key) {
    //JS new Date() is using client browser's local time. But we are just creating some filler data
    //and this would not cause any potential timezone issue
    const initDate = new Date()
    const workDate = new Date()

    const startTime = new Date();
    startTime.setHours(12)
    const endTime = new Date();
    endTime.setHours(13)

    let swapProposalAsRecipient = {
        initEmpName: "John Doe",
        initDcpos: ".....",
        initSkill: "",
        initJobCode: "",
        initProposalId: key,
        initNote: "",
        initiateDate: initDate,
        overallStatusId: 1,
        initWsCode: "A",
        initWorkdate: workDate,
        initWsFrom: startTime.toISOString(),
        initWsTo: endTime.toISOString(),
        initWsWorkMinutes: 60,
        initWsBreakMinutes: 10,
        initWsLabel: "",
        initWsAreaId: "..",
        initWsFormat: 1,
        recProposalId: key,
        recNote: "",
        recStatusId: 1,
        recWsCode: "A",
        recWorkdate: workDate,
        recWsFrom: startTime.toISOString(),
        recWsTo: endTime.toISOString(),
        recWsWorkMinutes: 60,
        recWsBreakMinutes: 0,
        recWsLabel: "",
        recWsAreaId: "..",
        recWsFormat: 1,
        initiatorAssignmentChanged: false,
        recipientAssignmentChanged: false,
        recipientOverTime: false,

    }
    return swapProposalAsRecipient
}

