import React from 'react'
import Launch from "components/Launch";

const LaunchView = ({layout, user, actions}) => {

    let body = <Launch user={user} actions={actions}/>

    return (layout({body, error: null}))
}

export default LaunchView