import {makeServiceStatus} from "helpers/serviceStatus";

function emptyNotifications() {
    return []
}

export function processNotificationsData(fetching, data, error) {
    if (error) {
        const serviceStatus = makeServiceStatus(data ? data.notifications : null, error)
        if (serviceStatus && !serviceStatus.success) {
            return {requestForm: emptyNotifications(), serviceStatus: serviceStatus}
        }
    }
    if (data && data.notifications.notifications) {

        let assignmentMapByDay = new Map()
        data.notifications.assignments.forEach((singleAssignment) => {
            let workDateStr = singleAssignment.date

            if(assignmentMapByDay.has(workDateStr)){
                assignmentMapByDay.get(workDateStr).push(singleAssignment)
            }else{
                let assignmentsArr = []
                assignmentsArr.push(singleAssignment)
                assignmentMapByDay.set(workDateStr, assignmentsArr)
            }

        })

        return {notifications: data.notifications.notifications, assignmentMapByDay : assignmentMapByDay}
    } else {
        return {notifications: emptyNotifications()}
    }
}

export function getNotification(notifications, id) {
    return notifications.find((notification) => notification.id === id)
}

export function fillNotification(key) {
    const workDate = new Date()
    const startTime = new Date();
    startTime.setHours(12)
    const endTime = new Date();
    endTime.setHours(13)
    const notification = {
        "areaId": "..",
        "areaName": "Loading...",
        "workshift": {
            "on": workDate.toISOString(),
            "from": startTime.toISOString(),
            "to": endTime.toISOString(),
            "code": "L",
            "name": "12PM-1PM",
            "label": "lunch",
            "category": "",
            "work": true,
            "skill": "",
            "format": 2,
            "id": key
        },
        "id": key
    }
    //console.log(JSON.stringify(notification))
    return notification
}