import React from 'react'
import Banner from "components/Banner"
import './View.css'
import Spinner from "../Spinner";

/**
 * Render a splash screen that can be rendered quickly without any
 * data fetches to slow it down.
 * @returns {JSX.Element}
 */
const SplashView = () => {
    return (
        <div id="splashView">
            <Banner/>
            <Spinner show={true} variant="danger"/>
        </div>
    )
}

export default SplashView